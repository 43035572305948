import { useMutation } from 'react-query'
import { accessoryUseCase } from '../../factories'

const useCreateAccessory = () => {
  const {
    mutate: createAccessory, isLoading, data, error,
  } = useMutation(accessoryUseCase.createAccessory)

  return {
    createAccessory,
    isLoading,
    error,
    data,
  }
}

export default useCreateAccessory
