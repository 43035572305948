import React from 'react'
import {
  Flex,
  Box,
} from '@chakra-ui/react'
import Icon from '../Icon'
import IconButton from '../IconButton'

const Incrementer = ({
  title,
  color,
  score,
  setScore,
  plus = true,
}) => {
  const plusIncrement = () => {
    if (Object.values(score).reduce((total, currentScore) => total + currentScore, 0) === 100) return
    const newTitle = title.toLowerCase().split(' ')[0]
    const newObject = score
    newObject[newTitle] += 5
    return setScore({
      ...score,
      ...newObject,
    })
  }

  const minusIncrement = () => {
    const newTitle = title.toLowerCase().split(' ')[0]
    const newObject = score
    if (newObject[newTitle] === 0) return
    newObject[newTitle] -= 5
    return setScore({
      ...score,
      ...newObject,
    })
  }

  return (
    <Flex
      bg="white.100"
      alignItems="center"
      justifyContent="space-between"
      height="57px"
      width="370px"
      pl={2}
      pr={6}
      borderRadius="10px"
      boxShadow="2px 4px 10px rgba(0, 0, 0, 0.25)">
      <Flex alignItems="center">
        <Box
          mr={4}
          height="57px"
          width="12px"
          bg={color}/>
        <Box
          fontSize="xl"
          fontFamily='Titillium Web, sans-serif'
          fontWeight="600"
          as="span">
          {title}
        </Box>
      </Flex>
      <Flex alignItems="center">
        {
          plus && (
            <>
              <IconButton
                onClick={plusIncrement}
                height="unset"
                minW="30px"
                bg="transparent"
                _active={{
                  bg: 'transparent',
                }}
                _hover={{
                  bg: 'transparent',
                }}
                aria-label="plus"
                icon={<Icon type="plus"/>}/>
              <IconButton
                onClick={minusIncrement}
                ml={4}
                height="unset"
                minW="30px"
                bg="transparent"
                _active={{
                  bg: 'transparent',
                }}
                _hover={{
                  bg: 'transparent',
                }}
                aria-label="minus"
                icon={<Icon type="minus"/>}/>
            </>
          )
        }
        <Box
          ml={8}
          minW="45px"
          fontWeight="700"
          fontSize="xl"
          color="grey.200"
          as="span">
          {score[title.toLowerCase().split(' ')[0]]}
          %
        </Box>
      </Flex>
    </Flex>
  )
}

export default Incrementer
