import {css} from '@emotion/react'

const styles = {
    dashboardView: css`
      & > .chakra-popover__popper:first-of-type {
        inset: -20px auto auto 0 !important;
        z-index: 1;
      }
      & > .chakra-button:first-of-type {
        z-index: 9;
      }
    `,
    searchButton: css`
      font-size: 16px;
      width: 100%;
      padding-left: 10px;
      padding-right: 0;
      transition: opacity 0.3s;
      font-family: Titillium Web, serif;
      justify-content: start;
      background-color: transparent;
      color: black;
      height: 30px;

      &:hover {
        background-color: #D9D9D9;
      }
      &:hover > .icon {
        opacity: 1;
      }
    `,
    card: css`
      height: 200px;
      width: 326px;
      box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.25);
      background-color: #EDEDED;
      border-radius: 10px;
      overflow: hidden;
    `,
    image: css`
      background: #fff;
      height: 101px;
      width: 100%;
      //object-fit: cover;
    `,
    info: css`
      padding: 13px;
      background-color: transparent;
    `,
    checkboxReu: css`
      .css-1r69mki {
        display: none;
        stroke-width: 0;
      }

      &.css-1isk33i[aria-checked=true]:hover, .css-1isk33i[data-checked]:hover, .css-1isk33i[aria-checked=true][data-hover], .css-1isk33i[data-checked][data-hover] {
        background: #C956FF;
      }

      & > .css-1isk33i[aria-checked=true], .css-1isk33i[data-checked] {
        background: #C956FF;
        color: #C956FF;
      }

      & > span:nth-of-type(1) {
        height: 10px;
        width: 10px;
        border: 1px solid black;
      }
    `,
    paginator: css`
      & > div {
        display: flex;
        justify-content: center;
      }
    `,
    checkboxSu: css`
      .css-1r69mki {
        display: none;
        stroke-width: 0;
      }

      &.css-1isk33i[aria-checked=true]:hover, .css-1isk33i[data-checked]:hover, .css-1isk33i[aria-checked=true][data-hover], .css-1isk33i[data-checked][data-hover] {
        background: #FFD339;
      }

      & > .css-1isk33i[aria-checked=true], .css-1isk33i[data-checked] {
        background: #FFD339;
        color: #FFD339;
      }

      & > span:nth-of-type(1) {
        height: 10px;
        width: 10px;
        border: 1px solid black;
      }
    `,
    checkboxEu: css`
      .css-1r69mki {
        display: none;
        stroke-width: 0;
      }

      &.css-1isk33i[aria-checked=true]:hover, .css-1isk33i[data-checked]:hover, .css-1isk33i[aria-checked=true][data-hover], .css-1isk33i[data-checked][data-hover] {
        background: #FF8660;
      }

      & > .css-1isk33i[aria-checked=true], .css-1isk33i[data-checked] {
        background: #FF8660;
        color: #FF8660;
      }

      & > span:nth-of-type(1) {
        height: 10px;
        width: 10px;
        border: 1px solid black;
      }
    `,
    checkbox: css`
      .css-1r69mki {
        display: none;
        stroke-width: 0;
      }

      &.css-1isk33i[aria-checked=true]:hover, .css-1isk33i[data-checked]:hover, .css-1isk33i[aria-checked=true][data-hover], .css-1isk33i[data-checked][data-hover] {
        background: #8FCF74;
      }

      & > .css-1isk33i[aria-checked=true], .css-1isk33i[data-checked] {
        background: #8FCF74;
        color: #8FCF74;
      }

      & > span:nth-of-type(1) {
        margin-top: 3px;
        height: 9px;
        width: 9px;
        border: 1px solid black;
      }
      `,
    checkboxDashboard: css`
      &.css-1isk33i[aria-checked=true]:hover, .css-1isk33i[data-checked]:hover, .css-1isk33i[aria-checked=true][data-hover], .css-1isk33i[data-checked][data-hover] {
        background-color: transparent;
        border-color: #1C1C1C;
      }

      & > .css-1isk33i[aria-checked=true], .css-1isk33i[data-checked] {
        color: black;
      }

      & > span:nth-of-type(1) {
        background-color: transparent;
        height: 15px;
        width: 15px;
        border: 1px solid #1C1C1C;
      }
    `,
}

export default styles
