import {css} from "@emotion/react";

const styles = {
    select: css`
      border: 1px solid #A9A9A9;

      & > option {
        background-color: #ECECEC;
      }
    `,
    calendarContainer: css`
      .react-datepicker {
        padding: 15px;
      }

      .css-k008qs {
        justify-content: space-between;
        width: 320px;
      }

      .css-1s3bnvm {
        padding: 10px 0 0 0;
        width: 320px;
      }

      .react-datepicker__header {
        background-color: white;
        border: none;
        padding: 0;
      }

      .react-datepicker__month-container {
        width: 322px;
        padding: 0;
      }

      .react-datepicker__month {
        font-size: 18px;
        border: 1px solid black;
        border-radius: 6px;
        margin-top: 0;
      }

      .react-datepicker__day-names {
        display: none;
      }

      .react-datepicker__day {
        width: 44px;
        border: 1px solid #A9A9A9;
        margin: 0;
        opacity: 0.9;
      }

      .react-datepicker__day--outside-month {
        color: #A9A9A9;
      }

      .react-datepicker__day--in-range {
        background-color: #C1DBFF;
        color: black;
      }

      .react-datepicker__day--keyboard-selected {
        background-color: #C1DBFF;
        color: black;
      }
    `,
    toast: css`
      animation-name: scroll;
      animation-duration: 4s;
      animation-timing-function: linear;
      @keyframes scroll {
        0% {
          left: 5px;
        }
        100% {
          left: 100%;
        }
      }
    `,
    menu: css `
      & > div {
        width: 100%;
      }
    `,

}
export default styles