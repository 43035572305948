import HttpRequest from "./http-request";

const productService = () => {
    const {post} = HttpRequest()
    const createProduct = (input) => {
        return post('new-product', input)
    }
    const updateProduct = (input) => {
        return post('update-product', input)
    }
    const getProduct = (id) => {
        return post('get-product', id)
    }
    const listProducts = (variables) => {
        return post('product-list', variables)

    }
    const getRecomended = (variables) => {
        return post('get-recommended', variables)
    }

    const getCustomBids = (variables) => {
        return post('get-custom-bids', variables)
    }

    const updatePriceBid = (input) => {
        return post('update-price-bid', input)
    }

    return {
        updatePriceBid,
        getCustomBids,
        getRecomended,
        createProduct,
        updateProduct,
        getProduct,
        listProducts,
    }
}

export default productService
