import {TabPanel, Tab, Tabs, TabList, TabPanels,} from '@chakra-ui/react'
import EuOverview from './Tabs/EuOverview'
import EuUploadsBid from "./Tabs/EuUploadsBid";
import EuEstimate from "./Tabs/EuEstimate";

const EuTabs = ({project, reports}) => (
    <Tabs minW="900px" m="auto" maxW="1240px">
        <TabList mx="-1px" border="none" pt={5} bg="white.100">
            <Tab fontSize='18px' fontFamily='Titillium Web' minW="201px" fontWeight="700" _active={{background: 'transparent',}} _selected={{color: 'green.200', borderColor: 'green.200', borderBottomWidth: '3px', boxShadow: 'none',}} color="green.200">
                Overview
            </Tab>
            <Tab fontSize='18px' fontFamily='Titillium Web' minW="201px" fontWeight="700" _active={{background: 'transparent',}} _selected={{color: 'green.200', borderColor: 'green.200', borderBottomWidth: '3px', boxShadow: 'none',}} color="green.200">
                Uploads
            </Tab>
            <Tab
                display={project?.estimateEu && project?.estimateEu?.length > 0 ? 'block' : 'none'}
                fontSize='18px' fontFamily='Titillium Web' minW="201px" fontWeight="700" _active={{background: 'transparent',}} _selected={{color: 'green.200', borderColor: 'green.200', borderBottomWidth: '3px', boxShadow: 'none',}} color="green.200">
                Estimate
            </Tab>
        </TabList>
        <TabPanels bg="#f5f5f5" py={10} px={8} border="1px solid #DBDADA" borderBottom="none">
            <TabPanel m="auto" maxW="1160px" px={0}>
                <EuOverview project={project} reports={reports}/>
            </TabPanel>
            <TabPanel m="auto" maxW="1160px" px={0}>
                <EuUploadsBid/>
            </TabPanel>
            <TabPanel m="auto" maxW="1160px" px={0}>
                <EuEstimate estimate={project?.estimateEu && project?.estimateEu?.length > 0 ? project?.estimateEu[0] : []}/>
            </TabPanel>
        </TabPanels>
    </Tabs>
)

export default EuTabs
