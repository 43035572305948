import React from 'react'
import {Flex, Box, Image, Grid, GridItem,} from '@chakra-ui/react'
import {S3_BUCKET,} from '../../constants'
import {InputLabel, TextareaLabel} from '../../components/Theme'

const EuProperty = ({register, image}) => (
    <>
        <Flex>
            <Box mr={12}>
                <Flex bg="white.100" alignItems="center" justifyContent="center" overflow="hidden" border="1px solid" width="263px" height="127px">
                    <Image heigh="100%" width={!!image ? "100%" : "40%"} src={!!image ? `${S3_BUCKET}${image}` : ' '} objectFit="cover"/>
                </Flex>
            </Box>
            <Flex justifyContent="space-between" flexDirection="column" w="100%">
                <Grid templateColumns="repeat(2, 4fr)" gap={6}>
                    <GridItem colSpan={1}>
                        <InputLabel disabled bg='#A9A9A9 !important' {...register('title')} label="Project Title:"/>
                    </GridItem>
                    <GridItem colSpan={1}>
                        <InputLabel disabled bg='#A9A9A9 !important' {...register('propertyType')} label="Property Type:"/>
                    </GridItem>
                    <GridItem colSpan={1}>
                        <InputLabel disabled type="number" bg='#A9A9A9 !important' {...register('propertySize')} label="Property Size (sq.ft)"/>
                    </GridItem>
                    <GridItem colSpan={1}>
                        <InputLabel disabled bg='#A9A9A9 !important' {...register('complienceHyperlink')} label="Compliance Hyperlink (If Applicable):"/>
                    </GridItem>
                </Grid>
            </Flex>
        </Flex>
        <TextareaLabel disabled bg='#A9A9A9 !important' label="Notes" {...register('notes', {required: false})}/>
    </>
)

export default EuProperty
