import React, {useEffect, useState} from "react";
import {
    Box, Button,
    Flex,
    FormControl, FormLabel,
    Modal,
    ModalBody,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Text, Textarea, useToast,
} from "@chakra-ui/react";
import IconButton from "../../components/Theme/IconButton";
import Icon from "../../components/Theme/Icon";
import httpRequest from "../../core/services/http-request";
import {Input, InputLabel, TextareaLabel} from "../../components/Theme";
import InputMask from "react-input-mask";
import {ReactComponent as Send} from "../../assets/send.svg";
import styles from "./styles";
import moment from "moment";
import ToastComponent from "../../components/toastComponent/ToastComponent";
import {ReactComponent as SendMessage} from "../../assets/cloudMail.svg";

const NewMessageModal = ({isOpen, onClose, message, onCloseOld, getUse}) => {
    const {post} = httpRequest()
    const [loading, setLoading] = useState(false)
    const [newMessage, setNewMessage] = useState('')
    const toast = useToast()

    const sendMessage = () => {
        let data = {
            notif: message?.notification[0]?.id,
            receiver: message?.userId,
            contact: message?.id,
            message: newMessage,
            compId: message?.notification[0]?.company?.id,
        }
        setLoading(true)
        post('new-message', data).then(() => {
            toast({
                render: () => (
                    <ToastComponent icon={<SendMessage/>} title='Message Sent!' bg='#8FCF74'/>
                ),
                duration: 4000,
                isClosable: true,
                position: "top-right"
            })
            getUse()
            setLoading(false)
            onCloseOld()
            onClose()
        })
            .catch(() => setLoading(false))
    }

    return (
        <Modal isCentered isOpen={isOpen} onClose={onClose}>
            <ModalOverlay/>
            <ModalContent overflow="hidden" borderRadius="10px" minW="848px">
                <ModalHeader bg='#8FCF74' fontWeight="700" fontSize="24px" textAlign="center">
                    Create Message
                </ModalHeader>
                <IconButton height="unset" onClick={onClose} _hover={{bg: 'none',}} _active={{bg: 'none',}} bg="none" aria-label="close button" position="absolute" top="1.5rem" right="0.75rem" icon={<Icon type="darkClose"/>}/>
                <ModalBody p='40px' overflow="scroll" maxH="600px" css={styles.messages2}>
                    <Flex margin="auto" alignItems="center" flexDirection="column">
                        <Flex w="100%" justifyContent='space-between'>
                            <InputLabel maxW='364px' value={message?.name} readOnly label="Name:"/>
                            <InputLabel maxW='364px' value={moment().format('MM.DD.YYYY')} readOnly label="Date:"/>
                        </Flex>
                        <Flex mt={3} w="100%" justifyContent='space-between'>
                            <InputLabel maxW='364px' value={message?.phone} readOnly label="Phone Number:"/>
                            <InputLabel maxW='364px' value={message?.email} readOnly label="Email:"/>
                        </Flex>
                        <InputLabel mt={3} mb={3} value={message?.subject}  readOnly label="Subject:"/>
                        <TextareaLabel minH='200px' onChange={(e) => setNewMessage(e.target.value)} label="Message:"/>
                        <Button  type="submit" mt={3} width="254px" onClick={() => sendMessage()} isLoading={loading} variant="blue" size="lg">
                            Send
                        </Button>
                    </Flex>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}
export default NewMessageModal