import {css} from '@emotion/react'

const styles = {
    paginator: css`
      & > div {
        display: flex;
        justify-content: center;
      }
    `,
    select: css`
      border: 1px solid rgba(169, 169, 169, 0.87);
      border-radius: 5px;
      font-size: inherit;
      background: #fff;

      &::placeholder {
        color: #a9a9a9;
        opacity: 1;
      }

      &:-ms-input-placeholder {
        color: #a9a9a9;
      }

      &::-ms-input-placeholder {
        color: #a9a9a9;
      }

      &:focus {
        box-shadow: none;
        outline: none;
        border-color: unset;
      }
    `,
    checkboxReu: css`
      .css-1r69mki {
        display: none;
        stroke-width: 0;
      }
      &.css-1isk33i[aria-checked=true]:hover, .css-1isk33i[data-checked]:hover, .css-1isk33i[aria-checked=true][data-hover], .css-1isk33i[data-checked][data-hover] {
        background: #C956FF;
        box-shadow: none;
      }

      & > .css-1isk33i[aria-checked=true], .css-1isk33i[data-checked] {
        background: #C956FF;
        box-shadow: none;
        color: #C956FF;
      }

      & > span:nth-of-type(1) {
        height: 10px;
        width: 10px;
        border: 1px solid black;
      }

    `,
    checkboxGeneral: css`
      .css-1r69mki {
        display: none;
        stroke-width: 0;
      }
      &.css-1isk33i[aria-checked=true]:hover, .css-1isk33i[data-checked]:hover, .css-1isk33i[aria-checked=true][data-hover], .css-1isk33i[data-checked][data-hover] {
        background: #193C5B;
        box-shadow: none;
      }

      & > .css-1isk33i[aria-checked=true], .css-1isk33i[data-checked] {
        background: #193C5B;
        box-shadow: none;
        color: #193C5B;
      }

      & > span:nth-of-type(1) {
        height: 10px;
        width: 10px;
        border: 1px solid black;
      }

    `,
    checkboxSu: css`
      .css-1r69mki {
        display: none;
        stroke-width: 0;
      }

      &.css-1isk33i[aria-checked=true]:hover, .css-1isk33i[data-checked]:hover, .css-1isk33i[aria-checked=true][data-hover], .css-1isk33i[data-checked][data-hover] {
        background: #FFD339;
        box-shadow: none;
      }

      & > .css-1isk33i[aria-checked=true], .css-1isk33i[data-checked] {
        background: #FFD339;
        color: #FFD339;
        box-shadow: none;
      }

      & > span:nth-of-type(1) {
        height: 10px;
        width: 10px;
        border: 1px solid black;
      }
    `,
    checkboxEu: css`
      .css-1r69mki {
        display: none;
        stroke-width: 0;
      }

      &.css-1isk33i[aria-checked=true]:hover, .css-1isk33i[data-checked]:hover, .css-1isk33i[aria-checked=true][data-hover], .css-1isk33i[data-checked][data-hover] {
        background: #FF8660;
        box-shadow: none;
      }

      & > .css-1isk33i[aria-checked=true], .css-1isk33i[data-checked] {
        background: #FF8660;
        color: #FF8660;
        box-shadow: none;
      }

      & > span:nth-of-type(1) {
        height: 10px;
        width: 10px;
        border: 1px solid black;
      } `,
    checkboxAu: css`
      .css-1r69mki {
        display: none;
        stroke-width: 0;
      }

      &.css-1isk33i[aria-checked=true]:hover, .css-1isk33i[data-checked]:hover, .css-1isk33i[aria-checked=true][data-hover], .css-1isk33i[data-checked][data-hover] {
        background: #ACDAFF;
        box-shadow: none;
      }

      & > .css-1isk33i[aria-checked=true], .css-1isk33i[data-checked] {
        background: #ACDAFF;
        color: #ACDAFF;
        box-shadow: none;
      }

      & > span:nth-of-type(1) {
        height: 10px;
        width: 10px;
        border: 1px solid black;
      } `,
    checkboxSa: css`
      .css-1r69mki {
        display: none;
        stroke-width: 0;
      }

      &.css-1isk33i[aria-checked=true]:hover, .css-1isk33i[data-checked]:hover, .css-1isk33i[aria-checked=true][data-hover], .css-1isk33i[data-checked][data-hover] {
        background: #A6F484;
        box-shadow: none;
      }

      & > .css-1isk33i[aria-checked=true], .css-1isk33i[data-checked] {
        background: #A6F484;
        color: #A6F484;
        box-shadow: none;
      }

      & > span:nth-of-type(1) {
        height: 10px;
        width: 10px;
        border: 1px solid black;
      }
    `,
    checkbox: css`
      .css-1r69mki {
        display: none;
        stroke-width: 0;
      }

      &.css-1isk33i[aria-checked=true]:hover, .css-1isk33i[data-checked]:hover, .css-1isk33i[aria-checked=true][data-hover], .css-1isk33i[data-checked][data-hover] {
        background: #8FCF74;
      }

      & > .css-1isk33i[aria-checked=true], .css-1isk33i[data-checked] {
        background: #8FCF74;
        color: #8FCF74;
      }

    `,
    usersColors: css`
      width: 12px;
      height: 12px;
      margin-left: 10px;
      border-radius: 50%;
      border: 1px solid black;
    `,
    toast: css`
      animation-name: scroll;
      animation-duration: 4s;
      animation-timing-function: linear;
      @keyframes scroll {
        0% {
          left: 5px;
        }
        100% {
          left: 100%;
        }
      }
    `,
}

export default styles
