import {Box, Button, Flex, Heading, Text} from "@chakra-ui/react";
import moment from "moment";
import {ReactComponent as LeftArrow} from "../../../assets/leftIconShipping.svg";
import {ReactComponent as SearchIcon} from "../../../assets/searchWhite.svg";
import {ReactComponent as PlusIcon} from "../../../assets/plusIcon.svg";
import {ReactComponent as DownloadPdf} from "../../../assets/downloadIconWhite.svg";
import {ReactComponent as DoneIcon} from "../../../assets/doneIcon.svg";
import {ReactComponent as CreateOrderIcon} from "../../../assets/penIcon.svg";
import {ReactComponent as ShippingIcon} from "../../../assets/shippingIcon.svg";
import {ReactComponent as ReceiptIcon} from "../../../assets/bidAcceptedIcon.svg";
import {ReactComponent as EditDetailsIcon} from "../../../assets/edit.svg";
import {ReactComponent as ShareEstimate} from "../../../assets/users.svg";
import {ReactComponent as ArchiveIcon} from "../../../assets/archiveIcon.svg";
import {SU, SUPERADMIN} from "../../../constants/user/userEnum";


const Header = ({
                    shippingDetails,
                    error,
                    archiveOrder,
                    edit,
                    editShippingDetail,
                    onOpen,
                    tabIndex,
                    createShippingDetail,
                    estimate,
                    shippingPageStatus,
                    trackId,
                    handleImage,
                    inputRef,
                    setShippingPageStatus,
                    setEdit,
                    setTrackId,
                    loading,
                    user,
                    loadingPdf,
                    downloadPDF,
                    createInvoice,
                    onOpenDelivered,
                    createShipping,
                    createOrder,
                    createReceipt,
                    markFulfilled,
                }) => (
    <Box
        display={tabIndex === 5 || tabIndex === 6 ? 'none' : 'block'}
        m="auto"
        minW="1240px"
        maxW="1240px"
        border="1px solid"
        borderColor="#DBDADA"
        bg="#f5f5f5"
        w="100%"
        p={8}>
        <Flex justifyContent='space-between'>
            <Flex flexDirection='column'>
                <Heading mt={0} mb={3}>
                    {
                        tabIndex === 0 ? (
                            'Estimate #' + estimate?.reference
                        ) : tabIndex === 1 ? (
                            'Order #' + estimate?.reference
                        ) : tabIndex === 2 && shippingPageStatus !== 3 ? (
                            'Shipping Overview #' + estimate?.reference
                        ) : tabIndex === 2 && shippingPageStatus === 3 ? (
                            'Shipping Details #' + trackId
                        ) : tabIndex === 3 || tabIndex === 4 ? (
                            'Invoice #' + estimate?.reference
                        ) : ('')
                    }
                </Heading>
                <Flex>
                    <Text fontWeight='700' fontSize='14px'>Proposal :</Text>
                    <Text fontSize='14px'>&nbsp;{estimate?.report?.proposalName}</Text>
                </Flex>
                <Flex>
                    <Text fontWeight='700' fontSize='14px'>Date :</Text>
                    <Text fontSize='14px'>
                        &nbsp;{
                        tabIndex === 0 ? (
                            moment(estimate?.createdAt?.date).format('MM/DD/YYYY')
                        ) : tabIndex === 1 ? (
                            moment(estimate?.orderDateCreated?.date).format('MM/DD/YYYY')
                        ) : tabIndex === 2 ? (
                            moment(estimate?.shippingDateCreated?.date).format('MM/DD/YYYY')
                        ) : tabIndex === 3 ? (
                            moment(estimate?.invoiceDateCreated?.date).format('MM/DD/YYYY')
                        ) : tabIndex === 4 ? (
                            moment(estimate?.receiptDateCreated?.date).format('MM/DD/YYYY')
                        ) : ''
                    }
                    </Text>
                </Flex>
                <Flex>
                    <Text fontWeight='700' fontSize='14px'>Generated By :</Text>
                    <Text
                        fontSize='14px'>&nbsp;{estimate?.userAdminConsultant?.firstName + ' ' + estimate?.userAdminConsultant?.lastName}
                    </Text>
                </Flex>
            </Flex>
            <Flex flexDirection='column'>
                <Flex>
                    <input onChange={handleImage} type="file" multiple style={{display: 'none'}} ref={inputRef}/>
                    <Button mr={4}
                            leftIcon={
                                shippingPageStatus === 3 && tabIndex === 2 ? <LeftArrow/> :
                                    tabIndex === 3 || tabIndex === 4 ? <SearchIcon/>
                                        : <PlusIcon/>}
                            onClick={() => {
                                if (estimate?.archived) return
                                if (shippingPageStatus === 3 && tabIndex === 2) {
                                    setShippingPageStatus(1)
                                    setEdit(false)
                                    setTrackId(null)
                                } else if ((tabIndex === 3 || tabIndex === 4) /*&& estimate?.isInvoice*/) {
                                    return inputRef.current.click()
                                } else {
                                    if (!estimate?.isInvoice) {
                                        setShippingPageStatus(2)
                                    }
                                }
                            }}
                            display={
                                tabIndex === 2 && shippingPageStatus !== 2 && !edit ? 'flex' :
                                    edit ? 'none' :
                                        tabIndex === 3 || tabIndex === 4 ? 'flex' :
                                            'none'}
                            color={(shippingPageStatus === 3 && tabIndex === 2) || tabIndex === 3 || tabIndex === 4 ? 'white' : 'black'}
                            isLoading={loading}
                            alignItems='center'
                            backgroundColor={
                                tabIndex === 3 || tabIndex === 4 ? '#2F68D9' :
                                    shippingPageStatus === 3 && tabIndex === 2 ? '#353535'
                                        : '#FF9A7A'}
                            width='150px'>
                        {
                            shippingPageStatus === 3 && tabIndex === 2 ? (
                                'Shipping Overview'
                            ) : tabIndex === 3 || tabIndex === 4 ? (
                                'Browse For Files'
                            ) : (
                                'Create Detail'
                            )
                        }
                    </Button>
                    <Button
                        isLoading={loadingPdf}
                        onClick={() => {
                            if (tabIndex === 0 || tabIndex === 1 || tabIndex === 2) {
                                return downloadPDF()
                            }
                        }}
                        display={
                            tabIndex === 2 && shippingPageStatus === 2 && !edit ? 'none' :
                                edit ? 'none'
                                    : 'flex'}
                        width='150px'
                        leftIcon={<DownloadPdf/>}>
                        Download PDF
                    </Button>
                    <Button
                        display={tabIndex === 2 && shippingPageStatus === 2 && !edit ? 'flex' :
                            edit ? 'flex' :
                                'none'}
                        width='135px'
                        isLoading={loading}
                        leftIcon={<DoneIcon/>}
                        color='black'
                        backgroundColor='#C2EF62'
                        onClick={() => {
                            if (estimate?.archived) return
                            const filter = shippingDetails.filter(item => item.isChecked)
                            if (trackId && edit) {
                                return editShippingDetail(trackId, filter)
                            } else {
                                return createShippingDetail(filter)
                            }
                        }}>
                        Done
                    </Button>
                    <Button
                        leftIcon={
                            tabIndex === 0 ? <CreateOrderIcon/> :
                                tabIndex === 1 ? <ShippingIcon/> :
                                    tabIndex === 2 ? <ShippingIcon/> :
                                        tabIndex === 3 || tabIndex === 4 ? <ReceiptIcon/>
                                            : ''
                        }
                        ml={4}
                        display={
                            user?.type === SU ? 'none' :
                                tabIndex === 2 && shippingPageStatus === 2 && !edit ? 'none' :
                                    tabIndex === 2 && shippingPageStatus === 1 && !edit && user?.type === SU ? 'none' :
                                        tabIndex === 2 && shippingPageStatus === 3 && !edit && user?.type === SU ? 'none' :
                                            edit ? 'none' :
                                                'flex'}
                        isLoading={loading}
                        onClick={
                            tabIndex === 2 && shippingPageStatus !== 3 ? createInvoice :
                                tabIndex === 2 && shippingPageStatus === 3 && !estimate?.archived && user?.type === SUPERADMIN ? onOpenDelivered :
                                    tabIndex === 1 && !estimate?.archived ? createShipping :
                                        tabIndex === 0 && !estimate?.archived ? createOrder :
                                            tabIndex === 3 && !estimate?.archived ? createReceipt :
                                                tabIndex === 4 && !estimate?.archived ? markFulfilled
                                                    : ''}
                        color='black'
                        backgroundColor={
                            tabIndex === 0 ? '#8FCF74' :
                                tabIndex === 1 ? '#FF9A7A'
                                    : '#8FCF74'}
                        width='150px'>
                        {
                            tabIndex === 1 ? (
                                'Create Shipping'
                            ) : tabIndex === 2 ? (
                                'Mark as Delivered!'
                            ) : tabIndex === 3 ? (
                                'Mark as Paid!'
                            ) : tabIndex === 4 ? (
                                'Mark Fulfilled!'
                            ) : ('Create Order')
                        }
                    </Button>
                </Flex>
                <Flex justifyContent='end'>
                    <Button mt={3} mr={4}
                            leftIcon={<EditDetailsIcon width='12px' height='12px'/>}
                            onClick={() => !estimate?.archived && !estimate.isInvoice ? setEdit(true) : ''}
                            display={
                                tabIndex === 2 && shippingPageStatus === 3 && !edit ? 'flex' :
                                    edit ? 'none' :
                                        'none'}
                            color='black'
                            isLoading={loading}
                            alignItems='center'
                            backgroundColor='#FF9A7A'
                            width='150px'>
                        Edit Details
                    </Button>
                    <Button
                        display={
                            tabIndex === 2 && shippingPageStatus === 2 && !edit ? 'none' :
                                edit ? 'none' :
                                    'flex'}
                        onClick={onOpen}
                        leftIcon={<ShareEstimate/>}
                        mt={3}
                        width='150px'
                        backgroundColor='#FFE68D'
                        color='black'>
                        {
                            tabIndex === 0 ? 'Share Estimate' :
                                tabIndex === 1 ? 'Share Order' :
                                    tabIndex === 2 ? 'Share Shipping' :
                                        tabIndex === 3 ? 'Share Invoice' :
                                            tabIndex === 4 ? 'Share Receipt' : ''
                        }
                    </Button>
                    <Button
                        display={
                            user?.type === SU ? 'none' :
                                tabIndex === 2 && shippingPageStatus === 2 && !edit ? 'none' :
                                    edit ? 'none' :
                                        'flex'}
                        mt={3}
                        ml={4}
                        isLoading={loading}
                        onClick={archiveOrder}
                        leftIcon={<ArchiveIcon/>}
                        backgroundColor='white'
                        width='150px'
                        border='2px solid #5F5F5F'
                        color='#5F5F5F'>
                        Archive
                    </Button>
                </Flex>
            </Flex>
        </Flex>
        {error && (<Text mt={5} textAlign='center' color="red.100" fontSize="m">{error}</Text>)}
    </Box>
)

export default Header