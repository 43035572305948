import axios from "axios";

function HttpRequest() {
    const post = (url, model = {}) => {
        let config = {
            headers: {'Content-Type': 'application/json'},
        };
        let token = localStorage.getItem('token');
        if (token)
            config.headers["Authorization"] = `Bearer ${token}`
        return  axios({method: "post", url: `${process.env.REACT_APP_API_ENDPOINT}api/${url}`, data: model, headers: config.headers})
    }

    const postPdf = (url, model = {}) => {
        let config = {
            headers: {'Content-Type': 'application/json'},
        };
        let token = localStorage.getItem('token');
        if (token)
            config.headers["Authorization"] = `Bearer ${token}`
        return  axios({method: "post", url: `${process.env.REACT_APP_API_ENDPOINT}api/${url}`,responseType: "arraybuffer", data: model, headers: config.headers})
    }

    const get = (url) => {
        let config = {
            headers: {'Content-Type': 'application/json'},
        };
        let token = localStorage.getItem('token');
        if (token)
            config.headers["Authorization"] = `Bearer ${token}`
        return  axios({method: "get", url: `${process.env.REACT_APP_API_ENDPOINT}api/${url}`, headers: config.headers})
    }

    const getImage = (url) => {
        let config = {
            headers: {'Content-Type': 'application/json'},
        };
        let token = localStorage.getItem('token');
        if (token)
            config.headers["Authorization"] = `Bearer ${token}`
        return  axios({method: "get", url: `${process.env.REACT_APP_API_ENDPOINT}${url}`, headers: config.headers})
    }

    const put = (url, model = {}) => {
        let config = {
            headers: {'Content-Type': 'application/json'},
        };
        let token = localStorage.getItem('token');
        if (token)
            config.headers["Authorization"] = `Bearer ${token}`
        return  axios({method: "put", url: `${process.env.REACT_APP_API_ENDPOINT}api/${url}`, data: model, headers: config.headers})
    }

    return {
        post,
        getImage,
        put,
        get,
        postPdf
    }
}


export default HttpRequest
