import { css } from '@emotion/react'

const styles = {
  menu: css`
        & > div:nth-of-type(2) {
            width: 100%;
        }
    `,
}

export default styles
