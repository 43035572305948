const dataUseCase = (dataRepo) => {
    const getUsageMain = () => dataRepo.getUsageMain()

    const getUsage = (type) => {
        if (!type)
            throw Error('Type is required')
        return dataRepo.getUsage(type)
    }

    const getDataWithType = (type) => {
        if (!type)
            throw Error('Type is required')
        return dataRepo.getDataWithType(type)
    }

    return {
        getUsageMain,
        getUsage,
        getDataWithType,
    }
}

export default dataUseCase
