import { Checkbox as ChakraCheckbox } from '@chakra-ui/react'
import styled from '@emotion/styled'

const Checkbox = styled(ChakraCheckbox)`
    & > span{
        box-shadow: 0 0 0 0 rgb(0, 0, 0) !important;
    }

`

export default Checkbox
