import React, {useState} from 'react'
import Fixture from './Fixture'
import Lamp from './Lamp'
import Power from './Power'
import Control from './Control'
import Accessory from './Accessory'
import Recent from './Recent'
import {InviteModal} from '../../../components/Theme'

const LuminairesModals = ({idx, allAreas,register, getValues, watch, description, type, fixture, setFixture, lamp, setLamp, power, setPower, control, setControl, accessory, setAccessory, onClose, isOpen, recent, setRecent, application, setApplication, setArea, area, luminaireId, setType, luminaire}) => {
    const [createKey, setCreateKey] = useState(false)
    return (
        <>
            <Recent
                allAreas={allAreas}
                area={area}
                setArea={setArea}
                recent={recent}
                setRecent={setRecent}
                type={type}
                getValues={getValues}
                luminaireId={luminaireId}
                onClose={onClose}
                isOpen={isOpen}/>
            <InviteModal
                maxHBody='900px'
                px='50px'
                py='40px'
                isOpen={type !== 'recent' && isOpen}
                onClose={() => {
                    if (type === 'fixture' && createKey === false) {
                        setCreateKey(true)
                    } else if (type === 'lamp' && createKey === false) {
                        setCreateKey(true)
                    } else if (type === 'power-supply' && createKey === false) {
                        setCreateKey(true)
                    } else if (type === 'control' && createKey === false) {
                        setCreateKey(true)
                    } else if (type === 'accessory' && createKey === false) {
                        setCreateKey(true)
                    }
                }}
                bg="yellow.100"
                title={
                    type === 'fixture' ? 'Luminaire Details - Fixture'
                        : type === 'lamp' ? 'Luminaire Details - Lamp'
                            : type === 'power-supply' ? 'Luminaire Details - Power Supply'
                                : type === 'control' ? 'Luminaire Details - Control' : 'Luminaire Details - Accessories'}>
                {
                    type === 'fixture' ? (
                        <Fixture
                            idx={idx}
                            getValues={getValues}
                            watch={watch}
                            register={register}
                            application={application}
                            setApplication={setApplication}
                            fixture={fixture}
                            setFixture={setFixture}
                            description={description}
                            setType={setType}
                            luminaireId={luminaireId}
                            luminaire={luminaire}
                            createKey={createKey}
                            setCreateKey={setCreateKey}
                            area={area}
                            setArea={setArea}
                            recent={recent}
                            setRecent={setRecent}
                            type={type}
                            onClose={onClose}
                            isOpen={isOpen}/>
                    ) : type === 'lamp' ? (
                        <Lamp
                            luminaire={luminaire}
                            createKey={createKey}
                            lamp={lamp}
                            setLamp={setLamp}
                            setCreateKey={setCreateKey}
                            description={description}
                            setType={setType}
                            luminaireId={luminaireId}
                            area={area}
                            setArea={setArea}
                            setRecent={setRecent}
                            onClose={onClose}/>
                    ) : type === 'power-supply' ? (
                        <Power
                            createKey={createKey}
                            power={power}
                            setPower={setPower}
                            setCreateKey={setCreateKey}
                            description={description}
                            setType={setType}
                            luminaireId={luminaireId}
                            area={area}
                            setArea={setArea}
                            setRecent={setRecent}
                            onClose={onClose}/>
                    ) : type === 'control' ? (
                        <Control
                            createKey={createKey}
                            control={control}
                            setControl={setControl}
                            setCreateKey={setCreateKey}
                            description={description}
                            setType={setType}
                            luminaireId={luminaireId}
                            setRecent={setRecent}
                            area={area}
                            setArea={setArea}
                            onClose={onClose}/>
                    ) : type === 'accessory' ? (
                        <Accessory
                            accessory={accessory}
                            setAccessory={setAccessory}
                            createKey={createKey}
                            setCreateKey={setCreateKey}
                            setArea={setArea}
                            setRecent={setRecent}
                            area={area}
                            luminaire={luminaire}
                            luminaireId={luminaireId}
                            description={description}
                            setType={setType}
                            onClose={onClose}/>
                    ) : ('')
                }
            </InviteModal>
        </>
    )
}
export default LuminairesModals
