import React, {useEffect, useState} from 'react'
import {
    Text,
    Flex, Heading,
} from '@chakra-ui/react'

const Done = ({hearFromUs}) => {
    const [active, setActive] = useState(hearFromUs.current)

    const handleActive = (title) => {
        if (title === active) {
            return setActive('')
        }
        setActive(title)
    }

    useEffect(() => {
        hearFromUs.current = active
    }, [active, hearFromUs])

    return (
        <>
            <Heading
                mt={5}
                as="h2"
                textAlign="center"
                fontSize="4xl">
                How often would you like to hear from us?
            </Heading>
            <Flex
                width="730px"
                alignItems="center"
                justifyContent="space-between"
                my={14}>
                <Flex
                    cursor="pointer"
                    onClick={() => handleActive('monthly')}
                    alignItems="center">
                    <Flex
                        px={4}
                        py={3}
                        mr={12}
                        flexWrap="wrap"
                        alignItems="center"
                        justifyContent="center"
                        flexDirection="column"
                        border={active === 'monthly' ? '4px solid #222222' : 'none'}
                        boxShadow={active === 'monthly' ? 'inset 0px 0px 4px 4px rgba(34, 34, 34, 0.26)' : 'none'}
                        bg={active === 'monthly' ? '#8FCF74' : '#2C628D'}
                        height="200px"
                        width="240px">
                        <img
                            src={active === 'monthly' ? '/images/black.png' : '/images/calendar.png'}
                            width="100px"
                            alt="month"/>
                        <Text
                            mt={3}
                            color={active === 'monthly' ? 'black' : 'white'}
                            fontSize="lg">
                            Monthly
                        </Text>
                    </Flex>
                </Flex>
                <Flex
                    alignItems="center"
                    onClick={() => handleActive('quarterly')}
                    cursor="pointer">
                    <Flex
                        px={4}
                        py={5}
                        mr={12}
                        flexWrap="wrap"
                        alignItems="center"
                        justifyContent="center"
                        flexDirection="column"
                        border={active === 'quarterly' ? '4px solid #222222' : 'none'}
                        boxShadow={active === 'quarterly' ? 'inset 0px 0px 4px 4px rgba(34, 34, 34, 0.26)' : 'none'}
                        bg={active === 'quarterly' ? '#8FCF74' : '#2C628D'}
                        height="200px"
                        width="240px">
                        <img
                            src={active === 'quarterly' ? '/images/black3.png' : '/images/calendar3.png'}
                            width="100px"
                            alt="month"/>
                        <Text
                            mt={3}
                            color={active === 'quarterly' ? 'black' : 'white'}
                            fontSize="lg">
                            Quarterly
                        </Text>
                    </Flex>

                </Flex>
                <Flex
                    alignItems="center"
                    onClick={() => handleActive('annually')}
                    cursor="pointer">
                    <Flex
                        mr={12}
                        flexWrap="wrap"
                        alignItems="center"
                        justifyContent="center"
                        flexDirection="column"
                        border={active === 'annually' ? '4px solid #222222' : 'none'}
                        boxShadow={active === 'annually' ? 'inset 0px 0px 4px 4px rgba(34, 34, 34, 0.26)' : 'none'}
                        bg={active === 'annually' ? '#8FCF74' : '#2C628D'}
                        height="200px"
                        width="240px">
                        <img
                            src={active === 'annually' ? '/images/black1.png' : '/images/calendar1.png'}
                            width="100px"
                            alt="month"/>
                        <Text
                            mt={3}
                            color={active === 'annually' ? 'black' : 'white'}
                            fontSize="lg">
                            Annually
                        </Text>
                    </Flex>
                </Flex>
            </Flex>

        </>
    )
}

export default Done
