import {Table, Thead, Tbody, Tr, Th, Td, Heading} from '@chakra-ui/react'
import styles from './styles'

const TableProjectInvestment = ({width, yearOneExisting, yearOneLed, customBiding}) => {
    return (
        <Table ml={9} width={width}>
            <Thead>
                <Tr>
                    <Th></Th>
                    <Th textAlign="center" color="black"><Heading fontSize="sm" m={0}>YEAR 1</Heading></Th>
                    <Th textAlign="center" color="black"><Heading fontSize="sm" m={0}>YEAR 2</Heading></Th>
                    <Th textAlign="center" color="black"><Heading fontSize="sm" m={0}>YEAR 3</Heading></Th>
                    <Th textAlign="center" color="black"><Heading fontSize="sm" m={0}>YEAR 4</Heading></Th>
                    <Th textAlign="center" color="black"><Heading fontSize="sm" m={0}>YEAR 5</Heading></Th>
                </Tr>
            </Thead>
            <Tbody>
                <Tr css={styles.tr}>
                    <Td p={0} bg="white.100">Existing System Cost:</Td>
                    <Td ml={4} bg={customBiding ? '#D6D6D6' :'#C1DBFF'}>${yearOneExisting.toLocaleString('en-US')}</Td>
                    <Td bg={customBiding ? '#D6D6D6' :'#C1DBFF'}>${Math.round((yearOneExisting*2)).toLocaleString('en-US')}</Td>
                    <Td bg={customBiding ? '#D6D6D6' :'#C1DBFF'}>${Math.round((yearOneExisting*3)).toLocaleString('en-US')}</Td>
                    <Td bg={customBiding ? '#D6D6D6' :'#C1DBFF'}>${Math.round((yearOneExisting*4)).toLocaleString('en-US')}</Td>
                    <Td bg={customBiding ? '#D6D6D6' :'#C1DBFF'}>${Math.round((yearOneExisting*5)).toLocaleString('en-US')}</Td>
                </Tr>
                <Tr css={styles.tr}>
                    <Td p={0}  bg="white.100">LED System Cost:</Td>
                    <Td ml={4} bg={customBiding ? '#D6D6D6' :'#C1DBFF'}>${yearOneLed.toLocaleString('en-US')}</Td>
                    <Td bg={customBiding ? '#D6D6D6' :'#C1DBFF'}>${Math.round((yearOneLed*2)).toLocaleString('en-US')}</Td>
                    <Td bg={customBiding ? '#D6D6D6' :'#C1DBFF'}>${Math.round((yearOneLed*3)).toLocaleString('en-US')}</Td>
                    <Td bg={customBiding ? '#D6D6D6' :'#C1DBFF'}>${Math.round((yearOneLed*4)).toLocaleString('en-US')}</Td>
                    <Td bg={customBiding ? '#D6D6D6' :'#C1DBFF'}>${Math.round((yearOneLed*5)).toLocaleString('en-US')}</Td>
                </Tr>
                <Tr css={styles.tr}>
                    <Td p={0} bg="white.100">Upgrade Savings:</Td>
                    <Td ml={4} bg={customBiding ? '#8A8A8A':'#8FCF74'} fontWeight='600'>${(yearOneExisting - yearOneLed).toLocaleString('en-US')}</Td>
                    <Td bg={customBiding ? '#8A8A8A':'#8FCF74'} fontWeight='600'>${Math.round((yearOneExisting*2 - yearOneLed*2)).toLocaleString('en-US')}</Td>
                    <Td bg={customBiding ? '#8A8A8A':'#8FCF74'} fontWeight='600'>${(Math.round((yearOneExisting*3 - yearOneLed*3))).toLocaleString('en-US')}</Td>
                    <Td bg={customBiding ? '#8A8A8A':'#8FCF74'} fontWeight='600'>${(Math.round((yearOneExisting*4 - yearOneLed*4))).toLocaleString('en-US')}</Td>
                    <Td bg={customBiding ? '#8A8A8A':'#8FCF74'} fontWeight='600'>${(Math.round((yearOneExisting*5 - yearOneLed*5))).toLocaleString('en-US')}</Td>
                </Tr>
            </Tbody>
        </Table>
    )
}

export default TableProjectInvestment