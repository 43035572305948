import React, {useEffect, useState} from "react";
import {
    Box, Button,
    Flex,
    FormControl, FormLabel,
    Modal,
    ModalBody,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Text, Textarea, useDisclosure,
} from "@chakra-ui/react";
import IconButton from "../../components/Theme/IconButton";
import Icon from "../../components/Theme/Icon";
import httpRequest from "../../core/services/http-request";
import {Input, InputLabel, TextareaLabel} from "../../components/Theme";
import InputMask from "react-input-mask";
import {ReactComponent as Send} from "../../assets/send.svg";
import styles from "./styles";
import moment from "moment";
import NewMessageModal from "./NewMessageModal";

const MessageModal = ({isOpen, onClose, message, getUse}) => {
    const {post} = httpRequest()
    const [viewMessage, setViewMessage] = useState('')
    const {isOpen: isOpenNew, onOpen: onOpenNew, onClose: onCloseNew} = useDisclosure()


    useEffect(() => {
        if (!message) return
        post('get-message', message.id).then((e) => {
            setViewMessage(e.data.data[0])
        })
    },[isOpen])

    return (
        <Modal isCentered isOpen={isOpen} onClose={onClose}>
            <NewMessageModal getUse={getUse} isOpen={isOpenNew} message={viewMessage} onCloseOld={onClose} onClose={onCloseNew}/>
            <ModalOverlay/>
            <ModalContent overflow="hidden" borderRadius="10px" minW="848px">
                <ModalHeader bg='#C1DBFF' fontWeight="700" fontSize="24px" textAlign="center">
                    View Message
                </ModalHeader>
                <IconButton height="unset" onClick={onClose} _hover={{bg: 'none',}} _active={{bg: 'none',}} bg="none" aria-label="close button" position="absolute" top="1.5rem" right="0.75rem" icon={<Icon type="darkClose"/>}/>
                <ModalBody p='40px' overflow="scroll" maxH="600px" css={styles.messages}>
                    <Flex margin="auto" alignItems="center" flexDirection="column">
                        <Flex w="100%" justifyContent='space-between'>
                            <InputLabel maxW='364px' value={viewMessage?.name} readOnly label="Name:"/>
                            <InputLabel maxW='364px' value={moment(viewMessage?.createdAt?.date).format('MM.DD.YYYY')} readOnly label="Sent Date:"/>
                        </Flex>
                        <Flex mt={3} w="100%" justifyContent='space-between'>
                            <InputLabel maxW='364px' value={viewMessage?.phone} readOnly label="Phone Number:"/>
                            <InputLabel maxW='364px' value={viewMessage?.email} readOnly label="Email:"/>
                        </Flex>
                        <InputLabel mt={3} mb={3} value={viewMessage?.subject}  readOnly label="Subject:"/>
                        <TextareaLabel minH='200px' value={viewMessage?.notification ? viewMessage?.notification[0]?.message[0]?.message : ''} readOnly label="Message:"/>
                        <Button  type="submit" mt={3} width="254px" variant="blue" size="lg" onClick={onOpenNew}>
                            Reply
                        </Button>
                    </Flex>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}
export default MessageModal