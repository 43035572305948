import {css} from '@emotion/react'

const styles = {
    tBody: css`
      font-family: 'Frank Ruhl Libre', serif;

      & > tr > td:first-of-type {
        color: black;
      }

      & > tr > td {
        padding-top: 0;
        padding-bottom: 5px;
        font-size: 14px;
      }
    `,
}

export default styles
