import {useState} from 'react'
import {Box, Flex, Text, Button, ModalOverlay, ModalContent, ModalHeader, ModalBody, Modal, FormControl, FormLabel, Textarea, useToast} from '@chakra-ui/react'
import IconButton from "../../../components/Theme/IconButton";
import Icon from "../../../components/Theme/Icon";
import {useUser} from "../../../core/hooks";
import {useForm} from "react-hook-form";
import {Input, InputLabel} from "../../../components/Theme";
import InputMask from "react-input-mask";
import {ReactComponent as Send} from "../../../assets/send.svg";
import {ReactComponent as SendMessage} from "../../../assets/cloudMail.svg";
import HttpRequest from "../../../core/services/http-request";
import ToastComponent from "../../../components/toastComponent/ToastComponent";

const ContactToAdminModal = ({isOpenContact, onCloseContact, onCloseParent, company}) => {
    const {user} = useUser()
    const toast = useToast()
    const [loading, setLoading] = useState(false)
    const {post} = HttpRequest()
    const {formState: {errors}, register, handleSubmit} = useForm({
        defaultValues: {
            name: user?.firstName + ' ' + user?.lastName,
            phone: user?.phone,
            email: user?.email,
            subject: `Suggest Profile Changes for ${company ? company?.name : user?.associatedToCompany?.name}`,
        }
    })
    const onSubmit = (data) => {
        setLoading(true)
        data.type = 2
        post('new-contact', {data})
            .then(function () {
                toast({
                    render: () => (
                        <ToastComponent icon={<SendMessage/>} title='Message Sent! Our team will reach out to you soon...' bg='#8FCF74'/>
                    ),
                    duration: 4000,
                    isClosable: true,
                    position: "top-right"
                })
                setTimeout(() => {
                    onCloseParent()
                    setLoading(false)
                }, 1000)
            })
    }

    return (
        <Modal isCentered isOpen={isOpenContact} onClose={onCloseContact}>
            <ModalOverlay/>
            <ModalContent overflow="hidden" borderRadius="10px" minW="853px">
                <ModalHeader fontWeight="600" bg='#DD94FF' fontSize="3xl" textAlign="center">
                    <Text>Contact Us</Text>
                </ModalHeader>
                <IconButton height="unset" onClick={onCloseContact} _hover={{bg: 'none',}} _active={{bg: 'none',}} bg="none" aria-label="close button" position="absolute" top="1.5rem" right="0.75rem" icon={<Icon type="darkClose"/>}/>
                <ModalBody maxH="779px" p='40px'>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Flex margin="auto" alignItems="center" flexDirection="column" px={8} maxW="760px">
                            <Text fontSize="3xl" fontWeight="600">Contact Administrator</Text>
                            <Flex w="100%" mt={2}>
                                <Box w="100%" mr={2}>
                                    <InputLabel isInvalid={!!errors.name} {...register('name', {required: true})} label="Name:"/>
                                </Box>
                                <Box w="100%" ml={2}>
                                    <FormControl id="Phone" >
                                        <FormLabel color="blue.100" fontFamily='Titillium Web'>Phone Number:</FormLabel>
                                        <Input
                                            height='50px'
                                            isInvalid={!!errors.phone}
                                            as={InputMask}
                                            {...register('phone', {required: true})}
                                            mask="999-999-9999"
                                            maskChar=""
                                            autoComplete="off"
                                            formatChars={{'9': '[0-9]'}}
                                            errorBorderColor="red.100"/>
                                    </FormControl>
                                </Box>
                            </Flex>
                            <InputLabel mt={3} isInvalid={!!errors.email} {...register('email', {required: true})} label="Email:"/>
                            <InputLabel mt={3} isInvalid={!!errors.subject} {...register('subject', {required: true})} label="Subject:"/>
                            <FormControl mt={3} id="message">
                                <FormLabel color="blue.100" fontFamily='Titillium Web'>Message:</FormLabel>
                                <Textarea
                                    autoFocus
                                    isInvalid={!!errors.message}
                                    {...register('message', {required: true})}
                                    _focus={{boxShadow: 'none',}}
                                    bg="white.100"
                                    resize="none"
                                    height="130px"
                                    border="1px solid"
                                    borderColor="#A9A9A9"
                                    borderRadius="6px"/>
                            </FormControl>
                            <Button leftIcon={<Send/>} type="submit" isLoading={loading} mt={6} width="254px" variant="blue" size="lg">
                                Submit
                            </Button>
                        </Flex>
                    </form>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}

export default ContactToAdminModal
