import { Button as ChakraButton } from '@chakra-ui/react'
import styled from '@emotion/styled'

const Button = styled(ChakraButton)`
    overflow: hidden;
    font-weight: 700;
    position: relative;
    font-size: ${(props) => (props.primary ? '1rem' : props.large ? '1.125rem' : '0.75rem')};
    height: ${(props) => (
    props.primary ? '40px'
      : props.small ? '23px'
        : props.large ? '51px'
          : '28px')};
    color: ${(props) => (
    props.primary ? '#ffffff'
      : props.large ? '#ffffff'
        : '#222222')};
    background: ${(props) => (
    props.primary ? '#2b5daa'
      : props.secondary ? '#619C48'
        : props.primarysmall ? '#9CBDD8'
          : props.small ? '#8FCF74'
            : props.large ? '#2C628D'
              : '#619C48')};
    border-radius: 5px;
    &:hover {
        background: ${(props) => (
    props.primary ? '#2b5daa'
      : props.secondary ? '#619C48'
        : props.primarysmall ? '#9CBDD8'
          : props.small ? '#8FCF74'
            : props.large ? '#2C628D'
              : '#619C48'
  )};
    }
    box-shadow: none;
    &:focus {
        box-shadow: none;
    }
`
export default Button
