import {Table, Thead, Tbody, Tr, Th, Td,Heading} from '@chakra-ui/react'
import styles from './styles'

const TableProjectEco = ({width, mercuryAbated, acSavings, co2Abated, customBidding}) => (
        <Table width={width}>
            <Thead>
                <Tr>
                    <Th></Th>
                    <Th textAlign="center" color="black"><Heading fontSize='sm' m={0}>Monthly</Heading></Th>
                    <Th textAlign="center" color="black"><Heading fontSize='sm' m={0}>YEAR 1</Heading></Th>
                    <Th textAlign="center" color="black"><Heading fontSize='sm' m={0}>YEAR 3</Heading></Th>
                    <Th textAlign="center" color="black"><Heading fontSize='sm' m={0}>YEAR 5</Heading></Th>
                </Tr>
            </Thead>
            <Tbody>
                <Tr css={styles.tr}>
                    <Td p={0} textAlign="end" bg="white !important">AC Savings:</Td>
                    <Td bg={customBidding && '#A9A9A9 !important'}>${Math.round(acSavings / 12).toLocaleString('en-US')}</Td>
                    <Td bg={customBidding && '#A9A9A9 !important'}>${Math.round(acSavings).toLocaleString('en-US')}</Td>
                    <Td bg={customBidding && '#A9A9A9 !important'}>${Math.round(acSavings * 3).toLocaleString('en-US')}</Td>
                    <Td bg={customBidding && '#A9A9A9 !important'}>${Math.round(acSavings * 5).toLocaleString('en-US')}</Td>
                </Tr>
                <Tr css={styles.tr}>
                    <Td p={0} textAlign="end" bg="white !important">CO2 Abated (tons):</Td>
                    <Td bg={customBidding && '#A9A9A9 !important'}>{Math.round(co2Abated / 12).toLocaleString('en-US')}</Td>
                    <Td bg={customBidding && '#A9A9A9 !important'}>{Math.round(co2Abated).toLocaleString('en-US')}</Td>
                    <Td bg={customBidding && '#A9A9A9 !important'}>{Math.round(co2Abated * 3).toLocaleString('en-US')}</Td>
                    <Td bg={customBidding && '#A9A9A9 !important'}>{Math.round(co2Abated * 5).toLocaleString('en-US')}</Td>
                </Tr>
                <Tr css={styles.tr}>
                    <Td p={0} textAlign="end" bg="white !important">Mercury Abated (mg):</Td>
                    <Td bg={customBidding && '#A9A9A9 !important'}>{Math.round(mercuryAbated / 12).toLocaleString('en-US')}</Td>
                    <Td bg={customBidding && '#A9A9A9 !important'}>{Math.round(mercuryAbated).toLocaleString('en-US')}</Td>
                    <Td bg={customBidding && '#A9A9A9 !important'}>{Math.round(mercuryAbated * 3).toLocaleString('en-US')}</Td>
                    <Td bg={customBidding && '#A9A9A9 !important'}>{Math.round(mercuryAbated * 5).toLocaleString('en-US')}</Td>
                </Tr>
            </Tbody>
        </Table>
    )
export default TableProjectEco