import {css} from '@emotion/react'

const styles = {
    container: css`
      min-height: calc(100vh - 60px);
    `,
    toast: css`
      animation-name: scroll;
      animation-duration: 4s;
      animation-timing-function: linear;
      @keyframes scroll {
        0% {
          left: 5px;
        }
        100% {
          left: 100%;
        }
      }
    `,
}

export default styles
