import {Flex} from '@chakra-ui/react'
import {
    Switch,
    BrowserRouter as Router,
    Route as ReactRoute,
} from 'react-router-dom'
import routes from './configs'
import Route from './Route'
import NotFoundPageLogedIn from "./NotFoundPageLogedIn";
import NotFoundPageLogout from "./NotFoundPageLogout";

const Routes = () => (
    <Router>
        <Switch>
            {
                routes.map(({
                                path,
                                Component,
                                secured,
                                Layout,
                            }) => (
                    <Route
                        key={path}
                        exact
                        path={path}
                        secured={secured}
                        Layout={Layout}
                        component={Component}/>
                ))
            }
            <ReactRoute>
                {localStorage.getItem('token') ? <NotFoundPageLogedIn /> : <NotFoundPageLogout />}
            </ReactRoute>
        </Switch>
    </Router>
)

export default Routes
