import { useQuery } from 'react-query'
import { airtableUseCases } from '../../factories'

const useListProducts = (condition) => {
  const {data, refetch, isLoading, error} = useQuery(['airtable-data', condition.table], () => airtableUseCases.listProducts(condition))

  return {data, refetch, isLoading, error}
}

export default useListProducts
