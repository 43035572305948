import {HStack,Text, Flex, Image} from '@chakra-ui/react'
import {TextLabel} from "../../../components/Theme";
import {S3_BUCKET} from "../../../constants";

const CompanyViewHeader = ({company}) => (
    <HStack spacing='4' alignItems='start'>
        <Flex alignItems="center" justifyContent="center" overflow="hidden" border="2px solid" borderColor="grey.100" width="114px" height="100px">
            {company?.image && (
                <Image
                    src={S3_BUCKET+company?.image}
                    alt="company avatar"
                    width='100%'
                    height='100%'
                    objectFit="cover"/>
            )}
        </Flex>
        <HStack alignItems='start' w='100%'>
            <HStack spacing='4' flex='2'>
                <TextLabel
                    text={company?.name}
                    fontSize="24px !important"
                    fontWeight="700"/>
            </HStack>
            <HStack spacing='4' flex='2'>
                <Text width='150px'>Company Type:</Text>
                <TextLabel
                    text={company?.type}/>
            </HStack>
        </HStack>
    </HStack>
)

export default CompanyViewHeader