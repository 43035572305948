import {FormControl, Checkbox, FormLabel, Menu, MenuButton, Flex, MenuList, Grid, GridItem, Text,} from '@chakra-ui/react'
import React from 'react'
import styles from './styles'
import {ReactComponent as DownArrow} from '../../../assets/downArrow.svg'

const CustomSelectAreas = React.memo(({label, value, options, setValue, type, repeat,}) => (
    <FormControl css={styles.menu}>
        <FormLabel fontWeight="600" fontSize="md" color="blue.200">
            {label}
        </FormLabel>
        <Menu>
            <MenuButton as={Flex} px={4} alignItems="center" position='relative' border="1px solid" overflow="scroll" borderColor="rgba(169, 169, 169, 0.87)" borderRadius="5px" bg="white.100" height="40px">
                <Text whiteSpace="nowrap" fontSize="xl">
                    {(() => {
                            if (value.length>0) {
                                let a = []
                                for (let i = 0; i < value.length; i++) {
                                    a.push(value[i].name + ',')
                                }
                                let res = a[a.length - 1].slice(0, -1)
                                a.pop()
                                a.push(res)
                                return a
                            }
                        }
                    )()}
                    <Flex position='absolute' right='15px' top='15px'>
                        <DownArrow />
                    </Flex>
                </Text>
            </MenuButton>
            <MenuList pl={6}>
                <Grid templateColumns={`repeat(${repeat}, 1fr)`}>
                    {
                        options.map((item) => (
                            <GridItem key={item.id} colSpan={1}>
                                <Checkbox
                                    isChecked={value?.includes(item)}
                                    name={item?.name}
                                    onChange={(e) => {
                                        let updatedValue;
                                        if (e.target.checked) {
                                            updatedValue = [...value, item];
                                        } else {
                                            updatedValue = value.filter(v => v !== item);
                                        }
                                        setValue(updatedValue);
                                    }}
                                    iconColor="black"
                                    colorScheme="whiteAlpha">
                                    {item.name}
                                </Checkbox>
                            </GridItem>
                        ))
                    }
                </Grid>
            </MenuList>
        </Menu>
    </FormControl>
))

export default CustomSelectAreas