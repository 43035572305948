import { useMutation } from 'react-query'
import { notificationUseCase } from '../../factories'

const useNotificationToActivity = () => {
    const {
        mutate: notificationToActivity, isLoading, data, error,
    } = useMutation(notificationUseCase.notificationToActivity)

    return {
        notificationToActivity,
        isLoading,
        error,
        data,
    }
}

export default useNotificationToActivity
