import {Box, Flex, Heading, Text, Input, Grid, GridItem} from "@chakra-ui/react";
import moment from "moment";
import {InputLabel, Textarea} from "../../../components/Theme";
import styles from "./styles";
import Loading from "../../../components/Loading";
import Luminaires from "./parts/Luminaires";
import {REU} from "../../../constants/user/userEnum";
import DatePicker from "react-modern-calendar-datepicker";

const EstimateTab = ({
                         refererEstimate,
                         estimate,
                         luminaires,
                         subTotal,
                         notes,
                         setShipping,
                         setSalesTax,
                         setNotes,
                         salesTax,
                         shipping,
                         total,
                         depositAmount,
                         depositDue,
                         remainderDue,
                         remainderAmount,
                         setRemainderAmount,
                         setDepositAmount,
                         setDepositDue,
                         setRemainderDue,
                         user,
                         isLoading,
                     }) => {
    if (isLoading) return <Loading/>

    return (
        <Box ref={refererEstimate} display={estimate?.createdAt ? 'block' : 'none'} backgroundColor='white' p={9}>
            <Flex justifyContent='space-between'>
                <img src="/images/LogoIcon.svg" alt="logo"/>
                <Flex flexDirection='column'>
                    <Heading m={0} textAlign='end'>Estimate</Heading>
                    <Text>Reference # : {estimate?.reference}</Text>
                    <Text textAlign='end'>Date : {moment(estimate?.createdAt?.date)?.format('MM/DD/YYYY')}</Text>
                </Flex>
            </Flex>
            <Flex mt={4} justifyContent='space-between' fontSize='18px'>
                <Flex width='49%' borderRight='2px solid #9C9C9C' flexDirection='column'>
                    <Input readOnly border='none' fontFamily='Titillium Web' backgroundColor='#EEEEEE' width='98%' fontSize='18px' mb={3}
                           value='Billing Information:' color='#222222' fontWeight='700'/>
                    <Text>Focus Earth</Text>
                    <Text>Attn: Pete Musser</Text>
                    <Text>1070 Tunnel Road</Text>
                    <Text>Building 1, Suite 10, #202</Text>
                    <Text>Asheville, NC 28805</Text>
                    <Text>USA</Text>
                    <Text>(925) 323-0053</Text>
                </Flex>
                <Flex width='50%' flexDirection='column'>
                    <Input fontFamily='Titillium Web' readOnly border='none' backgroundColor='#EEEEEE' width='100%' fontSize='18px' mb={3}
                           value='Shipping Information:' color='#222222' fontWeight='700'/>
                    <Text>{estimate?.userReu?.company[0]?.name}</Text>
                    <Text>Attn : {estimate?.userReu?.firstName + ' ' + estimate?.userReu?.lastName}</Text>
                    <Text>{estimate?.report?.project?.address}</Text>
                    <Text>{estimate?.report?.project?.address2}</Text>
                    <Text>{estimate?.report?.project?.city + ', ' + estimate?.report?.project?.state + ', ' + estimate?.report?.project?.zipCode}</Text>
                    <Text>USA</Text>
                    <Text>{estimate?.userReu?.phone}</Text>
                </Flex>
            </Flex>
            <Input fontFamily='Titillium Web' readOnly border='none' backgroundColor='#8FCF74' width='100%' fontSize='18px' fontWeight='700'
                   color='#222222' value='Order Information' mt={10}/>
            <Grid templateColumns='repeat(7, 1fr)' my={5} px={4}>
                <GridItem colSpan={3} as='h2' m={0}>
                    Description
                </GridItem>
                <GridItem colSpan={1} as='h2' m={0} textAlign='center'>
                    Part Number
                </GridItem>
                <GridItem colSpan={1} as='h2' m={0} textAlign='center'>
                    QTY
                </GridItem>
                <GridItem colSpan={1} as='h2' m={0} textAlign='center'>
                    Price Per Unit
                </GridItem>
                <GridItem colSpan={1} as='h2' m={0} textAlign='center'>
                    Amount
                </GridItem>
            </Grid>
            <Luminaires luminaires={luminaires}/>
            <Flex justifyContent='end' mt={7}>
                <Flex width='50%' fontWeight='700' border='2px solid #8FCF74' height='64px' alignItems='center'
                      justifyContent='space-between' pl={6} pr={2} borderRadius='6px'>
                    <Input readOnly fontWeight='600' border='none' fontFamily='Titillium Web' value='Sub Total:' fontSize='18px'/>
                    <Flex justifyContent='end'>
                        <Input fontFamily='Titillium Web' readOnly border='none' fontSize='14px' fontWeight='600'
                               value={subTotal ? '$' + subTotal?.toLocaleString('en-US') : 0}/>
                    </Flex>
                </Flex>
            </Flex>
            <Flex fontSize='18px !important' justifyContent='space-between'>
                <Flex width='50%' flexDirection='column'>
                    <Text mb={2} color='#2C628D' fontWeight='700' fontFamily='Titillium Web'>Notes:</Text>
                    <Textarea fontSize='18px !important' disabled={user?.type === REU} value={notes}
                              onChange={(e) => setNotes(e.target.value)} minH='130px'/>
                </Flex>
                <Flex flexDirection='column' color='#2C628D' mt={7}>
                    <Flex alignItems='center' justifyContent='space-between' minW='230px'>
                        <Text fontFamily='Titillium Web' textAlign='right'>Sales Tax:</Text>
                        <InputLabel css={styles.centeredInput}
                                    placeholder={0}
                                    leftAddon='$'
                                    type='number'
                                    disabled={user?.type === REU}
                                    onChange={(e) => setSalesTax(e.target.value)}
                                    value={salesTax}
                                    maxW='137px'/>
                    </Flex>
                    <Flex alignItems='center' justifyContent='space-between'>
                        <Text fontFamily='Titillium Web' >Shipping:</Text>
                        <InputLabel css={styles.centeredInput}
                                    placeholder={0}
                                    leftAddon='$' type='number'
                                    disabled={user?.type === REU}
                                    onChange={(e) => setShipping(e.target.value)}
                                    value={shipping} maxW='137px'/>
                    </Flex>
                    <Flex alignItems='center' justifyContent='space-between'>
                        <Text fontFamily='Titillium Web' fontWeight='700' textAlign='right' minW='70px'>Total:</Text>
                        <InputLabel css={styles.centeredInput}
                                    placeholder={0}
                                    readOnly disabled={user?.type === REU}
                                    value={'$' + total?.toLocaleString('en-US')}  style={{'border': '2px solid #8FCF74'}} maxW='137px'/>
                    </Flex>
                </Flex>
            </Flex>
            <Flex justifyContent='space-between'>
                <Flex width='50%' flexDirection='column' fontSize='14px'>
                    <Flex width='full' fontWeight='700' mt={8}>
                        <Box width='45%' fontFamily='Titillium Web' fontWeight='700'>Payment Terms:</Box>
                        <Text fontWeight='normal' width='55%'>50% Deposit due upon order placement<br/>50% Remainder due
                            upon Delivery</Text>
                    </Flex>
                    <Flex fontWeight='700' mt={8}>
                        <Box width='45%' fontFamily='Titillium Web' fontWeight='700'>Make Checks Payable To:</Box>
                        <Text fontWeight='normal' color='#619C48' width='55%'>Focus Earth</Text>
                    </Flex>
                    <Flex fontWeight='700' mt={5}>
                        <Box width='45%' fontFamily='Titillium Web' fontWeight='700'>Mail To:</Box>
                        <Text fontWeight='normal' width='55%'>1070 Tunnel Road <br/> Building 1, Suite 10,
                            #202 <br/> Asheville, NC 28805</Text>
                    </Flex>
                    <Flex fontWeight='700' mt={5}>
                        <Box width='45%' fontFamily='Titillium Web' fontWeight='700'>Electronic Payment:</Box>
                        <Text fontWeight='normal' width='55%'>Email accounting@focusearth.com</Text>
                    </Flex>
                </Flex>
                <Flex maxW='50%' fontSize='18px !important' flexDirection='column' justifyContent='end' color='#2C628D'>
                    <Flex alignItems='center' justifyContent='end'>
                        <Text ml='20px' fontFamily='Titillium Web'>Deposit Amount:</Text>
                        <InputLabel css={styles.centeredInput}
                                    placeholder='0'
                                    leftAddon='$' type='number'
                                    disabled={user?.type === REU} onChange={(e) => setDepositAmount(e.target.value)}
                                    value={depositAmount} ml='20px' maxW='137px'/>
                    </Flex>
                    <Flex alignItems='center' justifyContent='end' css={styles.datePicker}>
                        <Text ml='20px' fontFamily='Titillium Web'>Deposit Due:</Text>
                        <DatePicker
                            name="startDate"
                            inputPlaceholder="Select a date"
                            selected={depositDue}
                            onChange={(date) => setDepositDue(date)}
                            format="YYYY / MM / DD"
                            disabled={user?.type === REU}
                            className='test-class'
                            wrapperClassName='wrapper-class'
                            calendarClassName='calendar-class'
                            formatInputText={() => {
                                if (!depositDue) return '';
                                return `${depositDue.month.toString().padStart(2, '0')}.${depositDue.day.toString().padStart(2, '0')}.${depositDue.year}`;
                            }}
                            value={depositDue}
                            />
                    </Flex>
                    <Flex alignItems='center' justifyContent='end'>
                        <Text ml='20px' fontFamily='Titillium Web'>Remainder Amount:</Text>
                        <InputLabel leftAddon='$' css={styles.centeredInput} readOnly ml='20px'
                                    disabled={user?.type === REU} value={remainderAmount?.toLocaleString('en-US')} maxW='137px'/>
                    </Flex>
                    <Flex alignItems='center' justifyContent='end' css={styles.datePicker}>
                        <Text ml='20px' fontFamily='Titillium Web'>Remainder Due:</Text>
                        <DatePicker
                            name="startDate"
                            selected={remainderDue}
                            onChange={(date) => setRemainderDue(date)}
                            format="YYYY / MM / DD"
                            disabled={user?.type === REU}
                            className='test-class'
                            wrapperClassName='wrapper-class'
                            inputPlaceholder="Select a date"
                            calendarClassName='calendar-class'
                            formatInputText={() => {
                                if (!remainderDue) return '';
                                return `${remainderDue.month.toString().padStart(2, '0')}.${remainderDue.day.toString().padStart(2, '0')}.${remainderDue.year}`;
                            }}
                            value={remainderDue}
                        />
                    </Flex>
                </Flex>
            </Flex>
        </Box>
    )
}
export default EstimateTab