import React from 'react'
import {Pie, defaults} from 'react-chartjs-2'

const ProjectChartInterests = ({score}) => {
    defaults.global.animation = undefined
    const {
        price,
        time,
        cost,
        security,
        human,
        other,
    } = score
    let empty = 100
    const data = {
        datasets: [
            {
                label: '# of Votes',
                data: Object.values(score)
                    .reduce((total, currentValue) => (
                        total + currentValue), 0) === 0
                    ? [100]
                    : [
                        price,
                        time,
                        cost,
                        security,
                        human,
                        other,
                        empty -= (price + time + cost + security + human + other),
                    ],
                backgroundColor: Object.values(score)
                    .reduce((total, currentValue) => (
                        total + currentValue), 0) === 0
                    ? ['#d9d9d9']
                    : [
                        'rgb(143,207,116)',
                        'rgb(255,127,86)',
                        'rgb(255,230,141)',
                        'rgb(74,133,180)',
                        'rgb(221,194,255)',
                        'rgb(196,196,196)',
                    ],
                borderWidth: 0,
            },
        ],
    }
    const options = {
        maintainAspectRatio: false,
        animation: false,
        responsive: true,
        tooltips: {
            callbacks: {
                title(tooltipItem) {
                    if (Object.values(score)
                        .reduce((total, currentValue) => (
                            total + currentValue), 0) === 0) {
                        return '0'
                    }
                    return Object.keys(score)[tooltipItem[0].index]
                },
                label(tooltipItem, newLabel) {
                    if (Object.values(score)
                        .reduce((total, currentValue) => (
                            total + currentValue), 0) === 0) {
                        return '0'
                    }
                    return `${newLabel['datasets'][0]['data'][tooltipItem['index']]}%`
                },
            },
        },
        hover: {mode: null},
    }

    return (
        <Pie
            width={1000}
            height={1000}
            options={options}
            data={data}/>
    )
}

export default ProjectChartInterests
