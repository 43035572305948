import React from 'react'
import {
    Container, Grid, Heading, Flex,
} from '@chakra-ui/react'
import styles from "./styles";

function TableWrapper(props) {
    const {children} = props
    return (
        <Container css={styles.tableWrapper} maxW="7xl">
            <Flex className='wrapperTotals'>
                Totals
            </Flex>
            <Grid templateColumns='repeat(2, 1fr)' gap={6} mb={6}>
                <Heading borderBottomWidth="1px" borderColor="grey.200" m={0} color="#A8A8A8" textAlign='center' fontSize="24px">
                    EXISTING
                </Heading>
                <Heading m={0} color="#8FCF74" borderBottomWidth="1px" borderColor="#8FCF74" textAlign='center' fontSize="24px">
                    RECOMENDED
                </Heading>
            </Grid>
            <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                <Grid templateColumns="repeat(3, 1fr)">
                    <Heading fontSize="14px">QTY</Heading>
                    <Heading style={{textWrap: 'nowrap'}} fontSize="14px">LUMINAIRE DESCRIPTION</Heading>
                    <Heading textAlign='end'  fontSize="14px">MOUNT HEIGHT</Heading>
                </Grid>
                <Grid templateColumns="repeat(3, 1fr)">
                    <Heading fontSize="14px">QTY</Heading>
                    <Heading fontSize="14px" style={{textWrap: 'nowrap'}}>LUMINAIRE DESCRIPTION</Heading>
                    <Heading textAlign='end' fontSize="14px">MOUNT HEIGHT</Heading>
                </Grid>
            </Grid>
            {children}
        </Container>
    )
}

export default TableWrapper
