import { css } from '@emotion/react'

const styles = {
  grid: css`
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
        padding-left: 2.75rem;
        padding-right: 2.75rem;
        flex-wrap: wrap;
        & > div {
            cursor: pointer;
            position: relative;
            overflow: hidden;
            height: 200px;
            width: 360px;
            border-radius: 8px;
            margin-bottom: 1.5rem;
        }

        & img {
            max-height: 75px;
            max-width: 91px;
        }
    `,
}

export default styles
