import {useEffect, useState} from 'react'
import {Link, useParams} from 'react-router-dom'
import {Flex, Text, Box, Heading, Divider, Image, Input, HStack, Select, Grid, GridItem,} from '@chakra-ui/react'
import Navbar from '../../components/Navbar'
import {useGetCompany, useUser,} from '../../core/hooks'
import {S3_BUCKET, SPECIALITIES} from '../../constants'
import styles from './styles'
import ChartCompany from '../../components/Theme/ChartCompany'
import {Incrementer, InputLabel, TextareaLabel} from "../../components/Theme";
import {InitialScore} from "../../constants/chartDatas";

const CompanyDetail = () => {
    const {id} = useParams()
    const {company: companyResponse} = useGetCompany(id)
    const [company, setCompany] = useState()
    const [score, setScore] = useState(InitialScore)
    const {user} = useUser()


    useEffect(() => {
        if (!companyResponse) return
        setCompany(companyResponse?.data?.data)
        setScore(JSON.parse(companyResponse?.data?.data?.propertyPriorities[0]))
    }, [companyResponse])

    return (
        <>
            <Navbar minW="1240px"
                title={(
                    <Flex>
                        <Link to="/companies">Companies</Link>
                        <Text>&nbsp;/&nbsp;{company?.name}</Text>
                    </Flex>
                )}/>
            <Box minW="1240px" margin="auto" maxW="1240px" css={styles.container} px={14} pt={8} pb={16} borderRight="1px solid" borderLeft="1px solid" borderColor="#DBDADA" bg="#f5f5f5">
                <Flex w="full" direction="row" flexWrap="wrap" position='relative' alignItems="center" justifyContent="space-between" mt={4}>
                    <Box>
                        <Image height="85px" width="85px" border="3px solid #C4C4C4 !important" mr="2" src={company?.image ? S3_BUCKET + company?.image : ''}/>
                    </Box>
                    <Box width="510px" mb={12}>
                        <Input value={company?.name} disabled fontWeight="700" bg="white" fontSize="24px" color="black !important" border="1px solid #C4C4C4 !important" mr={7}/>
                    </Box>
                    <Box mb={12} marginLeft={2} marginRight={2}>
                        <Text>Company Type:</Text>
                    </Box>
                    <Box width="30%" mb={12}>
                        <Input disabled bg="white" border="1px solid #C4C4C4 !important" value={company?.type} mr={7}/>
                    </Box>
                </Flex>
                <Divider my={10} borderColor="#DADADA" border="3px solid" orientation="horizontal"/>
                <Flex w="100%">
                    <InputLabel disabled value={company?.address} mr={7} label="Address:"/>
                    <InputLabel disabled value={company?.address2} ml={5} label="Address Line 2:"/>
                </Flex>
                <HStack pt="60px" spacing="16" alignItems="center" justifyContent="space-between">
                    <InputLabel disabled maxW="170px" value={company?.city} label="City:"/>
                    <label>
                        <Text as="span" width="105px" color="#2C628D" fontWeight="600" display="inline-block" pb="4px">
                            State:
                        </Text>
                        <Select disabled label="State:" mt="2px" border="1px solid #C4C4C4 !important" name="state" bg="white" maxW="105px">
                            <option value={company?.state}>{company?.state}</option>
                        </Select>
                    </label>
                    <InputLabel disabled value={company?.zipCode} maxW="105px" label="Zip Code:"/>
                    <InputLabel disabled value={company?.country} maxW="540px" label="Country:"/>
                </HStack>
                <Flex w="100%" pt="60px">
                    <InputLabel disabled value={company?.usersAssociated?.map((item) => item.name + ' ' + item.lastName).toString()} mr={7} label="Associated Users:"/>
                    <InputLabel disabled value={company?.website} ml={7} label="Website:"/>
                </Flex>
                <Flex w="100%" pt="20px">
                    <TextareaLabel disabled value={company?.notes} label="Notes:"/>
                </Flex>
                <Divider my={10} borderColor="#DADADA" border="3px solid" orientation="horizontal"/>
                <Heading mb={10} fontSize="xl" as="h2">
                    {user?.type === 'Reu' ? ('Property Types:') : ('Serviceable Property Types:')}
                </Heading>
                <Flex alignItems="center" justifyContent="space-between">
                    <Grid templateColumns="repeat(2, 1fr)" gap={5}>
                        {SPECIALITIES.map((interest) => (
                                <GridItem key={interest.title} colSpan={1}>
                                    <Incrementer
                                        plus={false}
                                        score={score}
                                        setScore={setScore}
                                        title={interest.title}
                                        color={interest.active}/>
                                </GridItem>
                            ))}
                    </Grid>
                    <ChartCompany
                        score={score}/>
                </Flex>
                <Box
                    w='full'
                    display={company?.owner?.type === 'Eu' ? 'block' : 'none'}>
                    <Flex
                        w="100%">
                        <Text
                            fontWeight='600'
                            mb={5}
                            fontSize='18px'>
                            Serviceable Areas:
                        </Text>
                    </Flex>
                    <Flex
                        w="100%">
                        <Text
                            fontWeight='600'
                            fontSize='14px'
                            color="#2C628D">
                            Serviceable Areas - States & Counties
                        </Text>
                    </Flex>
                    <Flex
                        w="100%"
                        border="1px solid #C4C4C4"
                        borderRadius="5px"
                        justifyContent="space-between"
                        p="10px"
                        mb={20}>
                        <Text
                            fontWeight='400'
                            fontSize='18px'>
                            {(() => {
                                if (company?.serviceArea?.length > 0) {
                                    let a = company?.serviceArea?.map((item) => item.name + '(' + item.child.filter((i) => i.value === true).map((e) => e.name) + ')-')
                                    let res = a[a.length - 1].slice(0, -1)
                                    a.pop()
                                    a.push(res)
                                    return a.toString().replace(/(.{100})..+/, "$1…")
                                }
                            })()}
                        </Text>
                    </Flex>
                </Box>
            </Box>
        </>
    )
}

export default CompanyDetail
