import {useEffect, useRef, useState} from "react";
import {Box, Divider, Heading, Text,} from "@chakra-ui/react";
import LuminsTotals from "./Lumis";
import {useUser} from "../../core/hooks";
import {useHistory} from "react-router-dom";
import {EU} from "../../constants/user/userEnum";

const Lumins = ({proposal, project, luminaire, setLuminaire}) => {
    const {user} = useUser()
    const reportTemplateRef = useRef(null);
    const history = useHistory()

    return (
        <Box ref={reportTemplateRef}>
            <Box>
                <Heading fontSize="3xl" as="h2">
                    {user?.type === EU ? (<Text as='h2' m={0}>Luminaire Upgrade</Text>) : (<Text as='h2' m={0}>Luminaires</Text>)}
                </Heading>
                <Text fontSize="md">
                    This page details the existing lighting components that can be recycled and the
                    recommended
                    upgrades that can replace them. Use the following notation to quickly review the
                    luminaire
                    descriptions:
                </Text>
                <Text mt={5} fontWeight='600'>
                    [Fixture QTY] Fixture Description (Lamp QTY) Component Descriptions {'{Mounting Height}'}
                </Text>
            </Box>
            <Divider my={4} oriantion="horisontal"/>
            <LuminsTotals
                user={user}
                luminaire={luminaire}
                setLuminaire={setLuminaire}
                project={project}
                proposal={proposal}
                />
        </Box>
    )
}

export default Lumins