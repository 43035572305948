import React, {useState} from 'react'
import {useHistory} from 'react-router-dom'
import {Flex, Text, Container, Heading,} from '@chakra-ui/react'
import {Button} from '../../components/Theme'
import styles from './styles'

function Register() {
    const [link, setLink] = useState('')
    const history = useHistory()
    const signUp = () => {
        history.push(`/register/${link}`)
    }

    return (
        <Container position="relative" display="flex" alignItems="center" flexDirection="column" mx={4} mt={10} py={8} maxW="6xl" borderRadius="10px" boxShadow="0px 4px 10px rgba(34, 34, 34, 0.25)" bg="rgba(255, 255, 255, 0.8)">
            <Heading mt={2} as="h2" fontSize="4xl">Select Your User Type</Heading>
            <Flex css={styles.grid}>
                <Flex mr={6} onClick={() => setLink('reu')} bg={link === 'reu' ? '#6AA1E0' : '#2C628D'} border={link === 'reu' ? '5px solid #2C628D' : 'none'} boxShadow={link === 'reu' ? 'inset 0px 0px 5px 6px rgba(34, 34, 34, 0.49)' : 'none'} alignItems="center" justifyContent="center">
                    <img style={{'color': 'white'}} src={link === 'reu' ? '/images/reu_black.svg' : '/images/reu.svg'} alt="Architechture"/>
                    <Text fontSize="15px" color={link === 'reu' ? 'black' : "white.100"} position="absolute" bottom="10px">I&apos;m a real estate owner/manager</Text>
                </Flex>
                <Flex onClick={() => setLink('su')} bg={link === 'su' ? '#FFE68D' : '#2C628D'} border={link === 'su' ? '5px solid #2C628D' : 'none'} boxShadow={link === 'su' ? 'inset 0px 0px 5px 6px rgba(49, 49, 49, 0.25)' : 'none'} alignItems="center" justifyContent="center">
                    <img src={link === 'su' ? '/images/su_black.svg' : '/images/su.svg'} alt="Energy"/>
                    <Text fontSize="15px" color={link === 'su' ? 'black' : "white.100"} position="absolute" bottom="10px">I&apos;m an LED supplier</Text>
                </Flex>
            </Flex>
            <Flex css={styles.grid}>
                <Flex mr={6} onClick={() => setLink('eu')} bg={link === 'eu' ? '#FF7F56' : '#2C628D'} border={link === 'eu' ? '5px solid #2C628D' : 'none'} boxShadow={link === 'eu' ? 'inset 0px 0px 5px 6px rgba(49, 49, 49, 0.25)' : 'none'} alignItems="center" justifyContent="center">
                    <img src={link === 'eu' ? '/images/el_black.svg' : '/images/el.svg'} alt="Electrician"/>
                    <Text fontSize="15px" color={link === 'eu' ? 'black' : "white.100"} position="absolute" bottom="10px">I&apos;m an electrician/electrical contractor</Text>
                </Flex>
                <Flex onClick={() => setLink('gu')} bg={link === 'gu' ? '#81C166' : '#2C628D'} border={link === 'gu' ? '5px solid #2C628D' : 'none'} boxShadow={link === 'gu' ? 'inset 0px 0px 5px 6px rgba(49, 49, 49, 0.25)' : 'none'} alignItems="center" justifyContent="center">
                    <img src={link === 'gu' ? '/images/gu_black.svg' : '/images/gu.svg'} alt="Education"/>
                    <Text fontSize="15px" color={link === 'gu' ? 'black' : "white.100"} position="absolute" bottom="10px">I&apos;m just here to learn!</Text>
                </Flex>
            </Flex>
            <Button width="467px" colorScheme="blue.200" bg={!link ? '#A9A9A9 !important' : '#619C48'} height="40px !important" isDisabled={!link} onClick={signUp}>
                <img src="/images/sign_up.svg" alt="sign Up"/>
                <Text ml={1} color="white" fontSize="18px">Sign Up</Text>
            </Button>
        </Container>
    )
}

export default Register
