/** @jsxImportSource @emotion/react */
import React from 'react'
import {Table, Thead, Tbody, Tr, Th, Td, Flex, Text,} from '@chakra-ui/react'
import styles from '../styles'
import {Link} from "react-router-dom";
import {Box} from "@material-ui/core";
import {ReactComponent as Unfinished} from "../../../assets/unfinishedIconca.svg";
import {ReactComponent as Ready} from "../../../assets/ready.svg";

const Security = ({project, reports, user, history}) => {
    return (
        <>
            <Table
                css={styles.table}
                variant="simple">
                <Thead>
                    <Tr
                        css={styles.tr}>
                        <Th>Rank</Th>
                        <Th>Report</Th>
                        <Th>Added Lumens</Th>
                        <Th>Lumen Increase/Sq.Ft.</Th>
                        <Th>Lumen Increase/Fixture(%)</Th>
                        <Th>Integrated Sensors(%)</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {
                        reports?.length > 0 ? reports?.map((item, idx) => (
                            <Tr
                                key={item.id}
                                css={styles.tr}
                                bg="white.100"
                                boxShadow="2px 4px 10px rgba(0, 0, 0, 0.25)"
                                onClick={() => history.push(`/projects/proposal/${encodeURIComponent(item.proposalName)}/${project.id}/${item.id}`)}>
                                <Td>
                                    <Text as='span'  float='left'>
                                        {idx + 1}
                                    </Text>
                                    <Box ml={5}>
                                        {
                                            item.status === 'Unfinished' ? (
                                                <Unfinished/>
                                            ) : (
                                                <Ready/>
                                            )
                                        }
                                    </Box>
                                </Td>
                                <Td>
                                    {item.proposalName ? `${item.proposalName}` : '-'}
                                </Td>
                                <Td>
                                    {item.addedlumens ? `${Number(item.addedlumens).toLocaleString('en-US')}` : '0'}
                                </Td>
                                <Td>
                                    {item.lumenincrease ? `${Number(item.lumenincrease).toLocaleString('en-US')}` : '0'}
                                </Td>
                                <Td>
                                    {item.lumincreasefixture ? `${Number(item.lumincreasefixture).toLocaleString('en-US')}%` : '0'}
                                </Td>
                                <Td>
                                    {item.integratedsensors ? `${Number(item.integratedsensors).toLocaleString('en-US')}%` : '0'}
                                </Td>
                            </Tr>
                        )) : (
                            <Tr>
                                <Td
                                    colSpan='7'>
                                    <Flex
                                        border='3px solid #8FCF74'
                                        p={14}
                                        mx={14}
                                        borderRadius='10px'>
                                        <Text>
                                            Looks like you don’t have any proposals yet... Click the orange Edit Details
                                            button to
                                            add
                                            more property information or <Link to="/contact">
                                            <Text as='span' textDecoration='underline'>click here</Text></Link>
                                            <Text as='span'> to contact your Energy Consultant.</Text>
                                        </Text>
                                    </Flex>
                                </Td>
                            </Tr>
                        )
                    }
                </Tbody>
            </Table>
        </>
    )
}

export default Security
