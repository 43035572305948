import {css} from '@emotion/react'

const styles = {
    container: css`
      min-height: calc(100vh - 60px);
    `,

    menuList: css`
      .chakra-menu__menu-list{
        padding: 0 !important;
      }
    `,

    menu: css`
      width: 100%;
      
      & > div:nth-of-type(2) {
        width: 100%;
      }
    `,
    checkbox: css`
      & > span:nth-of-type(1) {
        height: 20px;
        width: 20px;
        border: 2px solid #A9A9A9;
      }

      & > span {
        box-shadow: 0 0 0 0 rgb(0, 0, 0) !important;
      }
    `,
    checkboxComp: css`
      & > span:nth-of-type(1) {
        height: 20px;
        width: 20px;
        border: 2px solid black;
        &[data-checked] {
         background-color: #8FCF74; 
        }
      }

      & > span {
        box-shadow: 0 0 0 0 rgb(0, 0, 0) !important;
      }
    `,
    file: css`
      display: flex;
      box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.25);
      border-radius: 10px;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 17px 0 17px 22px;
      margin-bottom: 20px;
    `,
}

export default styles
