import { useQuery } from 'react-query'
import { orderUseCase } from '../../factories'

const useGetEstimate = (input) => {
    const {
        refetch, isLoading, data, error,
    } = useQuery(['estimate', input.id], () => orderUseCase.getEstimate(input),{refetchOnWindowFocus: false})

    return {
        refetch,
        isLoading,
        error,
        data,
    }
}

export default useGetEstimate
