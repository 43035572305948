import  {useEffect, useState} from 'react'
import {Stack, HStack, Box, Button, Text, VStack, Heading} from '@chakra-ui/react'
import {ChakraSelectLabel, InputLabel} from '../../../components/Theme'
import NavigateModal from './NavigateModal'
import {useGetExsiting} from "../../../core/hooks";

const Accessory = ({description, setType, onClose, setRecent,luminaire, accessory, setAccessory, area, createKey, setCreateKey, setArea, luminaireId,}) => {
    const [filters, setFilters] = useState({type: '', manufacturer: '', color: '', productCode: '',width: '',length: '',depth: '',diameter: '',})
    const [show, setShow] = useState(false);
    const [error, setError] = useState('');
    const [newAccessA, setNewAccessA] = useState(true);
    const [newAccessB, setNewAccessB] = useState(false);
    const [newAccessBDisplay, setNewAccessBDisplay] = useState(false);
    const [newAccessC, setNewAccessC] = useState(false);
    const [newAccessCDisplay, setNewAccessCDisplay] = useState(false);
    let accessoryCount = 0
    const {refetch} = useGetExsiting( {
        type : 'Accessories',
        accessoryTypeA : accessory.accessoryTypeA,
        accessoryTypeB : accessory.accessoryTypeB,
        accessoryTypeC : accessory.accessoryTypeC,
    })

    const create = () => {
        if (!accessory.accessoryTypeA) return
        refetch().then((res) => {
            if (res?.data?.data?.data){
                setAccessory({...accessory, accessoryExisting: res.data.data.data})
            }
            const newArray = area?.luminaires.map((luminaire) => {
                let newObj = {...luminaire}
                if (newObj.id === luminaireId) {
                    newObj = {...newObj, ...accessory,accessoryExisting:res.data.data.data}
                }
                return newObj
            })
            setArea({...area, luminaires: newArray})
            setRecent([...newArray])
        })
    }

    if (createKey === true) {
        create()
        setCreateKey(false)
        onClose()
    }

    const newAccessory = () => {
        if (!accessory.accessoryDescriptionA) {
            return setError('Create first')
        }
        ++accessoryCount
        setError('')
        if (accessoryCount === 1) {
            setNewAccessA(false)
            setNewAccessC(false)
            setNewAccessBDisplay(true)
            return setNewAccessB(true)
        }
        if (accessoryCount >= 2) {
            if (!accessory.accessoryDescriptionB) {
                return setError('Create second')
            }
            setNewAccessB(false)
            setNewAccessCDisplay(true)
            return setNewAccessC(true)
        }
    }

    useEffect(() => {
        if (show) {
            create()
            setShow(false)
        }
    }, [show])

    useEffect(() => {
        //Set description width parameters that exists
        if (newAccessA) {
            const description = (accessory.accessoryTypeA ? accessory.accessoryTypeA : '') + (accessory.accessoryManufacturerA ? " " + accessory.accessoryManufacturerA : '')
                + (accessory.accessoryColorA ? " " + accessory.accessoryColorA : '') + (accessory.accessoryProductCodeA ? " " + accessory.accessoryProductCodeA : '')
            setAccessory({
                ...accessory,
                accessoryDescriptionA: description
            })
        } else if (newAccessB) {
            const description = (accessory.accessoryTypeB ? accessory.accessoryTypeB : '') + (accessory.accessoryManufacturerB ? " " + accessory.accessoryManufacturerB : '')
                + (accessory.accessoryColorB ? " " + accessory.accessoryColorB : '') + (accessory.accessoryProductCodeB ? " " + accessory.accessoryProductCodeB : '')
            setAccessory({
                ...accessory,
                accessoryDescriptionB: description
            })
        } else if (newAccessC) {
            const description = (accessory.accessoryTypeC ? accessory.accessoryTypeC : '') + (accessory.accessoryManufacturerC ? " " + accessory.accessoryManufacturerC : '')
                + (accessory.accessoryColorC ? " " + accessory.accessoryColorC : '') + (accessory.accessoryProductCodeC ? " " + accessory.accessoryProductCodeC : '')
            setAccessory({
                ...accessory,
                accessoryDescriptionC: description
            })
        }

    }, [filters])

    useEffect(() => {
        if (!luminaire) return
        if (luminaire.accessoryDescriptionB){
            setNewAccessBDisplay(true)
        }
        if (luminaire.accessoryDescriptionC){
            setNewAccessCDisplay(true)
        }
    },[luminaire])

    return (
        <Stack spacing="5">
            <NavigateModal
                label={description}
                leftAction={() => {
                    create()
                    setType('control')
                }}
                rightAction={() => {
                    create()
                    setType('fixture')
                }}
                left="Control"
                right="Fixture"/>
            <HStack w="full" justifyContent="center" position="relative" alignItems="center" pt={5}>
                <Box position="absolute" bg="#A9A9A9" w="full" h="1px"/>
                <Box px="2" position="relative" bg="white" textTransform="uppercase" fontWeight="400" fontSize="xl" color="#2C628D" as="span">
                    accessories details
                </Box>
            </HStack>
            {error && (<Text textAlign='center' color="red.100" fontSize="m">{error}</Text>)}
            <VStack width='100%'>
                <HStack spacing="5" alignItems="flex-end" width='100%'>
                    <VStack>
                        <Heading fontSize='md' m={0} color='blue.200'>
                            Select
                        </Heading>
                        <Button
                            onClick={() => {
                                setNewAccessA(true)
                                setNewAccessB(false)
                                setNewAccessC(false)
                            }}
                            fontSize='18px'
                            width="40px"
                            height="46px">
                            A
                        </Button>
                    </VStack>
                    <InputLabel
                        maxW="82px"
                        type="number"
                        labelMarginRight={0}
                        pattern="[0-9]*"
                        label="QTY/Fixture"
                        inputHeight='46px'
                        value={accessory?.accessoryDescriptionAQty}
                        onChange={(e) => setAccessory({
                            ...accessory,
                            accessoryDescriptionAQty: e.target.value
                        })}/>
                    <InputLabel
                        color='#737373'
                        readOnly
                        label='Accessory Description'
                        inputHeight='46px'
                        value={accessory?.accessoryDescriptionA}
                        onChange={(e) => {
                            setAccessory({
                                ...accessory,
                                accessoryDescriptionA: e.target.value
                            })
                            setShow(true)
                        }}/>
                </HStack>
                <HStack  width='100%' spacing="5" alignItems="flex-end" display={newAccessBDisplay === true ? 'flex' : 'none'}>
                    <Button
                        onClick={() => {
                            setNewAccessA(false)
                            setNewAccessB(true)
                            setNewAccessC(false)
                        }}
                        fontSize='18px'
                        width="40px"
                        height="46px">
                        B
                    </Button>
                    <InputLabel
                        maxW="82px"
                        type="number"
                        pattern="[0-9]*"
                        inputHeight='46px'
                        value={accessory?.accessoryDescriptionBQty}
                        onChange={(e) => setAccessory({
                            ...accessory,
                            accessoryDescriptionBQty: e.target.value
                        })}/>
                    <InputLabel
                        inputHeight='46px'
                        color='#737373'
                        readOnly value={accessory?.accessoryDescriptionB}
                        onChange={(e) => {
                            setAccessory({
                                ...accessory,
                                accessoryDescriptionB: e.target.value
                            })
                            setShow(true)
                        }}/>
                </HStack>
                <HStack width='100%' spacing="5" alignItems="flex-end" display={newAccessCDisplay === true ? 'flex' : 'none'}>
                    <Button
                        onClick={() => {
                            setNewAccessA(false)
                            setNewAccessB(false)
                            setNewAccessC(true)
                        }}
                        fontSize='18px'
                        width="40px"
                        height="46px">
                        C
                    </Button>
                    <InputLabel
                        maxW="82px"
                        type="number"
                        pattern="[0-9]*"
                        inputHeight='46px'
                        value={accessory?.accessoryDescriptionCQty}
                        onChange={(e) => setAccessory({
                            ...accessory,
                            accessoryDescriptionCQty: e.target.value
                        })}/>
                    <InputLabel
                        inputHeight='46px'
                        color='#737373'
                        readOnly value={accessory?.accessoryDescriptionC}
                        onChange={(e) => {
                            setAccessory({
                                ...accessory,
                                accessoryDescriptionC: e.target.value
                            })
                            setShow(true)
                        }}/>
                </HStack>
               <HStack width='100%' pt={4}>
                   <Button w="138px" h="46px" color='black' fontWeight='600' borderRadius='4px' bg="#7DB2DE" onClick={newAccessory}>
                       <Text mr={2} mb={1} fontSize='20px' as='span'>+</Text>
                       New Accessory
                   </Button>
               </HStack>
            </VStack>
            <HStack w="full" justifyContent="center" position="relative" alignItems="center" py={5}>
                <Box position="absolute" bg="#A9A9A9" w="full" h="1px"/>
                <Box px="2" position="relative" bg="white" textTransform="uppercase" fontWeight="400" fontSize="xl" color="#2C628D" as="span">
                    filters
                </Box>
            </HStack>
            <HStack spacing="5">
                <ChakraSelectLabel
                    maxW="203px"
                    label="Type:"
                    height='46px'
                    placeholder="Select"
                    value={
                        newAccessA ? accessory?.accessoryTypeA :
                            newAccessB ? accessory?.accessoryTypeB :
                                newAccessC ? accessory?.accessoryTypeC : ''}
                    onChange={(e) => {
                        setFilters({
                            ...filters,
                            type: e.target.value,
                        })
                        if (newAccessA) {
                            setAccessory({
                                ...accessory,
                                accessoryTypeA: e.target.value
                            })
                        } else if (newAccessB) {
                            setAccessory({
                                ...accessory,
                                accessoryTypeB: e.target.value
                            })
                        } else {
                            setAccessory({
                                ...accessory,
                                accessoryTypeC: e.target.value
                            })
                        }
                    }}>
                    <option value="Mounting Bracket">Mounting Bracket</option>
                    <option value="Protective Lens Cover">Protective Lens Cover</option>
                    <option value="Light Shield">Light Shield</option>
                </ChakraSelectLabel>
                <InputLabel
                    height='46px'
                    maxW="203px"
                    value={
                        newAccessA ? accessory?.accessoryManufacturerA :
                            newAccessB ? accessory?.accessoryManufacturerB :
                                newAccessC ? accessory?.accessoryManufacturerC : ''}
                    onChange={(e) => {
                        setFilters({...filters, manufacturer: e.target.value})
                        if (newAccessA) {
                            setAccessory({
                                ...accessory,
                                accessoryManufacturerA: e.target.value
                            })
                        } else if (newAccessB) {
                            setAccessory({
                                ...accessory,
                                accessoryManufacturerB: e.target.value
                            })
                        } else {
                            setAccessory({
                                ...accessory,
                                accessoryManufacturerC: e.target.value
                            })
                        }
                    }}
                    label="Manufacturer:"/>
                <InputLabel
                    height='46px'
                    value={
                        newAccessA ? accessory?.accessoryColorA :
                            newAccessB ? accessory?.accessoryColorB :
                                newAccessC ? accessory?.accessoryColorC : ''}
                    onChange={(e) => {
                        setFilters({...filters, color: e.target.value})
                        if (newAccessA) {
                            setAccessory({
                                ...accessory,
                                accessoryColorA: e.target.value
                            })
                        } else if (newAccessB) {
                            setAccessory({
                                ...accessory,
                                accessoryColorB: e.target.value
                            })
                        } else {
                            setAccessory({
                                ...accessory,
                                accessoryColorC: e.target.value
                            })
                        }
                    }}
                    maxW="203px"
                    label="Color:"/>
                <InputLabel
                    height='46px'
                    value={
                        newAccessA ? accessory?.accessoryProductCodeA :
                            newAccessB ? accessory?.accessoryProductCodeB :
                                newAccessC ? accessory?.accessoryProductCodeC : ''}
                    onChange={(e) => {
                        setFilters({...filters, productCode: e.target.value})
                        if (newAccessA) {
                            setAccessory({
                                ...accessory,
                                accessoryProductCodeA: e.target.value
                            })
                        } else if (newAccessB) {
                            setAccessory({
                                ...accessory,
                                accessoryProductCodeB: e.target.value
                            })
                        } else {
                            setAccessory({
                                ...accessory,
                                accessoryProductCodeC: e.target.value
                            })
                        }
                    }}
                    label="Product Code:"/>
            </HStack>
            <HStack spacing="5">
                <InputLabel
                    height='46px'
                    type="number"
                    pattern="[0-9]*"
                    value={newAccessA ? accessory?.accessoryLengthA :
                          newAccessB ? accessory?.accessoryLengthB :
                            newAccessC ? accessory?.accessoryLengthC : ''}
                    onChange={(e) => {
                        setFilters({...filters, length: e.target.value})
                        if (newAccessA) {
                            setAccessory({
                                ...accessory,
                                accessoryLengthA: e.target.value
                            })
                        } else if (newAccessB) {
                            setAccessory({
                                ...accessory,
                                accessoryLengthB: e.target.value
                            })
                        } else {
                            setAccessory({
                                ...accessory,
                                accessoryLengthC: e.target.value
                            })
                        }
                    }}
                    label="Dimensions-Length (in):"/>
                <InputLabel
                    height='46px'
                    type="number"
                    pattern="[0-9]*"
                    value={newAccessA ? accessory?.accessoryWidthA :
                            newAccessB ? accessory?.accessoryWidthB :
                             newAccessC ? accessory?.accessoryWidthC : ''}
                    onChange={(e) => {
                        setFilters({...filters, width: e.target.value})
                        if (newAccessA) {
                            setAccessory({
                                ...accessory,
                                accessoryWidthA: e.target.value
                            })
                        } else if (newAccessB) {
                            setAccessory({
                                ...accessory,
                                accessoryWidthB: e.target.value
                            })
                        } else {
                            setAccessory({
                                ...accessory,
                                accessoryWidthC: e.target.value
                            })
                        }
                    }}
                    label="Dimensions-Width (in):"/>
                <InputLabel
                    height='46px'
                    type="number"
                    value={newAccessA ? accessory?.accessoryDepthA :
                             newAccessB ? accessory?.accessoryDepthB :
                                newAccessC ? accessory?.accessoryDepthC : ''}
                    label="Dimensions-Depth (in):"
                    onChange={(e) => {
                        setFilters({...filters, depth: e.target.value})
                        if (newAccessA) {
                            setAccessory({
                                ...accessory,
                                accessoryDepthA: e.target.value
                            })
                        } else if (newAccessB) {
                            setAccessory({
                                ...accessory,
                                accessoryDepthB: e.target.value
                            })
                        } else {
                            setAccessory({
                                ...accessory,
                                accessoryDepthC: e.target.value
                            })
                        }
                    }}/>
                <InputLabel
                    height='46px'
                    type="number"
                    value={newAccessA ? accessory?.accessoryDiameterA :
                             newAccessB ? accessory?.accessoryDiameterB :
                              newAccessC ? accessory?.accessoryDiameterC : ''}
                    onChange={(e) => {
                        setFilters({...filters, diameter: e.target.value})
                        if (newAccessA) {
                            setAccessory({
                                ...accessory,
                                accessoryDiameterA: e.target.value
                            })
                        } else if (newAccessB) {
                            setAccessory({
                                ...accessory,
                                accessoryDiameterB: e.target.value
                            })
                        } else {
                            setAccessory({
                                ...accessory,
                                accessoryDiameterC: e.target.value
                            })
                        }
                    }}
                    label="Dimensions-Diameter (in):"/>
            </HStack>
        </Stack>
    )
}

export default Accessory
