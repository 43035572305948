import {Table, Thead, Tbody, Tr, Th, Td, Heading} from '@chakra-ui/react'
import styles from './styles'

const TableProjectEcoBig = ({width,laborHourExisting,laborHourLed, customBidding}) =>  (
        <Table width={width}>
            <Thead>
                <Tr>
                    <Th></Th>
                    <Th textAlign="center" color="black"><Heading fontSize='sm' m={0}>Monthly</Heading></Th>
                    <Th textAlign="center" color="black"><Heading fontSize='sm' m={0}>YEAR 1</Heading></Th>
                    <Th textAlign="center" color="black"><Heading fontSize='sm' m={0}>YEAR 3</Heading></Th>
                    <Th textAlign="center" color="black"><Heading fontSize='sm' m={0}>YEAR 5</Heading></Th>
                </Tr>
            </Thead>
            <Tbody>
                <Tr css={styles.tr}>
                    <Td p={0} textAlign="end" bg="white.100">Existing System:</Td>
                    <Td bg={customBidding ? '#A9A9A9' : '#FFE6D8'}>{Math.round(laborHourExisting / 12).toLocaleString('en-US')}</Td>
                    <Td bg={customBidding ? '#A9A9A9' : '#FFE6D8'}>{laborHourExisting.toLocaleString('en-US')}</Td>
                    <Td bg={customBidding ? '#A9A9A9' : '#FFE6D8'}>{Math.round(laborHourExisting * 3).toLocaleString('en-US')}</Td>
                    <Td bg={customBidding ? '#A9A9A9' : '#FFE6D8'}>{Math.round(laborHourExisting * 5).toLocaleString('en-US')}</Td>
                </Tr>
                <Tr css={styles.tr}>
                    <Td p={0} textAlign="end" bg="white.100">LED Upgrade</Td>
                    <Td bg={customBidding ? '#A9A9A9' : '#FFE6D8'}>{Math.round(laborHourLed / 12).toLocaleString('en-US')}</Td>
                    <Td bg={customBidding ? '#A9A9A9' : '#FFE6D8'}>{laborHourLed.toLocaleString('en-US')}</Td>
                    <Td bg={customBidding ? '#A9A9A9' : '#FFE6D8'}>{Math.round(laborHourLed * 3).toLocaleString('en-US')}</Td>
                    <Td bg={customBidding ? '#A9A9A9' : '#FFE6D8'}>{Math.round(laborHourLed * 5).toLocaleString('en-US')}</Td>
                </Tr>
                <Tr css={styles.tr}>
                    <Td p={0} textAlign="end" bg="white.100">Labor Hours Saved:</Td>
                    <Td bg={customBidding ? '#A9A9A9' : '#8FCF74'}>{Math.round(laborHourExisting / 12 - laborHourLed / 12).toLocaleString('en-US')}</Td>
                    <Td bg={customBidding ? '#A9A9A9' : '#8FCF74'}>{Math.round(laborHourExisting - laborHourLed).toLocaleString('en-US')}</Td>
                    <Td bg={customBidding ? '#A9A9A9' : '#8FCF74'}>{Math.round((laborHourExisting * 3) - (laborHourLed * 3)).toLocaleString('en-US')}</Td>
                    <Td bg={customBidding ? '#A9A9A9' : '#8FCF74'}>{Math.round((laborHourExisting * 5) - (laborHourLed * 5)).toLocaleString('en-US')}</Td>
                </Tr>
            </Tbody>
        </Table>
    )

export default TableProjectEcoBig