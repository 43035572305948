import React, {useState, useEffect} from 'react'
import {Stack, HStack, Box,} from '@chakra-ui/react'
import {ChakraSelectLabel, InputLabel} from '../../../components/Theme'
import NavigateModal from './NavigateModal'
import {POWER_SUPPLY_TYPE} from "../../../constants/utils";
import {useGetExsiting} from "../../../core/hooks";

const Power = ({description, power, setPower, onClose, setRecent, area, createKey, setCreateKey, setArea, luminaireId, setType,}) => {
    const [filters, setFilters] = useState({type: '', manufacturer: '', model: '', productCode: '',powerQTYFixture: ''})
    const {refetch} = useGetExsiting( {
        type : 'Power%20Supplies',
        powerType : power.powerSupplyType,
    })

    const create = () => {
        if (!power.powerSupplyType) return
        refetch().then((res) => {
            if (res?.data?.data?.data){
                setPower({...power, powerSupplyExisting: res.data.data.data})
            }
            const newArray = area?.luminaires.map((luminaire) => {
                let newObj = {...luminaire}
                if (newObj.id === luminaireId) {
                    newObj = {...newObj, ...power,powerSupplyExisting:res.data.data.data}
                }
                return newObj
            })
            setArea({...area, luminaires: newArray})
            setRecent([...newArray])
        })
    }

    if (createKey === true) {
        create()
        setCreateKey(false)
        onClose()
    }

    useEffect(() => {
        //Set description width parameters that exists
        const description = (power.powerSupplyType ? power.powerSupplyType : '') + (power.powerSupplyManufacturer ? ' ' + power.powerSupplyManufacturer : '') + (power.powerSupplyModel ? ' ' + power.powerSupplyModel : '') + (power.powerSupplyProductCode ? '-' + power.powerSupplyProductCode : '')
        setPower({...power, powerSupplyDescription: description})
    }, [filters])

    return (
        <Stack spacing="5">
            <NavigateModal
                label={description}
                leftAction={() => {
                    create()
                    setType('lamp')
                }}
                rightAction={() => {
                    create()
                    setType('control')
                }}
                left="Lamp"
                right="Control"/>
            <HStack w="full" justifyContent="center" position="relative" alignItems="center" pt={5}>
                <Box position="absolute" bg="#A9A9A9" w="full" h="1px"/>
                <Box px="2" position="relative" bg="white" textTransform="uppercase" fontWeight="400" fontSize="xl" color="#2C628D" as="span">
                    Power Supply details
                </Box>
            </HStack>
            <HStack spacing="5" alignItems="flex-end">
                <InputLabel
                    height='46px'
                    maxW="90px"
                    type="number"
                    value={power?.powerQTYFixture}
                    onChange={(e) => {
                        setFilters({...filters, powerQTYFixture: e.target.value})
                        setPower({...power, powerQTYFixture: e.target.value,})
                    }}
                    pattern="[0-9]*"
                    label="QTY/Fixture"/>
                <InputLabel height='46px' color='#737373' backgroundColor='#EEEEEE !important' readOnly label="Power Supply Description" value={power?.powerSupplyDescription}/>
            </HStack>
            <HStack w="full" justifyContent="center" position="relative" alignItems="center" py={5}>
                <Box position="absolute" bg="#A9A9A9" w="full" h="1px"/>
                <Box px="2" position="relative" bg="white" textTransform="uppercase" fontWeight="400" fontSize="xl" color="#2C628D" as="span">
                    filters
                </Box>
            </HStack>
            <HStack spacing="5">
                <ChakraSelectLabel height='46px' maxW="203px" label="Type:" placeholder="Select" value={power?.powerSupplyType}
                    onChange={(e) => {
                        setFilters({...filters, type: e.target.value})
                        setPower({...power, powerSupplyType: e.target.value})
                    }}>
                    {POWER_SUPPLY_TYPE.map((item, index) => (<option key={index} value={item}>{item}</option>))}
                </ChakraSelectLabel>
                <InputLabel height='46px' maxW="203px" label="Manufacturer:" value={power?.powerSupplyManufacturer}
                    onChange={(e) => {
                        setFilters({...filters, manufacturer: e.target.value})
                        setPower({...power, powerSupplyManufacturer: e.target.value})
                    }}/>
                <InputLabel
                    height='46px'
                    value={power?.powerSupplyModel}
                    onChange={(e) => {
                        setFilters({...filters, model: e.target.value})
                        setPower({...power, powerSupplyModel: e.target.value})
                    }}
                    label="Model:"/>
                <InputLabel
                    height='46px'
                    value={power?.powerSupplyProductCode}
                    onChange={(e) => {
                        setFilters({...filters, productCode: e.target.value})
                        setPower({...power, powerSupplyProductCode: e.target.value})
                    }}
                    label="Product Code:"/>
            </HStack>
        </Stack>
    )
}

export default Power
