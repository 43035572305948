import React from 'react'
import ReactDOM from 'react-dom'
import {ChakraProvider} from '@chakra-ui/react'
import App from './App'
import reportWebVitals from './reportWebVitals'
import theme from './styles/customTheme'
import Fonts from "./styles/Fonts";

ReactDOM.render(
    <ChakraProvider
        resetCSS theme={theme}>
        <Fonts />
        <App/>
    </ChakraProvider>,
    document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()