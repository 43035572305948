import {Box, Flex, Heading, Text, Input, Divider, Button, Image, Grid, GridItem} from "@chakra-ui/react";
import moment from "moment";
import {InputLabel, Textarea} from "../../../components/Theme";
import {ReactComponent as SignatureIcon} from "../../../assets/signatureIcon.svg";
import {ReactComponent as AuthIcon} from "../../../assets/okIcon.svg";
import Luminaires from "./parts/Luminaires";
import Loading from "../../../components/Loading";
import {ReactComponent as DoneIcon} from "../../../assets/doneIcon.svg";
import styles from "./styles";
import {REU} from "../../../constants/user/userEnum";

const OrderTab = ({
                      refererOrder,
                      authorizeOrder,
                      loading,
                      approveEstimate,
                      estimate,
                      luminaires,
                      subTotal,
                      notes,
                      salesTax,
                      shipping,
                      total,
                      depositAmount,
                      depositDue,
                      remainderDue,
                      remainderAmount,
                      onOpen,
                      currentDate,
                      user,
                      imageUrl,
                      imageUrlAdmin,
                      isLoading,
                  }) => {
    if (isLoading) return <Loading />
    return (
        <Box ref={refererOrder} display={estimate?.orderDateCreated ? 'block' : 'none'} backgroundColor='white' p={9}>
            <Flex justifyContent='space-between'>
                <img src="/images/LogoIcon.svg" alt=""/>
                <Flex flexDirection='column'>
                    <Heading m={0} textAlign='end'>Order</Heading>
                    <Text>Reference # : {estimate?.reference}</Text>
                    <Text textAlign='end'>Date : {moment(estimate?.orderDateCreated?.date).format('MM/DD/YYYY')}</Text>
                </Flex>
            </Flex>
            <Flex mt={4} justifyContent='space-between' fontSize='18px'>
                <Flex width='49%' borderRight='2px solid #9C9C9C' flexDirection='column'>
                    <Input  mb={3} readOnly border='none' fontFamily='Titillium Web' backgroundColor='#EEEEEE' width='98%' fontSize='18px' value='Billing Information:' color='#222222' fontWeight='700'/>
                    <Text>Focus Earth</Text>
                    <Text>Attn: Pete Musser</Text>
                    <Text>1070 Tunnel Road</Text>
                    <Text>Building 1, Suite 10, #202</Text>
                    <Text>Asheville, NC 28805</Text>
                    <Text>USA</Text>
                    <Text>(925) 323-0053</Text>
                </Flex>
                <Flex width='50%' flexDirection='column'>
                    <Input mb={3} readOnly border='none' fontFamily='Titillium Web' backgroundColor='#EEEEEE' width='100%' fontSize='18px' value='Shipping Information:' color='#222222' fontWeight='700'/>
                    <Text>{estimate?.userReu?.company[0]?.name}</Text>
                    <Text>Attn : {estimate?.userReu?.firstName + ' ' + estimate?.userReu?.lastName}</Text>
                    <Text>{estimate?.report?.project?.address}</Text>
                    <Text>{estimate?.report?.project?.address2}</Text>
                    <Text>{estimate?.report?.project?.city + ', ' + estimate?.report?.project?.state + ', ' + estimate?.report?.project?.zipCode}</Text>
                    <Text>USA</Text>
                    <Text>{estimate?.userReu?.phone}</Text>
                </Flex>
            </Flex>
            <Input readOnly border='none' backgroundColor='#8FCF74' fontFamily='Titillium Web' width='100%' fontSize='18px' fontWeight='700' color='#222222' value='Order Information' mt={10}/>
            <Grid templateColumns='repeat(7, 1fr)' my={5} px={4}>
                <GridItem colSpan={3} as='h2' m={0}>
                    Description
                </GridItem>
                <GridItem colSpan={1} as='h2' m={0} textAlign='center'>
                    Part Number
                </GridItem>
                <GridItem colSpan={1} as='h2' m={0} textAlign='center'>
                    QTY
                </GridItem>
                <GridItem colSpan={1} as='h2' m={0} textAlign='center'>
                    Price Per Unit
                </GridItem>
                <GridItem colSpan={1} as='h2' m={0} textAlign='center'>
                    Amount
                </GridItem>
            </Grid>
            <Luminaires luminaires={luminaires}/>
            <Flex justifyContent='end' mt={7}>
                <Flex width='50%' fontWeight='700' border='2px solid #8FCF74' height='64px' alignItems='center' justifyContent='space-between' pl={6} pr={2} borderRadius='6px'>
                    <Input readOnly fontWeight='600' fontFamily='Titillium Web' border='none' value='Sub Total:' fontSize='18px'/>
                    <Flex justifyContent='end'>
                        <Input readOnly border='none' fontFamily='Titillium Web' fontSize='14px' fontWeight='600' value={subTotal ? '$' + subTotal?.toLocaleString('en-US') : 0}/>
                    </Flex>
                </Flex>
            </Flex>
            <Flex fontSize='18px !important' justifyContent='space-between'>
                <Flex width='50%' flexDirection='column'>
                    <Text mb={2} color='#2C628D' fontWeight='700' fontFamily='Titillium Web'>Notes:</Text>
                    <Textarea readOnly value={notes} minH='130px'/>
                </Flex>
                <Flex flexDirection='column' color='#2C628D' mt={7}>
                    <Flex alignItems='center' justifyContent='space-between' minW='230px'>
                        <Text fontFamily='Titillium Web' textAlign='right'>Sales Tax:</Text>
                        <InputLabel css={styles.centeredInput}
                                    placeholder='0'
                                    leftAddon='$'
                                    type='number'
                                    disabled={user?.type === REU}
                                    readOnly value={salesTax ? salesTax?.toLocaleString('en-US') : 0}
                                    maxW='137px'/>
                    </Flex>
                    <Flex alignItems='center' justifyContent='space-between'>
                        <Text fontFamily='Titillium Web' >Shipping:</Text>
                        <InputLabel css={styles.centeredInput}
                                    placeholder='0'
                                    leftAddon='$' type='number'
                                    disabled={user?.type === REU}
                                    readOnly value={shipping ? shipping?.toLocaleString('en-US') : 0}
                                     maxW='137px'/>
                    </Flex>
                    <Flex alignItems='center' justifyContent='space-between'>
                        <Text fontFamily='Titillium Web' fontWeight='700' textAlign='right' minW='70px'>Total:</Text>
                        <InputLabel css={styles.centeredInput}
                                    placeholder='0'
                                  disabled={user?.type === REU}
                                    readOnly value={total ? '$' + total?.toLocaleString('en-US') : '$'}  style={{'border': '2px solid #8FCF74'}} maxW='137px'/>
                    </Flex>
                </Flex>
            </Flex>
            <Flex justifyContent='space-between'>
                <Flex width='50%' flexDirection='column' fontSize='14px'>
                    <Flex fontWeight='700' mt={8}>
                        <Box width='45%' fontFamily='Titillium Web' fontWeight='700'>Payment Terms:</Box>
                        <Text fontWeight='normal' width='55%'>50% Deposit due upon order placement<br/>50% Remainder due upon Delivery</Text>
                    </Flex>
                    <Flex fontWeight='700' mt={8}>
                        <Box width='45%' fontFamily='Titillium Web' fontWeight='700'>Make Checks Payable To:</Box>
                        <Text fontWeight='normal' color='#619C48' width='55%'>Focus Earth</Text>
                    </Flex>
                    <Flex fontWeight='700' mt={5}>
                        <Box width='45%' pr={4} fontFamily='Titillium Web' fontWeight='700'>Mail To:</Box>
                        <Text fontWeight='normal' width='55%'>1070 Tunnel Road <br/> Building 1, Suite 10, #202 <br/>Asheville, NC 28805</Text>
                    </Flex>
                    <Flex fontWeight='700' mt={5}>
                        <Box width='45%' pr={4} fontFamily='Titillium Web' fontWeight='700'>Electronic Payment:</Box>
                        <Text fontWeight='normal' width='55%'>Email accounting@focusearth.com</Text>
                    </Flex>
                </Flex>
                <Flex width='50%' fontSize='18px !important' flexDirection='column' justifyContent='end'
                      color='#2C628D'>
                    <Flex alignItems='center' justifyContent='end'>
                        <Text ml={7} fontFamily='Titillium Web'>Deposit Amount:</Text>
                        <InputLabel readOnly value={depositAmount ? '$' + Number(depositAmount)?.toLocaleString('en-US') : '$'} ml={5} maxW='137px'/>
                    </Flex>
                    <Flex alignItems='center' justifyContent='end'>
                        <Text ml={7} fontFamily='Titillium Web'>Deposit Due:</Text>
                        <InputLabel readOnly value={depositDue && moment(`${depositDue.year}-${depositDue.month}-${depositDue.day}`, 'YYYY-M-D').format('MM.DD.YYYY')} ml={5} maxW='137px'/>
                    </Flex>
                    <Flex alignItems='center' justifyContent='end'>
                        <Text ml={7} fontFamily='Titillium Web'>Remainder Amount:</Text>
                        <InputLabel ml={5} readOnly value={'$' + remainderAmount?.toLocaleString('en-US')} maxW='137px'/>
                    </Flex>
                    <Flex alignItems='center' justifyContent='end'>
                        <Text ml={7} fontFamily='Titillium Web'>Remainder Due:</Text>
                        <InputLabel readOnly value={remainderDue && moment(`${remainderDue.year}-${remainderDue.month}-${remainderDue.day}`, 'YYYY-M-D').format('MM.DD.YYYY')} ml={5} maxW='137px'/>
                    </Flex>
                </Flex>
            </Flex>
            <Flex justifyContent='space-between' alignItems='center' mt={10}>
                <Divider borderColor="#222222" border="2px solid" width='35%' orientation="horizontal"/>
                <Text width='max-content'>CLIENT AUTHORIZATION</Text>
                <Divider width='35%' borderColor="#222222" border="2px solid" orientation="horizontal"/>
            </Flex>
            <Flex justifyContent='space-between' mt={10} fontWeight='700'>
                <Flex width='33%' alignItems='center'>
                    <Text color='#2C628D' mr={3}>Print Name:</Text>
                    <Input readOnly value={estimate?.userReu?.firstName + ' ' + estimate?.userReu?.lastName} fontWeight='700' style={{'width': '174px', 'height': '32px'}}/>
                </Flex>
                <Flex alignItems='center' width='33%' justifyContent='center' fontWeight='700'>
                    <Text fontWeight='700' color='#2C628D' mr={3}>Signature:</Text>
                    <Flex pr={2} pl={2} width='207px' alignItems='center' height='32px' justifyContent='space-between' borderRadius='6px' border='1px solid #A9A9A9'>
                        <Text height='100%' width='100%'>
                            {imageUrl ? (
                                <Image src={imageUrl} objectFit='contain' height='100%' alt="Electronic signature" width='100%'/>
                            ) : ('')}
                        </Text>
                        <Text as='span' onClick={estimate?.reuApproved || user?.type !== 'Reu' ? '' : onOpen} cursor='pointer'>
                            <SignatureIcon/>
                        </Text>
                    </Flex>
                </Flex>
                <Flex alignItems='center' width='33%' justifyContent='end'>
                    <Text fontWeight='700' color='#2C628D' mr={3}>Date:</Text>
                    <Input fontWeight='700' readOnly width='174px' height='32px' value={estimate?.reuSignatureDate ? estimate.reuSignatureDate : currentDate}/>
                </Flex>
            </Flex>
            <Flex display={user?.type === 'Reu' ? 'flex' : 'none'} mt={4} justifyContent='end'>
                <Button isLoading={loading} onClick={approveEstimate} fontSize='14px' leftIcon={<AuthIcon/>} width='170px' backgroundColor='#246B06'>
                    <Input p={0} width='70px' fontSize='14px' cursor='pointer' readOnly border='none' value={estimate?.reuApproved ? 'Authorized' : 'Authorize'}/>
                </Button>
            </Flex>
            <Flex justifyContent='space-between' alignItems='center' mt={5}>
                <Divider borderColor="#222222" border="2px solid" width='35%' orientation="horizontal"/>
                <Text width='max-content'>FOCUS EARTH AUTHORIZATION</Text>
                <Divider width='35%' borderColor="#222222" border="2px solid" orientation="horizontal"/>
            </Flex>
            <Flex justifyContent='space-between' mt={10} fontWeight='700'>
                <Flex width='33%' alignItems='center'>
                    <Text color='#2C628D' mr={3}>Print Name:</Text>
                    <Input readOnly value={estimate?.userAdminConsultant?.firstName + ' ' + estimate?.userAdminConsultant?.lastName} fontWeight='700' style={{'width': '174px', 'height': '32px'}}/>
                </Flex>
                <Flex alignItems='center' width='33%' justifyContent='center' fontWeight='700'>
                    <Text fontWeight='700' color='#2C628D' mr={3}>Signature:</Text>
                    <Flex pr={2} pl={2} width='207px' alignItems='center' height='32px' justifyContent='space-between' borderRadius='6px' border='1px solid #A9A9A9'>
                        <Text height='100%' width='100%'>
                            {imageUrlAdmin ? (
                                <Image objectFit='contain' height='100%' alt="Electronic signature" width='100%' src={imageUrlAdmin}/>
                            ) : ('')}
                        </Text>
                        <Text as='span' onClick={user?.type === 'Reu' ? '' : onOpen} cursor='pointer'>
                            <SignatureIcon/>
                        </Text>
                    </Flex>
                </Flex>
                <Flex alignItems='center' width='33%' justifyContent='end'>
                    <Text fontWeight='700' color='#2C628D' mr={3}>Date:</Text>
                    <Input fontWeight='700' readOnly value={estimate?.adminSignatureDate ? estimate.adminSignatureDate : currentDate} style={{'width': '174px', 'height': '32px'}}/>
                </Flex>
            </Flex>
            <Flex display={user?.type === 'Reu' ? 'none' : 'flex'} mt={4} justifyContent='end'>
                <Button
                    isLoading={loading}
                    onClick={authorizeOrder}
                    fontSize='14px'
                    width='170px'
                    leftIcon={estimate?.adminSignature ? <DoneIcon/> : <AuthIcon/>}
                    color={estimate?.adminSignature ? 'black' : 'white'}
                    backgroundColor={estimate?.adminSignature ? '#80CC5F' : '#246B06'}>
                    <Input
                        p={0}
                        width='70px'
                        fontSize='14px'
                        cursor='pointer'
                        readOnly
                        border='none'
                        value={estimate?.adminSignature ? 'Authorized!' : 'Authorize'}/>
                </Button>
            </Flex>
        </Box>
    )
}
export default OrderTab