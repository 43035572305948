import {Link} from 'react-router-dom'
import {Text, Heading, Stack, Button as TextButton,} from '@chakra-ui/react'
import {Button} from '../Theme'
import {ReactComponent as ConfirmIcon} from "../../assets/checkYourEmailIconRegister.svg";

const VerifyEmail = () => (
    <Stack
        mb={10}
        spacing={8}
        alignItems="center"
        flexDirection="column"
        justifyContent="space-between">
        <Heading
            textAlign="center"
            mt={-5}
            mb={3}
            as="h2"
            fontSize="4xl">
            <Link
                to="/register">
                <TextButton
                    fontWeight="600"
                    variant="transparent"
                    position="absolute"
                    top="30px"
                    left="30px">
                    <img
                        width="25px"
                        src="/images/back_register.png"
                        alt="back arrow"/>
                    <Text
                        ml={1}
                        fontSize="18px"
                        fontWeight='600'>
                        BACK
                    </Text>
                </TextButton>
            </Link>
            Check Your Email
        </Heading>
        <ConfirmIcon/>
        <Text
            textAlign="center"
            fontWeight="600"
            fontSize="24px">
            You will receive an email confirming your sign up.
            <br/>
            We&apos;re excited to welcome you to the Focus Earth community!
        </Text>
        <Link
            to="/login">
            <Button
                mt={10}
                colorScheme="blue.200"
                type="submit"
                height="40px !important"
                width="467px">
                <img
                    width="25px"
                    src="/images/login.svg"
                    alt="Energy"/>
                <Text
                    ml={1}
                    color="white"
                    fontWeight="700"
                    fontSize="18px">
                    Login
                </Text>
            </Button>
        </Link>
    </Stack>
)

export default VerifyEmail
