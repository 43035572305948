import { Button as ChakraButton } from '@chakra-ui/react'
import styled from '@emotion/styled'

const TextButton = styled(ChakraButton)`
    font-weight: 700;
    height: unset;
    width: unset;
    padding: 0;
    margin: 0;
    background: none;
    border-radius: none;
    &:hover {
        background: none;
    }
    box-shadow: none;
    &:focus {
        box-shadow: none;
    }
`
export default TextButton
