import {
    FormControl,
    Checkbox,
    FormLabel,
    Menu,
    MenuButton,
    Flex,
    MenuList,
    Box,
    Text,
    Divider
} from '@chakra-ui/react'
import styles from './styles'
import {ELECTRICAL_TYPE, REAL_ESTATE_TYPE, SUPPLIER_TYPE} from "../../constants/company/companyEnum";

const CustomSelect = ({label, value, admins, users, setValue, setNames, names, admin, setAdmin, companyType}) => (
    <FormControl
        css={styles.menu}>
        <FormLabel
            fontWeight="600"
            fontSize="18px">
            {label}
        </FormLabel>
        <Menu>
            <MenuButton
                as={Flex}
                px={4}
                alignItems="center"
                border="1px solid"
                fontSize='xl'
                overflow="scroll"
                borderColor="rgba(169, 169, 169, 0.87)"
                borderRadius="5px"
                bg="white.100"
                height="50px">
                <Text
                    whiteSpace="nowrap"
                    fontSize="xl">
                    {(() => {
                            let a = []
                            if (value.length > 0) {
                                for (let i = 0; i < value.length; i++) {
                                    a.push(value[i] + ', ')
                                }
                                if (a) {
                                    let res = a[a.length - 1].slice(0, -2)
                                    a.pop()
                                    a.push(res)
                                }
                            }
                            if (admin.length > 0) {
                                if (a.length > 0) {
                                    a.push(' ,' + admin[1])
                                } else {
                                    a.push(admin[1])
                                }
                            }
                            return a
                        }
                    )()}
                </Text>
            </MenuButton>
            <MenuList
                width='100% !important'
                pl={2}>
                <Flex
                    flexDirection='column'>
                    <Flex
                        mb={2}
                        alignItems='center'
                        justifyContent='space-between'
                        width='100% !important'>
                        <Box
                            borderRadius='50%'
                            bg='#FFD339'
                            height='9px !important'
                            width='12px !important'/>
                        <Text
                            fontWeight='300'
                            fontSize='14px'
                            fontFamily='Titillium Web'
                            ml={3}
                            width='40%'>
                            {
                                companyType === SUPPLIER_TYPE ? 'Supplier'
                                    : companyType === REAL_ESTATE_TYPE ? 'Real Estate'
                                        : companyType === ELECTRICAL_TYPE ? 'Electrician'
                                            : 'User'
                            }
                        </Text>
                        <Divider
                            borderColor='#000000'
                            border='1px solid'/>
                    </Flex>
                    {
                        users?.map((item) => (
                            <Box
                                width='100% !important'
                                key={item.id}>
                                <Checkbox
                                    isChecked={value?.includes(item.firstName + ' ' + item.lastName)}
                                    name={item.firstName + ' ' + item.lastName}
                                    onChange={(e) => {
                                        let s = []
                                        let ides = []
                                        if (e.target.checked) {
                                            s.push(item.firstName + ' ' + item.lastName)
                                            ides.push(item.id)
                                            ides = ides.concat(names)
                                            s = s.concat(value)
                                        } else {
                                            let index = value.indexOf(item.firstName + ' ' + item.lastName)
                                            let index2 = value.indexOf(item.id)
                                            value.splice(index, 1)
                                            names.splice(index2, 1)
                                            s = s.concat(value)
                                            ides = ides.concat(names)
                                        }
                                        setNames(ides)
                                        setValue(s)
                                    }}
                                    iconColor="black"
                                    colorScheme="whiteAlpha">
                                    <Text as='h2' m={0} fontSize='xl'>
                                        {item.firstName + ' ' + item.lastName}
                                    </Text>
                                </Checkbox>
                            </Box>
                        ))
                    }
                    <Flex
                        mt={2}
                        mb={2}
                        alignItems='center'
                        justifyContent='space-between'
                        width='100% !important'>
                        <Box
                            borderRadius='50%'
                            bg={companyType === SUPPLIER_TYPE ? '#ACDAFF' : '#C956FF'}
                            height='9px !important'
                            width='12px !important'/>
                        <Text
                            fontWeight='300'
                            fontSize='14px'
                            fontFamily='Titillium Web'
                            ml={3}
                            width='20%'>
                            Admin
                        </Text>
                        <Divider
                            borderColor='#000000'
                            border='1px solid'/>
                    </Flex>
                    {
                        admins.map((item) => (
                            <Box
                                width='100% !important'
                                key={item.id}>
                                <Checkbox
                                    isChecked={Number(admin[0]) === item.id}
                                    value={item.id}
                                    name={item.firstName + ' ' + item.lastName}
                                    onChange={(e) => {
                                        if (e.target.value === admin) {
                                            setAdmin("")
                                        } else {
                                            let a = [e.target.value, item.firstName + ' ' + item.lastName]
                                            setAdmin(a)
                                        }
                                    }}
                                    iconColor="black"
                                    colorScheme="whiteAlpha">
                                    <Text as='h2' m={0} fontSize='xl'>
                                        {item.firstName + ' ' + item.lastName}
                                    </Text>
                                </Checkbox>
                            </Box>

                        ))
                    }
                </Flex>
            </MenuList>
        </Menu>
    </FormControl>
)

export default CustomSelect
