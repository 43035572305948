import {Route as ReactRoute, useLocation, Redirect} from 'react-router-dom'
import {useUser} from '../core/hooks'

const Route = ({path, component, secured, Layout, exact,}) => {
    const {user} = useUser()
    const location = useLocation()
    const isAuth = !!user

    if (user === undefined) {
        return <></>
    }
    if (location.pathname === '/') {
        return <Redirect to={`/login?redirect=${location.pathname + location.search.replace(/&/g, '`')}`}/>
    }
    if (secured && !isAuth) {
        return <Redirect to={`/login?redirect=${location.pathname + location.search.replace(/&/g, '`')}`}/>
    }
    if (!secured && isAuth) {
       return <Redirect to="/recent-activity"/>
    }

    return (
        <Layout>
            <ReactRoute
                exact={exact}
                path={path}
                component={component}/>
        </Layout>
    )
}

export default Route
