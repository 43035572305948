import React, { useEffect, createRef, useState } from 'react'
import {
  Flex,
  Text,
  Box,
} from '@chakra-ui/react'
import Icon from '../Icon'

const DragDrop = ({
  handleFile,
  isCreatingLoading,
  success,
  error,
  isLoading,
  isCreatingAccessoryLoading,
}) => {
  const [dragging, setDragging] = useState(false)

  const handleDrag = (e) => {
    e.preventDefault()
    e.stopPropagation()
  }
  const handleDragIn = (e) => {
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      setDragging(true)
    }
    e.preventDefault()
    e.stopPropagation()
  }
  const handleDragOut = (e) => {
    setDragging(false)
    e.preventDefault()
    e.stopPropagation()
  }

  const handleDrop = (e) => {
    e.preventDefault()
    e.stopPropagation()
    const files = e.dataTransfer?.files
    if (files[0]?.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
      return
    }
    if (files && files[0]) handleFile(files[0])
    // setError('Wrong file format')
  }

  const dropRef = createRef()

  useEffect(() => {
    const div = dropRef.current
    div.addEventListener('dragenter', handleDragIn)
    div.addEventListener('dragleave', handleDragOut)
    div.addEventListener('dragover', handleDrag)
    div.addEventListener('drop', handleDrop)
    return () => {
      div.removeEventListener('dragenter', handleDragIn)
      div.removeEventListener('dragleave', handleDragOut)
      div.removeEventListener('dragover', handleDrag)
      div.removeEventListener('drop', handleDrop)
    }
  }, [])

  return (
    <Flex
      bg={(isLoading || isCreatingLoading || isCreatingAccessoryLoading) ? '#DBDADA' : success ? '#AAEE8D' : error ? 'red.100' : 'white.100'}
      zIndex={1}
      opacity={dragging ? '.5' : '1'}
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      width="100%"
      height="179px"
      borderRadius="10px"
      position="relative"
      overflow="hidden"
      border="3px dashed"
      borderColor="#A9A9A9">
      <Box
        ref={dropRef}
        onDrop={handleDrop}
        zIndex={2}
        bg="transparent"
        width="100%"
        height="100%"
        position="absolute"/>
      {
        (isLoading || isCreatingLoading || isCreatingAccessoryLoading) ? (
          <Box
            as="span"
            fontStyle="italic">
            Uploading...
          </Box>
        ) : (
          success ? (
            <>
              <Box
                fontWeight="700"
                as="span">
                Upload Successful!
              </Box>
              <Text
                mt={5}
                fontSize="xl">
                Drag + Drop To Add More
              </Text>
            </>
          ) : error ? (
            <Text
              fontWeight="bold"
              color="white"
              as="span">
              Uploading failed. Download template fill all fields and upload again.
            </Text>
          ) : (
            <>
              <Icon
                style={{
                  zIndex: 1,
                  maxWidth: '50px',
                  maxHeight: '50px',
                }}
                type="upload"/>
              <Text
                mt={5}
                fontSize="xl">
                <Box
                  fontWeight="700"
                  as="span">
                  Drag + Drop
                </Box>
                &nbsp;
                Files to Upload
              </Text>
            </>
          )
        )
      }
    </Flex>
  )
}

export default DragDrop
