import {useQuery} from 'react-query'
import {projectUseCase} from '../../factories'

const useGetProject = (id) => {
    const {
        refetch, isLoading, data, error,
    } = useQuery('project', () => projectUseCase.getProject(id), {refetchOnWindowFocus: false})

    return {
        refetch,
        isLoading,
        error,
        data,
    }
}

export default useGetProject
