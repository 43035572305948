import {Button, Flex, Text} from "@chakra-ui/react";
import mainStyles from "../../../styles/mainStyles";
import moment from "moment/moment";
import downloadFile from "../../../constants/mainFunctions";
import {ReactComponent as Download} from "../../../assets/Icons/DownloadIcon.svg";


const FileComponent = ({downloads}) => {
    return (
        <>
            {downloads?.map((value) => (
            <Flex
                css={mainStyles.file}
                key={value?.file?.files?.key}>
                <Text
                    width='40%'
                    fontWeight="500">
                    {value?.file?.name?.replace(/(.{30})..+/, "$1…")}
                </Text>
                <Text
                    width='20%'
                    color='#A9A9A9'
                    fontWeight="700">
                    {moment(value?.file?.createdAt?.date).format('MMM DD, YYYY')}
                </Text>
                <Flex
                    justifyContent='end'
                    width='40%'>
                    <Button
                        onClick={e => downloadFile(e, value?.file?.name)}
                        mr={8}
                        leftIcon={<Download/>}
                        bg="#2C628D" color="white" height="30px" fontSize="12px">
                        Download
                    </Button>
                </Flex>
            </Flex>
        ))}
        </>
    )
}

export default FileComponent