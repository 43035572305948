import {InviteModal, Textarea} from "../../../components/Theme";
import {Text, VStack} from '@chakra-ui/react'
import TextLabel from "../../../components/Theme/TextLabel";

const ViewAssociatedUsersModal = ({isOpen, onClose, company, onOpenDeleteUser}) => (
    <InviteModal
        isOpen={isOpen}
        onClose={onClose}
        bg="#FFE68D"
        title="Associated Users">
        <VStack py={8} maxW='418px' m='auto'>
            <Text fontSize='3xl'>
                View Users Associated With Company
            </Text>
            <TextLabel
                mt={4}
                mb={10}
                pl='30px'
                color='#5A5A5ADE'
                fontFamily='Titillium Web'
                bg='#DADADA'
                text={company?.name}/>
            <VStack bg='#FBFBFB' border='1px solid #A9A9A9' borderRadius='6px' w='full' py={5} maxH='218px' overflow='scroll'>
                {company?.owner && <Text>{company?.owner?.firstName + ' ' + company?.owner?.lastName}</Text>}
                {company?.usersAssociated?.map((item, index) => (
                    <Text key={index}>
                        {item.firstName + ' ' + item.lastName}
                    </Text>
                ))}
            </VStack>
            <Text color='#5A5A5ADE' fontFamily='Titillium Web'>Need to <Text as='span' textDecoration='underline' cursor='pointer' onClick={onOpenDeleteUser}>sever existing user</Text> from company?</Text>
        </VStack>

    </InviteModal>
)

export default ViewAssociatedUsersModal