import {
    FormControl, Checkbox, FormLabel, Menu, MenuButton, Flex, MenuList, Grid, GridItem, Text,
} from '@chakra-ui/react'
import styles from './styles'
import {ReactComponent as ArrowDown} from "../../assets/downArrow.svg";

const CustomSelect = ({label, value, options, setValue, repeat,}) => (
    <FormControl css={styles.menu}>
        <FormLabel fontWeight="600" fontSize="md" color="blue.200">{label}</FormLabel>
        <Menu>
            <MenuButton as={Flex} px={4} alignItems="center" border="1px solid rgba(169, 169, 169, 0.87)" overflow="scroll" borderRadius="5px" bg="white.100" height="50px">
                <Text whiteSpace="nowrap" fontSize="xl">
                    {value[1]}
                </Text>
                <Flex alignItems='center' height="50px" top='8px' right='15px' position='absolute'>
                    <ArrowDown />
                </Flex>
            </MenuButton>
            <MenuList  fontSize='18px' pl={6}>
                <Grid templateColumns={`repeat(${repeat}, 1fr)`}>
                    {Object.entries(options).map((item) => (
                            <GridItem key={item[0]} colSpan={1}>
                                <Checkbox
                                    css={styles.checkboxComp}
                                    type='radio'
                                    iconBorder='2px solid black'
                                    isChecked={value?.includes(item[1])}
                                    name={item[1]}
                                    onChange={() => setValue(item)}
                                    iconColor="black">
                                    {item[1]}
                                </Checkbox>
                            </GridItem>
                        ))}
                </Grid>
            </MenuList>
        </Menu>
    </FormControl>
)

export default CustomSelect
