import HttpRequest from "./http-request";

const dataService = () => {
    const {get} = HttpRequest()

    const getUsageMain = () => {
        return get('get-data-main')
    }

    const getUsage = (type) => {
        return get(`get-usage/${type}`)
    }

    const getDataWithType = (type) => {
        return get(`get-data/${type}`)
    }

    return {
        getUsageMain,
        getUsage,
        getDataWithType,
    }
}

export default dataService
