import React, {useEffect, useState} from 'react'
import {
    Text,
    Grid,
    GridItem,
    Flex,
    Box, Heading,
} from '@chakra-ui/react'
import {INTERESTS} from '../../constants'

const Interests = ({interests}) => {
    const [active, setActive] = useState([])

    const handleActive = (item) => {
        if (active.includes(item)) {
            return setActive(active.filter((string) => string !== item))
        }
        return setActive([...active, item])
    }

    useEffect(() => {
        interests.current = active
    }, [active, interests])

    return (
        <>
            <Heading
                mt={4}
                mb={3}
                textAlign="center"
                as="h2"
                fontSize="4xl">
                What are your primary interests?
                <Text
                    textAlign="center"
                    fontSize="sm">
                    (Click all that apply)
                </Text>
            </Heading>
            <Grid
                gap={10}
                mt={20}
                mb={20}
                templateColumns="repeat(5, 1fr)"
                width="100%">
                {
                    INTERESTS.map((item) => (
                        <GridItem key={item.title}>
                            <Flex
                                onClick={() => handleActive(item.title)}
                                bg={active.includes(item.title) ? item.active : item.passive}
                                border={active.includes(item.title) ? `4px solid ${item.active}` : 'none'}
                                boxShadow={active.includes(item.title) ? 'inset 0px 0px 4px 4px rgba(34, 34, 34, 0.26)' : 'none'}
                                alignItems="center"
                                justifyContent="center"
                                cursor="pointer"
                                position="relative"
                                overflow="hidden"
                                height="125px"
                                width="136px"
                                borderRadius="6px">
                                <img
                                    style={{
                                        maxHeight: '50px',
                                        maxWidth: '50px',
                                    }}
                                    src={active.includes(item.title) ? `/images/${item.logo}_black.png` : `/images/${item.logo}.png`}
                                    alt={item.logo}/>
                                <Box
                                    fontWeight="500"
                                    fontSize="15px"
                                    color={active.includes(item.title) ? 'black' : 'white'}
                                    position="absolute"
                                    bottom="10px"
                                    as="span">
                                    {item.title}
                                </Box>
                            </Flex>
                        </GridItem>
                    ))
                }

                <GridItem>
                    <Flex
                        onClick={() => handleActive('Technology')}
                        bg={active.includes('Technology') ? 'rgb(8, 94, 138)' : 'rgb(127, 171, 193)'}
                        border={active.includes('Technology') ? '4px solid rgb(8, 94, 138)' : 'none'}
                        boxShadow={active.includes('Technology') ? 'inset 0px 0px 4px 4px rgba(34, 34, 34, 0.26)' : 'none'}
                        alignItems="center"
                        justifyContent="center"
                        cursor="pointer"
                        position="relative"
                        overflow="hidden"
                        height="125px"
                        width="136px"
                        borderRadius="10px">
                        <img
                            style={{
                                maxHeight: '50px',
                                maxWidth: '50px',
                            }}
                            src={active.includes('Technology') ? "/images/technology_black.png" : '/images/technology.png'}
                            alt="technology"/>
                        <Box
                            fontWeight="500"
                            fontSize="15px"
                            color={active.includes('Technology') ? 'black' : 'white'}
                            position="absolute"
                            bottom="10px"
                            as="span">
                            Technology
                        </Box>
                    </Flex>
                </GridItem>
                <GridItem>
                    <Flex
                        onClick={() => handleActive('Finance')}
                        bg={active.includes('Finance') ? 'rgb(69, 121, 7)' : 'rgb(180, 208, 147)'}
                        border={active.includes('Finance') ? '4px solid rgb(69, 121, 7)' : 'none'}
                        boxShadow={active.includes('Finance') ? 'inset 0px 0px 4px 4px rgba(34, 34, 34, 0.26)' : 'none'}
                        alignItems="center"
                        justifyContent="center"
                        cursor="pointer"
                        position="relative"
                        overflow="hidden"
                        height="125px"
                        width="136px"
                        borderRadius="10px">
                        <img
                            style={{
                                maxHeight: '50px',
                                maxWidth: '50px',
                            }}
                            src={active.includes('Finance') ? "/images/finance_black.png" : '/images/finance.png'}
                            alt="finance"/>
                        <Box
                            fontWeight="500"
                            fontSize="15px"
                            color={active.includes('Finance') ? 'black' : 'white'}
                            position="absolute"
                            bottom="10px"
                            as="span">
                            Finance
                        </Box>
                    </Flex>
                </GridItem>
            </Grid>

        </>
    )
}

export default Interests
