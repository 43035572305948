import React, {useEffect, useRef, useState} from 'react'
import {useParams, Link} from 'react-router-dom'
import {Box, Flex, Image, List, Text, ListItem, Button,} from '@chakra-ui/react'
import Navbar from '../../components/Navbar/Navbar'
import {useGetProduct} from '../../core/hooks'
import styles from './styles'
import {ReactComponent as Edit} from '../../assets/edit.svg'
import {ReactComponent as Eye} from '../../assets/EyeIconProducts.svg'
import Loading from "../../components/Loading";

const Product = () => {
    const params = useParams()
    const [data, setData] = useState()
    const [orderShow, setOrderShow] = useState(false)
    const [colorShow, setColorShow] = useState(false)
    const [certifShow, setCertifShow] = useState(false)
    const [supplierUser, setSupplierUser] = useState({})
    const {data: productResponse, isLoading} = useGetProduct({id: params.id, type: params.type})
    const orderRef = useRef(null)
    const colorRef = useRef(null)
    const certifRef = useRef(null)

    useEffect(() => {
        if (!productResponse) return
        setData(productResponse?.data?.data[0])
        setSupplierUser(productResponse?.data?.data[1][0])
    }, [productResponse])


    useEffect(() => {
        const handleClickOutside = (event) => {
            if (orderRef.current && !orderRef.current.contains(event.target)) {
                setOrderShow(false)
            }
            if (colorRef.current && !colorRef.current.contains(event.target)) {
                setColorShow(false)
            }
            if (certifRef.current && !certifRef.current.contains(event.target)) {
                setCertifShow(false)
            }
        }
        document.addEventListener('mousedown', handleClickOutside)

        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        };
    }, [])

    if (isLoading) return <Loading />

    return (
        data ? (
            <>
                <Navbar minW="750px"
                        title={(
                            <Flex>
                                <Link to="/catalogues">Catalogues /&nbsp;</Link>
                                <Link style={{textTransform: 'capitalize'}} to={`/catalogues/${params.type}`}>{params.type}</Link>
                                <Text>&nbsp;/&nbsp;{params.type === 'lamps' ? data['Description'] :data['FE Product Description']}</Text>
                            </Flex>
                        )}/>
                <Box css={styles.container}>
                    <Flex className='header'>
                        <Flex width='100%'>
                            <Flex className='header_image'>
                                <Image src={!data['Image'] ? '/images/defaultLamp.jpg' : data['Image'][0].url}/>
                            </Flex>
                            <Box className='header_content'>
                                <Text className='content_title'>{params.type ==='fixtures' ?
                                    (data['Length (inches)'] ? data['Length (inches)']?.toString()?.replace(/(.{15})..+/, "$1…") + '"x' : ' ' )+
                                    (data['Width (inches)'] ? data['Width (inches)']?.toString()?.replace(/(.{15})..+/, "$1…") + '"x' : ' ' )+
                                    (data['Diameter (inches)'] ? data['Diameter (inches)']?.toString()?.replace(/(.{15})..+/, "$1…") + '"x' : ' ' )+
                                    (data['Depth (inches)'] ? data['Depth (inches)']?.toString()?.replace(/(.{15})..+/, "$1…") + '" ' : ' ' )+
                                    (data['Style'] ? data['Style']?.toString()?.replace(/(.{15})..+/, "$1…") + ' ': '' )+
                                    (data['Mount'] ? data['Mount']?.toString()?.replace(/(.{15})..+/, "$1…") + ' ': '' )+
                                    (data['Shape'] ? data['Shape']?.toString()?.replace(/(.{15})..+/, "$1…") + ' ': '' )+
                                    (data['Wattage'] ? data['Wattage']?.toString()?.replace(/(.{15})..+/, "$1…") + 'W ': '' )+
                                    (data['Lumens'] ? data['Lumens']?.toString()?.replace(/(.{15})..+/, "$1…") + 'L ' : '' ) +
                                    (data["CCT (K˚)"] ? data["CCT (K˚)"]?.split(',')?.map(s => s + "K").toString()?.replace(/(.{15})..+/, "$1…").replace(/,/g, ", ")+ ' ' : '') +
                                    (data['CRI'] ? data['CRI']?.toString()?.replace(/(.{15})..+/, "$1…") + 'CRI ' : '' )
                                    :
                                    (data['Length (inches)'] ? data['Length (inches)']?.toString()?.replace(/(.{15})..+/, "$1…") + '"x' : ' ' )+
                                    (data['Width (inches)'] ? data['Width (inches)']?.toString()?.replace(/(.{15})..+/, "$1…") + '"x' : ' ' )+
                                    (data['Diameter (inches)'] ? data['Diameter (inches)']?.toString()?.replace(/(.{15})..+/, "$1…") + '"x' : ' ' )+
                                    (data['Depth (inches)'] ? data['Depth (inches)']?.toString()?.replace(/(.{15})..+/, "$1…") + '" ' : ' ' )+
                                    (data['Category'] ? data['Category']?.toString() + ' ': '' )+
                                    (data['Type'] ? data['Type']?.toString() + ' ': '' )+
                                    (data['Base Category - Type'] ? data['Base Category - Type']?.toString() + ' ': '' )+
                                    (data['Wattage'] ? data['Wattage']?.toString()?.replace(/(.{15})..+/, "$1…") + 'W ': '' )+
                                    (data['Lumens'] ? data['Lumens']?.toString()?.replace(/(.{15})..+/, "$1…") + 'L ' : '' )+
                                    (data["CCT (K˚)"] ? data["CCT (K˚)"]?.split(',')?.map(s => s + "K")?.toString()?.replace(/(.{15})..+/, "$1…").replace(/,/g, ", ")+ ' ' : '') +
                                    (data['CRI'] ? data['CRI']?.toString()?.replace(/(.{15})..+/, "$1…") + 'CRI ' : '' ) }
                                </Text>
                                <Flex className='content_titles'>
                                    <Box>
                                        <Text>Supplier: <span>{supplierUser?.company?.length > 0 ? supplierUser?.company[0]?.name : supplierUser?.associatedToCompany?.name}</span></Text>
                                        <Text>Manufacturer: <span>{data['Manufacturer']}</span></Text>
                                    </Box>
                                    <Box>
                                        <Text>Product Type: <span>{params.type.slice(0,-1)}</span></Text>
                                        <Text display='flex' alignItems='center'  ref={orderRef}>
                                            Order Code: <Eye className='eye_icon' onClick={() => setOrderShow(true)}/>
                                            <Box as='span' position='relative'>{data['Order Code']?.toString()?.replace(/(.{15})..+/, "$1…")}
                                                <Box display={orderShow ? 'block' : 'none'} className='array_action'>{data['Order Code']?.toString()}</Box>
                                            </Box>
                                        </Text>
                                    </Box>
                                </Flex>
                            </Box>
                        </Flex>
                        <Link to={`/catalogues/edit-product/${params.type}/${params.id}`}>
                            <Button leftIcon={<Edit/>} width="112px" size="sm" variant="lightOrange">Edit Details</Button>
                        </Link>
                    </Flex>
                    <Flex className='main_lists'>
                        <Flex className='list'>
                            <List>
                                <ListItem>Dimensions - Length  (in):</ListItem>
                                <ListItem>Dimensions - Width (in):</ListItem>
                                <ListItem>Dimensions - Diameter (in):</ListItem>
                                <ListItem>Dimensions - Depth (in):</ListItem>
                                <ListItem>Application:</ListItem>
                                <ListItem>{params.type === 'fixtures' ? 'Style:' : 'Category:'}</ListItem>
                                <ListItem>{params.type === 'fixtures' ? 'Mount:' : 'Type:'}</ListItem>
                                <ListItem display={params.type === 'fixtures' ? 'none' : 'block'}>Base Category:</ListItem>
                                <ListItem>{params.type === 'fixtures' ? 'Shape:' : 'Base Type:'}</ListItem>
                                <ListItem>{params.type === 'fixtures' ? 'Lens Type:' : 'Lens Type:'}</ListItem>
                                <ListItem>Ingress Protection:</ListItem>
                                <ListItem display={params.type === 'fixtures' ? 'block' : 'none'}>Fixture Color:</ListItem>
                                <ListItem display={params.type === 'fixtures' ? 'block' : 'none'}>Trim:</ListItem>
                            </List>
                            <List>
                                <ListItem>{data['Length (inches)'] ? data['Length (inches)']?.toString()?.replace(/(.{15})..+/, "$1…") + '"' : ' '}</ListItem>
                                <ListItem>{data['Width (inches)'] ? data['Width (inches)']?.toString()?.replace(/(.{15})..+/, "$1…") + '"' : ' '}</ListItem>
                                <ListItem>{data['Diameter (inches)'] ? data['Diameter (inches)']?.toString()?.replace(/(.{15})..+/, "$1…") + '"' : ' '}</ListItem>
                                <ListItem>{data['Depth (inches)'] ? data['Depth (inches)']?.toString()?.replace(/(.{15})..+/, "$1…") + '"' : ' '}</ListItem>
                                <ListItem>{data['Application']?.toString()?.replace(/(.{15})..+/, "$1…") || ' '}</ListItem>
                                <ListItem>{params.type === 'fixtures' ? data['Style']?.toString()?.replace(/(.{15})..+/, "$1…") || ' ' : data['Category']?.toString()?.replace(/(.{15})..+/, "$1…") || ' '}</ListItem>
                                <ListItem>{params.type === 'fixtures' ? data['Mount']?.toString()?.replace(/(.{15})..+/, "$1…") || ' ' : data['Type']?.toString()?.replace(/(.{15})..+/, "$1…") || ' '}</ListItem>
                                <ListItem display={params.type === 'lamps' ? 'block' : 'none'}>{data['Base Category']?.toString()?.replace(/(.{15})..+/, "$1…") || ' '}</ListItem>
                                <ListItem>{params.type === 'fixtures' ? data['Shape']?.toString()?.replace(/(.{15})..+/, "$1…") || ' ' : data['Base Type']?.toString()?.replace(/(.{15})..+/, "$1…") || ' '}</ListItem>
                                <ListItem>{data['Lens Type']?.toString()?.replace(/(.{15})..+/, "$1…") || ' '}</ListItem>
                                <ListItem>{data['Ingress Protection']?.toString()?.replace(/(.{15})..+/, "$1…") || ' '}</ListItem>
                                <ListItem display={params.type === 'fixtures' ? 'block' : 'none'}>{data['Fixture Color']?.toString()?.replace(/(.{15})..+/, "$1…") || ' '}</ListItem>
                                <ListItem display={params.type === 'fixtures' ? 'block' : 'none'}>{data['Trim']?.toString()?.replace(/(.{15})..+/, "$1…") || ''}</ListItem>
                            </List>
                        </Flex>
                        <Flex className='list'>
                            <List>
                                <ListItem>Wattage:</ListItem>
                                <ListItem>Lumens:</ListItem>
                                <ListItem  display='flex' alignItems='center'>CCT (K˚): <Eye onClick={() => setColorShow(true)} style={{marginLeft: '5px', cursor: 'pointer'}}/></ListItem>
                                <ListItem>CRI:</ListItem>
                                <ListItem>L70 Lifetime (hours):</ListItem>
                                <ListItem>Warranty (years):</ListItem>
                                <ListItem>Voltage (min):</ListItem>
                                <ListItem>Voltage (max):</ListItem>
                                <ListItem>Dimmability:</ListItem>
                                <ListItem>Color Tunable:</ListItem>
                                <ListItem>Power Factor:</ListItem>
                                <ListItem>CBCP:</ListItem>
                            </List>
                            <List>
                                <ListItem>{data['Wattage'] ? data['Wattage']?.toString()?.replace(/(.{15})..+/, "$1…")+'W' : ''}</ListItem>
                                <ListItem>{data['Lumens'] ? data['Lumens']?.toString()?.replace(/(.{15})..+/, "$1…")+'L' : ''}</ListItem>
                                <ListItem position='relative' ref={colorRef}>
                                    {data["CCT (K˚)"] ? data["CCT (K˚)"]?.split(',')?.map(s => s + "K")?.toString()?.replace(/(.{15})..+/, "$1…").replace(/,/g, ", ") : ''}
                                    <Box display={colorShow ? 'block' : 'none'} className='array_action'>{data["CCT (K˚)"]?.split(',')?.map(s => s + "K")?.toString().replace(/,/g, ", ")}</Box>
                                </ListItem>
                                <ListItem>{data['CRI']?.toString()?.replace(/(.{15})..+/, "$1…") || ''}</ListItem>
                                <ListItem>{data['L70 Lifetime (hours)']?.toString()?.replace(/(.{15})..+/, "$1…") || ''}</ListItem>
                                <ListItem>{data['Warranty (years)']?.toString()?.replace(/(.{15})..+/, "$1…") || ''}</ListItem>
                                <ListItem>{data['Voltage (min)']?.toString()?.replace(/(.{15})..+/, "$1…") || ''}</ListItem>
                                <ListItem>{data['Voltage (max)']?.toString()?.replace(/(.{15})..+/, "$1…") || ''}</ListItem>
                                <ListItem>{data['Dimmability']?.toString()?.replace(/(.{15})..+/, "$1…") || ''}</ListItem>
                                <ListItem>{data['Color Tunable']?.toString()?.toString()?.replace(/(.{15})..+/, "$1…") || ''}</ListItem>
                                <ListItem>{data['Power Factor']?.toString()?.replace(/(.{15})..+/, "$1…") || ''}</ListItem>
                                <ListItem>{data['CBCP']?.toString()?.replace(/(.{15})..+/, "$1…") || ''}</ListItem>
                            </List>
                        </Flex>
                        <Flex className='list'>
                            <List>
                                <ListItem>THD (%):</ListItem>
                                <ListItem>Beam Angle:</ListItem>
                                <ListItem>Optics:</ListItem>
                                <ListItem display='flex' alignItems='center'>
                                    Certifications: <Eye onClick={() => setCertifShow(true)} style={{marginLeft: '5px', cursor: 'pointer'}} />
                                </ListItem>
                                <ListItem>Buy America:</ListItem>
                                <ListItem>Installation (minutes):</ListItem>
                                <ListItem>Delivery (days):</ListItem>
                                <ListItem>Price Per Unit ($):</ListItem>
                                <ListItem>Vol. Tier 1 (min qty):</ListItem>
                                <ListItem>Vol. Tier 1 PPU ($):</ListItem>
                                <ListItem>Vol. Tier 2 (min qty):</ListItem>
                                <ListItem>Vol. Tier 2 PPU ($):</ListItem>
                            </List>
                            <List>
                                <ListItem>{data['THD (%)'] ? data['THD (%)']?.toString()?.replace(/(.{15})..+/, "$1…")+'%' : ''}</ListItem>
                                <ListItem>{data['Beam Angle']?.toString()?.replace(/(.{15})..+/, "$1…") || ''}</ListItem>
                                <ListItem>{data['Optics']?.toString()?.replace(/(.{15})..+/, "$1…") || ''}</ListItem>
                                <ListItem position='relative' ref={certifRef}>
                                    {data['Certifications']?.toString()?.replace(/(.{15})..+/, "$1…").replace(/,/g, ", ") || ''}
                                    <Box display={certifShow ? 'block' : 'none'} className='array_action'>{data['Certifications']?.toString().replace(/,/g, ", ")}</Box>
                                </ListItem>
                                <ListItem>{data['Buy America']?.toString()?.replace(/(.{15})..+/, "$1…") || ''}</ListItem>
                                <ListItem>{data['Approx. Installation Time (minutes)']?.toString()?.replace(/(.{15})..+/, "$1…") || ''}</ListItem>
                                <ListItem>{data['Approx. Delivery (days)']?.toString()?.replace(/(.{15})..+/, "$1…") || ''}</ListItem>
                                <ListItem>{data['Price Per Unit ($)'] ? '$'+data['Price Per Unit ($)']?.toString()?.replace(/(.{15})..+/, "$1…") : ''}</ListItem>
                                <ListItem>{data['Volume Tier 1 (MIN QTY)']?.toString()?.replace(/(.{15})..+/, "$1…") || ''}</ListItem>
                                <ListItem>{data['Volume Tier 1 PPU ($)'] ? '$'+data['Volume Tier 1 PPU ($)']?.toString()?.replace(/(.{15})..+/, "$1…") : ''}</ListItem>
                                <ListItem>{data['Volume Tier 2 (MIN QTY)']?.toString()?.replace(/(.{15})..+/, "$1…") || ''}</ListItem>
                                <ListItem>{data['Volume Tier 2 PPU ($)'] ? '$'+data['Volume Tier 2 PPU ($)']?.toString()?.replace(/(.{15})..+/, "$1…") : ''}</ListItem>
                            </List>
                        </Flex>
                    </Flex>
                    <Flex className='secondary_lists'>
                        <List>
                            <ListItem>Supplier Product Description:</ListItem>
                            <ListItem>Product Specifications (hyperlink):</ListItem>
                            <ListItem>Installation Instructions (hyperlink):</ListItem>
                            <ListItem>Product Image (hyperlink):</ListItem>
                            <ListItem>Compatible Product Order Codes:</ListItem>
                            <ListItem display={params.type === 'lamps' ? 'block' : 'none'}>Compatible Ballast Product Codes:</ListItem>
                            <ListItem>Notes:</ListItem>
                        </List>
                        <List>
                            <ListItem>{data['Product Description']?.toString()?.replace(/(.{80})..+/, "$1…") || ''}</ListItem>
                            <ListItem>{data['Specifications (hyperlink)']?.toString()?.replace(/(.{80})..+/, "$1…") || ''}</ListItem>
                            <ListItem>{data['Installation Instructions (hyperlink)']?.toString()?.replace(/(.{80})..+/, "$1…") || ''}</ListItem>
                            <ListItem>{data['Product Image (hyperlink)']?.toString()?.replace(/(.{80})..+/, "$1…") || ''}</ListItem>
                            <ListItem>{data['Compatible Product Order Codes']?.toString()?.replace(/(.{80})..+/, "$1…") || ''}</ListItem>
                            <ListItem display={params.type === 'lamps' ? 'block' : 'none'}>{data['Compatible Ballast Product Codes']?.toString()?.replace(/(.{80})..+/, "$1…") || ''}</ListItem>
                            <ListItem>{data['Notes']?.toString()?.replace(/(.{80})..+/, "$1…") || ''}</ListItem>
                        </List>
                    </Flex>
                </Box>
            </>
        ) : ('')
    )
}

export default Product
