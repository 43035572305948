import {useState} from 'react'
import Fixture from './LuminairesModals/Fixture'
import Lamp from './LuminairesModals/Lamp/index'
import Power from './LuminairesModals/Power'
import Control from './LuminairesModals/Control'
import Accessory from './LuminairesModals/Accessory'
import {InviteModal} from '../../../components/Theme'
import Recent from "./LuminairesModals/Recent";

const LuminairesModals = ({
                              fixture,
                              fromAll,
                              customPricing,
                              setCustomPricing,
                              setFixture,
                              project,
                              lamp,
                              setLamp,
                              power,
                              setPower,
                              control,
                              setControl,
                              accessory,
                              reportId,
                              setAccessory,
                              type,
                              onClose,
                              isOpen,
                              recent,
                              setRecent,
                              setArea,
                              allLuminaires,
                              area,
                              luminaireId,
                              setType,
                              luminaire,
                          }) => {
    const [createKey, setCreateKey] = useState(false)
    return (
        <>
            <Recent
                type={type}
                isOpen={isOpen}
                onClose={onClose}
                area={area}
                setRecent={setRecent}
                setArea={setArea}
                luminaireId={luminaireId}
                recent={recent}/>
            <InviteModal
                minW='992px'
                maxHBody='900px'
                px='50px'
                py='40px'
                isOpen={type !== 'recent' && isOpen}
                onClose={() => {
                    if (type === 'fixture' && createKey === false) {
                        setCreateKey(true)
                    } else if (type === 'lamp' && createKey === false) {
                        setCreateKey(true)
                    } else if (type === 'power' && createKey === false) {
                        setCreateKey(true)
                    } else if (type === 'control' && createKey === false) {
                        setCreateKey(true)
                    } else if (type === 'accessory' && createKey === false) {
                        setCreateKey(true)
                    }
                }}
                bg="yellow.100"
                title={
                    type === 'fixture' ? 'Luminaire Recommendation - Fixture'
                        : type === 'lamp' ? 'Luminaire Recommendation - Lamp'
                            : type === 'power' ? 'Luminaire Details - Power Supply'
                                : type === 'control' ? 'Luminaire Details - Control' : 'Luminaire Details - Accessories'}>
                {
                    type === 'fixture' ? (
                        <Fixture
                            reportId={reportId}
                            customPricing={customPricing}
                            setCustomPricing={setCustomPricing}
                            project={project}
                            fromAll={fromAll}
                            allLuminaires={allLuminaires}
                            luminaire={luminaire}
                            fixture={fixture}
                            createKey={createKey}
                            recent={recent}
                            setCreateKey={setCreateKey}
                            setFixture={setFixture}
                            description={fixture?.fixtureRecomendedDescription}
                            setType={setType}
                            luminaireId={luminaireId}
                            area={area}
                            setArea={setArea}
                            setRecent={setRecent}
                            onClose={onClose}/>
                    ) : type === 'lamp' ? (
                        <Lamp
                            reportId={reportId}
                            customPricing={customPricing}
                            setCustomPricing={setCustomPricing}
                            project={project}
                            fromAll={fromAll}
                            createKey={createKey}
                            setCreateKey={setCreateKey}
                            description={lamp?.lampRecomendedDescription}
                            setType={setType}
                            lamp={lamp}
                            setLamp={setLamp}
                            luminaireId={luminaireId}
                            area={area}
                            luminaire={luminaire}
                            recent={recent}
                            setArea={setArea}
                            setRecent={setRecent}
                            onClose={onClose}/>
                    ) : type === 'power' ? (
                        <Power
                            reportId={reportId}
                            customPricing={customPricing}
                            setCustomPricing={setCustomPricing}
                            project={project}
                            fromAll={fromAll}
                            createKey={createKey}
                            setCreateKey={setCreateKey}
                            description={power?.powerSupplyRecomendedDescription}
                            setType={setType}
                            power={power}
                            setPower={setPower}
                            luminaireId={luminaireId}
                            area={area}
                            luminaire={luminaire}
                            setArea={setArea}
                            recent={recent}
                            setRecent={setRecent}
                            type={type}
                            onClose={onClose}
                            isOpen={isOpen}/>
                    ) : type === 'control' ? (
                        <Control
                            reportId={reportId}
                            customPricing={customPricing}
                            setCustomPricing={setCustomPricing}
                            project={project}
                            fromAll={fromAll}
                            createKey={createKey}
                            setCreateKey={setCreateKey}
                            description={control?.controlRecomendedDescription}
                            setType={setType}
                            luminaireId={luminaireId}
                            recent={recent}
                            control={control}
                            setControl={setControl}
                            luminaire={luminaire}
                            setRecent={setRecent}
                            area={area}
                            setArea={setArea}
                            type={type}
                            onClose={onClose}
                            isOpen={isOpen}/>
                    ) : (
                        <Accessory
                            reportId={reportId}
                            customPricing={customPricing}
                            setCustomPricing={setCustomPricing}
                            project={project}
                            fromAll={fromAll}
                            createKey={createKey}
                            setCreateKey={setCreateKey}
                            accessory={accessory}
                            setAccessory={setAccessory}
                            setArea={setArea}
                            setRecent={setRecent}
                            recent={recent}
                            area={area}
                            luminaire={luminaire}
                            luminaireId={luminaireId}
                            description={accessory?.accessoryRecomendedDescription}
                            setType={setType}
                            isOpen={isOpen}
                            onClose={onClose}
                            type={type}/>
                    )
                }
            </InviteModal>
        </>
    )
}

export default LuminairesModals
