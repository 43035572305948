import React, {useState} from "react";
import {Modal, ModalBody, ModalContent, ModalHeader, ModalOverlay, Text, Flex, Button, useDisclosure, useToast,} from "@chakra-ui/react";
import IconButton from "../../Theme/IconButton";
import Icon from "../../Theme/Icon";
import httpRequest from "../../../core/services/http-request";
import BiddingOpenModalAlert from "./BiddingOpenModal";
import {ReactComponent as SendMessage} from "../../../assets/installationBidOpennedIcon.svg";
import ToastComponent from "../../toastComponent/ToastComponent";

const BiddingOpenAcceptModal = ({isOpen, onClose, project, recentProposal, getReport}) => {
    const {post} = httpRequest()
    const toast = useToast()
    const [loading, setLoading] = useState(false)
    const {isOpen: isOpenBidding, onOpen: onOpenBidding, onClose: onCloseBidding} = useDisclosure()

    const sendBiddToElectricians = () => {
        if (!project?.zipCode) return
        setLoading(true)
        post('open-bid-check-zipCode', project?.zipCode).then(function (res) {
            setLoading(false)
            if (res.data.data === 'No Electricians for this area' || res.data.data === 'There is no Companies') {
                onOpenBidding()
            } else {
                post('send-bidd-to-electricians', recentProposal.id).then(function (res) {
                    setLoading(false)
                    onClose()
                    toast({
                        render: () => (
                            <ToastComponent bg='#8FCF74' icon={<SendMessage/>} title='Installation Bidding Opened!'/>
                        ),
                        duration: 4000,
                        isClosable: true,
                        position: "top-right"
                    })
                    getReport()
                })
            }
        })
    }

    return (
        <Modal isCentered isOpen={isOpen} onClose={onClose}>
            <BiddingOpenModalAlert
                isOpen={isOpenBidding}
                onClose={() => {
                    onCloseBidding()
                    onClose()
                }}/>
            <ModalOverlay/>
            <ModalContent overflow="hidden" borderRadius="10px" minW="853px">
                <ModalHeader bg='#FFE68D' fontWeight="700" fontSize="24px" textAlign="center">
                    Open Bidding Process?
                </ModalHeader>
                <IconButton height="unset" onClick={onClose} _hover={{bg: 'none',}} _active={{bg: 'none',}} bg="none" aria-label="close button" position="absolute" top="1.5rem" right="0.75rem" icon={<Icon type="darkClose"/>}/>
                <ModalBody pt='50px' pl='120px' pr='120px' fontSize='24px' textAlign='center' overflow="scroll" maxH="600px">
                    <Text>
                        Are you sure you're ready to open the bidding process?
                        This will notify all EUs in serviceable area...
                    </Text>
                    <Flex pb='80px' alignItems='center' flexDirection='column'>
                        <Button mt={10} mb={3} isLoading={loading} onClick={sendBiddToElectricians} width='254px' height='51px' fontWeight='700' fontSize='18px' backgroundColor='#2C628D'>
                            Yes, Open Bidding
                        </Button>
                        <Button width='254px' height='51px' onClick={onClose} color='black' fontWeight='700' fontSize='18px' border='1px solid black' backgroundColor='white'>
                            No, Go Back
                        </Button>
                    </Flex>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}

export default BiddingOpenAcceptModal