import React from 'react'
import NavbarPage from './Navbar'

const Navbar = (props) => {
    const {children, title, minW, open, editMode} = props
    return (
        <NavbarPage
            minW={minW}
            open={open}
            editMode={editMode}
            title={title}>
            {children}
        </NavbarPage>
    )
}

export default Navbar
