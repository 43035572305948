const notificationUseCase = (notificationRepo) => {
    const createNotification = (input) => notificationRepo.createNotification(input)

    const notificationToActivity = (input) => {
        if (!input?.id){
            return
        }
        return notificationRepo.notificationToActivity(input)
    }

    const updateNotification = (input) => {
        if (!input?.id) {
            throw Error('ID is required')
        }
        return notificationRepo.updateNotification(input)
    }

    const listNotifications = (variables) => notificationRepo.listNotifications(variables)

    return {
        createNotification,
        updateNotification,
        listNotifications,
        notificationToActivity,
    }
}

export default notificationUseCase
