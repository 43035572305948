import React, {useEffect, useState} from 'react'
import {Flex, Box, Text, Button,} from '@chakra-ui/react'
import {ReactComponent as Star} from "../../assets/starIconLittle.svg";
import {ReactComponent as DragIcon} from "../../assets/projectDragIcon.svg";
import {ReactComponent as ArrowRight} from "../../assets/arrow_right.svg";
import {ReactComponent as LightCountIcon} from "../../assets/LightOnIcon.svg";
import {ReactComponent as ApprovedIcon} from "../../assets/approvedIcon.svg";
import {ReactComponent as FulfilledIcon} from "../../assets/fulfilledIcon.svg";
import {ReactComponent as ShippedIcon} from "../../assets/shippedIcon.svg";
import httpRequest from "../../core/services/http-request";
import {ReactComponent as Unhide} from "../../assets/UnhideIcon.svg";
import {ReactComponent as Activate} from "../../assets/activateIcon.svg";
import {ReactComponent as Proposal} from "../../assets/Icons/proposalReviewIcon.svg";

const ListTypeProject = ({
                             project,
                             projectStatus,
                             projectStatusEu,
                             projectId,
                             searchProjects,
                             priority,
                             history,
                             user,
                             hidden,
                             archived,
                         }) => {
    const {post} = httpRequest()
    const [loading, setLoading] = useState(false)
    const [loadingArchive, setLoadingArchive] = useState(false)
    const [status, setStatus] = useState('')
    const [bg, setBg] = useState('')
    const [name, setName] = useState('')

    useEffect(() => {
        if (status) {
            if (hidden) {
                setBg('#6D8DA7')
                return setName('Unhide')
            }  else if (archived) {
                setBg('rgba(109, 141, 167, 1)')
                setName('Activate')
            }else {
                if (status === 'Proposal Review') {
                    setBg('green.100')
                    setName('View Proposal')
                } else if (status === 'Light Count') {
                    setBg('#F0D533')
                    setName('Property Info')
                } else if (status === 'Approved') {
                    setBg('#65E42F')
                    setName('Estimate')
                } else if (status === 'Fulfilled') {
                    setBg('#E69FFF')
                    setName('Invoice')
                } else if (status === 'Shipped') {
                    setBg('#FFB185')
                    setName('Track')
                } else if (status === 'Active Bidding') {
                    setBg('#FFE68D')
                    setName('Bid')
                } else if (status === 'Awaiting Decision') {
                    setBg('#FFB185')
                } else if (status === 'Project Awarded!') {
                    setBg('#8FCF74')
                } else {
                    setBg('black')
                }
            }
        }
    }, [status])

    const unhideProject = () => {
        setLoading(true)
        let data = {projectId: projectId}
        post('unhide-project', data).then((res) => {
            setStatus(res.data.data.status)
            setLoading(false)
            searchProjects()
        })
    }

    const activateProject = () => {
        let data = {projectId: project?.id}
        setLoadingArchive(true)
        post('activate-project',data).then((res) => {
            setStatus(res.data.data.status)
            setLoadingArchive(false)
            searchProjects()
        })
    }

    useEffect(() => {
        if (!user) return
        if (user.type === 'Eu') {
            setStatus(projectStatusEu)
        } else {
            setStatus(projectStatus)
        }
    }, [searchProjects])

    return (
        <Flex width='100%' justifyContent='space-between' alignItems='center' bg='#EDEDED' minH='50px' borderRadius='10px' px={5}>
            <Flex alignItems='center'>
                <DragIcon id='drag_button' cursor='pointer'/>
                <Text ml={3} cursor='pointer'>{priority ? (<Star fill='#FFD439'/>) : (<Star fill='#C5C5C5'/>)}</Text>
                <Text ml={3} fontSize='16px' cursor='pointer' fontFamily='Titillium Web'
                      onMouseDown={(e) => {
                    e.stopPropagation();
                    history.push(status === 'Hidden' ? "#" : `/project/${project.id}`)
                }}>{project?.title}</Text>
                <Box ml={3} bg='#A7A7A7' width='4px' height='4px' borderRadius='50%'/>
                <Text ml={3} fontSize='16px' cursor='pointer' fontWeight='400' fontFamily='Titillium Web'
                      onMouseDown={(e) => {
                          e.stopPropagation();
                          history.push(status === 'Hidden' ? "#" : `/project/${project.id}`)
                      }}>
                    {((project?.address ? project?.address : '')+(project?.address2 ? ', ' + project?.address2 : '')+(project?.city ? ', ' + project?.city : '')+(project?.state ? ', ' + project?.state : '')+(project?.zipCode ? ', ' + project?.zipCode : ''))}</Text>
            </Flex>
            <Flex>
                <Button leftIcon={
                         status === 'Light Count' ? <LightCountIcon />
                              : status === 'Approved' ? <ApprovedIcon />
                                  : status === 'Fulfilled' ? <FulfilledIcon />
                                    : status === 'Proposal Review' ? <Proposal width='15px' height='15px'/>
                                       : status === 'Shipped' ? <ShippedIcon /> : ''}
                        height='20px'
                        backgroundColor='transparent'
                        mr={5}
                        color='black'
                        onMouseDown={(e) => {
                            e.stopPropagation();
                            history.push(status === 'Hidden' ? "#" : `/project/${project.id}`)
                        }}>
                    {projectStatus}
                </Button>
                <Button
                    minW='118px'
                    leftIcon={hidden ? <Unhide/> : archived ? <Activate width='13px' height='13px'/> : <ArrowRight/>}
                    isLoading={loading || loadingArchive}
                    height='20px'
                    bg={bg}
                    color={hidden || archived ? 'white' : 'black'}
                    onMouseDown={(e) => {
                        e.stopPropagation();
                        if (hidden){
                            return unhideProject()
                        } else if (archived){
                            return activateProject()
                        } {
                            status === 'Proposal Review' ? history.push(`/project/${project.id}`)
                                : status === 'Light Count' ? history.push(`/project/${project.id}`)
                                    : status === 'Approved' ? history.push(`/project/${project.id}`)
                                        : status === 'Fulfilled' ? history.push(`/project/${project.id}`)
                                            : status === 'Shipped' ? history.push(`/project/${project.id}`) : history.push(`/project/${project.id}`)
                        }
                    }}>
                    {name}
                </Button>
            </Flex>
        </Flex>
    )
}
export default ListTypeProject
