import React, {useEffect, useState} from 'react'
import {Accordion, AccordionItem, AccordionButton, Flex, AccordionPanel, Text, Table, Thead, Tbody, Tr, Th, Td, Button, Heading, useDisclosure, IconButton, HStack, Stack, VStack,} from '@chakra-ui/react'
import Icon from '../Icon'
import styles from './styles'
import {ReactComponent as Arrow} from '../../../assets/arrow.svg'
import {ReactComponent as Clock} from '../../../assets/Clock.svg'
import LuminairesModals from '../../../pages/CreateProject/LuminairesModals'
import {ReactComponent as Delete} from '../../../assets/del.svg'
import {ReactComponent as ErrorIcon} from '../../../assets/luminaireErrorIcon.svg'
import {InviteModal as DeleteAlert} from '..'
import {useUser} from "../../../core/hooks";

const AccordionSolo = ({luminaire, getValues,allAreas, idx, watch, setRecent, register, area, setArea, recent, remove, application, setApplication, data, areas, areaName}) => {
    const [type, setType] = useState('')
    const {user} = useUser()
    const [empty, setEmpty] = useState(false)
    const [lengthError, setLengthError] = useState(false)
    const [description, setDescription] = useState([])
    const {onOpen, isOpen, onClose} = useDisclosure()
    const {onOpen: onOpenAlert, isOpen: isOpenAlert, onClose: onCloseAlert} = useDisclosure()
    const [fixture, setFixture] = useState({
        id: luminaire?.id,
        qty: luminaire.qty ? luminaire.qty : '',
        fixtureDescription: luminaire.fixtureDescription ? luminaire.fixtureDescription : '',
        fixtureApplication: getValues(`areas.${idx}.application`) ? getValues(`areas.${idx}.application`) : '',
        areaName: getValues(`areas.${idx}.title`) ? getValues(`areas.${idx}.title`) : '',
        fixtureMountHeight: luminaire.fixtureMountHeight ? luminaire.fixtureMountHeight : '',
        fixtureMount: luminaire.fixtureMount ? luminaire.fixtureMount : '',
        fixtureStyle: luminaire.fixtureStyle ? luminaire.fixtureStyle : '',
        fixtureLens: luminaire.fixtureLens ? luminaire.fixtureLens : '',
        fixtureLength: luminaire.fixtureLength ? luminaire.fixtureLength : '',
        fixtureWidth: luminaire.fixtureWidth ? luminaire.fixtureWidth : '',
        fixtureDepth: luminaire.fixtureDepth ? luminaire.fixtureDepth : '',
        fixtureDiameter: luminaire.fixtureDiameter ? luminaire.fixtureDiameter : '',
        fixtureExisting: luminaire.fixtureExisting ? luminaire.fixtureExisting : '',
    })
    const [lamp, setLamp] = useState({
        id: luminaire?.id,
        lampExisting: luminaire.lampExisting ? luminaire.lampExisting : [],
        lampPerFixture: luminaire.lampPerFixture ? luminaire.lampPerFixture : '',
        lampDescription: luminaire.lampDescription ? luminaire.lampDescription : '',
        lampCategoryType: luminaire.lampCategoryType ? luminaire.lampCategoryType : '',
        lampBaseCategory: luminaire.lampBaseCategory ? luminaire.lampBaseCategory : '',
        lampCategory: luminaire.lampCategory ? luminaire.lampCategory : '',
        lampBaseCategoryType: luminaire.lampBaseCategoryType ? luminaire.lampBaseCategoryType : '',
        lampWattage: luminaire.lampWattage ? luminaire.lampWattage : '',
        lampColorTemperature: luminaire.lampColorTemperature ? luminaire.lampColorTemperature : '',
        lampProductCode: luminaire.lampProductCode ? luminaire.lampProductCode : '',
        lampLensType: luminaire.lampLensType ? luminaire.lampLensType : '',
    })
    const [power, setPower] = useState({
        powerSupplyExisting: luminaire.powerSupplyExisting ? luminaire.powerSupplyExisting : [],
        powerSupplyDescription: luminaire.powerSupplyDescription ? luminaire.powerSupplyDescription : '',
        powerSupplyType: luminaire.powerSupplyType ? luminaire.powerSupplyType : '',
        powerSupplyModel: luminaire.powerSupplyModel ? luminaire.powerSupplyModel : '',
        powerSupplyProductCode: luminaire.powerSupplyProductCode ? luminaire.powerSupplyProductCode : '',
        powerSupplyManufacturer: luminaire.powerSupplyManufacturer ? luminaire.powerSupplyManufacturer : '',
        powerQTYFixture: luminaire.powerQTYFixture ? luminaire.powerQTYFixture : '',
    })
    const [control, setControl] = useState({
        controlDescription: luminaire.controlDescription ? luminaire.controlDescription : '',
        controlType: luminaire.controlType ? luminaire.controlType : '',
        controlManufacturer: luminaire.controlManufacturer ? luminaire.controlManufacturer : '',
        controlDimming: luminaire.controlDimming ? luminaire.controlDimming : '',
        controlModel: luminaire.controlModel ? luminaire.controlModel : '',
        controlRecordId: luminaire.fixtureRecordId ? luminaire.fixtureRecordId : '',
        controlExisting: luminaire.controlExisting ? luminaire.controlExisting : [],
        controlQTYFixture: luminaire.controlQTYFixture ? luminaire.controlQTYFixture : '',
    })
    const [accessory, setAccessory] = useState({
        accessoryDescriptionA: luminaire.accessoryDescriptionA ? luminaire.accessoryDescriptionA : '',
        accessoryDescriptionAQty: luminaire.accessoryDescriptionAQty ? luminaire.accessoryDescriptionAQty : '',
        accessoryLengthA: luminaire.accessoryLengthA ? luminaire.accessoryLengthA : '',
        accessoryLengthB: luminaire.accessoryLengthB ? luminaire.accessoryLengthB : '',
        accessoryLengthC: luminaire.accessoryLengthC ? luminaire.accessoryLengthC : '',
        accessoryWidthA: luminaire.accessoryWidthA ? luminaire.accessoryWidthA : '',
        accessoryWidthB: luminaire.accessoryWidthB ? luminaire.accessoryWidthB : '',
        accessoryWidthC: luminaire.accessoryWidthC ? luminaire.accessoryWidthC : '',
        accessoryDepthA: luminaire.accessoryDepthA ? luminaire.accessoryDepthA : '',
        accessoryDepthB: luminaire.accessoryDepthB ? luminaire.accessoryDepthB : '',
        accessoryDepthC: luminaire.accessoryDepthC ? luminaire.accessoryDepthC : '',
        accessoryDiameterA: luminaire.accessoryDiameterA ? luminaire.accessoryDiameterA : '',
        accessoryDiameterB: luminaire.accessoryDiameterB ? luminaire.accessoryDiameterB : '',
        accessoryDiameterC: luminaire.accessoryDiameterC ? luminaire.accessoryDiameterC : '',
        accessoryDescriptionBQty: luminaire.accessoryDescriptionBQty ? luminaire.accessoryDescriptionBQty : '',
        accessoryDescriptionCQty: luminaire.accessoryDescriptionCQty ? luminaire.accessoryDescriptionCQty : '',
        accessoryDescriptionB: luminaire.accessoryDescriptionB ? luminaire.accessoryDescriptionB : '',
        accessoryDescriptionC: luminaire.accessoryDescriptionC ? luminaire.accessoryDescriptionC : '',
        accessoryTypeA: luminaire.accessoryTypeA ? luminaire.accessoryTypeA : '',
        accessoryManufacturerA: luminaire.accessoryManufacturerA ? luminaire.accessoryManufacturerA : '',
        accessoryColorA: luminaire.accessoryColorA ? luminaire.accessoryColorA : '',
        accessoryProductCodeA: luminaire.accessoryProductCodeA ? luminaire.accessoryProductCodeA : '',
        accessoryTypeB: luminaire.accessoryTypeB ? luminaire.accessoryTypeB : '',
        accessoryManufacturerB: luminaire.accessoryManufacturerB ? luminaire.accessoryManufacturerB : '',
        accessoryColorB: luminaire.accessoryColorB ? luminaire.accessoryColorB : '',
        accessoryProductCodeB: luminaire.accessoryProductCodeB ? luminaire.accessoryProductCodeB : '',
        accessoryTypeC: luminaire.accessoryTypeC ? luminaire.accessoryTypeC : '',
        accessoryManufacturerC: luminaire.accessoryManufacturerC ? luminaire.accessoryManufacturerC : '',
        accessoryColorC: luminaire.accessoryColorC ? luminaire.accessoryColorC : '',
        accessoryProductCodeC: luminaire.accessoryProductCodeC ? luminaire.accessoryProductCodeC : '',
        accessoryRecordId: luminaire.fixtureRecordId ? luminaire.fixtureRecordId : '',
    })

    const openAlert = (e) => {
        e.stopPropagation()
        onOpenAlert()
    }

    useEffect(() => {
        if (!luminaire.fixtureDescription || !luminaire.lampDescription) {
            return setEmpty(true)
        }
        return setEmpty(false)
    }, [luminaire])

    useEffect(() => {
        const desc = []
        if (fixture.fixtureDescription) {
            desc.push(fixture.fixtureDescription)
        }
        if (lamp.lampDescription) {
            desc.push(` , (${lamp.lampPerFixture}) ${lamp.lampDescription}`)
        }
        if (power.powerSupplyDescription) {
            desc.push(` powered by ${power.powerSupplyDescription}`)
        }
        if (control.controlDescription) {
            desc.push(` controlled by ${control.controlDescription}`)
        }
        if (accessory.accessoryDescriptionA) {
            desc.push(` with ${accessory.accessoryDescriptionA}`)
        }
        if (accessory.accessoryDescriptionB) {
            desc.push(`, ${accessory.accessoryDescriptionB}`)
        }
        if (accessory.accessoryDescriptionC) {
            desc.push(`, ${accessory.accessoryDescriptionC}`)
        }
        setDescription(desc)
        if (lamp.lampType === 'Fluorescent' && !fixture.fixtureLength) {
            setLengthError(true)
        } else {
            setLengthError(false)
        }
    }, [fixture, lamp, power, control, accessory])

    useEffect(() => {
        if (Object.keys(luminaire).length === 1) {
            setType('fixture')
            onOpen()
        }
    }, [])

    return (
        <>
            <DeleteAlert isOpen={isOpenAlert} onClose={onCloseAlert} bg="#FF9494" title='Delete Luminaire'>
                <Stack py="10" spacing="10">
                    <VStack spacing="5" justifyContent="center">
                        <Text fontWeight="600" fontSize="24px" textAlign="center">Are you sure you want to <br/>delete this luminaire?</Text>
                        <HStack spasing="1.25rem">
                            <Button w="230px" height='50px' bg="#DBDADA" fontSize='14x' color="black" fontWeight="700" onClick={onCloseAlert}>
                                Nevermind, Let’s Go Back
                            </Button>
                            <Button fontSize='14x' height='50px' w="230px" bg="#FB7575" color="black" fontWeight="700" onClick={() => remove(luminaire.id)}>
                                Yes, Delete Luminaire
                            </Button>
                        </HStack>
                    </VStack>
                </Stack>
            </DeleteAlert>
            <LuminairesModals
                allAreas={allAreas}
                data={data}
                areaName={areaName}
                idx={idx}
                watch={watch}
                getValues={getValues}
                register={register}
                application={application}
                setApplication={setApplication}
                fixture={fixture}
                setFixture={setFixture}
                lamp={lamp}
                setLamp={setLamp}
                power={power}
                setPower={setPower}
                control={control}
                setControl={setControl}
                accessory={accessory}
                setAccessory={setAccessory}
                setType={setType}
                areas={areas}
                luminaire={luminaire}
                luminaireId={luminaire.id}
                setRecent={setRecent}
                recent={recent}
                area={area}
                description={description}
                setArea={setArea}
                type={type}
                isOpen={isOpen}
                onClose={onClose}/>
            <Accordion allowMultiple defaultIndex={0} allowToggle>
                <AccordionItem border="none">
                    {({isExpanded}) => (
                        <>
                            <AccordionButton position="relative" p={0} bg={empty && !isExpanded ? '#FFE8E1' : lengthError ? '#FFDFDF' : 'white'} width="550px" minHeight="52px" _focus={{boxShadow: '0px 2px 10px 2px rgba(0, 0, 0, 0.45)'}} border="1px" borderColor={lengthError ? "#DA2D2D" : "grey.200"} boxShadow="0px 2px 10px 2px rgba(0, 0, 0, 0.45)">
                                <IconButton onClick={openAlert} display={isExpanded === true && user.type !== 'Eu' ? 'flex' : 'none'} height="20px" minW="20px" m="0" position="absolute" top="-10px" left="-10px" borderRadius="5px" bg="#FB7575" aria-label="del" icon={<Delete width="8px" height="10px"/>}/>
                                <Flex px={4} minW="100%" alignItems="center" justifyContent="space-between">
                                    <Flex flex={1} alignItems="center" height="100%">
                                        <Text maxW="250px" overflow="scroll" fontFamily='Frank Ruhl Libre' whiteSpace="nowrap" fontSize="xl">
                                            {luminaire?.qty ? luminaire?.qty : ''}
                                        </Text>
                                    </Flex>
                                    <Text display={lengthError ? 'block' : 'none'}><ErrorIcon width='40px'/></Text>
                                    <Flex flex={3} alignItems="center" height="100%">
                                        <Text fontFamily='Frank Ruhl Libre' maxW="350px" overflow="scroll" fontSize="xl">{description}</Text>
                                    </Flex>
                                    <Flex flex={1} height="100%" alignItems="center" justifyContent="flex-end">
                                        <Icon style={{transform: isExpanded ? 'rotate(90deg)' : 'inherit', position: 'relative', transition: 'all .3s',}} type="right-arrow"/>
                                    </Flex>
                                </Flex>
                            </AccordionButton>
                            <AccordionPanel bg="white.100" width="550px" borderWidth="1px" borderColor="grey.200" p={0}>
                                <Table variant="unstyled">
                                    <Thead>
                                        <Tr>
                                            <Th fontSize="18px" fontFamily='Titillium Web' fontWeight='700' color='#C4C4C4'>COMPONENT</Th>
                                            <Th fontSize="xl" fontWeight="700" color="grey.200">
                                                <Flex alignItems="center" justifyContent="space-between">
                                                    <Th pl='0' fontSize="18px" fontFamily='Titillium Web' color='#C4C4C4'>DESCRIPTION</Th>
                                                    <Button size="sm" leftIcon={<Clock/>} w="139px" variant="lightBlue" minH="29px"
                                                        onClick={() => {
                                                            setType('recent')
                                                            onOpen()}}>
                                                        Recent Luminaires
                                                    </Button>
                                                </Flex>
                                            </Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody css={styles.tBody}>
                                        <Tr>
                                            <Td>Fixture</Td>
                                            <Td>
                                                <Flex pr="1" borderRadius="5px" alignItems="center" minH="28px" justifyContent="space-between" border="1px solid" borderColor="border.100">
                                                    <Text maxW="230px" overflow="scroll" fontSize="14px">
                                                        {luminaire?.fixtureDescription}
                                                    </Text>
                                                    <Button height="20px" width="50px" leftIcon={<Arrow/>} variant="yellow"
                                                        onClick={() => {
                                                            setType('fixture')
                                                            onOpen()
                                                        }}>
                                                        <Text mt={1}>Edit</Text>
                                                    </Button>
                                                </Flex>
                                            </Td>
                                        </Tr>
                                        <Tr>
                                            <Td>Lamp</Td>
                                            <Td>
                                                <Flex pr="1" minH="28px" borderRadius="5px" alignItems="center" justifyContent="space-between" border="1px solid" borderColor="border.100">
                                                    <Text maxW="230px" overflow="scroll" fontSize="14px">
                                                        {luminaire?.lampDescription}
                                                    </Text>
                                                    <Button height="20px" width="50px" leftIcon={<Arrow/>} variant="yellow"
                                                        onClick={() => {
                                                            setType('lamp')
                                                            onOpen()
                                                        }}>
                                                        <Text mt={1}>Edit</Text>
                                                    </Button>
                                                </Flex>
                                            </Td>
                                        </Tr>
                                        <Tr>
                                            <Td>Power Supply</Td>
                                            <Td>
                                                <Flex pr="1" minH="28px" borderRadius="5px" alignItems="center" justifyContent="space-between" border="1px solid" borderColor="border.100">
                                                    <Text maxW="230px" overflow="scroll" fontSize="14px">
                                                        {luminaire?.powerSupplyDescription}
                                                    </Text>
                                                    <Button height="20px" width="50px" leftIcon={<Arrow/>} variant="yellow"
                                                        onClick={() => {
                                                            setType('power-supply')
                                                            onOpen()
                                                        }}>
                                                        <Text mt={1}>Edit</Text>
                                                    </Button>
                                                </Flex>
                                            </Td>
                                        </Tr>
                                        <Tr>
                                            <Td>Control</Td>
                                            <Td>
                                                <Flex pr="1" minH="28px" borderRadius="5px" alignItems="center" justifyContent="space-between" border="1px solid" borderColor="border.100">
                                                    <Text maxW="230px" overflow="scroll" fontSize="14px">
                                                        {luminaire?.controlDescription}
                                                    </Text>
                                                    <Button height="20px" width="50px" leftIcon={<Arrow/>} variant="yellow"
                                                        onClick={() => {
                                                            setType('control')
                                                            onOpen()
                                                        }}>
                                                        <Text mt={1}>Edit</Text>
                                                    </Button>
                                                </Flex>
                                            </Td>
                                        </Tr>
                                        <Tr>
                                            <Td>Accessory</Td>
                                            <Td>
                                                <Flex pr="1" minH="28px" borderRadius="5px" alignItems="center" justifyContent="space-between" border="1px solid" borderColor="border.100">
                                                    <Text maxW="230px" overflow="scroll" fontSize="14px">
                                                        {luminaire?.accessoryDescriptionA && luminaire?.accessoryDescriptionA + (luminaire?.accessoryDescriptionB && ', '+luminaire?.accessoryDescriptionB) + (luminaire?.accessoryDescriptionC && ', '+luminaire?.accessoryDescriptionC)}
                                                    </Text>
                                                    <Button height="20px" width="50px" leftIcon={<Arrow/>} variant="yellow" display={user?.type === 'Eu' ? 'none' : 'flex'}
                                                        onClick={() => {
                                                            setType('accessory')
                                                            onOpen()
                                                        }}>
                                                        <Text mt={1}>Edit</Text>
                                                    </Button>
                                                </Flex>
                                            </Td>
                                        </Tr>
                                    </Tbody>
                                </Table>
                            </AccordionPanel>
                        </>
                    )}
                </AccordionItem>
            </Accordion>
        </>
    )
}

export default AccordionSolo
