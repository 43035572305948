import {forwardRef, useEffect, useRef, useState} from 'react'
import {FormControl, FormLabel, Box, Flex,Heading} from '@chakra-ui/react'
import Input from '../Input'

const InputLabel = forwardRef((props, ref) => {
    const {
        label,
        defaultValue,
        name,
        value,
        afterIndex,
        css,
        bg,
        mr,
        ml,
        type,
        leftAddon,
        changeactive,
        isInvalid,
        disabled,
        onChange,
        pattern,
        rightAddon,
        maxW,
        mt,
        labelFontSize,
        labelColor,
        errormessage,
        step,
        labelMarginRight='default',
        inputHeight,
        ...rest
    } = props
    const [inputTextWidth, setInputTextWidth] = useState(0);
    const hiddenSpanRef = useRef(null);

    useEffect(() => {
        if (!afterIndex) return
        if (hiddenSpanRef.current) {
            setInputTextWidth(hiddenSpanRef.current.offsetWidth);
        }
    }, [value]);

    return (
        <FormControl css={css} maxW={maxW} alignItems="center" position="relative" mr={mr} ml={ml} mt={mt}>
            <FormLabel fontFamily='Titillium Web' fontWeight='600' fontSize={labelFontSize || "md"} color={labelColor || "blue.200"} mr={labelMarginRight}>
                {label}
            </FormLabel>
            <Flex alignItems="center" position='relative'>
                <Box position='absolute' zIndex='999' borderRadius='50%' width='25px' height='25px' right='-10px' top='-10px' display={changeactive ? 'block' : 'none'} backgroundColor='orange'/>
                {
                    leftAddon
                    && (
                        <Box zIndex="10" left="5px" position="absolute" as="span">
                            {leftAddon}
                        </Box>
                    )
                }
                {
                    rightAddon
                    && (
                        <Box zIndex="10" right="7px" position="absolute" as="span">
                            {rightAddon}
                        </Box>
                    )
                }
                <Input
                    height={inputHeight ?? '50px'}
                    step={step}
                    type={type}
                    defaultValue={defaultValue}
                    onChange={onChange}
                    isInvalid={isInvalid}
                    value={value}
                    pattern={pattern}
                    isDisabled={disabled}
                    name={name}
                    ref={ref}
                    bg={bg}
                    {...rest}/>
                {
                    afterIndex && (
                        <>
                            <Box
                                position="absolute"
                                left={`${inputTextWidth + 20}px`}
                                top="50%"
                                transform="translateY(-50%)"
                                pointerEvents="none"
                                color="gray.500"
                                fontSize="md">
                                {afterIndex}
                            </Box>
                            <Box
                                ref={hiddenSpanRef}
                                position="absolute"
                                visibility="hidden"
                                whiteSpace="nowrap"
                                fontSize="inherit"
                                fontFamily="inherit"
                                padding="inherit"
                                border="inherit"
                            >
                                {value}
                            </Box>
                        </>
                    )
                }
            </Flex>
            {isInvalid && <Heading position='absolute' bottom='-30px' left='0' as='h4' color='red.200' fontSize='sm'>{errormessage}</Heading>}
        </FormControl>
    )
})

export default InputLabel
