import {css} from '@emotion/react'

const styles = {
    input: css`
      padding-left: 2.5rem;
      padding-right: 1rem;
      background: #fff;
      margin-top: 5px;
      min-height: 50px;
      border: 1px solid #A9A9A9;
      font-size: 18px;
      &::placeholder {
        color: #a9a9a9;
        opacity: 1;
      }

      &:-ms-input-placeholder {
        color: #a9a9a9;
      }

      &::-ms-input-placeholder {
        color: #a9a9a9;
      }

      &:focus {
        box-shadow: none;
        outline: none;
        border-color: unset;
      }
    `,
}

export default styles
