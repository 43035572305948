import {useEffect, useState} from 'react'
import {useParams, Link, useHistory} from 'react-router-dom'
import {Text, Flex, Box, Image, Heading, Button, Divider, useDisclosure, useToast,} from '@chakra-ui/react'
import {useGetProject, useUser} from '../../core/hooks'
import {S3_BUCKET} from '../../constants'
import Navbar from '../../components/Navbar'
import {ProjectChartInterests} from '../../components/Theme'
import {ReactComponent as Edit} from '../../assets/edit.svg'
import {ReactComponent as StarIcon} from '../../assets/starIconProject.svg'
import {ReactComponent as StarIconYellow} from '../../assets/starIconProjectYellow.svg'
import AllTabPanels from './AllTabPanels'
import ColleagueModal from './Modals/ColleagueModal'
import {ReactComponent as Plusblack} from "../../assets/plus.svg";
import {ReactComponent as ChangeStatus} from "../../assets/changeStatus.svg";
import {ReactComponent as PropDetails} from "../../assets/PropDetails.svg";
import {ReactComponent as EyeIcon} from "../../assets/EyeIcon.svg";
import ChangeStatusModal from "./Modals/ChangeStatusModal";
import EuTabs from "./EuTabs";
import httpRequest from "../../core/services/http-request";
import ToastComponent from "../../components/toastComponent/ToastComponent";
import Loading from "../../components/Loading";
import {EU, SA, SU, SUPERADMIN} from "../../constants/user/userEnum";
import SuTabs from "../SuProject/SuTabs";
import {ReactComponent as DoneIcon} from "../../assets/Icons/DoneIcon.svg";
import {ReactComponent as Question} from "../../assets/questionIcon.svg";

const Project = () => {
    const {post} = httpRequest()
    const params = useParams()
    const toast = useToast()
    const [loading, setLoading] = useState(false)
    const [loadingPriority, setLoadingPriority] = useState(false)
    const {isOpen: isOpenStatus, onOpen: onOpenStatus, onClose: onCloseStatus} = useDisclosure()
    const {data: projectResponse, isLoading, refetch} = useGetProject(params.id)
    const {user} = useUser()
    const [project, setProject] = useState([])
    const [priority, setPriority] = useState(false)
    const [adminName, setAdminName] = useState('')
    const [consultants, setConsultants] = useState([])
    const [colleagues, setColleagues] = useState([])
    const history = useHistory()
    const [reports, setReports] = useState([])

    const addPriority = () => {
        setLoadingPriority(true)
        post('add-priority', params.id).then((e) => {
            setPriority(e.data.data)
            if (e.data.data) {
                toast({
                    render: () => (
                        <ToastComponent icon={<StarIcon fill='#222222'/>} title='Priority Added to Project' bg='#FABA3F'/>
                    ),
                    duration: 4000,
                    isClosable: true,
                    position: "top-right"
                })
                setLoadingPriority(false)
            } else {
                toast({
                    render: () => (
                        <ToastComponent icon={<StarIcon fill='#DBDADA'/>} title='Priority removed from Project' bg='#E3E3E3'/>
                    ),
                    duration: 4000,
                    isClosable: true,
                    position: "top-right"
                })
                setLoadingPriority(false)
            }
        })
    }

    const hideProject = () => {
        setLoading(true)
        const data = {
            projectId: projectResponse?.data?.data[0]?.id,
            userId: user.id,
        }
        post('hide-project', data).then(() => {
            setLoading(false)
            history.push('/projects')
        })
    }

    useEffect(() => {
        if (!projectResponse) return
        if (user.type === 'Eu') {
            let consultants = []
            let colleg = [projectResponse.data.data[0][0]?.user]
            let data = projectResponse?.data.data
            let reports = []
            setProject(data[0][0]?.project)
            for (let i = 0; i < data[1]?.length; i++) {
                reports.push(data[1][i].report)
            }
            for (let i = 0; i < data[0][0]?.project?.invitedUsersToProject?.length; i++) {
                if (data[0][0].project.invitedUsersToProject[i].type === 'Au' || data[0][0].project.invitedUsersToProject[i].type === 'Sa') {
                    consultants.push(data[0][0].project.invitedUsersToProject[i])
                } else {
                    colleg.push(data[0][0].project.invitedUsersToProject[i])
                }
            }
            setConsultants(consultants)
            setColleagues(colleg)
            setPriority(data[0][0]?.priority)
            setReports(reports)
        }  else {
            let consultants = []
            let colleg = [projectResponse.data.data[0]?.project?.user]
            setProject(projectResponse?.data?.data[0]?.project)
            setReports(projectResponse?.data?.data[0]?.project?.report)
            setPriority(projectResponse?.data?.data[0]?.priority)
            for (let i = 0; i < projectResponse?.data?.data[0]?.project?.invitedUsersToProject?.length; i++) {
                if (projectResponse?.data.data[0].project.invitedUsersToProject[i].type === 'Au' || projectResponse?.data.data[0].project.invitedUsersToProject[i].type === 'Sa') {
                    consultants.push(projectResponse?.data.data[0].project.invitedUsersToProject[i])
                } else {
                    colleg.push(projectResponse?.data.data[0].project.invitedUsersToProject[i])
                }
            }
            setConsultants(consultants)
            setColleagues(colleg)
        }
        if (consultants.length === 0){
            post('get-admin-name').then((e) => setAdminName(e.data.data))
        }

    }, [projectResponse])

    if (isLoading) return <Loading />
    return (
        <>
            <Navbar minW="900px" title={(
                    <Flex>
                        <Link as='h2' m={0} to="/projects" >Projects</Link>
                        <Text as='h2' m={0}>
                            &nbsp;/&nbsp;
                            {
                                user?.type === EU ? (
                                    ((project?.city ? project?.city + ', ' : '') + (project?.state ? project?.state + ', ' : '') + ' ' + (project?.zipCode ? project?.zipCode : '')).replace(/^,|,$/g, '').trim()
                                    +(project?.propertyType ? ' / '+project?.propertyType : '' )+' / Overview'
                                ) : user?.type === SU ? (
                                    ((project?.city ? project?.city + ', ' : '') + (project?.state ? project?.state + ', ' : '') + ' ' + (project?.zipCode ? project?.zipCode : '')).replace(/^,|,$/g, '').trim()
                                    +(project?.propertyType ? ' / '+project?.propertyType : '' )+' / Scope of Work'
                                ) : project?.title
                            }
                        </Text>
                    </Flex>)}/>
            <Flex minW="900px" m="auto" maxW="1240px" border="1px solid" borderColor="#DBDADA" justifyContent="space-between" bg="#f5f5f5" w="100%">
                <Flex pl={8} py={8}>
                    <Image src={project?.image ? S3_BUCKET + project?.image : '/images/defaultLamp.jpg'} borderRadius="5px" mr={8} objectFit="cover" height="146px" width="220px"/>
                    <Box>
                        <Heading mt={0} mb={1} fontSize="24px" as="h1">
                            <Flex alignItems='center'>
                                <button style={{'display': user?.type === SU ? 'none' : 'block'}} onClick={addPriority} disabled={loadingPriority}>
                                    {priority ? (<StarIconYellow/>) : (<StarIcon/>)}
                                </button>
                                <Text ml={user?.type === SU ? 0 : 3} as='h2' mt={0} mb={0}>
                                    {user?.type === EU || user?.type === SU ?
                                        ((project?.city ? project?.city + ', ' : '') + (project?.state ? project?.state + ', ' : '') + ' ' + (project?.zipCode ? project?.zipCode : '')).replace(/^,|,$/g, '').trim()
                                        : (project?.address + ' | ' + project?.title)}
                                </Text>
                            </Flex>
                        </Heading>
                        <Text fontSize="16px">
                            <Text display='inline-block' fontWeight='700'  fontSize='18px'>
                                {
                                    user?.type === EU ? (
                                        <>
                                            <Text fontWeight='700'>
                                                Property Type:&nbsp;
                                                <Text as='span' fontWeight='400'>
                                                    {project?.propertyType}
                                                </Text>
                                            </Text>
                                            <Text fontWeight='700'>
                                                Energy Consultants:&nbsp;
                                                <Text as='span' fontWeight='400'>
                                                    {consultants.length > 0? consultants?.map((user) => user?.firstName + ' ' + user?.lastName): adminName}
                                                </Text>
                                            </Text>
                                        </>
                                    ) :
                                        <Text fontWeight='700'>
                                            Energy Consultants:&nbsp;
                                            <Text as='span' fontWeight='400'>
                                                {consultants.length > 0? consultants?.map((user) => user?.firstName + ' ' + user?.lastName): adminName}
                                            </Text>
                                        </Text>
                                }
                            </Text>
                        </Text>
                        <Text fontSize="18px">
                            <Text display='inline-block' fontWeight='700'>
                                {user?.type === 'SUPERADMIN' || user?.type === 'Sa' || user?.type === 'Au' ? ('Associated Users: ') : ('Colleagues: ')}
                            </Text>
                            &nbsp;{colleagues?.length > 0 && colleagues?.map((user) => user?.firstName + ' ' + user?.lastName)?.toString()}
                        </Text>
                        <Text fontSize="18px">
                            <Text display='inline-block' fontWeight='700'>Status:&nbsp;</Text>
                            {project?.status}
                        </Text>
                        <Text fontSize="18px" display={user?.type === EU || user?.type === SU ? 'none' : 'block'}>
                            <Text display='inline-block' fontWeight='700'>Notes:&nbsp;</Text>
                            {user?.type === SUPERADMIN || user?.type === SA ? (
                                    project?.notes?.replace(/(.{65})..+/, "$1…")
                                ) : (
                                    project?.notes?.replace(/(.{130})..+/, "$1…")
                            )}
                        </Text>
                    </Box>
                </Flex>
                <Flex justifyContent='center' alignItems="center">
                    <Box mx={8} mb={4} width="110px" height="110px" display={user?.type === SUPERADMIN || user?.type === SA  || user?.type === EU || user?.type === SU ? 'none' : 'block'}>
                        {project?.propertyPriorities && <ProjectChartInterests
                            score={JSON.parse(project?.propertyPriorities)}/>}
                    </Box>
                    <Divider orientation="vertical" borderColor="#DBDADA"/>
                    <Flex pl={10} pr={10} mb={9} flexDirection="column">
                        {
                            user?.type === SUPERADMIN || user?.type === SA  ? (
                                <>
                                    <Flex>
                                        <Link to={`/report/${params.id}/create`}>
                                            <Button color="black" borderRadius="4px" w='135px' leftIcon={<Plusblack/>} variant="green">New Report</Button>
                                        </Link>
                                        <Link to={`/project/edit/${project?.id}`}>
                                            <Button w="135px" mb={5} ml={5} borderRadius="4px" leftIcon={<Edit/>} variant="lightOrange">Edit Details</Button>
                                        </Link>
                                    </Flex>
                                    <Flex>
                                        <ChangeStatusModal
                                            value={projectResponse?.data?.data[0]?.id}
                                            refetch={refetch}
                                            project={project}
                                            isOpen={isOpenStatus}
                                            onClose={onCloseStatus}/>
                                        <Button leftIcon={<ChangeStatus/>} onClick={onOpenStatus} w='135px' color='black' borderRadius="4px" mr={5} bg="#FFE68D">Change Status</Button>
                                        <ColleagueModal
                                            consultants={consultants}
                                            colleagues={colleagues}
                                            project={project}/>
                                    </Flex>
                                </>
                            ) : (
                                <>
                                    {
                                        user?.type === EU ? (
                                            <>
                                                <Link to={`/project/project-details/${project?.id}`}>
                                                    <Button w="135px" mb={3} leftIcon={<PropDetails/>} backgroundColor='#666666'>Property Details</Button>
                                                </Link>
                                                <ColleagueModal
                                                    project={project}/>
                                                <Button w="135px" mt={3} isLoading={loading} leftIcon={<EyeIcon/>} onClick={hideProject} backgroundColor='#6D8DA7'>
                                                    Hide Project
                                                </Button>
                                            </>
                                        ) : user?.type === SU ? (
                                            <>
                                                <Link to={`/project/project-details/${project?.id}`}>
                                                    <Button w="135px" mb={5} color='black' leftIcon={<DoneIcon/>} backgroundColor='#C2EF62'>Done</Button>
                                                </Link>
                                                <Button bg="#4A4A4A" color="white" w="135px" mb={5} leftIcon={<Question/>}>
                                                    <Text fontWeight="600" as='h2' fontSize='12px' mt={0} mb={0}>
                                                        Submit Question
                                                    </Text>
                                                </Button>
                                                <Button w="135px" isLoading={loading} leftIcon={<EyeIcon/>} onClick={hideProject} backgroundColor='#6D8DA7'>
                                                    Hide Project
                                                </Button>
                                            </>
                                        ) : (
                                            <>
                                                <Link to={`/project/edit/${project?.id}`}>
                                                    <Button w="135px" mb={3} leftIcon={<Edit/>} variant="lightOrange">Edit Details</Button>
                                                </Link>
                                                <ColleagueModal
                                                    project={project}/>
                                                <Button w="135px" mt={3} isLoading={loading} leftIcon={<EyeIcon/>} onClick={hideProject} backgroundColor='#6D8DA7'>
                                                    Hide Project
                                                </Button>
                                            </>
                                        )
                                    }
                                </>
                            )
                        }
                    </Flex>
                </Flex>
            </Flex>
            {user?.type === EU ? (
                    <EuTabs
                        project={project}
                        reports={reports}/>
                ) :
                <AllTabPanels
                    project={project}
                    user={user}
                    reports={reports}/>
            }
        </>
    )
}

export default Project
