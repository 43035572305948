import {Box, Text, Heading, Flex,} from '@chakra-ui/react'
import {ChartElement, CustomDivider, TableProjectEco, TableProjectEcoBig} from '../../components/Theme'
import Loading from "../../components/Loading";
import {ReactComponent as HumanElementIcons} from "../../assets/Icons/humanELementBigIcon.svg";

const Eco = ({
                 project,
                 proposal,
                 laborHourExisting,
                 laborHourLed,
                 loading,
                 illuminance,
                 energySavedEco,
                 acSavings,
                 co2Abated,
                 mercuryAbated,
                 globalCRI,
                 globalLumens,
                 customBidding,
                 lumens,
                 cries,
                 titles
             }) => {
    if (loading) return <Loading/>
    return (
        <>
            <Box>
                <Heading fontSize="3xl">Eco Incentive</Heading>
                <Text fontSize="md">
                    By illuminating your building with LEDs, you&apos;re making a good choice for the planet. Choosing
                    an energy-conscious technology reduces your environmental footprint. The chart below outlines some
                    of the ways your upgrade will work towards a greener future.
                </Text>
            </Box>
            <CustomDivider border='2px'/>
            <Box>
                <Heading fontSize="xl">Environmental Benefits</Heading>
                <TableProjectEco
                    width="100%"
                    customBidding={customBidding}
                    acSavings={acSavings}
                    co2Abated={co2Abated}
                    energySavedEco={energySavedEco}
                    mercuryAbated={mercuryAbated}/>
            </Box>
            <CustomDivider border='2px'/>
            {globalCRI < 0 || globalLumens < 0 ? (
                <Flex justifyContent="space-between" w='full'>
                    <Flex flexDirection="column" maxW='400px'>
                        <Heading mt={0} fontSize="3xl">Human Element</Heading>
                        <Text fontSize="md">
                            Studies show that a properly lit work environment can increase employee productivity by
                            10-15%. Upgrading to solid state LEDs will alleviate your team of the headaches and eye
                            strain so often associated with traditional fluorescents and HIDs. Properly illuminated work
                            environments also reduce on-site accidents and can minimize insurance premiums.
                        </Text>
                    </Flex>
                    <HumanElementIcons/>
                </Flex>
            ) : (
                <Flex justifyContent="space-between" flexWrap="wrap" alignItems="center">
                    <Flex w="400px" flexDirection="column">
                        <Heading fontSize="xl">Human Element</Heading>
                        <Text mb={4} fontSize="md">
                            Studies show that a properly lit work environment can increase employee productivity by
                            10-15%.
                            Upgrading to solid state LEDs will alleviate your team of the headaches and eye strain so
                            often
                            associated with traditional fluorescents and HIDs.
                        </Text>
                        <Flex alignItems="center">
                            <Flex mr={3} alignItems="center" justifyContent="center" width="130px" height="35px"
                                  bg={customBidding ? '#A9A9A9' : '#9CBDD8'}>
                                <Box as="span">
                                    {globalCRI}%
                                </Box>
                            </Flex>
                            <Box as="span">
                                Avg. Improved CRI
                            </Box>
                        </Flex>
                        <Flex alignItems="center">
                            <Flex mr={3} alignItems="center" justifyContent="center" width="130px" height="35px"
                                  bg={customBidding ? '#A9A9A9' : '#FFD339'}>
                                <Box as="span">
                                    {globalLumens}%
                                </Box>
                            </Flex>
                            <Box as="span">
                                Avg. Improved Illumination
                            </Box>
                        </Flex>
                        <Text mt={4} fontSize="md">
                            Properly illuminated work environments also reduce on-site accidents and can minimize
                            insurance
                            premiums.
                        </Text>
                    </Flex>
                    <Box height="200px" w="400px">
                        {!customBidding &&
                            <ChartElement
                                CRI={cries}
                                lumens={lumens}/>
                        }
                    </Box>
                </Flex>
            )}
            <CustomDivider border='2px'/>
            <Box>
                <Heading fontSize="xl">
                    Precious Time
                </Heading>
                <Text mb={4} fontSize="md">
                    Additionally, long lasting LEDs reduce the amount of time you must spend on lamp replacement,
                    allowing your company electrician to focus on more important tasks.
                </Text>
                <Heading fontSize="md" m={0}>
                    Labor Hours Saved
                </Heading>
                <TableProjectEcoBig
                    width="100%"
                    customBidding={customBidding}
                    laborHourExisting={laborHourExisting}
                    laborHourLed={laborHourLed}/>
                <Text mt={8} fontSize="md">
                    Note: We defer to your own internal measurements for just how much time this upgrade will save in
                    your acquisition and coordination departments.
                </Text>
            </Box>
        </>
    )
}
export default Eco