import React from 'react'
import {Heading, Box, Stack, Flex, Text, Button, useDisclosure,} from '@chakra-ui/react'
import {useHistory} from 'react-router-dom'
import {InviteModal} from '../../../components/Theme'
import {useSignOut, useUser} from "../../../core/hooks";
import ContactToAdminModal from "./ContactToAdminModal";

const Template = ({title, text, buttonText, buttonVariant, action}) => (
    <Box>
        <Flex alignItems="center" justifyContent="space-between">
            <Box mr="8">
                <Heading m="0" as="h3" fontSize="xl">{title}</Heading>
                <Text mt="5" fontWeight="400" fontSize="md">{text}</Text>
            </Box>
            <Button onClick={action} bg={buttonVariant} minW="202px" height="50px" textTransform="uppercase" color="#222222">
                {buttonText}
            </Button>
        </Flex>
        <Box my="5" height="1px" w="full" bg="#D3D3D3"/>
    </Box>

)
const EditCompanyModal = ({isOpen, onClose, company}) => {
    const history = useHistory()
    const {setUser, user} = useUser()
    const {signOut} = useSignOut()
    const {isOpen: isOpenContact, onOpen: onOpenContact, onClose: onCloseContact} = useDisclosure()

    const toRegister = () => {
        signOut()
        history.replace(`/register/${user.type}`)
        setUser(null)
    }

    return (
        <InviteModal size="xl" title="Are you sure?" bg="#FF9494" onClose={onClose} isOpen={isOpen}>
            <ContactToAdminModal
                company={company}
                onCloseParent={onClose}
                isOpenContact={isOpenContact}
                onCloseContact={onCloseContact}/>
            <Heading as="h2" fontWeight="700" fontSize="3xl">
                You’re currently synced with {company ? company?.name : user?.associatedToCompany?.name} - which of the following are you trying to do:
            </Heading>
            <Box my="10" bg="#000" w="full" height="2px"/>
            <Stack spacing="0">
                <Template
                    action={onOpenContact}
                    buttonVariant="#DD94FF"
                    title="Suggest Company profile changes"
                    text="To keep Company profiles synchronized, contact a Focus Earth Administrator to suggest information updates."
                    buttonText="Contact Administrator"/>
                <Template
                    action={() => history.push(`/edit-company/${company ? company?.id : user?.associatedToCompany?.id}`)}
                    buttonVariant="#FFD494"
                    title="Edit Company"
                    text={`New positions are exciting! If you’ll no longer be working on ${company ? company?.name : user?.associatedToCompany?.name} projects, enter your new company information. This will sever your sync with ${user?.company?.length > 0 ? user?.company[0]?.name : user?.associatedToCompany?.name}.`}
                    buttonText="Unlock Editing"/>
                <Template
                    action={toRegister}
                    buttonVariant="#FF9494"
                    title="Different Company"
                    text="If you work for multiple organizations, please create a new user profile for each entity."
                    buttonText="Create New user"/>
                <Template
                    action={onClose}
                    buttonVariant="#94E5FF"
                    title="Nevermind"
                    text={`I’ll stick with ${company ? company?.name : user?.associatedToCompany?.name} projects for now...`}
                    buttonText="Back to Profile"/>
            </Stack>
        </InviteModal>
    )
}

export default EditCompanyModal
