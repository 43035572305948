import {Stack, HStack, Box, Text,} from '@chakra-ui/react'
import {useEffect, useState} from 'react'
import {InputLabel, ChakraSelectLabel} from '../../../components/Theme'
import NavigateModal from './NavigateModal'
import {useGetExsiting} from "../../../core/hooks";

const Fixture = ({ idx, getValues, description, onClose, setRecent, setApplication, area, setArea, luminaireId, setType, createKey, fixture, setFixture, setCreateKey,}) => {
    const [filters, setFilters] = useState({application: '', mount: '', style: '', lens: '', length: '', width: '', depth: '', diameter: '',})
    const [errors, setErrors] = useState('')
    const app = getValues(`areas.${idx}.application`)
    const {refetch} = useGetExsiting({
        type : 'Fixtures',
        mount: fixture.fixtureMount,
        style: fixture.fixtureStyle,
        lensType: fixture.fixtureLens,
        application: fixture.fixtureApplication,
    })

    const create = () => {
        if (!fixture.fixtureMount && !fixture.fixtureStyle && !fixture.fixtureLens) return
        refetch()
            .then((res) => {
            if (res?.data?.data?.data){
                setFixture({...fixture, fixtureExisting: res.data.data.data})
            }
            const newArray = area?.luminaires.map((luminaire) => {
                let newObj = {...luminaire}
                if (newObj.id === luminaireId) {
                    newObj = {...newObj, ...fixture,fixtureExisting:res.data.data.data}
                }
                return newObj
            })
            setArea({...area, luminaires: newArray})
            setRecent([...newArray])
        })
    }

    if (createKey) {
        create()
        setCreateKey(false)
        onClose()
    }

    useEffect(() => {
        //Set description width parameters that exists
        const description = (fixture.fixtureLength ? fixture.fixtureLength + '"' : '') +
            (fixture.fixtureWidth ? (fixture.fixtureLength ? 'x' : '')+fixture.fixtureWidth + '"' : '') +
            (fixture.fixtureDiameter ? (fixture.fixtureLength || fixture.fixtureWidth ? 'x' : '')+fixture.fixtureDiameter.charAt(0).toUpperCase() + fixture.fixtureDiameter.slice(1)+'"' : '') +
            (fixture.fixtureDepth ? (fixture.fixtureLength || fixture.fixtureWidth || fixture.fixtureDiameter ? 'x' : '')+fixture.fixtureDepth.charAt(0).toUpperCase() + fixture.fixtureDepth.slice(1)+'"' : '') +
            fixture.fixtureMount.charAt(0).toUpperCase() + fixture.fixtureMount.slice(1) + " " +
            fixture.fixtureStyle.charAt(0).toUpperCase() + fixture.fixtureStyle.slice(1)
        setFixture({...fixture, fixtureDescription: description})
    }, [filters])

    return (
        <Stack spacing="5">
            <NavigateModal
                label={description}
                leftAction={() => {
                    create()
                    setType('accessory')
                }}
                rightAction={() => {
                    create()
                    setType('lamp')
                }}
                left="Accessories"
                right="Lamp"/>
            <HStack w="full" justifyContent="center" position="relative" alignItems="center" pt={5}>
                <Box position="absolute" bg="#A9A9A9" w="full" h="1px"/>
                <Box px="2" position="relative" bg="white" textTransform="uppercase" fontWeight="400" fontSize="xl" color="#2C628D" as="span">
                    Fixture Details
                </Box>
            </HStack>
            {errors && (<Text p={0} textAlign='center' color="red.100" fontSize="m">{errors}</Text>)}
            <HStack spacing="5" alignItems="flex-end">
                <InputLabel height='46px' maxW="82px" type="number" pattern="[0-9]*" label="QTY" value={fixture?.qty} onChange={(e) => setFixture({...fixture, qty: e.target.value})}/>
                <InputLabel height='46px' maxW="142px" type="number" pattern="[0-9]*" label="Mount Height (ft)" value={fixture?.fixtureMountHeight}
                    onChange={(e) => {
                        if (getValues(`areas.${idx}.ceilingHeight`)) {
                            if (e.target.value <= Number(getValues(`areas.${idx}.ceilingHeight`))) {
                                setFixture({...fixture, fixtureMountHeight: e.target.value})
                                setErrors('')
                            } else {
                                return setErrors('Mount height cannot be higher than ceiling height')
                            }
                        } else {
                            setFixture({...fixture, fixtureMountHeight: e.target.value})
                            setErrors('')
                        }
                    }}/>
                <InputLabel height='46px' color='#737373' backgroundColor='#EEEEEE !important' value={fixture?.fixtureDescription} readOnly label="Fixture Description"/>
            </HStack>
            <HStack w="full" justifyContent="center" position="relative" alignItems="center" py={5}>
                <Box position="absolute" bg="#A9A9A9" w="full" h="1px"/>
                <Box px="2" position="relative" bg="white" textTransform="uppercase" fontWeight="400" fontSize="xl" color="#2C628D" as="span">
                    filters
                </Box>
            </HStack>
            <HStack spacing="5">
                <ChakraSelectLabel height='46px' disabled={!!app} placeholder="Select" maxW="203px" value={app ? app : fixture?.fixtureApplication}
                    onChange={(e) => {
                        setFilters({...filters, application: e.target.value})
                        setFixture({...fixture, fixtureApplication: e.target.value})
                        setApplication(e.target.value)
                    }}
                    label="Application:">
                    <option value="Indoor">Indoor</option>
                    <option value="Outdoor">Outdoor</option>
                    <option value="Garage">Garage</option>
                </ChakraSelectLabel>
                <ChakraSelectLabel height='46px' placeholder="Select" maxW="203px" value={fixture?.fixtureMount}
                    onChange={(e) => {
                        setFilters({...filters, mount: e.target.value})
                        setFixture({...fixture, fixtureMount: e.target.value})
                    }}
                    label="Mount:">
                    <option value="Arm">Arm</option>
                    <option value="Cove">Cove</option>
                    <option value="Pole">Pole</option>
                    <option value="Posttop">Post Top</option>
                    <option value="Pendant">Pendant</option>
                    <option value='Recessed'>Recessed</option>
                    <option value="Suspended">Suspended</option>
                    <option value="Surface">Surface</option>
                    <option value="Track">Track</option>
                    <option value="Wall">Wall</option>
                </ChakraSelectLabel>
                <ChakraSelectLabel height='46px' placeholder="Select" maxW="203px" label="Style:" value={fixture?.fixtureStyle}
                    onChange={(e) => {
                        setFilters({...filters, style: e.target.value})
                        setFixture({...fixture, fixtureStyle: e.target.value})
                    }}>
                    <option value="Accent">Accent</option>
                    <option value="Area/Flood">Area/Flood</option>
                    <option value="Can">Can</option>
                    <option value="Garage">Garage</option>
                    <option value="Landscape">Landscape</option>
                    <option value="Lowbay/Highbay">Lowbay/HighBay</option>
                    <option value="Parking/Roadway">Parking/Roadway</option>
                    <option value="Task">Task</option>
                    <option value="Troffer">Troffer</option>
                </ChakraSelectLabel>
                <ChakraSelectLabel height='46px' value={fixture?.fixtureLens} maxW="203px" label="Lens:"
                    onChange={(e) => {
                        setFilters({...filters, lens: e.target.value})
                        setFixture({...fixture, fixtureLens: e.target.value})
                    }}>
                    <option value="None">None</option>
                    <option value="Acryllic">Acryllic</option>
                    <option value="Clear">Clear</option>
                    <option value="Frosted">Frosted</option>
                    <option value="Parabolic">Parabolic</option>
                </ChakraSelectLabel>
            </HStack>
            <HStack spacing="5">
                <InputLabel height='46px' type="number" pattern="[0-9]*" value={fixture?.fixtureLength}
                    onChange={(e) => {
                        setFilters({...filters, length: e.target.value})
                        setFixture({...fixture, fixtureLength: e.target.value})
                    }}
                    label="Dimensions-Length (in):"/>
                <InputLabel height='46px' type="number" pattern="[0-9]*" value={fixture?.fixtureWidth}
                    onChange={(e) => {
                        setFilters({...filters, width: e.target.value})
                        setFixture({...fixture, fixtureWidth: e.target.value})
                    }}
                    label="Dimensions-Width (in):"/>
                <InputLabel height='46px' type="number" value={fixture?.fixtureDepth} label="Dimensions-Depth (in):"
                    onChange={(e) => {
                        setFilters({...filters, depth: e.target.value})
                        setFixture({...fixture, fixtureDepth: e.target.value})
                    }}/>
                <InputLabel height='46px' type="number" value={fixture?.fixtureDiameter}
                    onChange={(e) => {
                        setFilters({...filters, diameter: e.target.value})
                        setFixture({...fixture, fixtureDiameter: e.target.value})
                    }}
                    label="Dimensions-Diameter (in):"/>
            </HStack>
        </Stack>
    )
}

export default Fixture
