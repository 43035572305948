import React, {useState, useEffect} from 'react'
import {
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionIcon,
    AccordionPanel,
    Input,
    Flex,
    Image,
    Box,
    VStack,
    HStack,
    GridItem,
    SimpleGrid
} from '@chakra-ui/react'
import {S3_BUCKET} from '../../constants'
import EuAreaInput from './EuAreaInput'
import AccordionSolo from './AccordionSolo/index'

const EuArea = ({idx, register, data,}) => {
    const [area, setArea] = useState({luminaires: []})

    useEffect(() => {
        if (!data) return
        setArea({
            luminaires: data.luminaires ? data.luminaires : [],
            image: data.image ? data.image : ''
        })
    }, [data])
    return (
        <>
            <Accordion
                defaultIndex={0}
                allowToggle>
                <AccordionItem
                    position="relative">
                    <Flex
                        px="4"
                        alignItems="center"
                        justifyContent="space-between"
                        borderRadius="10px"
                        height="57px"
                        bg="#2C628D"
                        flex="1">
                        <Flex
                            alignItems="center">
                            <Input
                                color="white"
                                variant="unstyled"
                                maxW="150px"
                                placeholder="Area Title"
                                value={data?.title}/>
                        </Flex>
                        <Flex
                            alignItems="center">
                            <AccordionButton
                                borderRadius="3px"
                                border="2px solid"
                                borderColor="white"
                                fontSize="sm"
                                color="white"
                                height="30px"
                                maxW="120px">
                                Area Details
                                <AccordionIcon/>
                            </AccordionButton>
                        </Flex>
                    </Flex>
                    <AccordionPanel
                        pb={4}
                        mt={-4}>
                        <Flex
                            flexDirection="column">
                            <HStack
                                spacing={10}>
                                <VStack>
                                    <Image
                                        src={data?.image ? data?.image && `${S3_BUCKET}${data.image}` : ' '}
                                        height="274px"
                                        width="341px"/>
                                </VStack>
                                <EuAreaInput
                                    idx={idx}
                                    data={data}
                                    register={register}/>
                            </HStack>
                        </Flex>
                    </AccordionPanel>
                </AccordionItem>
            </Accordion>
            {
                area?.luminaires?.length > 0 && (
                    <HStack
                        mt="10"
                        mb="5"
                        spacing="6"
                        w="full">
                        <HStack
                            w="550px"
                            spacing="4rem"
                            pl="7"
                            fontWeight="600">
                            <Box
                                fontSize="xl"
                                fontWeight="600"
                                as="span">
                                QTY
                            </Box>
                            <Box
                                fontSize="xl"
                                fontWeight="600"
                                as="span">
                                Luminaire Description
                            </Box>
                        </HStack>
                        {
                            area?.luminaires.length > 1 && (
                                <HStack
                                    w="550px"
                                    spacing="4rem"
                                    pl="7"
                                    fontWeight="600">
                                    <Box
                                        fontSize="xl"
                                        fontWeight="600"
                                        as="span">
                                        QTY
                                    </Box>
                                    <Box
                                        fontSize="xl"
                                        fontWeight="600"
                                        as="span">
                                        Luminaire Description
                                    </Box>
                                </HStack>
                            )
                        }
                    </HStack>
                )
            }
            <SimpleGrid
                my="6"
                w="full"
                columns={{base: 2}}
                spacing={6}>
                {
                    area?.luminaires?.map((luminaire) => (
                        <GridItem
                            key={luminaire.id}>
                            <AccordionSolo
                                luminaire={luminaire}/>
                        </GridItem>
                    ))
                }
            </SimpleGrid>
        </>
    )
}

export default EuArea
