const Button = {
    baseStyle: {
        color: 'black.100',
        borderRadius: '5px',
    },
    variants: {
        green: {
            bg: 'green.100',
        },
        pink: {
            bg: '#fda88c',
        },
        red: {
            bg: '#F1381F',
            color: 'white.100',
        },
        blue: {
            bg: '#2C628D',
            color: 'white.100',
        },
        lightBlue: {
            bg: '#9CBDD8',
        },
        white: {
            color: '#2C628D',
            bg: 'white.100',
        },
        yellow: {
            bg: '#FFE68D',
        },
        orange: {
            color: 'white.100',
            bg: '#FF7F56',
        },
        lightOrange: {
            bg: '#FEAB7C',
        },
        lime: {
            bg: '#C2EF62',
        },
        transparent: {
            color: 'black.100',
            fontWeight: '700',
            height: 'unset',
            width: 'unset',
            padding: '0',
            margin: '0',
            background: 'none',
            borderRadius: 'none',
        },
    },
    sizes: {
        sm: {
            fontSize: '0.75rem',
            height: '30px',
        },
        md: {
            fontSize: '0.75rem',
            height: '40px',
        },
        lg: {
            fontSize: '1rem',
            height: '51px',
        },
    },
    defaultProps: {
        size: 'md',
        variant: 'blue',
    },
}

export default Button
