import {css} from '@emotion/react'

const styles = {
    checkbox: css`
      border: 1px solid #A9A9A9;
      border-radius: 6px;
      margin-left: 10px;
      box-shadow: none;

      &:focus {
        border: none;
        background-color: #8FCF74;
      }

      & > span {
        border: none;
      }

      & > span[data-checked] {
        border: none;
        box-shadow: none;
        background-color: #8FCF74;

        &:hover {
          background-color: #8FCF74;
        }

        &:focus {
          box-shadow: none;
          background-color: #8FCF74;
        }
      }

      & > span[data-focus] {
        box-shadow: none;
      }
    `,
    toast: css`
      animation-name: scroll;
      animation-duration: 4s;
      animation-timing-function: linear;
      @keyframes scroll {
        0% {
          left: 5px;
        }
        100% {
          left: 100%;
        }
      }
    `,
}

export default styles