import React, {useEffect, useState} from 'react'
import {Link, useHistory, useParams} from 'react-router-dom'
import {Flex, Text, Box, Heading, useDisclosure, Button,} from '@chakra-ui/react'
import Navbar from '../../components/Navbar'
import {useDeleteCompany, useGetCompany, useGetCompanyChanges, useUpdateCompany, useUser} from '../../core/hooks'
import {useForm} from "react-hook-form";
import InviteToCompanyModal from './InviteToCompanyModal'
import DeleteFileModal from "../EditCompany/Modals/DeleteFileModal";
import Loading from "../../components/Loading";
import {REU_COMPANY_TYPES, SU_COMPANY_TYPES, EU_COMPANY_TYPES} from "../../constants/types";
import CustomDivider from "../../components/Theme/CustomDivider";
import DoneButton from "../../components/Theme/Buttons/DoneButton";
import mainStyles from "../../styles/mainStyles";
import {ReactComponent as Edit_Modal} from "../../assets/edit_modal.svg";
import Header from "./Parts/Header";
import {InitialScore} from "../../constants/chartDatas";
import ViewAssociatedUsersModal from "./Modals/ViewAssociatedUsersModal";
import DeleteUserFromCompanyModal from "./Modals/DeleteUserFromCompanyModal";
import DeleteConfirmationModal from "./Modals/DeleteConfirmationModal";
import Details from "./Parts/Details";
import CompanyPropertyTypes from "../../components/Theme/DataChartsParts/CompanyPropertyTypes";
import Uploads from "./Parts/Uploads";
import useRemoveUserFromCompany from "../../core/hooks/company/useRemoveUserFromCompany";
import {ReactComponent as Delete} from "../../assets/deleteUserIcon.svg";
import SyncCompanyModal from "./Modals/SyncCompanyModal";
import {ELECTRICAL_TYPE, REAL_ESTATE_TYPE, SUPPLIER_TYPE} from "../../constants/company/companyEnum";
import ServiceableAreas from "../EditCompany/Modals/ServiceableAreas";
import {REU} from "../../constants/user/userEnum";

const CompanyEditAdmin = () => {
    const {id} = useParams()
    const {user} = useUser()
    const {company: companyResponse, refetch: refetchCompany} = useGetCompany(id)
    const {company: companyChangesResponse} = useGetCompanyChanges(id)
    const {removeUser, isLoading: isLoadingRemoveUser} = useRemoveUserFromCompany()
    const {mutate: updateCompany, isLoading} = useUpdateCompany()
    const [score, setScore] = useState(InitialScore)
    const {deleteCompany, isLoadingDeleting} = useDeleteCompany()
    const history = useHistory()
    const [serviceArea, setServiceArea] = useState([])
    const {isOpen, onOpen, onClose} = useDisclosure()
    const {isOpen: isOpenServiceArea, onOpen: onOpenServiceArea, onClose: onCloseServiceArea} = useDisclosure()
    const {isOpen: isOpenDeleteFile, onOpen: onOpenDeleteFile, onClose: onCloseDeleteFile} = useDisclosure()
    const {isOpen: isOpenView, onOpen: onOpenView, onClose: onCloseView} = useDisclosure()
    const {isOpen: isOpenDeleteUser, onOpen: onOpenDeleteUser, onClose: onCloseDeleteUser} = useDisclosure()
    const {isOpen: isOpenConfirmation, onOpen: onOpenConfirmation, onClose: onCloseConfirmation} = useDisclosure()
    const {isOpen: isOpenSync, onOpen: onOpenSync, onClose: onCloseSync} = useDisclosure()
    const [files, setFiles] = useState([])
    const [company, setCompany] = useState()
    const [removeUserId, setRemoveUserId] = useState()
    const [companySubType, setCompanySubType] = useState([])
    const [companyTypes, setCompanyTypes] = useState([])
    const {formState: {errors}, register, handleSubmit, setValue, reset, watch} = useForm({defaultValues: {...companyResponse?.data.data}})
    const [fileForDelete, setFileForDelete] = useState()
    const image = watch('image', '')
    const [companyChanges, setCompanyChanges] = useState([])

    useEffect(() => {
        if (!companyChangesResponse) return
        setCompanyChanges(companyChangesResponse.data.data)
    },[companyChangesResponse])


    const DeleteCompany = () => {
        deleteCompany(company.id,
            {
                onSuccess() {
                    history.push('/companies')
                },
            })
    }

    useEffect(() => {
        if (!companyResponse) return
        setCompany(companyResponse?.data?.data)
        setFiles(companyResponse?.data?.data?.files)
        setScore(JSON.parse(companyResponse?.data?.data?.propertyPriorities[0]))
        reset({...companyResponse?.data?.data})
    }, [companyResponse])

    const submit = (data) => {
        const input = {
            ...data,
            subType: companySubType,
            type: 6,
            serviceArea: [JSON.stringify(serviceArea)],
            propertyPriorities: [JSON.stringify(score)],
        }
        updateCompany(input, {
            onSuccess() {
                history.goBack()
            }
        })
    }

    const removeUserFromCompany = (id) => {
        let type = 'associated'
        if (Number(id) === company?.owner?.id){
            type = 'owner'
        }
        let data = {
            id: id,
            type: type,
        }
        removeUser(data,{
            onSuccess:() => {
                onCloseConfirmation()
                onCloseDeleteUser()
                onCloseView()
            },
            onError: (err) => console.log(err),
        })

    }

    useEffect(() => {
        if (!company) return
        if (company?.type === REAL_ESTATE_TYPE){
            setCompanyTypes(REU_COMPANY_TYPES)
            if (company?.companySubType){
                setCompanySubType([company?.companySubType, REU_COMPANY_TYPES[company.companySubType]])
            }
        } else if (company?.type === SUPPLIER_TYPE){
            setCompanyTypes(SU_COMPANY_TYPES)
            if (company?.companySubType) {
                setCompanySubType([company?.companySubType, SU_COMPANY_TYPES[company.companySubType]])
            }
        } else if (company?.type === ELECTRICAL_TYPE){
            setCompanyTypes(EU_COMPANY_TYPES)
            if (company?.companySubType) {
                setCompanySubType([company?.companySubType, EU_COMPANY_TYPES[company.companySubType]])
            }
        }
        setServiceArea(JSON.parse(company?.serviceAreas[0]))
    },[company])

    if (isLoading) return <Loading />

    return (
        <>
            <form onSubmit={handleSubmit(submit)}>
                <DeleteFileModal
                    getUser={refetchCompany}
                    fileId={fileForDelete}
                    onClose={onCloseDeleteFile}
                    isOpen={isOpenDeleteFile}/>
                {company && <InviteToCompanyModal
                    company={company}
                    isOpen={isOpen}
                    onClose={onClose}/>}
                <ViewAssociatedUsersModal
                    onOpenDeleteUser={onOpenDeleteUser}
                    company={company}
                    isOpen={isOpenView}
                    onClose={onCloseView}/>
                <SyncCompanyModal
                    company={company}
                    history={history}
                    isOpen={isOpenSync}
                    onClose={onCloseSync}/>
                <DeleteUserFromCompanyModal
                    userId={removeUserId}
                    onOpenConfirmation={onOpenConfirmation}
                    setUserId={setRemoveUserId}
                    isOpen={isOpenDeleteUser}
                    onClose={onCloseDeleteUser}
                    company={company}/>
                <DeleteConfirmationModal
                    isOpen={isOpenConfirmation}
                    onClose={onCloseConfirmation}
                    userId={removeUserId}
                    loading={isLoadingRemoveUser}
                    removeUserFromCompany={removeUserFromCompany}/>
                <Navbar minW="1240px"
                    title={(
                        <Flex>
                            <Link as='h2' m={0} to="/companies">Companies</Link>
                            <Text as='h2' m={0}>&nbsp;/&nbsp;{company?.name} / Edit Details</Text>
                        </Flex>
                    )}>
                    <DoneButton />
                </Navbar>
                <Box css={mainStyles.container} px='40px' pt='20px'>
                    <Header
                        onOpen={onOpen}
                        onOpenSync={onOpenSync}
                        company={company}
                        companyTypes={companyTypes}
                        companySubType={companySubType}
                        setCompanySubType={setCompanySubType}
                        isLoadingDeleting={isLoadingDeleting}
                        image={image}
                        setValue={setValue}/>
                    <CustomDivider />
                    <Details
                        onOpenView={onOpenView}
                        errors={errors}
                        register={register}
                        companyChanges={companyChanges}
                        company={company}/>
                    <CustomDivider />
                    <Heading mb={10} fontSize="xl" as="h2">
                        {user?.type === REU ? ('Property Types:') : ('Serviceable Property Types:')}
                    </Heading>
                    <CompanyPropertyTypes
                        plus={true}
                        score={score}
                        setScore={setScore}/>
                    <CustomDivider />
                    <Box
                        w='full'
                        display={company?.type === ELECTRICAL_TYPE ? 'block' : 'none'}>
                        <Flex
                            w="100%">
                            <Text
                                fontWeight='600'
                                mb={5}
                                fontSize='18px'>
                                Serviceable Areas:
                            </Text>
                        </Flex>
                        <Flex
                            w="100%">
                            <Text
                                fontWeight='600'
                                fontSize='14px'
                                color="#2C628D">
                                Serviceable Areas - States & Counties
                            </Text>
                        </Flex>
                        <Flex
                            w="100%"
                            border="1px solid #C4C4C4"
                            borderRadius="5px"
                            justifyContent="space-between"
                            p="10px"
                            mb={20}>
                            <Text
                                fontWeight='400'
                                fontSize='18px'>
                                {(() => {
                                    if (serviceArea.length > 0) {
                                        let a = serviceArea?.map((item) => item.name + '(' + item.child.filter((i) => i.value === true).map((e) => e.name) + ')-')
                                        let res = a[a.length - 1].slice(0, -1)
                                        a.pop()
                                        a.push(res)
                                        return a.toString().replace(/(.{100})..+/, "$1…")
                                    }
                                })()}
                            </Text>
                            <ServiceableAreas
                                serviceArea={serviceArea}
                                setServiceArea={setServiceArea}
                                isOpen={isOpenServiceArea}
                                onClose={onCloseServiceArea}/>
                            <Button
                                onClick={onOpenServiceArea}
                                leftIcon={<Edit_Modal/>}
                                w="60px"
                                height="22px"
                                variant="primary"
                                backgroundColor="#FFE68D">
                                Edit
                            </Button>
                        </Flex>
                    </Box>
                    <Uploads
                        companyId={company?.id}
                        refetchCompany={refetchCompany}
                        onOpenDeleteFile={onOpenDeleteFile}
                        setFileForDelete={setFileForDelete}
                        files={files}/>
                    <Flex justifyContent='end'>
                        <Button
                            isLoading={isLoadingDeleting}
                            onClick={() => deleteCompany()}
                            leftIcon={<Delete/>}
                            w="135px"
                            bg='#F1381F'
                            mr={5}
                            color='black'>
                            Delete Company
                        </Button>
                        <DoneButton />
                    </Flex>
                </Box>
            </form>
        </>
    )
}

export default CompanyEditAdmin
