import React, {useState} from 'react'
import {useForm} from 'react-hook-form'
import {useHistory} from 'react-router-dom'
import {
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    Flex,
    Text,
    Button as TextButton,
} from '@chakra-ui/react'
import {
    useForgotPasswordSubmit,
} from '../../../core/hooks'
import {Input, Button} from '../../../components/Theme'

const ResetPassword = ({email, onClose, forgotPassword}) => {
    const [error, setError] = useState('')
    const {resetPassword, isLoading} = useForgotPasswordSubmit()
    const {register, handleSubmit} = useForm()
    const history = useHistory()

    const submit = (data) => {
        resetPassword(
            {
                email: email.current,
                code: data.code,
                password: data.password,
            },
            {
                onSuccess: () => {
                    onClose()
                    setError('')
                    history.push('/login')
                },
                onError: (e) => setError(e.response.data.errorMessage),
            },
        )
    }

    const resend = () => {
        forgotPassword(
            {email: email.current},
            {
                onSuccess: () => {
                    setError('Please check your email for new code')
                },
                onError: (e) => {
                    setError(e.response.data.errorMessage)
                },
            },
        )
    }

    return (
        <>
            <ModalOverlay/>
            <ModalContent>
                <ModalHeader>
                    Reset Password
                </ModalHeader>
                <ModalCloseButton
                    _focus={{boxShadow: 'none'}}/>
                <ModalBody>
                    <form
                        onSubmit={handleSubmit(submit)}>
                        <Flex
                            alignItems="center"
                            flexDirection="column">
                            <Input
                                mb={6}
                                autoComplete="off"
                                type="string"
                                placeholder="code"
                                {...register('code')}/>
                            <Input
                                mb={3}
                                type="password"
                                placeholder="New password"
                                {...register('password')}/>
                            <TextButton
                                variant="transparent"
                                onClick={resend}
                                fontSize="sm">
                                Resend Code
                            </TextButton>
                            {
                                error && (
                                    <Text
                                        mt={6}
                                        color="red.100"
                                        fontSize="sm">
                                        {error}
                                    </Text>
                                )
                            }
                            <Button
                                type="submit"
                                width="270px"
                                colorScheme="blue.200"
                                isLoading={isLoading}
                                my={8}>
                                Reset
                            </Button>
                        </Flex>
                    </form>
                </ModalBody>
            </ModalContent>
        </>
    )
}

export default ResetPassword
