import React, {useEffect, useMemo, useState} from 'react'
import {Accordion as ChakraAccordion, GridItem,} from '@chakra-ui/react'
import AccordionSolo from "./AccordionSolo";

function Accordion({area, setArea,customPricing,reportId, setCustomPricing, recent, setRecent, project, vale, handleRecommended,}) {
    const [groupedLuminaires, setGroupedLuminaires] = useState([]);

    const filter = (lumins) => {
        const groupedObjects = new Map();

        if (lumins.length > 0) {
            for (let j = 0; j < lumins?.length; j++) {
                let { id, qty, fixtureMountHeight, ...rest } = lumins[j];
                let luminWithoutIdQty = JSON.stringify(rest);
                if (groupedObjects.has(luminWithoutIdQty)) {
                    groupedObjects.get(luminWithoutIdQty).globalQty += Number(qty);
                    groupedObjects.get(luminWithoutIdQty).globalQtyLamp += Number(lumins[j]?.lampPerFixture);
                } else {
                    groupedObjects.set(luminWithoutIdQty, {
                        ...JSON.parse(luminWithoutIdQty),
                        globalQty: Number(qty),
                        globalQtyLamp: Number(lumins[j]?.lampPerFixture),
                    });
                }
            }
        }
        return Array.from(groupedObjects.values());
    };
    useEffect(() => {
        const allLuminaires = area
            ?.filter(item => vale?.includes(item.title))
            .flatMap(e => e.luminaires);

        const groupedLuminaires = filter(allLuminaires);
        setGroupedLuminaires([...groupedLuminaires]);
    }, [area, vale, setArea,recent]);

    return (
        <ChakraAccordion key={JSON.stringify(groupedLuminaires)} minW="900px" maxW="1240px" allowMultiple>
            {groupedLuminaires.length > 0 && groupedLuminaires?.map((luminaire, index) => (
                    <GridItem key={luminaire.id}>
                        <AccordionSolo
                            reportId={reportId}
                            customPricing={customPricing}
                            setCustomPricing={setCustomPricing}
                            fromAll={true}
                            project={project}
                            setRecent={setRecent}
                            recent={recent}
                            handleRecommended={handleRecommended}
                            luminaire={luminaire}
                            area={area}
                            setArea={setArea}/>
                    </GridItem>
                ))}
        </ChakraAccordion>
    )
}

export default Accordion
