import {Flex, Heading, Image} from '@chakra-ui/react'

const Loading = ({height}) => (
    <Flex minH={height ? height : '100vh'} alignItems='center' justifyContent='center'>
        <Flex alignItems='center' width='max-content'>
            <Image src="/images/loading.gif" width='50px' height='50px' alt='loading...'/>
            <Heading m={0} fontSize='26px' ml='10px' fontWeight='600'>
                Loading ...
            </Heading>
        </Flex>
    </Flex>
)

export default Loading
