import React, {useEffect, useRef, useState} from 'react'
import {Flex, Box, Image, Grid, GridItem, Text, Button, useDisclosure,Heading} from '@chakra-ui/react'
import {useUpload, useUser} from '../../core/hooks'
import {PROPERTY_TYPES, PROJECT_PRIORITIES, S3_BUCKET,} from '../../constants'
import {InputLabel, TextareaLabel, ChakraSelectLabel, Incrementer, ProjectChartInterests, ChakraSelectLabel as Select,} from '../../components/Theme'
import {ReactComponent as UploadPhoto} from '../../assets/uploadPhoto.svg'
import {ReactComponent as QuestionIcon} from '../../assets/questionIconBlack.svg'
import {ReactComponent as SyncIcon} from '../../assets/syncIcon.svg'
import {STATES} from '../../constants/serviceableAreas'
import LightCountInfoModal from "./LightCountInfoModal";
import SyncCompany from "./LuminairesModals/SyncCompanyModal";
import {EU, SA, SUPERADMIN} from "../../constants/user/userEnum";

const Property = ({companySync, setCompanySync,register, propertySize, beFinancing,salesTax,setSalesTax,setInterestRate, interestRate, opDayError, errors, image, propertyPriorities,setError, getValues, setPropertyPriorities, setValue, error, lightCountEstimate, setLightCountEstimate}) => {
    const inputRef = useRef()
    const salesRef = useRef()
    const {user} = useUser()
    const {upload, isLoading} = useUpload()
    const {isOpen, onOpen, onClose} = useDisclosure()
    const {isOpen: isOpenSync, onOpen: onOpenSync, onClose: onCloseSync} = useDisclosure()
    const [finance, setFinance] = useState(getValues('lightCount'))
    const [lightE, setLightE] = useState(getValues('lightCount'))

    const handleImage = (e) => {
        const file = e.target.files[0]
        const type = file.type.split('/')[1] || 'jpeg'
        const name = `${new Date().getTime() * Math.random()}.${type}`
        upload(
            {name, file, type},
            {
                onSuccess: (data) => setValue('image', data.data.data),
                onError: (imageError) => console.log(imageError),
            },
        )
    }

    useEffect(() => {
        if (beFinancing) {
            setFinance(beFinancing)
        } else {
            setFinance(getValues('beFinancing'))
        }
        setLightE(getValues('lightCount'))
        setSalesTax(getValues('salesTax'))
        setInterestRate(getValues('interestRate'))
    }, [getValues, beFinancing])

    useEffect(() => {
        if (!propertySize) return
        let a = (0.01 * Number(propertySize)).toFixed(2)
        setLightCountEstimate(a)
        setError('')
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [propertySize])

    return (
        <>
            {error && (<Heading m={0} textAlign="center" color="red.100" fontSize="m">{error}</Heading>)}
            <SyncCompany
                isOpen={isOpenSync}
                onClose={onCloseSync}
                setCompany={setCompanySync}
                company={companySync}/>
            <Flex mt="-1rem">
                <Box mr={12} mt={4}>
                    <Flex bg="white.100" alignItems="center" justifyContent="center" overflow="hidden" border="1px solid" width="263px" height="127px">
                        <Image height={!!image ? "100%" : "0"} width={!!image ? "100%" : "40%"} src={!!image ? `${S3_BUCKET}${image}` : ' '} objectFit="cover"/>
                    </Flex>
                    <input onChange={handleImage} type="file" style={{display: 'none'}} accept="image/*" ref={inputRef}/>
                    <Flex alignItems='center' justifyContent='space-between' mt={5}>
                        <Button display={user?.type === 'Eu' ? 'none' : 'flex'} onClick={() => inputRef.current.click()} width="120px" leftIcon={<UploadPhoto/>} size="sm" variant="green">
                              {isLoading ? 'Processing ...' : 'Upload Photo'}
                        </Button>
                        {user?.type === SUPERADMIN || user?.type === SA ? (
                            <Button color='black' bg='#FF9A7A' size="sm" width="120px" leftIcon={<SyncIcon />} onClick={onOpenSync}>Sync Company</Button>
                            ) : ''}
                    </Flex>
                </Box>
                <Flex justifyContent="space-between" flexDirection="column" w="100%">
                    <Grid mt={8} templateColumns="repeat(3, 1fr)" gap={6}>
                        <InputLabel disabled={user?.type === 'Eu'} isInvalid={!!errors.title} {...register('title', {required: true})} label="Project Title:"/>
                        <GridItem
                            colSpan={1}>
                            <ChakraSelectLabel disabled={user?.type === EU} placeholder="Select Property Type" isInvalid={!!errors.propertyType} {...register('propertyType', {required: false})} label="Property Type:">
                                {PROPERTY_TYPES.map((type) => (<option key={type} value={type}>{type}</option>),)}
                            </ChakraSelectLabel>
                        </GridItem>
                        <GridItem colSpan={1}>
                            <InputLabel
                                disabled={user?.type === EU}
                                type="number"
                                isInvalid={!!errors.propertySize}
                                value={propertySize}
                                onChange={(e) => {
                                    setValue('propertySize', e.target.value)
                                    if (!e.target.value && lightE === 'Yes Please'){
                                        setValue('lightCountEstimate', 0)
                                        setLightCountEstimate('')
                                        setError('Write property size')
                                    }
                                }}
                                label="Property Size (sq.ft):"/>
                        </GridItem>
                    </Grid>
                    <Grid mt={8} templateColumns="repeat(3, 1fr)" gap={6}>
                        <GridItem colSpan={1}>
                            <InputLabel disabled={user?.type === EU} isInvalid={!!errors.address} {...register('address', {required: false})} label="Address:"/>
                        </GridItem>
                        <GridItem colSpan={1}>
                            <InputLabel disabled={user?.type === EU} isInvalid={!!errors.address2} {...register('address2', {required: false})} label="Address 2:"/>
                        </GridItem>
                        <GridItem colSpan={1}>
                            <InputLabel disabled={user?.type === EU} isInvalid={!!errors.city} {...register('city', {required: false})} label="City:"/>
                        </GridItem>
                    </Grid>
                </Flex>
            </Flex>
            <Grid mt={8} templateColumns="repeat(12, 1fr)" gap={6}>
                <GridItem colSpan={3}>
                    <Select disabled={user?.type === EU} isInvalid={!!errors.state} {...register('state', {required: false})} label="State:">
                        {STATES.map((item) => (<option key={item} value={item}>{item}</option>))}
                    </Select>
                </GridItem>
                <GridItem colSpan={3}>
                    <InputLabel disabled={user?.type === EU} type="number" isInvalid={!!errors.zipCode} {...register('zipCode', {required: false, valueAsNumber: true,})} pattern="[0-9]*" label="Zip Code:"/>
                </GridItem>
                <GridItem colSpan={3}>
                    <InputLabel disabled={user?.type === EU} leftAddon="$" type="number" isInvalid={!!errors.laborCost} {...register('laborCost', {required: false})} label="Labor Cost (hourly):"/>
                </GridItem>
                <GridItem colSpan={3}>
                    <InputLabel disabled={user?.type === EU} leftAddon="$" type="number" pattern="[0-9.]*" isInvalid={!!errors.costPerKilowattHour} {...register('costPerKilowattHour', {required: false})} label="Cost per kilowatt hour ($/kWh):"/>
                </GridItem>
                <GridItem colSpan={3}>
                    <InputLabel disabled={user?.type === EU} type="number" isInvalid={opDayError} {...register('operationDaysPerYear', {required: false})} label="Operation Days Per Year:"/>
                </GridItem>
                <GridItem colSpan={3}>
                    <InputLabel
                        ref={salesRef}
                        afterIndex='%'
                        disabled={user?.type === EU}
                        value={salesTax}
                        type='number'
                        onChange={(e) => setSalesTax(e.target.value.replace(/[^0-9.]/g, ''))}
                        label="Sales Tax % (If Applicable):"/>
                </GridItem>
                <GridItem colSpan={3}>
                    <InputLabel disabled={user?.type === EU} {...register('complienceHyperlink')} label="Compliance Hyperlink (If Applicable):"/>
                </GridItem>
                <GridItem colSpan={3}>
                    <LightCountInfoModal
                        propertySize={propertySize}
                        isOpen={isOpen}
                        register={register}
                        setLightE={setLightE}
                        onClose={onClose}
                        getValues={getValues}
                        lightCountEstimate={lightCountEstimate}
                        setValue={setValue}
                        setLightCountEstimate={setLightCountEstimate}/>
                    <ChakraSelectLabel
                        type="boolean"
                        disabled={user?.type === EU}
                        placeholder='Select'
                        isInvalid={!!errors.lightCount}
                        onChange={(e) => {
                            setLightE(e.target.value)
                            if (e.target.value === 'No Thanks') {
                                setValue('lightCountEstimate', 0)
                                setValue('lightCount', "No Thanks")
                            } else if (e.target.value === 'Yes Please') {
                                if (!lightCountEstimate || !propertySize) return setError('Write property size')
                                setValue('lightCountEstimate', lightCountEstimate)
                                setValue('lightCount', "Yes Please")
                            }
                            setError('')
                        }}
                        value={lightE}
                        label={
                            <Flex justifyContent='space-between'>
                                <Text as='h2' m={0}>Light Count:</Text>
                                <Button width='10px !important' height='19px' bg='#FFE68D' onClick={onOpen}>
                                    <QuestionIcon/>
                                </Button>
                            </Flex>}>
                        <option value='Yes Please'>Yes Please</option>
                        <option value='No Thanks'>No Thanks</option>
                    </ChakraSelectLabel>
                </GridItem>
            </Grid>
            <Grid mt={8} templateColumns="repeat(12, 1fr)" gap={6} mb={6}>
                <GridItem colSpan={3}>
                    <ChakraSelectLabel
                        disabled={user?.type === 'Eu'}
                        value={finance}
                        isInvalid={!!errors.beFinancing}
                        placeholder='Select'
                        label="Will you be financing this project?:"
                        onChange={(e) => {
                            setValue('beFinancing', e.target.value)
                            setFinance(e.target.value)
                        }}>
                        <option value='no'>No</option>
                        <option value='yes'>Yes</option>
                    </ChakraSelectLabel>
                </GridItem>
                <GridItem colSpan={3}>
                    <InputLabel disabled={finance === 'no' || finance === '' || user?.type === 'Eu'} afterIndex='%'
                                value={interestRate}
                                onChange={(e) => setInterestRate(e.target.value.replace(/[^0-9.]/g, ''))}
                                label="Interest Rate (%):"/>
                </GridItem>
                <GridItem colSpan={3}>
                    <InputLabel disabled={finance === 'no' || finance === '' || user?.type === 'Eu'} leftAddon="$" type="number" pattern="[0-9.]*" {...register('depositAmount', {valueAsNumber: true})} label="Deposit Amount ($):"/>
                </GridItem>
                <GridItem colSpan={3}>
                    <InputLabel disabled={finance === 'no' || finance === '' || user?.type === 'Eu'} type="number" {...register('loanPeriod', {valueAsNumber: true})} label="Loan Period (months):"/>
                </GridItem>
            </Grid>
            <TextareaLabel disabled={user?.type === 'Eu'} isInvalid={!!errors.notes} label="Notes:" {...register('notes', {required: false})}/>
            <Flex mt={6}>
                <Box>
                    <Text as='h2' m={0} fontSize="md" color="blue.200">Project Priorities:</Text>
                    <Grid templateColumns="repeat(2, 1fr)" gap={5} mt={2}>
                        {PROJECT_PRIORITIES.map(
                            (priority) => (
                                <GridItem key={priority.title} colSpan={1}>
                                    <Incrementer plus={user?.type !== 'Eu'} score={propertyPriorities} setScore={(data) => setPropertyPriorities(data)} title={priority.title} color={priority.active}/>
                                </GridItem>
                            ),)}
                    </Grid>
                </Box>
                <Box p="10">
                    <Flex width="349px" height="349px">
                        <ProjectChartInterests score={propertyPriorities}/>
                    </Flex>
                </Box>
            </Flex>
        </>
    )
}

export default Property
