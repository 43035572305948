import {
    Button,
    Flex,
    Modal,
    ModalBody,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Heading
} from "@chakra-ui/react";
import IconButton from "../../components/Theme/IconButton";
import Icon from "../../components/Theme/Icon";
import React, {useState} from "react";
import httpRequest from "../../core/services/http-request";

const DeliveredModal = ({trackId, isOpenDelivered, onCloseDelivered, setShippingPageStatus, getEstimate}) => {
    const {post} = httpRequest()
    const [loading, setLoading] = useState(false)
    const markAsDelivered = () => {
        setLoading(true)
        post('set-delivered-shipping',trackId).then(() => {
            getEstimate()
            onCloseDelivered()
            return setShippingPageStatus(1)
        })
        setLoading(false)
    }

    return (
        <>
            <Modal
                isCentered
                isOpen={isOpenDelivered}
                onClose={() => {
                    onCloseDelivered()
                }}>
                <ModalOverlay/>
                <ModalContent
                    overflow="hidden"
                    borderRadius="10px"
                    maxW="520px">
                    <ModalHeader
                        bg='#8FCF74'
                        fontWeight="700"
                        fontSize="24px"
                        textAlign="center">
                        Mark Delivered
                    </ModalHeader>
                    <IconButton
                        height="unset"
                        onClick={() => {
                            onCloseDelivered()
                        }}
                        _hover={{
                            bg: 'none',
                        }}
                        _active={{
                            bg: 'none',
                        }}
                        bg="none"
                        aria-label="close button"
                        position="absolute"
                        top="1.5rem"
                        right="0.75rem"
                        icon={
                            <Icon type="darkClose"/>
                        }/>
                    <ModalBody
                        pb={7}
                        fontSize='24px'
                        textAlign='center'
                        overflow="scroll"
                        maxH="600px">
                        <Heading
                            textAlign='center'
                            fontSize="24px">
                            Are you sure you want to mark this shipment as delivered?
                        </Heading>
                        <Flex
                            justifyContent='space-between'>
                            <Button
                                onClick={onCloseDelivered}
                                height='50px'
                                fontSize='14px'
                                width='230px'
                                color='black'
                                bg='#DBDADA'>
                                Nevermind, Let’s Go Back
                            </Button>
                            <Button
                                isLoading={loading}
                                onClick={markAsDelivered}
                                height='50px'
                                fontSize='14px'
                                width='230px'
                                color='black'
                                bg='#8FCF74'>
                                Yes, Mark Delivered
                            </Button>
                        </Flex>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}

export default DeliveredModal