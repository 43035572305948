import React from 'react'
import { Bubble } from 'react-chartjs-2'

const ChartBubble = ({
  environment,
  illumination,
  technology,
  financial,
}) => {
  // defaults.global.animation = undefined

  const data = {
    labels: [''],
    datasets: [
      {
        label: 'My First dataset',
        type: 'bubble',
        fill: false,
        lineTension: 0.1,
        backgroundColor: [
          'rgba(255, 127, 86, 0.2)',
          'rgba(255, 127, 86, 0.4)',
          'rgba(255, 127, 86, 0.6)',
          'rgba(255, 127, 86, 0.8)',
          'rgba(255, 127, 86, 1)',
          'rgba(44, 98, 141, 0.2)',
          'rgba(44, 98, 141, 0.4)',
          'rgba(44, 98, 141, 0.6)',
          'rgba(44, 98, 141, 0.8)',
          'rgba(44, 98, 141, 1)',
          'rgba(201, 86, 255, 0.2)',
          'rgba(201, 86, 255, 0.4)',
          'rgba(201, 86, 255, 0.6)',
          'rgba(201, 86, 255, 0.8)',
          'rgba(201, 86, 255, 1)',
          'rgba(138, 179, 120, 0.2)',
          'rgba(138, 179, 120, 0.4)',
          'rgba(138, 179, 120, 0.6)',
          'rgba(138, 179, 120, 0.8)',
          'rgba(138, 179, 120, 1)',
        ],
        borderColor: 'none',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 1,
        borderJoinStyle: 'miter',
        pointBorderColor: 'none',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 1,
        pointHoverRadius: 1,
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: '#fff',
        pointHoverBorderWidth: 1,
        pointRadius: 1,
        pointHitRadius: 1,
        data: [
          {
            r: illumination.intensity,
            y: 9,
            x: 1,
          },
          {
            r: illumination.uniformity,
            y: 9,
            x: 3,
          },
          {
            r: illumination.color_quality,
            y: 7,
            x: 1,
          },
          {
            r: illumination.color_rendering,
            y: 7,
            x: 3,
          },
          {
            r: illumination.human_centric,
            y: 8,
            x: 2,
          },
          {
            r: technology.efficiency,
            y: 9,
            x: 9,
          },
          {
            r: technology.sensors,
            y: 9,
            x: 7,
          },
          {
            r: technology.energy_monitoring,
            y: 7,
            x: 9,
          },
          {
            r: technology.system_longevity,
            y: 7,
            x: 7,
          },
          {
            r: technology.internet_of_things,
            y: 8,
            x: 8,
          },
          {
            r: financial.flexible_space,
            y: 4,
            x: 1,
          },
          {
            r: financial.expense_reduction,
            y: 4,
            x: 3,
          },
          {
            r: financial['financing/laas'],
            y: 2,
            x: 1,
          },
          {
            r: financial.user_experience,
            y: 2,
            x: 3,
          },
          {
            r: financial.asset_resilience,
            y: 3,
            x: 2,
          },
          {
            r: environment.clean_energy,
            y: 4,
            x: 9,
          },
          {
            r: environment.sustainability,
            y: 4,
            x: 7,
          },
          {
            r: environment.eco_design,
            y: 2,
            x: 9,
          },
          {
            r: environment.green_programs,
            y: 2,
            x: 7,
          },
          {
            r: environment.grey_energy,
            y: 3,
            x: 8,
          },
        ],
      },
    ],
  }

  const options = {
    scales: {
      xAxes: [{
        display: true,
        scaleLabel: {
          display: true,
        },
        ticks: {
          display: false,
          beginAtZero: true,
          steps: 1,
          stepValue: 1,
          max: 10,

        },
      }],
      yAxes: [{
        position: 'right',
        display: true,
        ticks: {
          display: false,
          beginAtZero: true,
          steps: 1,
          stepValue: 1,
          max: 10,
        },
      }],
    },
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    tooltips: {
      callbacks: {
        title(tooltipItem) {
          // Object.keys(environment)[tooltipItem[0].index]
          if (tooltipItem[0].index < 5) {
            return Object.keys(environment)[tooltipItem[0].index]
          }
          if (tooltipItem[0].index > 4 && tooltipItem[0].index < 10) {
            return Object.keys(illumination)[tooltipItem[0].index - 5]
          }
          if (tooltipItem[0].index > 9 && tooltipItem[0].index < 15) {
            return Object.keys(technology)[tooltipItem[0].index - 10]
          }
          return Object.keys(financial)[tooltipItem[0].index - 15]
        },
        label(tooltipItem, newLabel) {
          return `${newLabel['datasets'][0]['data'][tooltipItem['index']].r}%`
        },
      },
    },
    hover: { mode: null },
  }
  return (
    <Bubble
      redraw
      options={options}
      data={data}/>
  )
}

export default ChartBubble
