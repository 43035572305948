/** @jsxImportSource @emotion/react */
import React, {useEffect, useState} from 'react'
import {Table, Thead, Tbody, Tr, Th, Td, Flex, Text,} from '@chakra-ui/react'
import styles from '../styles'
import {Link} from "react-router-dom";
import {Box} from "@material-ui/core";
import {ReactComponent as Unfinished} from "../../../assets/unfinishedIconca.svg";
import {ReactComponent as Ready} from "../../../assets/ready.svg";

const Time = ({project,reports, history}) => {
    const [minDay, setMinDay] = useState()

    useEffect(() => {
        if (!reports) return
        let days = []
        for (let i = 0; i < reports?.length; i++) {
            days.push(Number(reports[i].ETAdays?.replace(',', '')))
        }
        let min = Math.min(...days)
        setMinDay(min)
    }, [reports])

    return (
        <>
            <Table
                css={styles.table}
                variant="simple">
                <Thead>
                    <Tr css={styles.tr}>
                        <Th>Rank</Th>
                        <Th>Report</Th>
                        <Th>ETA (Days)</Th>
                        <Th>ETA Beyond Shortest (Days)</Th>
                        <Th>Stocked?</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {
                        reports?.length > 0 ? reports?.sort((a: any,b: any) => Number(a?.ETAdays?.replace(',','')) - Number(b?.ETAdays?.replace(',',''))).map((item, idx) => (
                            <Tr
                                key={item.id}
                                css={styles.tr}
                                bg="white.100"
                                boxShadow="2px 4px 10px rgba(0, 0, 0, 0.25)"
                                onClick={() => history.push(`/projects/proposal/${encodeURIComponent(item.proposalName)}/${project.id}/${item.id}`)}>
                                <Td>
                                    <Text as='span'  float='left'>
                                        {idx + 1}
                                    </Text>
                                    <Box ml={5}>
                                        {
                                            item.status === 'Unfinished' ? (
                                                <Unfinished/>
                                            ) : (
                                                <Ready/>
                                            )
                                        }
                                    </Box>
                                </Td>
                                <Td>
                                    {item.proposalName ? `${item.proposalName}` : '-'}
                                </Td>
                                <Td>
                                    {item.ETAdays ? `${item.ETAdays}` : '0'}
                                </Td>
                                <Td>
                                    {minDay ? `${(Number(item.ETAdays?.replace(',', '')) - minDay).toLocaleString('en-US')}` : item.ETAdays}
                                </Td>
                                <Td>
                                    {item.stocked ? `${item.stocked}` : 'No'}
                                </Td>
                            </Tr>
                        )) : (
                            <Tr>
                                <Td
                                    colSpan='7'>
                                    <Flex
                                        border='3px solid #8FCF74'
                                        p={14}
                                        mx={14}
                                        borderRadius='10px'>
                                        <Text>
                                            Looks like you don’t have any proposals yet... Click the orange Edit Details
                                            button to
                                            add
                                            more property information or <Link to="/contact">
                                            <Text as='span' textDecoration='underline'>click here</Text></Link>
                                            <Text as='span'> to contact your Energy Consultant.</Text>
                                        </Text>
                                    </Flex>
                                </Td>
                            </Tr>
                        )
                    }
                </Tbody>
            </Table>
        </>
    )
}

export default Time
