import {Box,  Flex, Heading, Input,Text, Stack, } from "@chakra-ui/react";
import moment from "moment/moment";
import {ReactComponent as CalendarIcon} from "../../../assets/calendarIconSmall.svg";
import {TextareaLabel, } from "../../../components/Theme";


const EuEstimate = (estimate) => {
    return (
        <Box backgroundColor='white' p={9} maxW='913px' m='auto' border='1px solid #C4C4C4'>
            <Flex justifyContent='space-between'>
                <Box>
                    <Heading fontSize='24px' mt={0} mb={3}>Estimate Details</Heading>
                    <Text fontSize='18px'>{estimate['estimate']?.user?.company?.length > 0 ? estimate['estimate']?.user?.company[0]?.name : estimate['estimate']?.user?.associatedToCompany?.name}</Text>
                    <Text fontSize='18px'>{estimate['estimate']?.user?.company?.length > 0 ? estimate['estimate']?.user?.company[0]?.website : estimate['estimate']?.user?.associatedToCompany?.website}</Text>
                    <Text fontSize='18px'>{estimate['estimate']?.user?.phone}</Text>
                    <Text fontSize='18px'>{estimate['estimate']?.user?.email}</Text>
                </Box>
                <Box fontSize='18px' textAlign='end'>
                    <Text fontWeight='300'>Reference # : {estimate['estimate']?.reference}</Text>
                    <Text fontWeight='300'>Date : {moment(estimate?.createdAt?.date)?.format('MM/DD/YYYY')}</Text>
                </Box>
            </Flex>
            <Flex mt={4} justifyContent='space-between' fontSize='18px'>
                <Flex width='49%' borderRight='2px solid #9C9C9C' flexDirection='column'>
                    <Input readOnly border='none' backgroundColor='#EEEEEE' mb={3} width='98%' fontSize='18px'
                           value='Property Information:' color='#222222' fontFamily='Titillium Web'/>
                    <Text>Focus Earth</Text>
                    <Text>Attn: Pete Musser</Text>
                    <Text>1070 Tunnel Road</Text>
                    <Text>Building 1, Suite 10, #202</Text><br/>
                    <Text>Asheville, NC 28805</Text>
                    <Text>USA</Text>
                    <Text>(925) 323-0053</Text>
                </Flex>
                <Flex width='50%' flexDirection='column'>
                    <Input readOnly border='none' backgroundColor='#EEEEEE' width='100%' fontSize='18px'
                           mb={3} value='Billing Information:' color='#222222' fontFamily='Titillium Web'/>
                    <Text>{estimate['estimate']?.estimateReu?.userReu?.company?.length > 0 ? estimate['estimate']?.estimateReu?.userReu?.company?.name : estimate['estimate']?.estimateReu?.userReu?.associatedToCompany?.name}</Text>
                    <Text>Attn : {estimate['estimate']?.estimateReu?.userReu?.firstName + ' ' + estimate['estimate']?.estimateReu?.userReu?.lastName}</Text>
                    <Text>{estimate['estimate']?.project?.address}</Text>
                    <Text>{estimate['estimate']?.project?.address2}</Text><br/>
                    <Text>{estimate['estimate']?.project?.city + ', ' + estimate['estimate']?.project?.state + ', ' + estimate['estimate']?.project?.zipCode}</Text>
                    <Text>USA</Text>
                    <Text>{estimate['estimate']?.estimateReu?.userReu?.phone}</Text>
                </Flex>
            </Flex>
            <Stack spacing={3} w='100%' mt={8}>
                <Flex bg='#8FCF74' height='37px' borderRadius='5px' alignItems='center' pl={3} as='h2' m={0}>
                    Scope of Work
                </Flex>
                <Flex w='100%' justifyContent='space-between' px={2}>
                    <Heading fontSize='14px'>Description</Heading>
                    <Heading fontSize='14px'>Amount</Heading>
                </Flex>
                <Flex w='100%' justifyContent='space-between' px={2} border='1px solid #A9A9A9' borderRadius='6px' minH='33px' alignItems='center'>
                    <Text fontSize='14px'>Labor</Text>
                    <Text fontSize='14px'>{estimate['estimate']?.bid?.length > 0 && '$'+estimate['estimate']?.bid[0]?.labor}</Text>
                </Flex>
                <Flex w='100%' justifyContent='space-between' px={2} border='1px solid #A9A9A9' borderRadius='6px' minH='33px' alignItems='center'>
                    <Text fontSize='14px'>Materials</Text>
                    <Text fontSize='14px'>{estimate['estimate']?.bid?.length > 0 && '$'+estimate['estimate']?.bid[0]?.materials}</Text>
                </Flex>
                <Flex w='100%' justifyContent='space-between' px={2} border='1px solid #A9A9A9' borderRadius='6px' minH='33px' alignItems='center'>
                    <Text fontSize='14px'>Permits</Text>
                    <Text fontSize='14px'>{estimate['estimate']?.bid?.length > 0 && '$'+estimate['estimate']?.bid[0]?.permits}</Text>
                </Flex>
                <Flex w='100%' justifyContent='space-between' px={2} border='1px solid #A9A9A9' borderRadius='6px' minH='33px' alignItems='center'>
                    <Text fontSize='14px'>Tax</Text>
                    <Text fontSize='14px'>{estimate['estimate']?.bid?.length > 0 && '$'+estimate['estimate']?.bid[0]?.tax}</Text>
                </Flex>
                <Flex w='100%' justifyContent='space-between' px={2} border='1px solid #A9A9A9' borderRadius='6px' minH='33px' alignItems='center'>
                    <Text fontSize='14px'>Recycling/Disposal</Text>
                    <Text fontSize='14px'>{estimate['estimate']?.bid?.length > 0 && '$'+estimate['estimate']?.bid[0]?.disposal}</Text>
                </Flex>
                <Flex w='100%' justifyContent='space-between' minH='40px' alignItems='center'>
                    <Flex width='60%' alignItems='center' justifyContent={'space-between'} border='1px solid #A9A9A9' borderRadius='6px' minH='40px'>
                        <Flex color='#2C628D' fontSize='16px' px={2} fontFamily='Titillium Web'>Availability</Flex>
                        <Flex color='#2C628D' alignItems='center' fontSize='18px' px={2}>{estimate['estimate']?.bid?.length > 0 && estimate['estimate']?.bid[0]?.availability[1]} <CalendarIcon style={{marginLeft: '10px'}}/></Flex>
                    </Flex>
                    <Flex width='38%' border='2px solid #8FCF74' borderRadius='6px' minH='40px' alignItems='center' justifyContent='space-between'>
                        <Flex color='#2C628D' fontSize='18px' px={2} fontFamily='Titillium Web'>Total:</Flex>
                        <Flex color='#2C628D' fontSize='16px' px={2} >{estimate['estimate']?.bid?.length > 0 && '$'+estimate['estimate']?.bid[0]?.total}</Flex>
                    </Flex>
                </Flex>
                <TextareaLabel
                    label='Notes:'
                />
            </Stack>
        </Box>
    )
}

export default EuEstimate