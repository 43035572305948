import { useMutation } from 'react-query'
import { reportUseCase } from '../../factories'

const useCreateReport = () => {
  const {
    mutate: createReport, isLoading, data, error,
  } = useMutation(reportUseCase.createReport)

  return {
    createReport,
    isLoading,
    error,
    data,
  }
}

export default useCreateReport
