import React from "react";
import {Modal, ModalBody, ModalContent, ModalHeader, ModalOverlay, Text,} from "@chakra-ui/react";
import IconButton from "../../Theme/IconButton";
import Icon from "../../Theme/Icon";

const BiddingDone = ({isOpen, onClose}) => (
        <Modal isCentered isOpen={isOpen} onClose={onClose}>
            <ModalOverlay/>
            <ModalContent overflow="hidden" borderRadius="10px" minW="853px">
                <ModalHeader bg='#C2EF62' fontWeight="700" fontSize="24px" textAlign="center">
                    Done
                </ModalHeader>
                <IconButton height="unset" onClick={onClose} _hover={{bg: 'none',}} _active={{bg: 'none',}} bg="none" aria-label="close button" position="absolute" top="1.5rem" right="0.75rem" icon={<Icon type="darkClose"/>}/>
                <ModalBody pt='50px' pb='50px' pl='120px' pr='120px' fontSize='24px' textAlign='center' overflow="scroll" maxH="600px">
                    <Text>
                        Congratulations! You’ve marked this proposal as Ready For Review - all associated users can view it.
                    </Text>
                </ModalBody>
            </ModalContent>
        </Modal>
    )

export default BiddingDone