import React from 'react'
import {Flex, Box, Avatar,} from '@chakra-ui/react'

const UserCard = ({name, onClick, avatar, color,}) => (
    <Flex pl={2} overflowX="scroll" alignItems="center" height="57px" width="330px" borderRadius="10px" boxShadow="2px 4px 10px rgba(0, 0, 0, 0.25)" bg="white.100">
        <Box mr={4} height="100%" width="12px" bg={color}/>
        <Avatar mr={10} name={name} size="sm" src={avatar}/>
        <Box onClick={onClick} _hover={{fontWeight: '700', textDecoration: 'underline',}} cursor="default" fontWeight="400" fontSize="xl" textTransform="capitalize" as="span">
            {name}
        </Box>
    </Flex>
)

export default UserCard
