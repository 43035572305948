import { useMutation } from 'react-query'
import { activityUseCase } from '../../factories'

const useCreateActivity = () => {
  const {
    mutate: createActivity, isLoading, data, error,
  } = useMutation(activityUseCase.createActivity)

  return {
    createActivity,
    isLoading,
    error,
    data,
  }
}

export default useCreateActivity
