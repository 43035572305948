import React from 'react'
import {Pie} from 'react-chartjs-2'
import {Box} from '@chakra-ui/react'

const ChartUserComplete = ({complete}) => {
    // defaults.global.animation = undefined

    const data = {
        datasets: [
            {
                label: '# of Votes',
                data: complete,
                backgroundColor: [
                    '#8FCF74',
                ],
                borderColor: ['#8FCF74', '#d9d9d9'],
                borderWidth: 1,
            },
        ],
    }
    const options = {
        maintainAspectRatio: false,
        animation: false,
        responsive: true,
        tooltips: {
            enabled: false,
        },
        hover: {mode: null},
    }
    return (
        <Box
            width="74px"
            height="74px">
            <Pie
                redraw
                options={options}
                data={data}/>
        </Box>
    )
}

export default ChartUserComplete
