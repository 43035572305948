import { css } from '@emotion/react'

const styles = {
  profileHeader: css`
    border-bottom: 1px solid #DBDADA;
    justify-content: space-between;
    align-items: center;
    padding: 0 50px;
    height: 139px;
    width: 100%;

    .header-main {

      .header-image {
        align-items: center;
        justify-content: center;
        overflow: hidden;
        border: 1px solid #A9A9A9;
        border-radius: 100%;
        width: 85px;
        height: 85px;

        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }
    }

    .header-chart {
      align-items: center;

      p {
        margin-right: 20px;
        font-size: 18px;
      }
    }

  `,
}

export default styles
