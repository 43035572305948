import {Input, InputGroup, InputLeftElement} from "@chakra-ui/react";
import {ReactComponent as SearchIcon} from "../../../assets/search.svg";

const Search = ({setSearch}) => (
    <InputGroup
        alignItems="center"
        height="29px"
        borderRadius="5px"
        _focus={{borderColor: 'blue.200',}}
        _hover={{borderColor: 'blue.200',}}
        borderColor="blue.200"
        bg="blue.200">
        <InputLeftElement
            height="29px"
            pointerEvents="none"
            children={(<SearchIcon height="16px" width="16px"/>)}/>
        <Input
            onChange={(e) => setSearch(e.target.value)}
            _focus={{borderColor: 'blue.200',}}
            _hover={{borderColor: 'blue.200',}}
            height="29px"
            color="white.100"
            placeholder="Search"/>
    </InputGroup>
)

export default Search