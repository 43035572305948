import React, {useEffect, useRef, useState} from 'react'
import Navbar from "../../components/Navbar";
import styles from "../Upload/styles";
import {Box, Text, Flex, Button, Button as ChakraButton, Image} from '@chakra-ui/react'
import {ReactComponent as Search} from "../../assets/searchWhite.svg";
import {useUploadMultiple} from "../../core/hooks";
import {DragDrop} from "../../components/Theme";
import {S3_BUCKET} from "../../constants";
import fileSaver from "file-saver/dist/FileSaver";
import {arrayMove, SortableContainer, SortableElement} from "react-sortable-hoc";
import moment from "moment/moment";
import {ReactComponent as Download} from "../../assets/download.svg";
import httpRequest from "../../core/services/http-request";
import {MAIN} from "../../constants/fileTypes";

const UploadMain = () => {
    const inputRef = useRef()
    const {post} = httpRequest()
    const {upload, isLoading, error,} = useUploadMultiple()
    const [files, setFiles] = useState([])
    const [success, setSuccess] = useState(false)

    const handleImage = (e) => {
        const filesToUpload = []
        const dataToPreview = []
        Object.values(e.target.files).forEach(
            (file) => {
                const type = file.type.split('/')[1] || 'jpeg'
                const name = `${new Date().getTime() * Math.random()}.${type}`
                dataToPreview.push({
                    name: file.name,
                    key: name,
                    sortId: files.length,
                    createdAt: new Date().toISOString(),
                })
                filesToUpload.push({name, file, type,})
            },
        )
        upload(
            filesToUpload,
            {
                onSuccess: (e) => {
                    post('create-uploads',[e[0].data.data, dataToPreview, 1]).then(() => {
                        getFiles()
                    }).catch((e) => {
                        console.log(e)
                    })
                    setSuccess(true)
                },
            },
        )
    }

    function downloadFile(e, key) {
        fileSaver.saveAs(S3_BUCKET + key, key);
    }

    const onSortEnd = (e) => {
        var newFiles = arrayMove(files, e.oldIndex, e.newIndex)
        for (let i = 0; i < newFiles?.length; i++) {
            newFiles[i].sortId = newFiles.indexOf(newFiles[i])
        }
        post('sort-uploads',newFiles).then(() => {
            setFiles(newFiles)
            getFiles()
        })
    }

    const SortableItem = SortableElement(({value}) =>
        <Flex cursor='pointer' css={styles.file} key={value?.files?.key}>
            <Flex alignItems="center" w='40%'>
                <Image src="/icons/drag.svg" alt="Drag"/>
                <Text ml="10" fontWeight="500">{value?.files?.name}</Text>
            </Flex>
            <Flex w='30%' color='#A9A9A9' fontWeight="700">{moment(value?.createdAt?.date).format('MMM DD, YYYY')}</Flex>
            <Flex>
                <Button onClick={e => downloadFile(e, value?.name)} mr={8} leftIcon={<Download/>} bg="#2C628D" color="white" height="30px" fontSize="12px">
                    Download
                </Button>
            </Flex>
        </Flex>)

    const SortableList = SortableContainer(({lists}) => {
        return (
            <Box>
                {lists?.sort(function (a, b) {
                        return a?.sortId - b?.sortId
                    }).map((value, index) => (
                        <SortableItem
                            key={value?.files?.key}
                            index={index}
                            value={value}/>
                    ))}
            </Box>
        )
    })

    const getFiles = () => {
        let data = {
            type: MAIN
        }
        post('get-files', data).then((e) => {
            setFiles(e.data.data)
        })
    }

    useEffect(() => {
        getFiles()
    },[])

    return (
        <>
            <Navbar minW="850px" title="Upload"/>
            <Box minW="850px" m="auto" maxW="1240px" bg="#f5f5f5" css={styles.container} height="100%" pt={8} px={16}>
                <Text fontSize="18px" fontWeight="400">
                    Upload pertinent company or project files by dragging them into the designated area (below) or
                    browse your device (blue button). Whether it’s a quote or insurance documentation - more information
                    allows you to qualify for more projects.
                </Text>
                <Flex mb={10} justifyContent='end'>
                    <Box maxW='254px'>
                        <input onChange={handleImage} type="file" multiple style={{display: 'none'}} ref={inputRef}/>
                        <ChakraButton leftIcon={<Search/>} onClick={() => inputRef.current.click()} minW="251px" fontSize="18px" size="lg" variant="blue">
                            Browse Files
                        </ChakraButton>
                    </Box>
                </Flex>
                <DragDrop
                    isCreatingLoading={isLoading}
                    success={success}
                    error={error}
                    handleFile={handleImage}
                    isLoading={isLoading}/>
                {files?.length > 0 && (
                        <Flex my="6" fontSize="20px" fontWeight="500">
                            <Text>Uploaded Files</Text>
                            <Text w='80%' textAlign='center'>Date</Text>
                        </Flex>
                    )}
                <Flex mt="6" flexDirection="column">
                    {!isLoading && (
                            <SortableList
                                lists={files}
                                onSortEnd={onSortEnd}
                                lockAxis="y"/>
                        )}
                </Flex>
            </Box>
        </>
    )
}

export default UploadMain