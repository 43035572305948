const airtableRepo = (airtableService) => {
  const listProducts = (filter) => airtableService.listProducts(filter)

  const getExisting = (input) => airtableService.getExisting(input)

  return {
    listProducts,
    getExisting,
  }
}

export default airtableRepo
