import {Grid, GridItem} from "@chakra-ui/react";
import {ChakraSelectLabel, InputLabel} from "../../../components/Theme";
import {BUY_AMERICA, CERTIFICATIONS, CONTROL_TYPE, DIMMABILITY} from "../../../constants/utils";
import CustomSelect from "../../../components/Theme/CustomSelect";
import React from "react";

const MainFieldsControl = ({errors, register,certifications, setCertifications,}) => (
    <Grid templateColumns="repeat(3, 1fr)" gap={6} justifyContent='end'>
        <GridItem colSpan={1}>
            <InputLabel type='number' isInvalid={!!errors.dimensionsLength} {...register('dimensionsLength')} label="Dimensions - Length (in):" />
        </GridItem>
        <GridItem colSpan={1}>
            <InputLabel type='number' isInvalid={!!errors.vMax} {...register('vMax')} label="Voltage (max):"/>
        </GridItem>
        <GridItem colSpan={1}>
            <InputLabel type='number' isInvalid={!!errors.installationTime} {...register('installationTime')} label="Installation (minutes):"/>
        </GridItem>
        <GridItem colSpan={1}>
            <InputLabel type='number' isInvalid={!!errors.dimensionsWidth} {...register('dimensionsWidth')} label="Dimensions - Width (in):"/>
        </GridItem>
        <GridItem colSpan={1}>
            <ChakraSelectLabel placeholder='Select' isInvalid={!!errors.dimmability} {...register('dimmability')} label="Dimmability:">
                {DIMMABILITY.map((item,index) => (
                    <option value={item} key={index}>{item}</option>
                ))}
            </ChakraSelectLabel>
        </GridItem>
        <GridItem type='number' colSpan={1}>
            <InputLabel type='number' isInvalid={!!errors.deliveryDays} {...register('deliveryDays')} label="Delivery (days):"/>
        </GridItem>
        <GridItem colSpan={1}>
            <InputLabel type='number' isInvalid={!!errors.dimensionsDiameter} {...register('dimensionsDiameter')} label="Dimensions - Diameter (in):"/>
        </GridItem>
        <GridItem colSpan={1}>
            <InputLabel type='number' isInvalid={!!errors.lifeTime} {...register('lifeTime')} label="LifeTime (hrs):" />
        </GridItem>
        <GridItem colSpan={1}>
            <InputLabel type="number" step="0.01" isInvalid={!!errors.pricePerUnit} {...register('pricePerUnit')} label="Price Per Unit ($):"/>
        </GridItem>
        <GridItem colSpan={1}>
            <InputLabel type='number' isInvalid={!!errors.dimensionsDepth} {...register('dimensionsDepth')} label="Dimensions - Depth (in):"/>
        </GridItem>
        <GridItem colSpan={1}>
            <InputLabel type='number' isInvalid={!!errors.warranty} {...register('warranty')} label="Warranty (yrs):"/>
        </GridItem>
        <GridItem colSpan={1}>
            <InputLabel type='number' isInvalid={!!errors.volumeTier1MinQty} {...register('volumeTier1MinQty')} label="Vol. Tier 1 (min. qty):"/>
        </GridItem>
        <GridItem colSpan={1}>
            <ChakraSelectLabel placeholder='Select' isInvalid={!!errors.controlType} {...register('controlType')} label="Type:">
                {CONTROL_TYPE.map((item,index) => (
                    <option value={item} key={index}>{item}</option>
                ))}
            </ChakraSelectLabel>
        </GridItem>
        <GridItem colSpan={1}>
            <CustomSelect
                repeat={1}
                value={certifications}
                options={CERTIFICATIONS}
                setValue={setCertifications}
                label="Certifications:"/>
        </GridItem>
        <GridItem colSpan={1}>
            <InputLabel type='number' isInvalid={!!errors.volumeTier1PPU} {...register('volumeTier1PPU')} label="Vol. Tier 1 PPU ($):"/>
        </GridItem>
        <GridItem colSpan={1}>
            <InputLabel type='number' isInvalid={!!errors.vMin} {...register('vMin')} label="Voltage (min):"/>
        </GridItem>
        <GridItem colSpan={1}>
            <ChakraSelectLabel placeholder='Select' isInvalid={!!errors.buyAmerica} {...register('buyAmerica')} label="Buy America:">
                {BUY_AMERICA.map((item,index) => (
                    <option value={item} key={index}>{item}</option>
                ))}
            </ChakraSelectLabel>
        </GridItem>
        <GridItem colSpan={1}>
            <InputLabel type='number' isInvalid={!!errors.volumeTier2MinQty} {...register('volumeTier2MinQty')} label="Vol. Tier 2 (min. qty.):"/>
        </GridItem>
        <GridItem colSpan={1}></GridItem>
        <GridItem colSpan={1}></GridItem>
        <GridItem colSpan={1}>
             <InputLabel type='number' isInvalid={!!errors.volumeTier2PPU} {...register('volumeTier2PPU')} label="Vol. Tier 2 PPU ($):"/>
        </GridItem>
    </Grid>
)

export default MainFieldsControl