import {
    Grid,
    GridItem,
    Button,
    Box, Text
} from '@chakra-ui/react'
import {Link} from 'react-router-dom'
import {ReactComponent as DownloadIcon} from '../../assets/downloadIconWhite.svg'
import moment from "moment/moment";
import {ESTIMATE} from "../../constants/orders/orderEnum";

const EstimatesTab = ({estimates, downloadPdf, loading}) => {
    return (
        <Box>
            {
                estimates?.filter((filtered) => filtered.isEstimate && !filtered?.archived)?.length > 0 ? (
                    <Grid fontSize='xl' fontFamily='Titillium Web' fontWeight='600' px={4} templateColumns="repeat(5, 1fr)">
                        <GridItem>Reference</GridItem>
                        <GridItem>Company</GridItem>
                        <GridItem>Date</GridItem>
                        <GridItem>Project</GridItem>
                        <GridItem pr={3} justifyContent='end' display='flex'>Download</GridItem>
                    </Grid>
                ) : (
                    <Text fontSize='18px' textAlign='center'>
                        No active estimates at this time - check back later!
                    </Text>
                    )
            }
            {
                estimates?.filter((filtered) => filtered.isEstimate && !filtered?.archived).sort((a: any, b: any) => moment.utc(b.createdAt.date).diff(moment.utc(a.createdAt.date))).map((item: any) => (
                    <Grid px={4} mt={4} templateColumns="repeat(5, 1fr)" alignItems='center' minH='57px' bg='white' borderRadius='10px' border='2px solid #A9A9A9' key={item.orderUid} z-index='1'>
                        <GridItem textDecoration='underline'>
                            <Link to={`/orders/estimate/su/${item.id}/1`} z-index='999'>
                                {item.reference}
                            </Link>
                        </GridItem>
                        <GridItem>
                            <Link to={`/orders/estimate/su/${item.id}/1`} z-index='999'>
                                {item?.userSu?.company?.length > 0 ? item?.userSu?.company[0]?.name : item?.userSu?.associatedToCompany?.name}
                            </Link>
                        </GridItem>
                        <GridItem>
                            <Link to={`/orders/estimate/su/${item.id}/1`} z-index='999'>
                                {moment(item?.createdAt?.date).format('MM/DD/YYYY')}
                            </Link>
                        </GridItem>
                        <GridItem>
                            <Link to={`/orders/estimate/su/${item.id}/1`} z-index='999'>
                                {item.report?.project?.city + ' (' + item.report?.project?.state + ')'}
                            </Link>
                        </GridItem>
                        <GridItem justifyContent='end' display='flex'>
                            <Button onClick={() => downloadPdf(item, ESTIMATE)} isLoading={loading[item.reference]} color='white' borderRadius='4px' width='90px' height='30px' bg='#2C628D'>
                                <DownloadIcon/>
                            </Button>
                        </GridItem>
                    </Grid>
                ))
            }
        </Box>
    )
}

export default EstimatesTab
