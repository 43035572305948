import { useMutation } from 'react-query'
import { companyUseCase } from '../../factories'

const useRemoveUserFromCompany = () => {
  const {
    mutate: removeUser, isLoading, data, error,
  } = useMutation(companyUseCase.removeUserFromCompany)

  return {
    removeUser,
    isLoading,
    error,
    data,
  }
}

export default useRemoveUserFromCompany
