import React, {useState} from 'react'
import {Link, useLocation} from 'react-router-dom'
import {
    Button,
    Flex,
    Input,
    InputGroup,
    InputLeftElement,
    Text,
    PopoverTrigger, PopoverContent, PopoverArrow, PopoverBody, Box, Popover,
} from '@chakra-ui/react'
import Navbar from '../../components/Navbar'
import {ReactComponent as Upload} from '../../assets/cloud-upload.svg'
import {ReactComponent as Search} from '../../assets/search.svg'
import {ReactComponent as Plus} from '../../assets/plus.svg'
import {Checkbox, Icon} from "../../components/Theme";
import styles from "../Projects/styles";
const FILTERS = [
    'Fixture',
    'Lamp',
    'Power Supply',
    'Control',
    'Accessory',
]
const HeaderForCatalogues = ({handleSearch, title}) => {
    const [filter, setFilter] = useState([])
    const handleCheckbox = (e) => {
        if (filter.includes(e)) {
            return setFilter(filter.filter((name) => name !== e))
        }
        setFilter([...filter, e])
    }
    const location = useLocation()
    return (
        <>
        <Navbar
            title={(
                <Flex>
                    <Link as='h2' m={0} to="/catalogues">Catalogues</Link>
                    <Text as='h2' m={0} textTransform="capitalize">
                        &nbsp;/
                        {' '}
                        {title}
                    </Text>
                </Flex>
            )}>
            <Flex>
                {(() => {
                    if (location.pathname.includes('fixture')){
                        return (
                            <Button
                                as={Link}
                                mr={10}
                                pl={8}
                                pr={8}
                                width='173px'
                                height='40px'
                                size="sm"
                                to="/catalogues/create-product/fixture"
                                variant="lightBlue"
                                leftIcon={<Plus/>}>
                                New Fixture
                            </Button>
                        )
                    }else if (location.pathname.includes('lamp')){
                        return (
                            <Button
                                as={Link}
                                mr={10}
                                size='sm'
                                pl={8}
                                pr={8}
                                width='173px'
                                height='40px'
                                to="/catalogues/create-product/lamp"
                                variant="lightBlue"
                                leftIcon={<Plus/>}>
                                New Lamp
                            </Button>
                        )
                    }else if (location.pathname.includes('power')){
                        return (
                            <Button
                                as={Link}
                                mr={10}
                                pl={8}
                                pr={8}
                                width='173px'
                                height='40px'
                                size="sm"
                                to="/catalogues/create-accessory/power-supply"
                                variant="lightBlue"
                                leftIcon={<Plus/>}>
                                New Power-Supply
                            </Button>
                        )
                    }else if (location.pathname.includes('control')){
                        return (
                            <Button
                                as={Link}
                                mr={10}
                                pl={8}
                                pr={8}
                                width='173px'
                                height='40px'
                                size="sm"
                                to="/catalogues/create-accessory/control"
                                variant="lightBlue"
                                leftIcon={<Plus/>}>
                                New Control
                            </Button>
                        )
                    }else if (location.pathname.includes('accessor')){
                        return (
                            <Button
                                as={Link}
                                mr={10}
                                pl={8}
                                pr={8}
                                width='173px'
                                height='40px'
                                size="sm"
                                to="/catalogues/create-accessory/accessory"
                                variant="lightBlue"
                                leftIcon={<Plus/>}>
                                New Accessory
                            </Button>
                        )
                    }
                })()}
                {location.pathname==='/catalogues' && (
                    <Link to="/catalogue/upload">
                        <Button
                            mr={8}
                            ml={12}
                            width='173px'
                            height='30px'
                            leftIcon={<Upload/>}
                            px={8}
                            size="sm"
                            variant="green">
                            Upload
                        </Button>
                    </Link>
                )}
                {
                    location.pathname==='/catalogues/all-products' && (
                        <Popover>
                            <PopoverTrigger>
                                <Button
                                    leftIcon={<Icon type="filter"/>}
                                    mr={12}
                                    width='173px'
                                    height='40px'
                                    variant="lightBlue"
                                    size="sm">
                                    <Text
                                        ml={2}
                                        fontSize="sm">
                                        Filter
                                    </Text>
                                </Button>
                            </PopoverTrigger>
                            <PopoverContent
                                bg="white"
                                zIndex="500"
                                p={6}
                                w="190px"
                                pr={0}
                                flexDirection="row"
                                _focus={{outline: 'none'}}>
                                <PopoverArrow/>
                                <PopoverBody
                                    display="flex"
                                    flexDirection="column">
                                    {
                                        FILTERS.map((item) => (
                                            <Checkbox
                                                key={item}
                                                css={styles.checkbox}
                                                onChange={(e) => handleCheckbox(e.target.name)}
                                                name={item}
                                                colorScheme="#8FCF74"
                                                iconColor="#8FCF74">
                                                <Box
                                                    ml={2}
                                                    fontSize="sm"
                                                    as="span">
                                                    {item}
                                                </Box>
                                            </Checkbox>
                                        ))
                                    }
                                </PopoverBody>
                            </PopoverContent>
                        </Popover>
                    )
                }
                <InputGroup
                    alignItems="center"
                    height="29px"
                    borderRadius="5px"
                    _focus={{
                        borderColor: 'blue.200',
                    }}
                    _hover={{
                        borderColor: 'blue.200',
                    }}
                    borderColor="blue.200"
                    bg="blue.200">
                    <InputLeftElement
                        height="29px"
                        pointerEvents="none"
                        children={
                            (
                                <Search
                                    height="16px"
                                    width="16px"/>
                            )
                        }/>
                    <Input
                        onChange={handleSearch}
                        _focus={{
                            borderColor: 'blue.200',
                        }}
                        _hover={{
                            borderColor: 'blue.200',
                        }}
                        height="29px"
                        color="white.100"
                        placeholder="Search"/>
                </InputGroup>
            </Flex>
        </Navbar>
        </>
    )
}
export default HeaderForCatalogues
