import {css} from '@emotion/react'

const mainStyles = {
    container: css`
        min-height: calc(100vh - 60px);
        margin: auto;
        padding-bottom: 40px;
        background-color: #F5F5F5;
        width: 1240px;
        flex-direction: column;
        border-right: 1px solid #DBDADA;
        border-left: 1px solid #DBDADA;
    `,

    tabNames: css`
        border: none;
        background-color: white;
        font-size: 18px;
        padding-top: 40px;
        position: relative;

        button {
            min-width: 208px;
            font-size: 18px;
            font-weight: 700;
            color: #619C48;

            &[aria-selected="true"] {
                border-bottom: 4px solid;
                color: #619C48;
                background-color: transparent;
                box-shadow: none;
                position: relative;
            }
        }
    `,

    orderTabNames: css`
        border: none;
        font-size: 18px;
        background-color: white;
        padding-top: 40px;

        button {
            min-width: 177px;
            font-size: 18px;
            font-weight: 700;
            color: #619C48;

            &[aria-selected="true"] {
                border-bottom: 4px solid;
                color: #619C48;
                background-color: transparent;
                box-shadow: none;
            }
        }
    `,


    tabPanels: css`
        padding: 20px 30px;
        border-top: 1px solid #DBDADA;
        border-bottom: none;
    `,

    toast: css`
        animation-name: scroll;
        animation-duration: 4s;
        animation-timing-function: linear;
        @keyframes scroll {
            0% {
                left: 5px;
            }
            100% {
                left: 100%;
            }
        }
    `,

    file: css`
        display: flex;
        box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 17px 0 17px 22px;
        margin-bottom: 20px;
        width: 100%;
        position: relative;
        cursor: pointer;
    `,

    paginator: css`
        & > div {
            display: flex;
            justify-content: center;
        }
    `,
}

export default mainStyles
