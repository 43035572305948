import React, {useEffect, useState} from 'react'
import {
    Text,
    Grid,
    GridItem,
    Box,
    Flex, Heading,
} from '@chakra-ui/react'
import {FEATURES} from '../../constants'

const Features = ({features}) => {
    const [active, setActive] = useState([...features.current])

    const handleActive = (item) => {
        if (active.includes(item)) {
            return setActive(active.filter((string) => string !== item))
        }
        return setActive([...active, item])
    }

    useEffect(() => {
        features.current = active
    }, [features, active])

    return (
        <>
            <Heading
                textAlign="center"
                fontSize="4xl">
                <div
                    style={{'display': 'flex', 'justify-content': 'center'}}>
                    <h2
                        style={{'margin': '0', 'width': '500px'}}>
                        Which features of LED lighting are most important to you?
                    </h2>
                </div>
                <Text
                    textAlign="center"
                    fontSize="sm">
                    (Select all that apply)
                </Text>
            </Heading>
            <Grid
                gap={8}
                mt={10}
                mb={10}
                width="845px"
                templateColumns="repeat(4, 1fr)">
                <GridItem
                    colSpan={1}>
                    {
                        FEATURES.illumination.map((item) => (
                            <Flex
                                key={item.title}
                                onClick={() => handleActive(item.title)}
                                alignItems="center"
                                justifyContent="center"
                                mb={4}
                                cursor="pointer"
                                borderRadius="6px"
                                bg={active.includes(item.title) ? item.active : item.passive}
                                height="46px">
                                <Box
                                    fontWeight="500"
                                    color="white.100"
                                    fontSize="md"
                                    as="span">
                                    {item.title}
                                </Box>
                            </Flex>
                        ))
                    }
                </GridItem>
                <GridItem
                    colSpan={1}>
                    {
                        FEATURES.technology.map((item) => (
                            <Flex
                                key={item.title}
                                onClick={() => handleActive(item.title)}
                                alignItems="center"
                                justifyContent="center"
                                mb={4}
                                cursor="pointer"
                                borderRadius="6px"
                                bg={active.includes(item.title) ? item.active : item.passive}
                                height="46px">
                                <Box
                                    fontWeight="500"
                                    color="white.100"
                                    fontSize="md"
                                    as="span">
                                    {item.title}
                                </Box>
                            </Flex>
                        ))
                    }
                </GridItem>
                <GridItem
                    colSpan={1}>
                    {
                        FEATURES.financial.map((item) => (
                            <Flex
                                key={item.title}
                                onClick={() => handleActive(item.title)}
                                alignItems="center"
                                justifyContent="center"
                                mb={4}
                                cursor="pointer"
                                borderRadius="6px"
                                bg={active.includes(item.title) ? item.active : item.passive}
                                height="46px">
                                <Box
                                    fontWeight="500"
                                    color="white.100"
                                    fontSize="md"
                                    as="span">
                                    {item.title}
                                </Box>
                            </Flex>
                        ))
                    }
                </GridItem>
                <GridItem
                    colSpan={1}>
                    {
                        FEATURES.environment.map((item) => (
                            <Flex
                                borderRadius="6px"
                                key={item.title}
                                onClick={() => handleActive(item.title)}
                                alignItems="center"
                                justifyContent="center"
                                mb={4}
                                cursor="pointer"
                                bg={active.includes(item.title) ? item.active : item.passive}
                                height="46px">
                                <Box
                                    fontWeight="500"
                                    color="white.100"
                                    fontSize="md"
                                    as="span">
                                    {item.title}
                                </Box>
                            </Flex>
                        ))
                    }
                </GridItem>
            </Grid>
        </>
    )
}

export default Features
