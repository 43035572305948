import React from 'react'
// import { useHistory } from 'react-router-dom'
// import {
//     Grid,
//     GridItem,
//     Select,
//     FormControl,
//     FormLabel,
//     Flex,
// } from '@chakra-ui/react'
// import { useUpdateUser, useUser } from '../../core/hooks'
// import { Button, InputLabel } from '../../components/Theme'
import Navbar from '../../components/Navbar'

const Company = () => {
  console.log('company')
  // const [company, setCompany] = useState<any>({
  //     name: '',
  //     website: '',
  //     hqAddress: '',
  //     hqAddress2: '',
  //     cityStateZip: '',
  //     hqCountry: '',
  // })
  // const [errors, setErrors] = useState<string[]>()
  // const { mutate: updateUser, isLoading } = useUpdateUser()
  // const { user, setUser } = useUser()
  // const history = useHistory()
  // console.log(errors)

  // const handleInput = (name: string, value: string) => {
  //     setCompany({
  //         ...company,
  //         [name]: value,
  //     })
  // }

  // const submit = (e: any) => {
  //     e.preventDefault()
  //     let newErrors: string[] = []
  //     if (!company.name.trim()) {
  //         newErrors.push('name')
  //     } else {
  //         newErrors = newErrors.filter((item: string) => item !== 'name')
  //     }
  //     if (!company.website.trim()) {
  //         newErrors.push('website')
  //     } else {
  //         newErrors = newErrors.filter((item: string) => item !== 'website')
  //     }
  //     if (!company.hqAddress.trim()) {
  //         newErrors.push('hqAddress')
  //     } else {
  //         newErrors = newErrors.filter((item: string) => item !== 'hqAddress')
  //     }
  //     if (!company.cityStateZip.trim()) {
  //         newErrors.push('cityStateZip')
  //     } else {
  //         newErrors = newErrors.filter((item: string) => item !== 'cityStateZip')
  //     }
  //     if (!company.hqCountry.trim()) {
  //         newErrors.push('hqCountry')
  //     } else {
  //         newErrors = newErrors.filter((item: string) => item !== 'hqCountry')
  //     }

  //     setErrors(newErrors)

  //     if (newErrors.length > 0) return
  //     const input = {
  //         id: user?.id,
  //         company: JSON.stringify(company),
  //     }
  //     setUser({
  //         ...user,
  //         ...input,
  //     })
  //     updateUser(
  //         input,
  //         {
  //             onSuccess: () => {
  //                 history.push(user?.type === 'Su' ? '/catalogues' : '/projects')
  //             },
  //             onError: (error) => {
  //                 console.log(error)
  //             },
  //         },
  //     )
  // }

  return (
    <>
      <Navbar
        title="Company"/>
      {/* <form
                style={{ padding: '1rem 2rem' }}
                onSubmit={submit}>
                <Grid
                    gap={4}
                    templateColumns="repeat(2, 1fr)">
                    <GridItem
                        px={4}
                        colSpan={1}>
                        <InputLabel
                            isInvalid={errors?.includes('name')}
                            onChange={(e) => handleInput('name', e.target.value)}
                            value={company.name}
                            label="Company Name*"/>
                    </GridItem>
                    <GridItem
                        px={4}
                        colSpan={1}>
                        <InputLabel
                            isInvalid={errors?.includes('website')}
                            onChange={(e) => handleInput('website', e.target.value)}
                            value={company.website}
                            label="Company Website*"/>
                    </GridItem>
                    <GridItem
                        px={4}
                        colSpan={1}>
                        <InputLabel
                            isInvalid={errors?.includes('hqAddress')}
                            onChange={(e) => handleInput('hqAddress', e.target.value)}
                            value={company.hqAddress}
                            label="HQ Address*"/>
                    </GridItem>
                    <GridItem
                        px={4}
                        colSpan={1}>
                        <InputLabel
                            onChange={(e) => handleInput('hqAddress2', e.target.value)}
                            value={company.hqAddress2}
                            label="HQ Address Line 2"/>
                    </GridItem>
                    <GridItem
                        px={4}
                        colSpan={1}>
                        <InputLabel
                            isInvalid={errors?.includes('cityStateZip')}
                            onChange={(e) => handleInput('cityStateZip', e.target.value)}
                            value={company.cityStateZip}
                            label="HQ City, State, Zip*"/>
                    </GridItem>
                    <GridItem
                        px={4}
                        colSpan={1}>
                        <FormControl mt={4}>
                            <FormLabel
                                fontWeight="600"
                                fontSize="md"
                                color="blue.200">
                                HQ Country*
                            </FormLabel>
                            <Select
                                isInvalid={errors?.includes('hqCountry')}
                                onChange={(e) => handleInput('hqCountry', e.target.value)}
                                _focus={{
                                    borderColor: 'rgba(169, 169, 169, 0.87)',
                                }}
                                borderColor="rgba(169, 169, 169, 0.87)"
                                placeholder="Select a Country">
                                <option value="us">United States</option>
                                <option value="canada">Canada</option>
                                <option value="mexico">Mexico</option>
                            </Select>
                        </FormControl>
                    </GridItem>
                </Grid>
                <Flex
                    alignItems="center"
                    justifyContent="center"
                    w="100%"
                    mt={8}>
                    <Button
                        alignSelf="center"
                        isLoading={isLoading}
                        isDisabled={isLoading}
                        colorScheme="blue.200"
                        width="170px"
                        large={+true}
                        type="submit">
                        Save Company
                    </Button>
                </Flex>
            </form> */}
    </>
  )
}

export default Company
