//Lamp types
export const PRODUCT_TYPES = {
    1: 'Fixture',
    2: 'Lamp',
    3: 'Power Supply',
    4: 'Control',
    5: 'Accessory',
}

// Lamp filters for airtable recommended base
export const FIXTURE_FILTER = (luminaire) => `
        {Mount} = '${luminaire?.fixtureMount?.charAt(0).toUpperCase() + luminaire?.fixtureMount?.slice(1)}', 
        {Application} = '${luminaire?.fixtureApplication}',
        {Style} = '${luminaire?.fixtureStyle}',
        {Lens Type} = '${luminaire?.fixtureLens}',
        {Width (inches)} = '${luminaire?.fixtureWidth}', 
        {Length (inches)} = '${luminaire?.fixtureLength}',
        {Diameter (inches)} = '${luminaire?.fixtureDiameter}', 
        {Depth (inches)} = '${luminaire?.fixtureDepth}'
    `
export const FIXTURE_FILTER_MIN = (luminaire) => `
        FIND('${luminaire?.fixtureMount?.charAt(0).toUpperCase() + luminaire?.fixtureMount?.slice(1)}', {Mount})`

export const LAMP_FILTER = (luminaire) => `
        {Category} = '${luminaire?.lampCategory}',
        {Type} = '${luminaire?.lampCategoryType}',
        {Base Category} = '${luminaire?.lampBaseCategory}',
        {Base Type} = '${luminaire?.lampBaseCategoryType}',
        {Wattage} = '${luminaire?.lampWattage}',
        {CCT (K˚)} = '${luminaire?.lampColorTemperature}',
        {Lens Type} = '${luminaire?.lampLensType}'
    `

export const LAMP_FILTER_MIN = (luminaire) => `
        FIND('${luminaire?.lampCategory}', {Category})`

export const POWER_SUPPLY_FILTER = (luminaire) => `FIND('${luminaire?.powerSupplyType}', {Type})`
export const CONTROL_FILTER = (luminaire) => `FIND('${luminaire?.controlType}', {Type})`
export const ACCESSORIES_FILTER = (luminaire) => `FIND('${luminaire?.accessoryTypeA}', {Category})`

// Company types width subtypes
export const REU_COMPANY_TYPES = {
    11: 'Real Estate',
    12: 'Property Investment',
    13: 'Property Management',
    14: 'Facilities Management',
    15: 'Architecture',
    16: 'REIT',
}

export const SU_COMPANY_TYPES = {
    21: 'Supplier',
    22: 'Luminaire Manufacturer',
    23: 'Distributor',
    24: 'Component Manufacturer',
}

export const EU_COMPANY_TYPES = {
    31: 'Electrical',
    32: 'Electrical Contractor',
    33: 'Electrician',
}