import { Image, Button, Text, HStack, VStack} from '@chakra-ui/react'
import {ReactComponent as Edit} from "../../../assets/edit.svg";
import {S3_BUCKET} from "../../../constants";
import {Link} from "react-router-dom";
import TextLabel from "../../../components/Theme/TextLabel";
import {COMPANY_TYPES} from "../../../constants/company/companyEnum";

const Header = ({company}) => (
    <HStack spacing={8} justifyContent='space-between'>
        <HStack spacing={8} alignItems='start'>
            <Image
                border="2px solid #DBDADA"
                alt="company avatar"
                width="106px"
                height="101px"
                objectFit="cover"
                src={company?.image ? S3_BUCKET + company?.image : ''}/>
            <TextLabel
                minW='468px'
                fontSize='3xl'
                fontWeight='600'
                text={company?.name}/>
        </HStack>
        <VStack >
            <HStack spacing={8}>
                <Text fontSize='xl'>
                    Company Type:
                </Text>
                <TextLabel
                    fullWidth='auto'
                    w='320px'
                    text={
                        company?.companySubType ? COMPANY_TYPES[company?.companySubType]
                             : company?.type}/>
            </HStack>
            <HStack  w='full' justifyContent='end'>
                <Button
                    as={Link}
                    to={'/companies/edit-company/' + company?.id}
                    leftIcon={<Edit/>}
                    w="135px"
                    variant="pink">
                    Edit Details
                </Button>
            </HStack>
        </VStack>
    </HStack>
)

export default Header