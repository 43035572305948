import React, {useState} from 'react'
import {
    Box,
    Flex,
    useDisclosure,
    Text,
    Button,
    Stack,
    Select
} from '@chakra-ui/react'
import {
    InviteModal,
    Input,
} from '../../../components/Theme'
import {useInviteUser, useUser} from '../../../core/hooks'
import styles from "../styles";

const UserModal = ({isOpen, onClose, onOpen}) => {
    const [error, setError] = useState('')
    const {user} = useUser()
    const [email, setEmail] = useState('')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [userType, setUserType] = useState('')
    const {inviteUser, isLoading} = useInviteUser()

    const invite = () => {
        if (email===user.email){
            return setError("You cant check you email")
        }
        if (user.type==='SUPERADMIN'){
            inviteUser({
                    receiver: email,
                    url: `${process.env.REACT_APP_API_ENDPOINT.replace("api/", "register/")}${userType}`,
                    senderName: firstName,
                    senderLastName: lastName,
                },
                {
                    onSuccess: () => {
                        onClose()
                        setError()
                    },
                    onError: (e) => {
                        if (e.response.data.errorMessage === 1109) {
                            setError('*This email is already active in the system.')
                        }
                    },
                },
            )
        }else {
            inviteUser({
                    receiver: email,
                    url: `${process.env.REACT_APP_API_ENDPOINT.replace("api/", "register/")}${user.type}`,
                    senderName: firstName,
                    senderLastName: lastName,
                },
                {
                    onSuccess: () => {
                        onClose()
                        setError()
                    },
                    onError: (e) => {
                        if (e.response.data.errorMessage === 1109) {
                            setError('*This email is already active in the system.')
                        }
                    },
                },
            )
        }

    }
    return (
        <Box>
            <InviteModal
                title="Add New User"
                isOpen={isOpen}
                onClose={onClose}>
                <Flex
                    alignItems="center"
                    flexDirection="column">
                    <Text
                        my={12}
                        fontSize="3xl">
                        Invite a new user to join the Energy Portal.
                    </Text>
                    <Stack
                        mx="auto"
                        w="418px"
                        spacing={4}>
                        <Input
                            onChange={(e) => setFirstName(e.target.value)}
                            fontSize='18px !important'
                            placeholder="First Name"/>
                        <Input
                            onChange={(e) => setLastName(e.target.value)}
                            fontSize='18px !important'
                            placeholder="Last Name"/>
                        <Input
                            fontSize='18px !important'
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Contact Email"/>
                        {
                            user?.type==='SUPERADMIN' && (
                                <Select
                                    onChange={(e) => setUserType(e.target.value)}
                                    css={styles.select}>
                                    <option value="Reu">Real Estate</option>
                                    <option value="Su">Supplier</option>
                                    <option value="El">Electrican</option>
                                </Select>
                            )
                        }

                    </Stack>
                    {error && (<Text color="red.100" fontSize="m" mt={2}>{error}</Text>)}
                    <Button
                        isLoading={isLoading}
                        onClick={invite}
                        mx="auto"
                        fontSize='18px'
                        type="button"
                        mt={12}
                        mb="2.8rem"
                        height='51px'
                        borderRadius="5px"
                        width="254px">
                        Submit
                    </Button>
                </Flex>
            </InviteModal>
        </Box>

    )
}

export default UserModal
