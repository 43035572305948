import {useEffect, useState} from "react";
import {Box} from "@chakra-ui/react";
import ShippingMain from "./ShippingPages/ShippingMain";
import ShippingCreateDetails from "./ShippingPages/ShippingCreateDetails";
import ShippingViewDetails from "./ShippingPages/ShippingViewDetails";
import Loading from "../../../components/Loading";

const Shipping = ({
                      createShippingAllow,
                      refererShipping,
                      setCreateShippingAllow,
                      shippingPageStatus,
                      setShippingPageStatus,
                      setEditShippingAllow,
                      editShippingAllow,
                      onOpenDelivered,
                      estimate,
                      luminaires,
                      getEstimate,
                      setLoading,
                      notes,
                      user,
                      trackId,
                      edit,
                      setEdit,
                      setTrackId,
                      callback,
                      isLoading,
                      setCompanySu,
                      setCompanyReu,
                      companySu,
                      companyReu,
                  }) => {

    if (isLoading) return <Loading/>
    return (
        <Box
            ref={refererShipping}
            display={estimate?.shippingDateCreated ? 'block' : 'none'}
            backgroundColor='white'
            p={9}>
            {
                shippingPageStatus === 2 ? (
                    <ShippingCreateDetails
                        companyReu={companyReu}
                        companySu={companySu}
                        getEstimate={getEstimate}
                        setLoading={setLoading}
                        setCreateShippingAllow={setCreateShippingAllow}
                        createShippingAllow={createShippingAllow}
                        setShippingPageStatus={setShippingPageStatus}
                        estimate={estimate}
                        callback={(e) => {
                            callback(e)
                        }}
                        luminaires={luminaires}/>
                ) : shippingPageStatus === 3 ? (
                    <ShippingViewDetails
                        companySu={companySu}
                        getEstimate={getEstimate}
                        editShippingAllow={editShippingAllow}
                        setEditShippingAllow={setEditShippingAllow}
                        setLoading={setLoading}
                        edit={edit}
                        setEdit={setEdit}
                        trackId={trackId}
                        setShippingPageStatus={setShippingPageStatus}
                        estimate={estimate}
                        callback={(e) => {
                            callback(e)
                        }}
                        luminaires={luminaires}/>
                ) : <ShippingMain
                    onOpenDelivered={onOpenDelivered}
                    user={user}
                    companySu={companySu}
                    setTrackId={setTrackId}
                    shippingPageStatus={shippingPageStatus}
                    setShippingPageStatus={setShippingPageStatus}
                    estimate={estimate}
                    luminaires={luminaires}/>
            }

        </Box>
    )
}

export default Shipping