import {Flex, Image, Box, Heading, Text, Accordion, AccordionItem, AccordionButton,AccordionPanel, AccordionIcon, Button} from '@chakra-ui/react'
import {ReactComponent as Calculator} from '../../../assets/calculatorIcon.svg'
import {ReactComponent as AcceptIcon} from '../../../assets/acceptIconShape.svg'
import {ReactComponent as Download} from '../../../assets/cloud-download.svg'
import {ReactComponent as Print} from '../../../assets/printIcon.svg'
import {CustomDivider} from "../../../components/Theme";
import {S3_BUCKET} from "../../../constants";
import {ReactComponent as CreateEstimate} from '../../../assets/createEstimateIcon.svg'

const Header = ({setDownloadingPDF, user, approvedReport, proposal, params, createEstimate, loading, project, error, downloadingPDF, history}) => (
    <Flex m="auto" minW="1240px" maxW="1240px" border="1px solid #DBDADA" justifyContent="space-between" bg="#f5f5f5">
        <Flex pl={5} py={8}>
            <Image
                src={project.image ? S3_BUCKET + project.image : '/images/defaultLamp.jpg'}
                borderRadius="5px"
                mr={8}
                objectFit="contain"
                height="146px"
                width="220px"/>
            <Box>
                <Heading mt={2} mb={3} fontSize="3xl">{proposal?.proposalName}</Heading>
                <Text fontWeight='600' fontSize="xl">
                    Areas:&nbsp;
                    <Text as='span' fontWeight='normal'>{proposal?.proposal?.map((item) => ' ' + item.title).toString()}</Text>
                </Text>
                <Text fontWeight='600' fontSize="xl">
                    Notes:&nbsp;
                    <Text as='span' fontWeight='normal'>{project?.notes?.length > 164 ? project?.notes?.substring(0, 164) + '...' : project?.notes}</Text>
                    {error && (<Text textAlign='center' color="red.100" fontSize="m">{error}</Text>)}
                </Text>
            </Box>
        </Flex>
        <Flex>
            <CustomDivider />
            <Flex px={12} py={8} alignItems="start" flexDirection="column">
                {user?.type === 'Reu' ? (
                        <>
                            <Accordion allowToggle>
                                <AccordionItem>
                                    {({isExpanded}) => (
                                        <>
                                            <AccordionButton
                                                mb={!isExpanded ? 4 : 0}
                                                color="white.100"
                                                width='150px'
                                                height="40px"
                                                fontSize="sm"
                                                _active={{background: '', boxShadow: 'none',}}
                                                _hover={{background: '',}}
                                                borderRadius="4px"
                                                bg="blue.200">
                                                <AccordionIcon/>
                                                 Download or Print
                                            </AccordionButton>
                                            <AccordionPanel
                                                fontSize='sm'
                                                p={0}
                                                mb={isExpanded ? 4 : 0}
                                                borderRadius='4px'
                                                borderBottom='4px solid #46CD0D'
                                                bg='#EAF6FF'>
                                                <Button
                                                    _focus=''
                                                    _active=''
                                                    width='80%'
                                                    maxH='30px'
                                                    disabled={downloadingPDF.bool && downloadingPDF.type === 'print'}
                                                    isLoading={downloadingPDF.bool && downloadingPDF.type === 'pdf'}
                                                    mx='auto'
                                                    onClick={() => setDownloadingPDF({type: 'pdf', bool: true})}
                                                    mx='auto'
                                                    leftIcon={<Download width='12px' height='12px'/>}
                                                    fontFamily='Titillium Web'
                                                    color='black'
                                                    display='block'
                                                    textAlign='left'
                                                    bg='transparent'
                                                    p={0}>
                                                    Download PDF
                                                </Button>
                                                <Button
                                                    maxH='30px'
                                                    _focus=''
                                                    _active=''
                                                    disabled={downloadingPDF.bool && downloadingPDF.type === 'pdf'}
                                                    isLoading={downloadingPDF.bool && downloadingPDF.type === 'print'}
                                                    onClick={() => setDownloadingPDF({type: 'print', bool: true})}
                                                    mx='auto'
                                                    textAlign='left'
                                                    width='80%'
                                                    leftIcon={<Print/>}
                                                    fontFamily='Titillium Web'
                                                    color='black'
                                                    display='block'
                                                    bg='transparent'
                                                    p={0}>
                                                    Print PDF
                                                </Button>
                                            </AccordionPanel>
                                        </>
                                    )}
                                </AccordionItem>
                            </Accordion>
                            <Button
                                leftIcon={<AcceptIcon/>}
                                width='150px'
                                height="40px"
                                fontSize="sm"
                                isLoading={loading}
                                onClick={approvedReport}
                                _active={{background: '', boxShadow: 'none',}}
                                _hover={{background: '',}}
                                borderRadius="4px"
                                bg="#8FCF74">
                                <Box color='black' as="span">
                                    {proposal?.approved ? ('Approved') : ('Approve')}
                                </Box>
                            </Button>
                        </>
                    ) : (
                        <>
                            <Button
                                mb={4}
                                onClick={() => history.push(`/report/${params.projectId}/${params.reportId}`)}
                                leftIcon={<Calculator/>}
                                width='150px'
                                height="40px"
                                fontSize="sm"
                                _active={{background: '', boxShadow: 'none',}}
                                _hover={{background: '',}}
                                borderRadius="4px"
                                bg="#D76E34">
                                <Box color='#222222' as="span">
                                    Calculator
                                </Box>
                            </Button>
                            <Accordion allowToggle>
                                <AccordionItem>
                                    {({isExpanded}) => (
                                        <>
                                            <AccordionButton
                                                mb={!isExpanded ? 4 : 0}
                                                color="white.100"
                                                width='150px'
                                                height="40px"
                                                fontSize="sm"
                                                _active={{background: '', boxShadow: 'none',}}
                                                _hover={{background: '',}}
                                                borderRadius="4px"
                                                bg="blue.200">
                                                <AccordionIcon/>
                                                <Text ml={1}>
                                                    Download or Print
                                                </Text>
                                            </AccordionButton>
                                            <AccordionPanel
                                                fontSize='12px'
                                                p={0}
                                                mb={isExpanded ? 4 : 0}
                                                borderRadius='4px'
                                                borderBottom='4px solid #46CD0D'
                                                bg='#EAF6FF'>
                                                <Button
                                                    _focus=''
                                                    _active=''
                                                    width='80%'
                                                    maxH='30px'
                                                    disabled={downloadingPDF.bool && downloadingPDF.type === 'print'}
                                                    isLoading={downloadingPDF.bool && downloadingPDF.type === 'pdf'}
                                                    mx='auto'
                                                    onClick={() => setDownloadingPDF({type: 'pdf', bool: true})}
                                                    leftIcon={<Download width='12px' height='12px'/>}
                                                    fontFamily='Titillium Web'
                                                    color='black'
                                                    display='block'
                                                    textAlign='left'
                                                    bg='transparent'
                                                    p={0}>
                                                    Download PDF
                                                </Button>
                                                <Button
                                                    maxH='30px'
                                                    _focus=''
                                                    _active=''
                                                    disabled={downloadingPDF.bool && downloadingPDF.type === 'pdf'}
                                                    isLoading={downloadingPDF.bool && downloadingPDF.type === 'print'}
                                                    onClick={() => setDownloadingPDF({type: 'print', bool: true})}
                                                    mx='auto'
                                                    textAlign='left'
                                                    width='80%'
                                                    leftIcon={<Print/>}
                                                    fontFamily='Titillium Web'
                                                    color='black'
                                                    display='block'
                                                    bg='transparent'
                                                    p={0}>
                                                    Print PDF
                                                </Button>
                                            </AccordionPanel>
                                        </>
                                    )}
                                </AccordionItem>
                            </Accordion>
                            <Button
                                leftIcon={<CreateEstimate/>}
                                width='150px'
                                height="40px"
                                isLoading={loading}
                                onClick={createEstimate}
                                fontSize="sm"
                                _active={{background: '', boxShadow: 'none',}}
                                _hover={{background: '',}}
                                borderRadius="4px"
                                bg="#8FCF74">
                                <Box color='#222222' as="span">
                                    Create Estimate
                                </Box>
                            </Button>
                        </>
                    )
                }
            </Flex>
        </Flex>
    </Flex>
)

export default Header