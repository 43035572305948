import {css} from '@emotion/react'

const styles = {
    container: css`
      min-height: calc(100vh - 60px);
    `,
    file: css`
      display: flex;
      box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.25);
      border-radius: 10px;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 17px 0 17px 22px;
      margin-bottom: 20px;
    `,
}

export default styles
