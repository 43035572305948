import axios from "axios";


const useCheckEmail = () =>{
    const checkEmail = async (email) => {
        return axios({
            method: "post",
            url: `${process.env.REACT_APP_API_ENDPOINT}api/check-email`,
            data: email
        })
    }
    return {
        checkEmail
    }
}


export default useCheckEmail
