import { useQuery } from 'react-query'
import { companyUseCase } from '../../factories'

const useListCompanys = (variables) => {
  const {
    refetch: listCompanys, isLoading, data, error,
  } = useQuery('companys', () => companyUseCase.listCompanys(variables))

  return {
    listCompanys,
    isLoading,
    error,
    data,
  }
}

export default useListCompanys
