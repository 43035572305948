import React from 'react'
import {Button, Flex, FormControl, FormLabel, Grid, GridItem} from '@chakra-ui/react'
import {InputLabel, ChakraSelectLabel, TextareaLabel} from '../../components/Theme'
import CustomSelect from '../../components/Theme/CustomSelect'
import {LED2} from '../../constants'
import {ReactComponent as Edit_Modal} from "../../assets/edit_modal.svg";

const SecondaryFields = ({register, orderCodesComp, compatibleProductOrderCodes, setCompatibleProductOrderCodes, onOpenProducts}) => (
    <Grid templateColumns="repeat(1, 1fr)" gap={6}>
        <GridItem colSpan={1}>
            <InputLabel {...register('productDescription')} label="Product Description:"/>
        </GridItem>
        <GridItem colSpan={1}>
            <InputLabel {...register('productSpecificationsHyperlink')} label="Product Specifications (hyperlink):"/>
        </GridItem>
        <GridItem colSpan={1}>
            <InputLabel {...register('installationInstructionsHyperlink')} label="Installation Instructions (hyperlink):"/>
        </GridItem>
        <GridItem colSpan={1}>
            <InputLabel {...register('productImageHyperlink')} label="Product Image (hyperlink):"/>
        </GridItem>
        <GridItem colSpan={1}>
            <FormControl width='100%' alignItems="center" position="relative">
                <FormLabel fontFamily='Titillium Web' fontWeight="600" fontSize="md" color="blue.200">
                    Compatible Product Order Codes:
                </FormLabel>
                <Flex pl='10px' width='100%' position='relative' alignItems='center' backgroundColor='white' height='40px' border='1px solid rgba(169, 169, 169, 0.87)' borderRadius='5px'>
                    {compatibleProductOrderCodes.toString()}
                    <Button
                        right='10px'
                        position='absolute'
                        height="24px"
                        width="24px"
                        backgroundColor="#FFE68D"
                        onClick={onOpenProducts}>
                        <Edit_Modal/>
                    </Button>
                </Flex>
            </FormControl>
        </GridItem>
        <GridItem colSpan={1}>
            <TextareaLabel minH='150px' label="Notes:" {...register('notes')}/>
        </GridItem>
    </Grid>
)

export default SecondaryFields
