import React, {useState, useEffect} from 'react'
import {Link, useHistory, useParams} from 'react-router-dom'
import {useFieldArray, useForm} from 'react-hook-form'
import {Flex, Text, Box, Tabs, TabList, Tab, TabPanels, TabPanel, Button} from '@chakra-ui/react'
import Navbar from '../../components/Navbar/Navbar'
import {useUser, useUpdateProject, useGetProject} from '../../core/hooks'
import styles from '../CreateProject/styles'
import Files from './Files'
import Areas from '../CreateProject/Areas'
import Property from '../CreateProject/Property'
import Loading from "../../components/Loading";

const EditProject = () => {
    const [propertyPriorities, setPropertyPropertyPriorities] = useState({price: 0, time: 0, cost: 0, security: 0, human: 0, other: 0,})
    const [changeLightCountEstimate, setChangeLightCountEstimate] = useState('')
    const {formState: {errors}, register, reset, handleSubmit, setValue, watch, control, getValues,} = useForm({
        defaultValues: {
            image: '',
            areas: [],
            requestLightCount: false,
            lightCountEstimate: '',
            files: [],
            beFinancing: 'no',
        },
    })
    const [opDayError, setOpDayError] = useState(false)
    const {fields: areas, append, remove} = useFieldArray({control, name: "areas",});
    const image = watch('image', '')
    const propertySize = watch('propertySize','')
    const requestLightCount = watch('requestLightCount', false)
    const lightCountEstimate = watch('lightCountEstimate', '')
    const history = useHistory()
    const {user} = useUser()
    const params = useParams()
    const [beFinancing, setBefinancing] = useState('')
    const [project, setProject] = useState('')
    const {data: projectResponse, isLoading: isLoadingProject, refetch} = useGetProject(params.id)
    const {mutate: updateProject, isLoading} = useUpdateProject()
    const allAreas = watch('areas', '')
    const [companySync, setCompanySync] = useState('')
    const [salesTax, setSalesTax] = useState(getValues('salesTax'))
    const [interestRate, setInterestRate] = useState(getValues('interestRate'))

    const [error, setError] = useState('')
    const onSubmit = (e) => {
        if (Number(getValues('operationDaysPerYear')) > 365) {
            setOpDayError(true)
            return setError('Operation Days Per Year cannot exceed 365')
        }
        setOpDayError(false)
        let costPKW
        if (getValues('costPerKilowattHour')[0] === '.') {
            costPKW = '0' + getValues('costPerKilowattHour')
        } else {
            costPKW = getValues('costPerKilowattHour')
        }
        if (error) return
        setError('')
        const {...newData} = e
        const input = {
            areas: e.areas,
            ...newData,
            image: image,
            costPerKilowattHour: costPKW,
            lightCountEstimate: lightCountEstimate,
            salesTax: salesTax,
            interestRate: interestRate,
            companySync: companySync,
            status: project?.status,
            propertyPriorities: [JSON.stringify(propertyPriorities)],
        }
        updateProject(
            input,
            {
                onSuccess: () => {
                    if (user?.type === 'SUPERADMIN' || user?.type === 'Sa'){
                        history.goBack()
                    } else {
                        history.push('/projects?success=true')
                    }
                },
            },
        )
    }
    useEffect(() => {
        if (!projectResponse) return
        setProject(projectResponse.data.data[0])
        if (user?.type !== 'Reu'){
            setCompanySync(projectResponse.data.data[0].project?.company)
        }
        reset(projectResponse.data.data[0].project)
        setPropertyPropertyPriorities(JSON.parse(projectResponse.data.data[0].project.propertyPriorities))
        setBefinancing(projectResponse.data.data[0].project.beFinancing)
    }, [projectResponse])

    if (isLoadingProject) return <Loading />

    return (
        <form noValidate onSubmit={handleSubmit(onSubmit)}>
            <Navbar minW="1240px" title={(
                    <Flex>
                        <Link to="/projects">Projects&nbsp;</Link>
                        <Text as='h2' m={0}>/ Edit Project</Text>
                    </Flex>)}>
                <Button display={user?.type === 'Eu' ? 'none' : 'flex'} bg="#C2EF62" color="black" mr={8} ml={12} px={8} isLoading={isLoading} w="135px" type="submit" h="40px">
                    <img src="/images/done.png" alt="done"/>
                    <Text fontWeight="700" ml={1} mt="2px">Done</Text>
                </Button>
            </Navbar>
            <Box m="auto" minW="1240px" maxW="1240px">
                <Tabs css={styles.container} bg="#F5F5F5">
                    <Flex mr="-1px" bg="white.100" justifyContent="space-between" alignItems="flex-end">
                        <TabList mx="-1px" border="none" pt={10} bg="white.100">
                            <Tab minW="201px" fontFamily='Titillium Web' fontWeight="400" fontSize='xl' _active={{background: 'transparent',}} _selected={{color: 'green.200',borderBottomWidth: '4px', borderColor: 'green.200', boxShadow: 'none', fontWeight: '700'}} color="green.200">
                                Property
                            </Tab>
                            <Tab minW="201px" fontFamily='Titillium Web' fontWeight="400" fontSize='xl' _active={{background: 'transparent',}} _selected={{borderColor: 'green.200',borderBottomWidth: '4px', boxShadow: 'none',fontWeight: '700'}} color={!!errors.areas ? 'red.100' : 'green.200'}>
                                Luminaires
                            </Tab>
                            <Tab minW="201px" fontFamily='Titillium Web' fontWeight="400" fontSize='xl' _active={{background: 'transparent',}} _selected={{color: 'green.200', borderBottomWidth: '4px', borderColor: 'green.200', fontWeight: '700', boxShadow: 'none',}} color="green.200">
                                Uploads
                            </Tab>
                        </TabList>
                    </Flex>
                    <TabPanels pt={6} px={10} borderTop="1px solid #DBDADA" borderBottom="none">
                        <TabPanel>
                            <Property
                                setInterestRate={setInterestRate}
                                interestRate={interestRate}
                                propertySize={propertySize}
                                setError={setError}
                                setSalesTax={setSalesTax}
                                salesTax={salesTax}
                                error={error}
                                companySync={companySync}
                                setCompanySync={setCompanySync}
                                opDayError={opDayError}
                                beFinancing={beFinancing}
                                setBeFinancing={setBefinancing}
                                requestLightCount={requestLightCount}
                                lightCountEstimate={changeLightCountEstimate}
                                setLightCountEstimate={setChangeLightCountEstimate}
                                getValues={getValues}
                                setValue={setValue}
                                image={image}
                                setPropertyPriorities={setPropertyPropertyPriorities}
                                propertyPriorities={propertyPriorities}
                                register={register}
                                errors={errors}/>
                        </TabPanel>
                        <TabPanel position="relative">
                            <Areas
                                getValues={getValues}
                                watch={watch}
                                setValue={setValue}
                                errors={errors}
                                register={register}
                                allAreas={allAreas}
                                remove={remove}
                                areas={areas}
                                append={append}/>
                        </TabPanel>
                        <TabPanel>
                            <Files
                                getValues={getValues}
                                setValue={setValue}/>
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </Box>
        </form>
    )
}

export default EditProject
