import {Grid, GridItem} from "@chakra-ui/react";
import {SPECIALITIES} from "../../../constants";
import {Incrementer} from "../index";

const Specialities = (props) => (
    <Grid templateColumns="repeat(2, 1fr)" gap={5}>
        {SPECIALITIES.map((interest) => (
            <GridItem key={interest.title} colSpan={1}>
                <Incrementer
                    plus={props?.plus}
                    score={props.score}
                    setScore={props.setScore}
                    title={interest.title}
                    color={interest.active}/>
            </GridItem>
        ))}
    </Grid>
)

export default Specialities