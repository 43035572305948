import {useEffect, useState} from 'react'
import {useHistory} from 'react-router-dom'
import {
    Flex, useDisclosure, Tabs, TabList, Tab, TabPanel, TabPanels, useToast,
} from '@chakra-ui/react'
import Navbar from '../../components/Navbar'
import {useUser, useUpload, useUserProgress, useUpdateUser, useValidateEmail} from '../../core/hooks'
import Details from './EditProfileParts/Details'
import {S3_BUCKET} from '../../constants'
import ChangePassword from './EditProfileParts/ChangePassword'
import Header from './EditProfileParts/Header'
import {useForm} from 'react-hook-form'
import Interests from './EditProfileParts/Interests'
import DoneButton from "../../components/Theme/Buttons/DoneButton";
import mainStyles from "../../styles/mainStyles";
import {
    InitialEnvironmentUser,
    InitialIlluminationUser, InitialPreferences,
    InitialScoreUser,
    InitialTechnologyUser,
    InitialValuationUser
} from "../../constants/chartDatas";
import ToastComponent from "../../components/toastComponent/ToastComponent";
import {ReactComponent as CloudIcon} from "../../assets/Icons/cloudIcon.svg";
import CommuniactionPreferences from "../../components/User/CommuniactionPreferences";
import {
    AU, EU,
    InitialPreferencesAu,
    InitialPreferencesReuSales, InitialPreferencesSuEu, InitialPreferencesSuperadmin,
    REU,
    SA,
    SU,
    SUPERADMIN
} from "../../constants/user/userEnum";
import CommuniactionPreferencesAdmin from "../../components/User/CommunicationPreferncesAdmin";

const Profile = () => {
    const history = useHistory()
    const toast = useToast()
    const {user, setUser} = useUser()
    const [company, setCompany] = useState()
    const {mutate: updateUser, isLoading} = useUpdateUser()
    const {upload, data, isLoading: isLoadingUpload} = useUpload()
    const {isOpen, onClose, onOpen} = useDisclosure()
    const newArray = useUserProgress(user)
    const [avatar, setAvatar] = useState(user?.avatar ? S3_BUCKET + user.avatar : '')
    const [complete, setComplete] = useState([])
    const {register, formState: {errors}, handleSubmit, reset, watch, setValue} = useForm()
    const [score, setScore] = useState(user.primaryInterests ? JSON.parse(user.primaryInterests) : InitialScoreUser)
    const [illumination, setIllumination] = useState(user.profileFeatures ? JSON.parse(user.profileFeatures).illumination : InitialIlluminationUser)
    const [technology, setTechnology] = useState(user.profileFeatures ? JSON.parse(user.profileFeatures).technology : InitialTechnologyUser)
    const [valuation, setValuation] = useState(user.profileFeatures ? JSON.parse(user.profileFeatures).valuation : InitialValuationUser)
    const [environment, setEnvironment] = useState(user.profileFeatures ? JSON.parse(user.profileFeatures).environment : InitialEnvironmentUser)
    const validateEmail = useValidateEmail()
    const phone = watch('phone')
    const [communicationPreferences, setCommunicationPreferences] = useState(user?.communicationPreferences ? JSON.parse(user.communicationPreferences) : InitialPreferences)

    const submit = (dataForm) => {
        const input = {
            id: user.id,
            firstName: dataForm.firstName,
            lastName: dataForm.lastName,
            title: dataForm.title,
            phone: dataForm.phone,
            avatar: data || user.avatar,
            communicationPreferences: JSON.stringify(communicationPreferences),
            primaryInterests: JSON.stringify(score),
            profileFeatures: JSON.stringify({
                illumination,
                technology,
                valuation,
                environment,
            }),
        }
        setUser({...user, ...input,})
        updateUser(input,
            {
                onSuccess: () => {
                    toast({
                        render: () => (
                            <ToastComponent icon={<CloudIcon />} title='Your Profile information was submitted!' bg='green.200'/>
                        ),
                        duration: 4000,
                        isClosable: true,
                        position: "top-right"
                    })
                    history.push('/profile')
                },
            },
        )
    }


    useEffect(() => {
        if (!user) return
        setComplete(newArray)
        reset(user)
        if (user?.type === AU){
            setCommunicationPreferences(user?.communicationPreferences ? JSON.parse(user.communicationPreferences) : InitialPreferencesAu)
        } else if (user?.type === REU || user?.type === SA){
            setCommunicationPreferences(user?.communicationPreferences ? JSON.parse(user.communicationPreferences) : InitialPreferencesReuSales)
        }else if (user?.type === SU || user?.type === EU){
            setCommunicationPreferences(user?.communicationPreferences ? JSON.parse(user.communicationPreferences) : InitialPreferencesSuEu)
        } else {
            setCommunicationPreferences(user?.communicationPreferences ? JSON.parse(user.communicationPreferences) : InitialPreferencesSuperadmin)
        }
        if (user?.company?.length > 0) {
            setCompany(user.company[0])
        }
        if (user?.associatedToCompany) {
            setCompany(user.associatedToCompany)
        }
    }, [user])

    return (
        <>
            <ChangePassword
                isOpen={isOpen}
                onClose={onClose}/>
            <form noValidate onSubmit={handleSubmit(submit)}>
                <Navbar title="Edit Profile">
                    <DoneButton isLoading={isLoading}/>
                </Navbar>
                <Flex  css={mainStyles.container}>
                    <Header
                        setValue={setValue}
                        processing={isLoadingUpload}
                        onOpen={onOpen}
                        complete={complete}
                        setAvatar={setAvatar}
                        upload={upload}
                        avatar={avatar}/>
                    <Tabs>
                        <TabList css={mainStyles.tabNames}>
                            <Tab>Profile</Tab>
                        </TabList>
                        <TabPanels css={mainStyles.tabPanels}>
                            <TabPanel>
                                <Details
                                    company={company}
                                    validateEmail={validateEmail}
                                    setValue={setValue}
                                    phone={phone}
                                    errors={errors}
                                    register={register}
                                    user={user}/>
                                <Interests
                                    setIllumination={setIllumination}
                                    illumination={illumination}
                                    setScore={setScore}
                                    score={score}
                                    setEnvironment={setEnvironment}
                                    environment={environment}
                                    setValuation={setValuation}
                                    valuation={valuation}
                                    setTechnology={setTechnology}
                                    technology={technology}
                                    edit={true}/>
                                {
                                    user?.type === SUPERADMIN ? (
                                        <CommuniactionPreferencesAdmin
                                            userType={user?.type}
                                            communicationPreferences={communicationPreferences}
                                            setCommunicationPreferences={setCommunicationPreferences}
                                        />
                                    ) : (
                                        <CommuniactionPreferences
                                            userType={user?.type}
                                            communicationPreferences={communicationPreferences}
                                            setCommunicationPreferences={setCommunicationPreferences}
                                        />
                                    )
                                }

                            </TabPanel>
                        </TabPanels>
                    </Tabs>
                </Flex>
            </form>
        </>
    )
}

export default Profile
