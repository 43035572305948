import {Flex, Box, Heading, Text, Image} from '@chakra-ui/react'
import {ChartUserComplete} from "../../../components/Theme";
import styles from '../styles'

const Header = (props) => (
    <Flex css={styles.profileHeader}>
        <Flex className='header-main'>
            <Flex className='header-image'>
                {props.avatar && (<Image src={props.avatar} alt="user avatar"/>)}
            </Flex>
            <Box className='header-name'>
                <Heading as="h2">{props.user.firstName}{' '}{props.user.lastName}</Heading>
            </Box>
        </Flex>
        <Flex className='header-chart'>
            <Text>{props.complete[0]}% Completed!</Text>
            <ChartUserComplete
                complete={props.complete}/>
        </Flex>
    </Flex>
)

export default Header