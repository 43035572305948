const Switch = {
    baseStyle: {
        track: {
            bg: '#C5C5C5',
            _focus: {
                  boxShadow: 'none'
            },
            _checked: {
                bg: '#30A300',
            },
            alignItems: 'center',
            paddingLeft: '5px',
            width: '43px',
            height: '24px',
        },
        thumb: {
            bg: 'white',
            width: '16px',
            height: '16px',
            _checked: {
                transform: 'translateX(24px)',
            },
        },
    },
    sizes: {
        md: {
            track: {
                width: '43px',
                height: '24px',
            },
            thumb: {
                width: '16px',
                height: '16px',
            },
        },
    },
    variants: {
        secondary: {
            track: {
                _checked: {
                    bg: '#FB7575',
                },
            }
        }
    }
};

export default Switch;