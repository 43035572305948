import { useMutation } from 'react-query'
import {productUseCase} from '../../factories'

const useUpdateProduct = () => {
    const {
        mutate: updateProduct, isLoading, data, error,
    } = useMutation(productUseCase.updateProduct)

    return {
        updateProduct,
        isLoading,
        error,
        data,
    }
}
export default useUpdateProduct
