import React, {useEffect, useState} from 'react'
import {
    Heading,
    Text,
    GridItem,
    SimpleGrid,
} from '@chakra-ui/react'
import {Card} from '../../components/Theme'
import HeaderForCatalogueAdmins from "./HeaderForCatalogueAdmins";
import {useListAirbaleProducts} from "../../core/hooks";
import Loading from "../../components/Loading";

const AccessoriesCatalogue = () => {
    const [offset, setOffset] = useState(null)
    const [fetchedData, setFetchedData] = useState([])
    const {data, isLoading, refetch} = useListAirbaleProducts({
        table: 'Accessories',
        filters: '',
        offset,
    })

    useEffect(() => {
        if (!data) return
        setFetchedData([...fetchedData, ...data.records])
        if (data.offset) {
            setOffset(data.offset)
        }
    }, [data])

    useEffect(() => {
        if (!offset) return
        refetch()
    }, [offset])

    const handleSearch = (e) => {
        setFetchedData(data.records?.filter((item) => (item.fields['Product Type']).toLowerCase().includes(e.target.value.toLowerCase())))
    }

    if (isLoading) return <Loading />

    return (
        <>
            <HeaderForCatalogueAdmins
                handleSearch={handleSearch}
                title=' LEDs Inc. / Interior'/>
            <SimpleGrid
                p="12"
                w="full"
                columns={{
                    base: 1,
                    sm: 2,
                    md: 3,
                    lg: 4,
                }}
                spacing="8">
                {
                    fetchedData?.map((product) => (
                        <GridItem
                            colSpan={1}
                            key={product.id}>
                            <Card
                                image={product.fields['Image'] ? product.fields['Image'][0]?.url : ''}>
                                <Heading
                                    fontSize="md"
                                    as="h3">
                                    {product.fields['Product Type']}
                                </Heading>
                                <Text
                                    fontSize="sm">
                                    {product.fields['Description']}
                                </Text>
                            </Card>
                        </GridItem>
                    ))
                }
            </SimpleGrid>
        </>
    )
}

export default AccessoriesCatalogue