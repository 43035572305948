const productUseCase = (productRepo) => {
    const createProduct = (input) => productRepo.createProduct(input) // NO VALIDATIONS NEED
    const updateProduct = (input) => {
        if (!input) {
            throw Error('ID is required')
        }
        return productRepo.updateProduct(input)
    }

    const getProduct = (id) => {
        if (!id) {
            throw Error('ID is required')
        }

        return productRepo.getProduct(id)
    }
    const listProducts = (variables) => productRepo.listProducts(variables)

    const getRecommended = (variables) => productRepo.getRecommended(variables)

    const getCustomBids = (variables) => productRepo.getCustomBids(variables)

    const updatePriceBid = (input) => productRepo.updatePriceBid(input)

    return {
        updatePriceBid,
        getRecommended,
        getCustomBids,
        createProduct,
        updateProduct,
        getProduct,
        listProducts,
    }
}

export default productUseCase
