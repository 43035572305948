import React, {useEffect, useState} from 'react'
import {useHistory, useParams} from 'react-router-dom'
import {Box, Button, Text, useDisclosure, useToast,} from '@chakra-ui/react'
import TableWrapper from '../Overview/TableWrapper'
import Accordion from '../Overview/Accordion'
import Navbar from '../Navbar'
import {useCreateReport, useGetReport,} from '../../core/hooks'
import {ReactComponent as Unfinished} from "../../assets/unfinishedReport.svg";
import Area from "../Overview/Area";
import BiddingOpenAcceptModal from "./ReportModals/BiddingOpenAcceptModal";
import BiddingCloseModal from "./ReportModals/BiddingCloseModal";
import InstallationBidSelectModal from "./ReportModals/InstallationBidSelectModal";
import httpRequest from "../../core/services/http-request";
import {ReactComponent as SendMessage} from "../../assets/unfinishedIcon.svg";
import BiddingDone from "./ReportModals/BiddingDone";
import Loading from "../Loading";
import DeleteReportModal from "./ReportModals/DeleteReportModal";
import {ReactComponent as DoneIcon} from "../../assets/Icons/DoneIcon.svg";
import mainStyles from "../../styles/mainStyles";
import Header from "../Overview/Parts/Header";
import ToastComponent from "../toastComponent/ToastComponent";

function OverviewReport() {
    const toast = useToast()
    const params = useParams()
    const history = useHistory()
    const {post} = httpRequest()
    const [errors, setErrors] = useState()
    const [recent, setRecent] = useState([])
    const [recentProposal, setRecentProposal] = useState([])
    const {isOpen: isOpenDone, onOpen: onOpenDone, onClose: onCloseDone} = useDisclosure()
    const {isOpen: isOpenBiddingClose, onOpen: onOpenBiddingClose, onClose: onCloseBiddingClose} = useDisclosure()
    const {isOpen: isOpenBiddingAccept, onOpen: onOpenBiddingAccept, onClose: onCloseBiddingAccept} = useDisclosure()
    const {isOpen: isOpenInstallation, onOpen: onOpenInstallation, onClose: onCloseInstallation} = useDisclosure()
    const {isOpen: isOpenDelete, onOpen: onOpenDelete, onClose: onCloseDelete} = useDisclosure()
    const [vale, setVale] = useState([])
    const [areaTitle, setAreaTitle] = useState([])
    const [report, setReport] = useState({proposal: '', status: '', installationEstimate: '', notes: '', areas: [],})
    const [area, setArea] = useState([])
    const [selectedBid, setSelectedBid] = useState([])
    const [loading, setLoading] = useState(false)
    const [loadingPage, setLoadingPage] = useState(false)
    const [loadingDelete, setLoadingDelete] = useState(false)
    const [project, setProject] = useState([])
    const {isLoading: isReportLoading, data: reportResponse, createReport} = useCreateReport()
    const {data: reportDataResponse, refetch} = useGetReport(params.id)
    const [customPricing, setCustomPricing] = useState({
        fixture: false,
        lamp: false,
        control: false,
        power: false,
        accessory: false,
    })

    const handelSubmit = () => {
        if (!area) return
        let can = false
        for (let i = 0; i < area.length; i++) {
            for (let j = 0; j < area[i].luminaires.length; j++) {
                if (area[i].luminaires[j].lampRecomendedAirtable || area[i].luminaires[j].fixtureRecomendedDescription
                    || area[i].luminaires[j].powerSupplyRecomendedDescription || area[i].luminaires[j].controlRecomendedDescription || area[i].luminaires[j].accessoryRecomendedDescription) {
                    can = true
                    break
                }
            }
        }
        if (!can) {
            return setErrors('Chose recommended lamp')
        }
        setErrors('')
        setLoading(true)
        if (recentProposal) {
            const input = {
                projectId: project.id,
                customPricing: customPricing,
                proposalName: report.proposal !== '' ? report.proposal : recentProposal.proposalName,
                proposal: area.filter((filtered) => vale.includes(filtered.title)),
                installation: selectedBid ? selectedBid : '',
                recentProposal: recentProposal.id,
                notes: report?.notes ? report?.notes : recentProposal.notes,
            }
            createReport(
                input,
                {
                    onSuccess: (data) => {
                        setLoading(false)
                        toast({
                            render: () => (
                                <ToastComponent bg='#FCA68B' icon={<SendMessage/>} title={`${input.proposalName} Marked As Unfinished...`}/>
                            ),
                            duration: 4000,
                            isClosable: true,
                            position: "top-right"
                        })
                        history.push(`/projects/proposal/${data.data.data.proposalName}/${params.projectId}/${params.id}`)
                        window.location.reload()
                    },
                },
            )
        } else {
            const input = {
                projectId: project.id,
                customPricing: customPricing,
                proposalName: report.proposal ? report.proposal : '',
                proposal: area ? area : '',
                installation: selectedBid ? selectedBid : '',
                notes: report?.notes ? report?.notes : recentProposal.notes,
            }
            createReport(input,
                {
                    onSuccess: (data) => {
                        setLoading(false)
                        history.push(`/projects/proposal/${data.data.data.proposalName}/${params.projectId}/${params.id}`)
                        window.location.reload()
                    },
                },
            )
        }
    }

    const reportDone = () => {
        if (!area) return
        if (!selectedBid) {
            return setErrors('Choose bid')
        }
        setErrors('')
        setLoading(true)
        const input = {
            projectId: project.id,
            customPricing:customPricing,
            proposalName: report.proposal !== '' ? report.proposal : recentProposal.proposalName,
            proposal: area.filter((filtered) => vale.includes(filtered.title)),
            installation: selectedBid ? selectedBid : '',
            recentProposal: recentProposal.id,
            notes: report?.notes ? report?.notes : '',
        }
        post("report-done", {input}).then((data) => {
            setLoading(false)
            onOpenDone()
            setTimeout(() => {
                history.push(`/projects/proposal/${data.data.data.proposalName}/${params.projectId}/${params.id}`)
                window.location.reload()
            }, 2000)
        })
    }

    useEffect(() => {
        if (!reportDataResponse) return
        let selectedNames = []
        let res = []
        let report = reportDataResponse.data.data
        setRecentProposal(report)
        setProject(report?.project)
        const areas = report?.project?.areas
        const newArray = []
        for (let i = 0; i < report?.proposal?.length; i++) {
            selectedNames.push(report.proposal[i].title)
        }
        for (let i = 0; i < areas.length; i++) {
            newArray.push(areas[i].title)
            if (!selectedNames.includes(areas[i].title)){
                res.push(areas[i])
            }
        }
        setAreaTitle(newArray)
        setVale(selectedNames)
        if (report.customPricing){
            setCustomPricing(report.customPricing)
        }
        setReport({...report, proposal: report.proposalName})
        setArea([...res, ...report.proposal])
        const newArrayRecent = report?.proposal?.map((e) => e?.luminaires.map((luminaire) => {
            return {...luminaire}
        }))
        setRecent([...newArrayRecent])
    }, [reportDataResponse])

    const deleteReport = () => {
        setLoadingDelete(true)
        post('delete-report', recentProposal.id).then(() => {
            setLoadingDelete(false)
        }).catch(() => setLoadingDelete(false))
        onCloseDelete()
        history.push(`/project/${params.projectId}`)
    }

    useEffect(() => {
        if (!recentProposal) return
        setSelectedBid(recentProposal?.installation)
    }, [recentProposal])


    if (loadingPage || loading) return <Loading />

    return (
        <Box d="flex" flexDirection="column" alignItems="flex-end" minH="100vh" bg="white.100" minW="900px" overflowX="hidden">
            <BiddingDone
                isOpen={isOpenDone}
                onClose={onCloseDone}/>
            <InstallationBidSelectModal
                getReport={refetch}
                selectedBid={selectedBid}
                setSelectedBid={setSelectedBid}
                recentProposal={recentProposal}
                project={project}
                isOpen={isOpenInstallation}
                onClose={onCloseInstallation}/>
            <BiddingCloseModal
                getReport={refetch}
                id={project?.id}
                isOpen={isOpenBiddingClose}
                onClose={onCloseBiddingClose}/>
            <DeleteReportModal
                history={history}
                loading={loadingDelete}
                deleteReport={deleteReport}
                onClose={onCloseDelete}
                isOpen={isOpenDelete}/>
            <BiddingOpenAcceptModal
                getReport={refetch}
                project={project}
                recentProposal={recentProposal}
                id={project?.id}
                isOpen={isOpenBiddingAccept}
                onClose={onCloseBiddingAccept}/>
            <Navbar title="Report">
                <Button
                    color='black'
                    isLoading={loading}
                    onClick={handelSubmit}
                    leftIcon={<Unfinished/>}
                    backgroundColor={Object.values(customPricing).some(value => value === true) ? '#FFE68D' : '#FFA183'}
                    width="135px"
                    mr='20px'>
                    {Object.values(customPricing).some(value => value === true) ? 'Request Pricing ' : 'Unfinished'}
                </Button>
                <Button
                    color='black'
                    width="135px"
                    isLoading={loading}
                    disabled={Object.values(customPricing).some(value => value === true)}
                    onClick={reportDone}
                    leftIcon={<DoneIcon/>}
                    backgroundColor={Object.values(customPricing).some(value => value === true) ? '#A9A9A9' : '#C2EF62'}>
                    Done
                </Button>
            </Navbar>
            <Box css={mainStyles.container} px='40px' pt='20px'>
                {errors && (<Text textAlign='center' color="red.100" fontSize="m">{errors}</Text>)}
                <Header
                    areaTitle={areaTitle}
                    edit={true}
                    onOpenBiddingAccept={onOpenBiddingAccept}
                    onOpenBiddingClose={onOpenBiddingClose}
                    recentProposal={recentProposal}
                    setVale={setVale}
                    vale={vale}
                    loading={loading}
                    report={report}
                    onOpenDelete={onOpenDelete}
                    onOpenInstallation={onOpenInstallation}
                    selectedBid={selectedBid}
                    setReport={setReport}/>
                <TableWrapper>
                    <Accordion
                        reportId={report?.id}
                        customPricing={customPricing}
                        setCustomPricing={setCustomPricing}
                        vale={vale}
                        setRecent={setRecent}
                        recent={recent}
                        area={area}
                        setArea={setArea}
                        project={project}/>
                    <Box>
                        {vale && (
                            area?.filter((item) => vale.includes(item.title)).map((item, idx) => (
                                <Area
                                    reportId={report?.id}
                                    customPricing={customPricing}
                                    setCustomPricing={setCustomPricing}
                                    recentProposal={recentProposal}
                                    project={project}
                                    key={item.id}
                                    area={area}
                                    setArea={setArea}
                                    idx={idx}
                                    setRecent={setRecent}
                                    recent={recent}
                                    data={item}
                                    areaName={item.title}
                                    areas={item}/>
                            ))
                        )}
                    </Box>
                </TableWrapper>
            </Box>
        </Box>
    )
}

export default OverviewReport