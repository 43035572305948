import React, {useState} from 'react'
import {Box, Flex, useDisclosure, Text, Button, Stack, useToast,} from '@chakra-ui/react'
import {InviteModal, Input,} from '../../../components/Theme'
import {useInviteUser, useUser} from "../../../core/hooks";
import {ReactComponent as InvitationIcon} from "../../../assets/invitationSendIcon.svg";
import ToastComponent from "../../../components/toastComponent/ToastComponent";

const UserModal = ({onCloseInvite}) => {
    const {user} = useUser()
    const toast = useToast()
    const {isOpen, onClose, onOpen} = useDisclosure()
    const [email, setEmail] = useState('')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [error, setError] = useState('')
    const {inviteUser, isLoading} = useInviteUser()

    const sendInvite = () => {
        if (email === '') {
            return setError('Write Email')
        }
        if (firstName === '') {
            return setError('Write First Name')
        }
        if (lastName === '') {
            return setError('Write Last Name')
        }
        setError('')
        inviteUser({
                receiver: email,
                sender: user?.email,
                senderName: firstName,
                senderLastName: firstName,
                url: `${process.env.REACT_APP_API_ENDPOINT.replace("api/", "register/Reu")}`,
            },
            {
                onSuccess: () => {
                    toast({
                        render: () => (
                            <ToastComponent icon={<InvitationIcon/>} title={`Invitation sent ${firstName} ${lastName}!`} bg='#2C628D'/>
                        ),
                        duration: 4000,
                        isClosable: true,
                        position: "top-right"
                    })
                    onClose()
                    onCloseInvite()
                },
                onError: (e) => {
                    if (e.response.data.errorMessage === 1109) {
                        setError('*This email is already active in the system.')
                    }
                },
            })
    }

    return (
        <Box>
            <Text mb="4rem" fontSize="18px">
                {user?.type === 'SUPERADMIN' ? 'Don’t see them? ' : 'Don’t see your colleague? '}
                <Button fontSize="18px" variant="link" color="#C956FF" onClick={onOpen}>Invite new user</Button>
            </Text>
            <InviteModal title="Add New User" isOpen={isOpen} onClose={onClose}>
                <Flex alignItems="center" flexDirection="column">
                    <Text my={12} fontSize="3xl">
                        Invite a new user to join the Energy Portal.
                    </Text>
                    <Stack mx="auto" w="418px" spacing={4}>
                        <Input onChange={(e) => setFirstName(e.target.value)} placeholder="First Name"/>
                        <Input onChange={(e) => setLastName(e.target.value)} placeholder="Last Name"/>
                        <Input onChange={(e) => setEmail(e.target.value)} placeholder="Contact Email"/>
                        {error && (<Text color="red.100" fontSize="m">{error}</Text>)}
                    </Stack>
                    <Button mx="auto" mt={10} onClick={sendInvite} fontSize='18px' mb="2.8rem" isLoading={isLoading} borderRadius="5px" width="254px">
                        Submit
                    </Button>
                </Flex>
            </InviteModal>
        </Box>
    )
}

export default UserModal
