import {useState} from 'react'
import {CompanyContext} from '../contexts'

function CompanyProvider(props) {
    const [company, setCompany] = useState()
    const {children} = props

    // useEffect(() => {
    //     companyUseCase
    //         .getCompany(id)
    //         .then((e) => {
    //             setCompany(e.data.data)
    //         })
    //         .catch(() => {
    //             setCompany(null)
    //         })
    // }, [])

    return (
        <CompanyContext.Provider value={{company, setCompany,}}>
            {children}
        </CompanyContext.Provider>
    )
}

export default CompanyProvider
