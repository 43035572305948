import {InviteModal} from "../../../components/Theme";
import {Text, VStack,Select, HStack, Button} from "@chakra-ui/react";
import TextLabel from "../../../components/Theme/TextLabel";

const DeleteUserFromCompanyModal = ({isOpen, onClose, company, onOpenConfirmation,userId, setUserId}) => (
        <InviteModal
            isOpen={isOpen}
            onClose={onClose}
            bg="#FF9494"
            title="Sever User From Company">
            <VStack py={8} maxW='418px' m='auto' spacing={5}>
                <Text fontSize='3xl'>
                    Select User To Sever From Company:
                </Text>
                <TextLabel
                    mt={4}
                    pl='30px'
                    color='#5A5A5ADE'
                    fontFamily='Titillium Web'
                    bg='#DADADA'
                    text='Estates Inc.'/>
                <Select
                    style={{paddingLeft: "30px"}}
                    size='lg'
                    _focus=''
                    onChange={(e) => setUserId(e.target.value)}
                    _active=''
                    borderColor='#A9A9A9'
                    placeholder='Select'>
                    {company?.owner && <option value={company?.owner?.id}>{company?.owner?.firstName + company?.owner?.lastName}</option>}
                    {company?.usersAssociated?.map((item, index) => (
                        <option key={index} value={item.id}>{item?.firstName + ' ' + item?.lastName}</option>
                    ))}
                </Select>
            </VStack>
            <HStack pb={8} justifyContent='center'>
                <Button onClick={onClose} height='50px' fontSize='xl' width='254px' color='black' bg='#DBDADA'>
                    Nevermind go back
                </Button>
                <Button onClick={onOpenConfirmation} disabled={!userId}  height='50px' fontSize='xl' width='254px' color='black' bg='#FB7575'>
                    Sever
                </Button>
            </HStack>
        </InviteModal>
    )
export default DeleteUserFromCompanyModal