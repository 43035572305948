import {Stack, HStack} from '@chakra-ui/react'
import {TextLabel} from "../../../components/Theme";

const CompanyViewDetails = ({company}) => (
    <Stack spacing='6'>
        <HStack spacing="6">
            <TextLabel
                bg='transparent'
                text={company?.address}
                label="Address:"/>
            <TextLabel
                bg='transparent'
                text={company?.address2}
                label="Address Line 2:"/>
        </HStack>
        <HStack spacing="6">
            <HStack spacing="6" flex="1">
                <TextLabel
                    bg='transparent'
                    text={company?.city}
                    label="City:"/>
                <TextLabel
                    bg='transparent'
                    maxW='100px'
                    text={company?.state}
                    label="State:"/>
                <TextLabel
                    bg='transparent'
                    text={company?.zipCode}
                    label="Zip Code:"/>
            </HStack>
            <HStack flex="1">
                <TextLabel
                    bg='transparent'
                    text="USA"
                    label="Country:"/>
            </HStack>
        </HStack>
        <HStack spacing='6'>
            <TextLabel
                bg='transparent'
                text={(company?.owner ? company?.owner?.firstName + ' ' + company?.owner?.lastName + ', ' : '') + company?.usersAssociated?.map((item) => item.firstName + ' ' + item.lastName).toString()}
                label="Associated Users:"/>
            <TextLabel
                bg='transparent'
                text={company?.website}
                label="Website:"/>
        </HStack>
        <TextLabel
            bg='transparent'
            text={company?.notes}
            label="Notes:"/>
    </Stack>

)

export default CompanyViewDetails