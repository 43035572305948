import {css} from '@emotion/react'

const styles = {
    tBody: css`
        & > tr > td:first-of-type {
            color: black;
        }

        & > tr > td {
            padding-top: 0;
            padding-bottom: 5px;
            font-size: 14px;
        }
    `,
    accordionButtons: css`
        background-color: white;
        min-height: 52px;
        border: 1px solid #C4C4C4;

        &:focus {
            box-shadow: 0 2px 10px 2px rgba(0, 0, 0, 0.45);
        }

        & div {
            min-height: 52px;
        }

        & p {
            font-size: 12px;
        }
    `,
    accordionPanel: css`
        padding: 0;
    `,
    panelQty: css`
        border-radius: 5px;
        justify-content: center;
        align-items: center;
        border: 1px solid #C4C4C4;
        width: 60px;
        font-size: 12px;
        min-height: 30px;
    `,
    panelQtyRecom: css`
        border-radius: 5px;
        justify-content: center;
        align-items: center;
        border: 1px solid #619C48;
        width: 60px;
        font-size: 12px;
        min-height: 30px;
    `,
    panelDescription: css`
        width: 100%;
        justify-content: space-between;
        border-radius: 5px;
        padding-left: 8px;
        padding-right: 8px;
        align-items: center;
        border: 1px solid #C4C4C4;
        min-height: 30px;
        font-size: 14px;
    `,
    panelDescriptionRecom: css`
        width: 100%;
        position: relative;
        justify-content: space-between;
        border-radius: 5px;
        padding-left: 8px;
        padding-right: 8px;
        align-items: center;
        border: 1px solid #619C48;
        min-height: 30px;
        font-size: 14px;
    `,
    editButton: css`
        width: 50px;
        color: black;
        height: 20px;
        background-color: #FFE68D;
    `,
}

export default styles
