import {Stack, HStack, Box, Button, Flex,} from '@chakra-ui/react'
import React from 'react'
import {ReactComponent as LeftIcon} from '../../../assets/left.svg'
import {ReactComponent as RightIcon} from '../../../assets/right.svg'

const NavigateModal = ({left, right, leftAction, rightAction, label,}) => (
    <HStack
        alignItems="center"
        spacing="5">
        <Stack
            w="full">
            <Box
                fontSize="md"
                fontWeight="600"
                color="#2C628D"
                as="span">
                Luminaire Description
            </Box>
            <Flex
                border='1px solid #A9A9A9'
                color='#737373'
                px="2"
                minH='40px'
                overflow="scroll"
                alignItems="center"
                as="span"
                borderRadius="5px"
                bg="#EEEEEE">
                {label}
            </Flex>
        </Stack>
        <Stack
            alignItems="center">
            <HStack
                w="full"
                justifyContent="center"
                position="relative"
                alignItems="center">
                <Box
                    position="absolute"
                    bg="#A9A9A9"
                    w="full"
                    h="1px"/>
                <Box
                    px="2"
                    position="relative"
                    bg="white"
                    fontWeight="600"
                    fontSize="md"
                    color="#2C628D"
                    as="span">
                    Navigate Components
                </Box>
            </HStack>
            <HStack>
                <Button
                    leftIcon={<LeftIcon/>}
                    onClick={leftAction}
                    minW="118px">
                    {left}
                </Button>
                <Button
                    rightIcon={<RightIcon/>}
                    onClick={rightAction}
                    minW="118px">
                    {right}
                </Button>
            </HStack>
        </Stack>
    </HStack>
)

export default NavigateModal
