import React from 'react'
import {
    Stack,
    VStack,
    HStack,
    Button,
    Text,
} from '@chakra-ui/react'
import {InviteModal} from '../../components/Theme'

const DeleteAlert = ({isOpenAlert, onCloseAlert, remove, idx,}) => (
    <InviteModal
        isOpen={isOpenAlert}
        onClose={onCloseAlert}
        bg="#FF9494"
        title="Delete Area">
        <Stack
            py="10"
            spacing="10">
            <VStack
                spacing="5"
                justifyContent="center">
                <Text
                    fontWeight="600"
                    fontSize="24px"
                    textAlign="center">
                    Are you sure you want to delete this area?
                    <br/>
                    All luminaire information in this area
                    <br/>
                    will be deleted as well...
                </Text>
                <HStack
                    spasing="1.25rem">
                    <Button
                        w="230px"
                        height='50px'
                        bg="#DBDADA"
                        fontSize='14px'
                        color="black"
                        fontWeight="700"
                        onClick={onCloseAlert}>
                        Nevermind, Let’s Go Back
                    </Button>
                    <Button
                        fontSize='14px'
                        height='50px'
                        w="230px"
                        bg="#FB7575"
                        color="black"
                        fontWeight="700"
                        onClick={() => {
                            remove(idx)
                            onCloseAlert()
                        }}>
                        Yes, Delete Area
                    </Button>
                </HStack>
            </VStack>
        </Stack>
    </InviteModal>
)

export default DeleteAlert
