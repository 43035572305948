import React, {useState, useMemo, useEffect} from 'react'
import {useHistory, useParams, Link, useLocation,} from 'react-router-dom'
import {useForm} from 'react-hook-form'
import {Box, Tabs, TabList, TabPanels, Tab, TabPanel, Flex, Text, Button, useDisclosure,} from '@chakra-ui/react'
import {useUpload, useGetProduct, useListProducts, useUpdateProduct,} from '../../core/hooks'
import Navbar from '../../components/Navbar/Navbar'
import styles from './styles'
import MainFields from './MainFields'
import SecondaryFields from './SecondaryFields'
import Loading from "../../components/Loading";
import RemoveProjectModal from "../EditProduct/RemoveProductModal";
import {CompatibleProductsModal} from "../../constants/mainFunctions";
import httpRequest from "../../core/services/http-request";

const EditAccessory = () => {
    const {register, handleSubmit, formState: {errors}, reset,} = useForm()
    const params = useParams()
    const history = useHistory()
    const location = useLocation()
    const type = params.type.includes('power') ? 'Power Supplies' : params.type.includes('control') ? 'Controls' : params.type
    const {updateProduct, isLoading: isLoadingUpdate} = useUpdateProduct(params.id)
    const [orderCodesComp, setOrderCodesComp] = useState([])
    const [compatibleProductOrderCodes, setCompatibleProductOrderCodes] = useState([])
    const [image, setImage] = useState('')
    const [certifications, setCertifications] = useState([])
    const [supplierUser, setSupplierUser] = useState({})
    const {upload, data, isLoading: uploadIsLoading,} = useUpload()
    const path = location.pathname.split('/')[2].split('-')[1]
    let condition = {condition: {filter: {orderCode: {recId: params.id}}, table: type.includes('power') ? 'Power%20Supplies' : type.includes('control') ? 'Controls' : 'Accessories'}}
    const {data: productsResponse} = useListProducts(condition)
    const {onOpen, isOpen, onClose} = useDisclosure()
    const [isDeleting, setIsDeleting] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [accessoryResponse, setAccessoryResponse] = useState()
    const {post} = httpRequest()
    const {isOpen: isOpenProducts, onOpen: onOpenProducts, onClose: onCloseProducts} = useDisclosure()

    const getProduct = () => {
        setIsLoading(true)
        post('get-product', {id: params.id, type: type}).then((res) => {
            setAccessoryResponse(res)
            setIsLoading(false)
        }).catch(() => setIsLoading(false))
    }

    useEffect(() => {
       getProduct()
    },[])

    const submit = (data) => {
        const input = {
            ...data,
            image,
            recordId: params.id,
            supplierId: supplierUser.id,
            certifications: certifications,
            companyId: supplierUser?.company?.length > 0 ? supplierUser?.company[0]?.id : supplierUser?.associatedToCompany?.id,
            compatibleProductOrderCodes: compatibleProductOrderCodes,
            type: params.type === 'power-supplies' ? 'power-supply' : params.type === 'accessories' ? 'accessory' : 'control',
        }
        updateProduct(input,
            {
                onSuccess: () => {
                    history.push(`/catalogues/accessory/${params.type}/${params.id}`)
                },
            },
        )
    }

    // Set compatible product order codes for this user
    useEffect(() => {
        if (!productsResponse) return
        let products = productsResponse.data.data
        let compatibleOrderCodes = []

        for (let i = 0; i < products?.length; i++) {
            if (type === 'lamp'){
                compatibleOrderCodes.push(products[i].fields['Order Code (calculated)'])
            } else {
                compatibleOrderCodes.push(products[i].fields['Order Code'])
            }
        }
        setOrderCodesComp(compatibleOrderCodes)
    },[productsResponse])

    useMemo(() => {
        if (!accessoryResponse) return
        let product = accessoryResponse.data.data[0]
        setSupplierUser(accessoryResponse?.data?.data[1][0])
        if (product['Image']){
            setImage(product['Image'][0].url)
        }
        if (product['Certifications']){
            setCertifications(product['Certifications'])
        }
        reset({
            description: product['FE Product Description'] ? product['FE Product Description'] : '',
            notes: product['Notes'] ? product['Notes'] : '',
            manufacturer: product['Manufacturer'] ? product['Manufacturer'] : '',
            orderCode: product['Order Code'] ? product['Order Code'] : '',
            dimensionsLength: product['Length (inches)'] ? product['Length (inches)'] : '',
            wattage: product['Wattage'] ? product['Wattage'] : '',
            dimensionsWidth: product['Width (inches)'] ? product['Width (inches)'] : '',
            dimensionsDiameter: product['Diameter (inches)'] ? product['Diameter (inches)'] : '',
            optics: product['Optics'] ? product['Optics'] : '',
            dimensionsDepth: product['Depth (inches)'] ? product['Depth (inches)'] : '',
            lifeTime: product['Lifetime (hours)'] ? product['Lifetime (hours)'] : '',
            buyAmerica: product['Buy America'] ? product['Buy America'].toString() : '',
            warranty: product['Warranty (years)'] ? product['Warranty (years)'] : '',
            installationTime: product['Approx. Installation Time (minutes)'] ? product['Approx. Installation Time (minutes)'] : '',
            fixtureMount: product['Mount'] ? product['Mount'].toString() : '',
            vMin: product['Voltage (min)'] ? product['Voltage (min)'] : '',
            deliveryDays: product['Approx. Delivery (days)'] ? product['Approx. Delivery (days)'] : '',
            vMax: product['Voltage (max)'] ? product['Voltage (max)'] : '',
            pricePerUnit: product['Price Per Unit ($)'] ? product['Price Per Unit ($)'] : '',
            dimmability: product['Dimmability'] ? product['Dimmability'].toString() : '',
            volumeTier1MinQty: product['Volume Tier 1 (min qty)'] ? product['Volume Tier 1 (min qty)'] : '',
            ingressProtection: product['Ingress Protection'] ? product['Ingress Protection'].toString() : '',
            volumeTier1PPU: product['Volume Tier 1 PPU ($)'] ? product['Volume Tier 1 PPU ($)'] : '',
            volumeTier2MinQty: product['Volume Tier 2 (min qty)'] ? product['Volume Tier 2 (min qty)'] : '',
            volumeTier2PPU: product['Volume Tier 2 PPU ($)'] ? product['Volume Tier 2 PPU ($)'] : '',
            supplierProductDescription: product['Product Description'] ? product['Product Description'] : '',
            installationInstructionsHyperlink: product['Installation Instructions (hyperlink)'] ? product['Installation Instructions (hyperlink)'] : '',
            productSpecificationsHyperlink: product['Specifications (hyperlink)'] ? product['Specifications (hyperlink)'] : '',
            productImageHyperlink: product['Product Image (hyperlink)'] ? product['Product Image (hyperlink)'].toString() : '',
            lampsPoweredQTY: product['Lamps Powered (qty)'] ? product['Lamps Powered (qty)'] : '',
            productDescription: product['Product Description'] ? product['Product Description'] : '',
            powerSupplyType: product['Type'] ? product['Type'].toString() : '',
            controlType: product['Type'] ? product['Type'].toString() : '',
            accessoryCategory: product['Category'] ? product['Category'] : '',
            compatibleOrderCodes: product['Compatible Product Order Codes'] ? setCompatibleProductOrderCodes([product['Compatible Product Order Codes']]) : '',
            compatibleProductOrderCodes: product['Compatible Product Order Codes'] ? product['Compatible Product Order Codes'].toString() : '',
        })
        if (product['Compatible Product Order Codes']){
            setCompatibleProductOrderCodes(product['Compatible Product Order Codes'].toString())
        }
    }, [accessoryResponse])

    if (isLoading) return <Loading />

    return (
        <form onSubmit={handleSubmit(submit)}>
            <RemoveProjectModal
                productId={params.id}
                isDeleting={isDeleting}
                history={history}
                setIsDeleting={setIsDeleting}
                table={params.type === 'power-supplies' ? 'Power%20Supplies' : params.type === 'accessories' ? 'Accessories' : 'Controls'}
                isOpen={isOpen}
                onClose={onClose}/>
            <CompatibleProductsModal
                isOpen={isOpenProducts}
                setCompatibleProductOrderCodes={setCompatibleProductOrderCodes}
                compatibleProductOrderCodes={compatibleProductOrderCodes}
                onClose={onCloseProducts}
                table={type.toLowerCase().includes('power') ? 'Power Supplies' : type.includes('control') ? 'Controls' : 'Accessories'}/>
            <Navbar minW="650px"
                title={(
                    <Flex>
                        <Link to="/catalogues">Catalogues&nbsp;</Link>
                        <Text>/&nbsp;{params.id ? `Edit ${path === 'power' ? 'power supply' : path}` : `Create ${path === 'power' ? 'power supply' : path}`}</Text>
                    </Flex>
                )}>
                <Button type="submit" w="135px" h="40px" mr={8} ml={12} px={8} isLoading={isLoadingUpdate || uploadIsLoading} isDisabled={isLoadingUpdate || uploadIsLoading} variant="lime">
                    <img src="/images/done.png" alt="done"/>
                    <Text fontWeight="700" fontSize="12px" ml={1} mt="2px">Done</Text>
                </Button>
            </Navbar>
            <Box pb={40} m="auto" minW="650px" maxW="1240px">
                <Tabs css={styles.container} bg="#F5F5F5">
                    <TabList border="none" pt={10} bg="white.100">
                        <Tab minW="208px" fontWeight="700" _active={{background: 'transparent',}} _selected={{color: 'green.200', borderColor: 'green.200', boxShadow: 'none',}} color="green.200">
                            Accessory Details
                        </Tab>
                    </TabList>
                    <TabPanels pt={6} px={10} borderTop="1px solid #DBDADA" borderBottom="none">
                        <TabPanel>
                            <MainFields
                                editMode={true}
                                user={supplierUser}
                                certifications={certifications}
                                setCertifications={setCertifications}
                                type={type}
                                register={register}
                                errors={errors}
                                image={image}
                                setImage={setImage}
                                upload={upload}/>
                            <SecondaryFields
                                onOpenProducts={onOpenProducts}
                                onOpen={onOpen}
                                orderCodesComp={orderCodesComp}
                                compatibleProductOrderCodes={compatibleProductOrderCodes}
                                setCompatibleProductOrderCodes={setCompatibleProductOrderCodes}
                                register={register}/>
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </Box>
        </form>
    )
}

export default EditAccessory
