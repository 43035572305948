import { css } from '@emotion/react'

const styles = {
  tr: css`
        height: 57px;
        width: 100%;
        align-items: center;
        background: #ffffff;
        overflow: hidden;
        box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
        & > td {
            border: none;
            font-size: 1.125rem;
        }
        & > td:nth-of-type(2) {
            font-weight: 500;
            &:hover {
                text-decoration: underline;
                cursor: default;
            }
        }
        & > td:last-of-type {
            border-radius: 0 10px 10px 0;
        }
    `,
  table: css`
        border-collapse: separate;
        border-spacing: 0 20px;
    `,
}

export default styles
