import React from 'react'
import ResetPasswordPage from './ResetPassword'

const ResetPassword = ({email, onClose, forgotPassword}) => (
    <ResetPasswordPage
        email={email}
        onClose={onClose}
        forgotPassword={forgotPassword}/>
)

export default ResetPassword
