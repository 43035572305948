import React, {useEffect, useRef, useState} from 'react'
import {GridItem, Grid, Text, Image, Flex, Box} from '@chakra-ui/react'
import {InviteModal} from "../../Theme";
import {ReactComponent as Eye} from "../../../assets/EyeIconProducts.svg";

const LampViewModal = ({isOpenLampView, onCloseLampView, luminaire, viewType, type}) => {
    const [led, setLed] = useState()
    const [orderShow, setOrderShow] = useState(false)
    const [colorShow, setColorShow] = useState(false)
    const [certifShow, setCertifShow] = useState(false)
    const colorRef = useRef(null)
    const certifRef = useRef(null)
    const orderRef = useRef(null)

    useEffect(() => {
        if (viewType === 'existing'){
            if (type === 'fixture'){
                setLed(luminaire?.fixtureExisting)
            } else if (type === 'lamp'){
                setLed(luminaire?.lampExisting)
            }

        } else if (viewType === 'recommended'){
            if (type === 'fixture'){
                if (luminaire?.fixtureRecomendedAirtable?.length > 0){
                    setLed(JSON.parse(luminaire?.fixtureRecomendedAirtable))
                }else {
                    setLed('')
                }
            } else if (type === 'lamp'){
                if (luminaire?.lampRecomendedAirtable?.length > 0){
                    setLed(JSON.parse(luminaire?.lampRecomendedAirtable))
                } else {
                    setLed('')
                }
            }
        }

    },[isOpenLampView])


    useEffect(() => {
        const handleClickOutside = (event) => {
            if (orderRef.current && !orderRef.current.contains(event.target)) {
                setOrderShow(false)
            }
            if (colorRef.current && !colorRef.current.contains(event.target)) {
                setColorShow(false)
            }
            if (certifRef.current && !certifRef.current.contains(event.target)) {
                setCertifShow(false)
            }
        }
        document.addEventListener('mousedown', handleClickOutside)

        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        };
    }, [])
    return (
        <InviteModal minW='1168px' bg='#193C5B' headerColor='white' isOpen={isOpenLampView} onClose={onCloseLampView} title='Product Details'>
            {led && (
                    <>
                        <Flex pl={3} mt={5} alignItems='center'>
                            <Flex borderRadius='5px' alignItems='center' justifyContent='center' height='123px' width='145px' border='2px solid #DBDADA'>
                                {led['Image'] && (
                                    <Image
                                        border='none'
                                        height='100%'
                                        width='100%'
                                        src={led['Image'] ? led['Image'][0]?.url : ''}/>
                                )}
                            </Flex>
                            <Grid ml={5} templateColumns='repeat(2,1fr)' width='full' gap={3}>
                                <GridItem colSpan='2' fontSize='24px'>
                                    <b>{led['FE Product Description']}</b>
                                </GridItem>
                                <GridItem colSpan='1' fontSize='18px'>
                                    <Flex><b>Supplier: </b>&nbsp;Focus Earth</Flex>
                                </GridItem>
                                <GridItem colSpan='1' fontSize='18px'>
                                    <Flex><b>Product Type: </b>&nbsp;
                                        {type ? type.charAt(0).toUpperCase() + type.slice(1) : type}
                                    </Flex>
                                </GridItem>
                                <GridItem colSpan='1' fontSize='18px'>
                                    <Flex><b>Manufacturer: </b>&nbsp;{led['Manufacturer']}</Flex>
                                </GridItem>
                                <GridItem colSpan='1' fontSize='18px' position='relative'>
                                    <Text display='flex' alignItems='center'  ref={orderRef}>
                                        Order Code:&nbsp; <Eye className='eye_icon' onClick={() => setOrderShow(true)}/>&nbsp;
                                        <Box as='span' position='relative'>{led['Order Code']?.toString()?.replace(/(.{15})..+/, "$1…")}
                                            {/*<Box display={orderShow ? 'block' : 'none'} position='absolute' top='0'>{led['Order Code']?.toString()}</Box>*/}
                                        </Box>
                                    </Text>
                                </GridItem>
                            </Grid>
                        </Flex>
                        <Grid fontSize='12px' pb={5} pl={3} mt={5} columnGap={5} templateColumns='repeat(3, 1fr)'>
                            <GridItem p={5} borderRadius='10px' border='1px solid #C4C4C4'>
                                <Flex gap={3} flexDirection='column'>
                                    <Flex justifyContent='space-between'>
                                        <Text width='65%' color='#A9A9A9'>Dimensions - Length  (in):</Text>
                                        <Text width='35%' fontWeight='600'>{led['Length (inches)']?.toString()?.replace(/(.{12})..+/, "$1…")}</Text>
                                    </Flex>
                                    <Flex justifyContent='space-between'>
                                        <Text width='65%' color='#A9A9A9'>Dimensions - Width (in):</Text>
                                        <Text width='35%' fontWeight='600'>{led['Width (inches)']?.toString()?.replace(/(.{12})..+/, "$1…")}</Text>
                                    </Flex>
                                    <Flex justifyContent='space-between'>
                                        <Text width='65%' color='#A9A9A9'>Dimensions - Diameter (in):</Text>
                                        <Text width='35%' fontWeight='600'>{led['Diameter (inches)']?.toString()?.replace(/(.{12})..+/, "$1…")}</Text>
                                    </Flex>
                                    <Flex justifyContent='space-between'>
                                        <Text width='65%' color='#A9A9A9'>Dimensions - Depth (in):</Text>
                                        <Text width='35%' fontWeight='600'>{led['Depth (inches)']?.toString()?.replace(/(.{12})..+/, "$1…")}</Text>
                                    </Flex>
                                    <Flex justifyContent='space-between'>
                                        <Text width='65%' color='#A9A9A9'>Application:</Text>
                                        <Text width='35%' fontWeight='600'>{led['Application']?.toString()?.replace(/(.{12})..+/, "$1…")}</Text>
                                    </Flex>
                                    <Flex justifyContent='space-between'>
                                        <Text width='65%' color='#A9A9A9'>Style:</Text>
                                        <Text width='35%' fontWeight='600'>{led['Style']?.toString()?.replace(/(.{12})..+/, "$1…")}</Text>
                                    </Flex>
                                    <Flex justifyContent='space-between'>
                                        <Text width='65%' color='#A9A9A9'>Mount:</Text>
                                        <Text width='35%' fontWeight='600'>{led['Mount']?.toString()?.replace(/(.{12})..+/, "$1…")}</Text>
                                    </Flex>
                                    <Flex justifyContent='space-between'>
                                        <Text width='65%' color='#A9A9A9'>Shape:</Text>
                                        <Text width='35%' fontWeight='600'>{led['Shape']?.toString()?.replace(/(.{12})..+/, "$1…")}</Text>
                                    </Flex>
                                    <Flex justifyContent='space-between'>
                                        <Text width='65%' color='#A9A9A9'>Lens Type:</Text>
                                        <Text width='35%' fontWeight='600'>{led['Lens Type']?.toString()?.replace(/(.{12})..+/, "$1…")}</Text>
                                    </Flex>
                                    <Flex justifyContent='space-between'>
                                        <Text width='65%' color='#A9A9A9'>Ingress Protection:</Text>
                                        <Text width='35%' fontWeight='600'>{led['Ingress Protection']?.toString()?.replace(/(.{12})..+/, "$1…")}</Text>
                                    </Flex>
                                    <Flex justifyContent='space-between'>
                                        <Text width='65%' color='#A9A9A9'>Fixture Color:</Text>
                                        <Text width='35%' fontWeight='600'>{led['Fixture Color']?.toString()?.replace(/(.{12})..+/, "$1…")}</Text>
                                    </Flex>
                                    <Flex justifyContent='space-between'>
                                        <Text width='65%' color='#A9A9A9'>Trim:</Text>
                                        <Text width='35%' fontWeight='600'>{led['Trim']?.toString()?.replace(/(.{12})..+/, "$1…")}</Text>
                                    </Flex>
                                </Flex>
                            </GridItem>
                            <GridItem p={5} borderRadius='10px' border='1px solid #C4C4C4'>
                                <Flex gap={3} flexDirection='column'>
                                    <Flex justifyContent='space-between'>
                                        <Text width='65%' color='#A9A9A9'>Wattage:</Text>
                                        <Text width='35%' fontWeight='600'>{led['Wattage'] && led['Wattage']?.toString()?.replace(/(.{12})..+/, "$1…")+'W'}</Text>
                                    </Flex>
                                    <Flex justifyContent='space-between'>
                                        <Text width='65%' color='#A9A9A9'>Lumens:</Text>
                                        <Text width='35%' fontWeight='600'>
                                            {
                                                viewType === 'existing' ? (
                                                    led['Directional Lumens'] && led['Directional Lumens']?.toString()?.replace(/(.{12})..+/, "$1…")+'L'
                                                ) : (
                                                    led['Lumens'] && led['Lumens']?.toString()?.replace(/(.{12})..+/, "$1…")+'L'
                                                )
                                            }
                                        </Text>
                                    </Flex>
                                    <Flex justifyContent='space-between'>
                                        <Text width='65%' color='#A9A9A9'>CCT (K˚):</Text>
                                        <Text width='35%' fontWeight='600'>{led['CCT (K˚)']?.toString()?.replace(/(.{7})..+/, "$1…")}</Text>
                                    </Flex>
                                    <Flex justifyContent='space-between'>
                                        <Text width='65%' color='#A9A9A9'>CRI:</Text>
                                        <Text width='35%' fontWeight='600'>{led['CRI']?.toString()?.replace(/(.{12})..+/, "$1…")}</Text>
                                    </Flex>
                                    <Flex justifyContent='space-between'>
                                        <Text width='65%' color='#A9A9A9'>L70 Lifetime (hours):</Text>
                                        <Text width='35%' fontWeight='600'>
                                            {viewType === 'existing' ? (
                                                Number(led['Lifetime (hrs)'])?.toString()?.replace(/(.{12})..+/, "$1…")
                                            ) : (
                                                Number(led['L70 Lifetime (hours)'])?.toString()?.replace(/(.{12})..+/, "$1…")
                                            )}
                                        </Text>
                                    </Flex>
                                    <Flex justifyContent='space-between'>
                                        <Text width='65%' color='#A9A9A9'>Warranty (years):</Text>
                                        <Text width='35%' fontWeight='600'>{led['Warranty (years)']?.toString()?.replace(/(.{12})..+/, "$1…")}</Text>
                                    </Flex>
                                    <Flex justifyContent='space-between'>
                                        <Text width='65%' color='#A9A9A9'>Voltage (min):</Text>
                                        <Text width='35%' fontWeight='600'>{led['Voltage (min)']?.toString()?.replace(/(.{12})..+/, "$1…")}</Text>
                                    </Flex>
                                    <Flex justifyContent='space-between'>
                                        <Text width='65%' color='#A9A9A9'>Voltage (max):</Text>
                                        <Text width='35%' fontWeight='600'>{led['Voltage (max)']?.toString()?.replace(/(.{12})..+/, "$1…")}</Text>
                                    </Flex>
                                    <Flex justifyContent='space-between'>
                                        <Text width='65%' color='#A9A9A9'>Dimmability:</Text>
                                        <Text width='35%' fontWeight='600'>{led['Dimmability']?.toString()?.replace(/(.{12})..+/, "$1…")}</Text>
                                    </Flex>
                                    <Flex justifyContent='space-between'>
                                        <Text width='65%' color='#A9A9A9'>Color Tunable:</Text>
                                        <Text width='35%' fontWeight='600'>{led['Color Tunable']?.toString()?.replace(/(.{12})..+/, "$1…")}</Text>
                                    </Flex>
                                    <Flex justifyContent='space-between'>
                                        <Text width='65%' color='#A9A9A9'>Power Factor:</Text>
                                        <Text width='35%' fontWeight='600'>{led['Power Factor']?.toString()?.replace(/(.{12})..+/, "$1…")}</Text>
                                    </Flex>
                                    <Flex justifyContent='space-between'>
                                        <Text width='65%' color='#A9A9A9'>CBCP:</Text>
                                        <Text width='35%' fontWeight='600'>{led['CBCP']?.toString()?.replace(/(.{12})..+/, "$1…")}</Text>
                                    </Flex>
                                </Flex>
                            </GridItem>
                            <GridItem p={5} borderRadius='10px' border='1px solid #C4C4C4'>
                                <Flex gap={3} flexDirection='column'>
                                    <Flex justifyContent='space-between'>
                                        <Text width='65%' color='#A9A9A9'>THD (%):</Text>
                                        <Text width='35%' fontWeight='600'>{led['THD (%)']?.toString()?.replace(/(.{12})..+/, "$1…")}</Text>
                                    </Flex>
                                    <Flex justifyContent='space-between'>
                                        <Text width='65%' color='#A9A9A9'>Beam Angle:</Text>
                                        <Text width='35%' fontWeight='600'>{led['Beam Angle']?.toString()?.replace(/(.{12})..+/, "$1…")}</Text>
                                    </Flex>
                                    <Flex justifyContent='space-between'>
                                        <Text width='65%' color='#A9A9A9'>Optics:</Text>
                                        <Text width='35%' fontWeight='600'>{led['Optics']?.toString()?.replace(/(.{12})..+/, "$1…")}</Text>
                                    </Flex>
                                    <Flex justifyContent='space-between'>
                                        <Text width='65%' color='#A9A9A9'>Certifications:</Text>
                                        <Text width='35%' fontWeight='600'>{led['Certifications']?.toString()?.replace(/(.{12})..+/, "$1…")}</Text>
                                    </Flex>
                                    <Flex justifyContent='space-between'>
                                        <Text width='65%' color='#A9A9A9'>Buy America:</Text>
                                        <Text width='35%' fontWeight='600'>{led['Buy America']?.toString()?.replace(/(.{12})..+/, "$1…")}</Text>
                                    </Flex>
                                    <Flex justifyContent='space-between'>
                                        <Text width='65%' color='#A9A9A9'>Installation (minutes):</Text>
                                        <Text width='35%' fontWeight='600'>
                                            {
                                                viewType === 'existing' ? (
                                                    led['Installation Time (min)']?.toString()?.replace(/(.{12})..+/, "$1…")
                                                ): (led['Approx. Installation Time (minutes)']?.toString()?.replace(/(.{12})..+/, "$1…"))
                                            }
                                        </Text>
                                    </Flex>
                                    <Flex justifyContent='space-between'>
                                        <Text width='65%' color='#A9A9A9'>Delivery (days):</Text>
                                        <Text width='35%' fontWeight='600'>{led['Approx. Delivery (days)']?.toString()?.replace(/(.{12})..+/, "$1…")}</Text>
                                    </Flex>
                                    <Flex justifyContent='space-between'>
                                        <Text width='65%' color='#A9A9A9'>Price Per Unit ($):</Text>
                                        <Text width='35%' fontWeight='600'>
                                            {viewType === 'existing' ? (
                                                Number(led['PPU ($)']?.toString()?.replace(/[^0-9.]/g, ''))+'$'
                                            ) : (
                                                Number(led['Price Per Unit ($)']?.replace(/[^0-9.]/g, ''))?.toString()?.replace(/(.{12})..+/, "$1…")+'$'
                                            )}
                                        </Text>
                                    </Flex>
                                    <Flex justifyContent='space-between'>
                                        <Text width='65%' color='#A9A9A9'>Vol. Tier 1 (min qty):</Text>
                                        <Text width='35%' fontWeight='600'>{led['Volume Tier 1 (MIN QTY)'] ? Number(led['Volume Tier 1 (MIN QTY)']?.toString()?.replace(/(.{12})..+/, "$1…")) : ''}</Text>
                                    </Flex>
                                    <Flex justifyContent='space-between'>
                                        <Text width='65%' color='#A9A9A9'>Vol. Tier 1 PPU ($)</Text>
                                        <Text width='35%' fontWeight='600'>{led['Volume Tier 1 PPU ($)'] ? Number(led['Volume Tier 1 PPU ($)']?.replace(/[^0-9.]/g, ''))?.toString()?.replace(/(.{12})..+/, "$1…")+'$' : ''}</Text>
                                    </Flex>
                                    <Flex justifyContent='space-between'>
                                        <Text width='65%' color='#A9A9A9'>Vol. Tier 2 (min qty):</Text>
                                        <Text width='35%' fontWeight='600'>{led['Volume Tier 2 (MIN QTY)'] ? Number(led['Volume Tier 2 (MIN QTY)']?.toString()?.replace(/(.{12})..+/, "$1…")) : ''}</Text>
                                    </Flex>
                                    <Flex justifyContent='space-between'>
                                        <Text width='65%' color='#A9A9A9'>Vol. Tier 2 PPU ($):</Text>
                                        <Text width='35%' fontWeight='600'>{led['Volume Tier 2 PPU ($)'] ? Number(led['Volume Tier 2 PPU ($)']?.replace(/[^0-9.]/g, ''))?.toString()?.replace(/(.{12})..+/, "$1…")+'$' : ''}</Text>
                                    </Flex>
                                </Flex>
                            </GridItem>
                        </Grid>
                    </>
                )
            }
        </InviteModal>
    )
}

export default LampViewModal