import {useEffect,useState} from 'react'
import {Link, useLocation} from 'react-router-dom'
import {Flex, Tabs, TabList, TabPanels, Tab, TabPanel, Button, Box, useDisclosure, useToast,} from '@chakra-ui/react'
import Navbar from '../../components/Navbar'
import {ReactComponent as Edit} from '../../assets/edit.svg'
import styles from './styles'
import {useGetCompany, useGetUser, useUser, useUserProgress} from '../../core/hooks'
import {S3_BUCKET} from '../../constants'
import {
    InitialEnvironmentUser,
    InitialIlluminationUser, InitialPreferences,
    InitialScore, InitialScoreUser,
    InitialTechnologyUser,
    InitialValuationUser
} from "../../constants/chartDatas";
import mainStyles from "../../styles/mainStyles";
import Header from "./ProfileTab/Header";
import Details from "./ProfileTab/Details";
import CompanyTab from "./CompanyTab/CompanyTab";
import EditCompanyModal from "./ProfileModals/EditCompanyModal";
import useConfirmSyncCompany from "../../core/hooks/company/useConfirmSyncCompany";
import ToastComponent from "../../components/toastComponent/ToastComponent";
import {ReactComponent as Synced} from "../../assets/Icons/syncedIcon.svg";
import {
    AU,
    EU,
    InitialPreferencesAu,
    InitialPreferencesReuSales,
    InitialPreferencesSuEu, InitialPreferencesSuperadmin,
    REU,
    SA,
    SU
} from "../../constants/user/userEnum";

const Profile = () => {
    const {user, setUser} = useUser()
    const toast = useToast()
    const [tabIndex, setTabIndex] = useState(0)
    const [companySyncType, setCompanySyncType] = useState(false)
    const {isOpen, onOpen, onClose} = useDisclosure()
    const [scoreCompany, setScoreCompany] = useState(InitialScore)
    const [score, setScore] = useState(user.primaryInterests ? JSON.parse(user.primaryInterests) : InitialScoreUser)
    const [illumination, setIllumination] = useState(user.profileFeatures ? JSON.parse(user.profileFeatures).illumination : InitialIlluminationUser)
    const [technology, setTechnology] = useState(user.profileFeatures ? JSON.parse(user.profileFeatures).technology : InitialTechnologyUser)
    const [valuation, setValuation] = useState(user.profileFeatures ? JSON.parse(user.profileFeatures).valuation : InitialValuationUser)
    const [environment, setEnvironment] = useState(user.profileFeatures ? JSON.parse(user.profileFeatures).environment : InitialEnvironmentUser)
    const [communicationPreferences, setCommunicationPreferences] = useState(user?.communicationPreferences ? JSON.parse(user.communicationPreferences) : InitialPreferences)
    const newArray = useUserProgress(user)
    const {company: companyResponse, refetch} = useGetCompany(user?.companyToSync ? user?.companyToSync : null)
    const {confirmCompany, isLoading} = useConfirmSyncCompany()
    const [avatar, setAvatar] = useState(user?.avatar ? S3_BUCKET + user.avatar : '')
    const [complete, setComplete] = useState([])
    const [viewCompany, setViewCompany] = useState()
    const [areas, setAreas] = useState([])
    const location = useLocation();
    const {getUser} = useGetUser()

    let searchParams = new URLSearchParams(location.search);

    useEffect(() => {
        if (!user) return
        setComplete(newArray)
        if (user.company.length > 0) {
            setViewCompany(user.company[0])
            setScoreCompany(JSON.parse(user.company[0]?.propertyPriorities[0]))
            setAreas(JSON.parse(user.company[0]?.serviceAreas[0]))
        }
        if (user.associatedToCompany) {
            setViewCompany(user.associatedToCompany)
            setScore(JSON.parse(user.associatedToCompany?.propertyPriorities[0]))
            setAreas(JSON.parse(user.associatedToCompany?.serviceAreas[0]))
        }
        if (user?.companyToSync){
            refetch(user?.companyToSync)
        }
        if (user?.type === AU){
            setCommunicationPreferences(user?.communicationPreferences ? JSON.parse(user.communicationPreferences) : InitialPreferencesAu)
        } else if (user?.type === REU || user?.type === SA){
            setCommunicationPreferences(user?.communicationPreferences ? JSON.parse(user.communicationPreferences) : InitialPreferencesReuSales)
        }else if (user?.type === SU || user?.type === EU){
            setCommunicationPreferences(user?.communicationPreferences ? JSON.parse(user.communicationPreferences) : InitialPreferencesSuEu)
        } else {
            setCommunicationPreferences(user?.communicationPreferences ? JSON.parse(user.communicationPreferences) : InitialPreferencesSuperadmin)
        }
    }, [user])

    const confirmSyncCompany = () => {
        confirmCompany({id: viewCompany.id}, {
            onSuccess:() => {
                toast({
                    render: () => (
                        <ToastComponent icon={<Synced/>} title={`Congrats! you’re synced with ${viewCompany.name}`} bg='#FFD4A1'/>
                    ),
                    duration: 4000,
                    isClosable: true,
                    position: "top-right"
                })
                getUser(
                    localStorage.getItem("token"),
                    {
                        onSuccess: (userResponse) => {
                            setUser(userResponse.data.data)
                            setCompanySyncType(false)
                        },
                    },
                )
            },
            onError:() => {

            }
        })
    }

    useEffect(() => {
        if (!companyResponse) return
        setViewCompany(companyResponse.data.data)
        setCompanySyncType(true)
    },[companyResponse])

    useEffect(() => {
        let company = searchParams.get('company');
        if (company) setTabIndex(1)
        getUser(
            localStorage.getItem("token"),
            {
                onSuccess: (userResponse) => {
                    setUser(userResponse.data.data)
                    setCompanySyncType(false)
                },
            },
        )
    },[])

    return (
        <>
            <EditCompanyModal
                company={viewCompany}
                isOpen={isOpen}
                onClose={onClose}/>
            <Navbar
                minW="1240px"
                title="Profile"/>
            <Flex css={mainStyles.container}>
                <Header
                    avatar={avatar}
                    complete={complete}
                    user={user}/>
                <Tabs index={tabIndex} onChange={(index) => setTabIndex(index)}>
                    <TabList css={mainStyles.tabNames}>
                        <Tab>Profile</Tab>
                        <Tab>Company</Tab>
                    </TabList>
                    <TabPanels css={styles.tabPanels}>
                        <TabPanel className='tab-panel'>
                            <Box className='details-button' as={Link} to="/profile/edit">
                                <Button leftIcon={<Edit/>} w="135px" variant="pink">Edit Details</Button>
                            </Box>
                            <Details
                                setIllumination={setIllumination}
                                illumination={illumination}
                                setScore={setScore}
                                score={score}
                                setEnvironment={setEnvironment}
                                environment={environment}
                                setValuation={setValuation}
                                valuation={valuation}
                                setTechnology={setTechnology}
                                technology={technology}
                                company={viewCompany}
                                setUser={setUser}
                                communicationPreferences={communicationPreferences}
                                setCommunicationPreferences={setCommunicationPreferences}
                                user={user}/>
                        </TabPanel>
                        <TabPanel className='tab-panel'>
                            {viewCompany ? (
                                <>
                                    <Flex display={viewCompany?.status === 3 ? 'flex' : 'none'} pt="2rem" justifyContent="center" top="0" left="0" w="full" zIndex="5" height="100%" bg="rgba(0, 0, 0, 0.5)" position="absolute">
                                        <Box color="white" fontSize="5xl" as="span">Creating company is blocked by administration</Box>
                                    </Flex>
                                    <CompanyTab
                                        isLoading={isLoading}
                                        confirmSyncCompany={confirmSyncCompany}
                                        companySyncType={companySyncType}
                                        user={user}
                                        areas={areas}
                                        scoreCompany={scoreCompany}
                                        company={viewCompany}
                                        onOpen={onOpen}/>
                                </>
                            ) : (
                                <Button as={Link} to="/create-company" bg='#8FCF74' width='200px' fontSize='xl' color='black'>Create Company</Button>
                            )}
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </Flex>
        </>
    )
}

export default Profile
