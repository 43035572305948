import React, {forwardRef, useEffect, useState} from "react";
import {
    Modal,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    Accordion as ChakraAccordion,
    ModalBody,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Text,
    Flex,
    Divider,
    Box,
    Button
} from "@chakra-ui/react";
import {InputLabel} from '../../../components/Theme'
import IconButton from "../../../components/Theme/IconButton";
import Icon from "../../../components/Theme/Icon";
import styles from "../../../pages/Project/Modals/styles";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker, {CalendarContainer} from "react-datepicker";
import {ReactComponent as CalendarIcon} from "../../../assets/calendarIconSmall.svg";
import {ReactComponent as ClearDate} from "../../../assets/clearDateIcon.svg";
import {ReactComponent as LeftArrow} from "../../../assets/leftArrowIcon.svg";
import {getMonth, getYear} from "date-fns";
import {ReactComponent as RightArrow} from "../../../assets/rightArrowIcon.svg";
import httpRequest from "../../../core/services/http-request";

const InstallationBidSelectModal = ({
                                        getReport,
                                        isOpen,
                                        onClose,
                                        project,
                                        recentProposal,
                                        selectedBid,
                                        setSelectedBid
                                    }) => {
    const [selectInstallation, setSelectInstallation] = useState([])
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;
    const [loading, setLoading] = useState(false)
    const {post} = httpRequest()
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December",];

    const RenderCustomInput = forwardRef(({value, onClick}, ref) => (
        <Flex border='1px solid black' onClick={onClick} ref={ref} position='relative' backgroundColor='white'
              _active='' _focus='' w='full' borderColor='#A9A9A9' mt={2} cursor='auto' height='40px' borderRadius='6px'
              alignItems='center'>
            {
                recentProposal?.euBids.filter((filtered) => filtered.user.id === selectedBid?.user?.id) && !value ? (
                    recentProposal?.euBids.filter((filtered) => filtered.user.id === selectedBid?.user?.id)[0] ? recentProposal?.euBids.filter((filtered) => filtered.user.id === selectedBid?.user?.id)[0].availability[0] + '-' + recentProposal?.euBids.filter((filtered) => filtered.user.id === selectedBid?.user?.id)[0].availability[1] : recentProposal.availability
                ) : (value)
            }
            <Text position='absolute' right='10px'>
                <CalendarIcon/>
            </Text>
        </Flex>
    ));

    function pad(d) {
        return (d < 10) ? '0' + d.toString() : d.toString();
    }

    const renderCustomDays = e => pad(e)

    const renderCustomContainer = ({className, children}) => {
        return (
            <Flex width='320px' justifyContent='center' css={styles.calendarContainer}>
                <CalendarContainer className={className}>
                    <Flex>
                        <Text fontSize='18px' fontWeight='700'>
                            Select Date(s) Below
                        </Text>
                        <Button leftIcon={<ClearDate/>} onClick={() => setDateRange([null, null])} height='30px'
                                backgroundColor='#D1D1D1' color='black' fontSize='12px'>
                            Clear Dates
                        </Button>
                    </Flex>
                    <Box position='relative' p='15px' width='320px'>
                        {children}
                    </Box>
                </CalendarContainer>
            </Flex>
        );
    };

    const setInstallationEstimate = (e) => {
        setSelectInstallation(e)
        setSelectedBid(e)
    }

    useEffect(() => {
        if (!selectedBid) return
        setSelectInstallation(selectedBid)
    }, [selectedBid])

    useEffect(() => {
        if (isOpen) {
            setLoading(true)
            post('set-seen-bid', recentProposal?.id).then(() => {
                getReport()
                setLoading(false)
            })
        }
    }, [isOpen])
    return (
        <Modal isCentered isOpen={isOpen} onClose={onClose}>
            <ModalOverlay/>
            <ModalContent overflow="hidden" borderRadius="10px" minW="992px">
                <ModalHeader bg='#FFAF8D' fontWeight="700" fontStyle='normal' fontSize="24px" textAlign="center">
                    Installation Bid: {project[0]?.title}
                </ModalHeader>
                <IconButton height="unset" onClick={onClose} _hover={{bg: 'none',}} _active={{bg: 'none',}} bg="none"
                            aria-label="close button" position="absolute" top="1.5rem" right="0.75rem"
                            icon={<Icon type="darkClose"/>}/>
                <ModalBody pt='40px' pl='60px' pr='60px' pb='60px' overflow="scroll" maxH="600px">
                    {
                        loading ? (
                            'Loading...'
                        ) : (
                            <>
                                <Flex justifyContent='space-between'>
                                    <Divider mt={3} width='300px' borderColor="#DADADA" border="1px solid"
                                             orientation="horizontal"/>
                                    <Text fontSize='18px' color='#2C628D'>
                                        SELECT INSTALLATION BID
                                    </Text>
                                    <Divider mt={3} width='300px' borderColor="#DADADA" border="1px solid"
                                             orientation="horizontal"/>
                                </Flex>
                                <Flex color='#2C628D' justifyContent='space-between' mt={5} fontWeight='600'>
                                    <Text>Installation Estimate:</Text>
                                    <Text>Company:</Text>
                                    <Text width='20%'>Date:</Text>
                                </Flex>
                                <ChakraAccordion mt={4} allowToggle>
                                    <AccordionItem>
                                        {({isExpanded}) => (
                                            <>
                                                <AccordionButton borderRadius='6px' height="46px"
                                                                 _focus={{boxShadow: '0px 2px 10px 2px rgba(0, 0, 0, 0.45)'}}
                                                                 border="1px" borderColor="grey.200">
                                                    <Flex minW="100%" pl='40px' pr='40px' fontSize='18px'
                                                          alignItems='center' height="46px">
                                                        {
                                                            selectedBid === 'Sourced Separately' ? (
                                                                    <Text textAlign='center'>
                                                                        Sourced Separately
                                                                    </Text>
                                                            ) : (
                                                                <>
                                                                    <Text width='33%' borderRight='1px solid #222222'
                                                                          textAlign='start'>
                                                                        {selectInstallation?.total ? '$' + selectInstallation?.total : ''}
                                                                    </Text>
                                                                    <Text width='33%' borderRight='1px solid #222222'
                                                                          textAlign='center'>
                                                                        {selectInstallation?.companyName}
                                                                    </Text>
                                                                    <Text width='33%' textAlign='end'>
                                                                        {selectInstallation.availability ? ` ${selectInstallation.availability[0]} - ${selectInstallation.availability[1]}` : ' '}
                                                                    </Text>
                                                                </>
                                                            )
                                                        }
                                                    </Flex>
                                                    <Icon style={{
                                                        transform: isExpanded ? 'rotate(180deg)' : 'inherit',
                                                        position: 'relative',
                                                        right: '14px',
                                                        transition: 'all .3s',
                                                        height: '12px',
                                                        color: '#A9A9A9',
                                                    }} type="downArrow"/>
                                                </AccordionButton>
                                                <AccordionPanel border='1px solid #A9A9A9' borderRadius='6px' mt={4}
                                                                p={6}>
                                                    {
                                                        recentProposal?.euBids ? (
                                                            <>
                                                                <Flex
                                                                    onClick={() => setInstallationEstimate('Sourced Separately')}
                                                                    cursor='pointer' border='1px solid #A9A9A9'
                                                                    height="46px" pl='40px' bg='#EDEDED' fontSize='18px'
                                                                    alignItems='center' borderRadius='6px'>
                                                                    Sourced Separately
                                                                </Flex>
                                                                {
                                                                    recentProposal?.euBids.map((item) =>
                                                                        <Flex cursor='pointer'
                                                                              onClick={() => {
                                                                                  setInstallationEstimate(item)
                                                                              }} mt={4} key={item.id} pr='40px'
                                                                              pl='40px' fontSize='18px'
                                                                              border='1px solid #A9A9A9'
                                                                              borderRadius='6px' minW="100%"
                                                                              alignItems='center' height="46px">
                                                                            <Text width='33%'
                                                                                  borderRight='1px solid #222222'>
                                                                                ${item.total}
                                                                            </Text>
                                                                            <Text width='33%'
                                                                                  borderRight='1px solid #222222'
                                                                                  textAlign='center'>
                                                                                {item.companyName}
                                                                            </Text>
                                                                            <Text width='33%' textAlign='end'>
                                                                                {item.availability ? ` ${item.availability[0]} - ${item.availability[1]}` : ''}
                                                                            </Text>
                                                                        </Flex>
                                                                    )}
                                                            </>
                                                        ) : ('')
                                                    }
                                                </AccordionPanel>
                                            </>
                                        )}
                                    </AccordionItem>
                                </ChakraAccordion>
                                <Flex justifyContent='space-between' mt={10}>
                                    <Divider mt={3} width='340px' borderColor="#DADADA" border="1px solid"
                                             orientation="horizontal"/>
                                    <Text fontSize='18px' color='#2C628D'>
                                        BID DETAILS
                                    </Text>
                                    <Divider mt={3} width='340px' borderColor="#DADADA" border="1px solid"
                                             orientation="horizontal"/>
                                </Flex>
                                <Flex justifyContent='space-between' mt={10}>
                                    <InputLabel maxW='257px' label='Labor' type='number'
                                                value={selectInstallation.labor ? selectInstallation.labor : ''}
                                                onKeyPress={(event) => {
                                                    if (!/[0-9]/.test(event.key)) {
                                                        event.preventDefault();
                                                    }
                                                }} leftAddon="$" backgroundColor='#A9A9A9' color='#5A5A5A'/>
                                    <InputLabel maxW='257px' leftAddon="$"
                                                value={selectInstallation.materials ? selectInstallation.materials : ''}
                                                onKeyPress={(event) => {
                                                    if (!/[0-9]/.test(event.key)) {
                                                        event.preventDefault();
                                                    }
                                                }} type='number' label='Materials'/>
                                    <InputLabel maxW='257px'
                                                value={selectInstallation.permits ? selectInstallation.permits : ''}
                                                onKeyPress={(event) => {
                                                    if (!/[0-9]/.test(event.key)) {
                                                        event.preventDefault();
                                                    }
                                                }} type='number' min='1' leftAddon="$" label='Permits'/>
                                </Flex>
                                <Flex justifyContent='space-between' mt={7}>
                                    <InputLabel maxW='257px' leftAddon="$"
                                                value={selectInstallation.tax ? selectInstallation.tax : ''}
                                                onKeyPress={(event) => {
                                                    if (!/[0-9]/.test(event.key)) {
                                                        event.preventDefault();
                                                    }
                                                }} type='number' label='Tax'/>
                                    <InputLabel maxW='257px' type='number'
                                                value={selectInstallation.disposal ? selectInstallation.disposal : ''}
                                                onKeyPress={(event) => {
                                                    if (!/[0-9]/.test(event.key)) {
                                                        event.preventDefault();
                                                    }
                                                }} leftAddon="$" label='Recycling/Disposal'/>
                                    <Box w='257px'>
                                        <Text fontWeight='600' fontSize='14px' color='blue.200'>Availability</Text>
                                        <Box width='257px'>
                                            <DatePicker
                                                selectsRange={true}
                                                useWeekdaysShort={true}
                                                startDate={startDate}
                                                endDate={endDate}
                                                onChange={(update) => {
                                                    setDateRange(update);
                                                }}
                                                renderDayContents={renderCustomDays}
                                                renderCustomHeader={({
                                                                         date,
                                                                         decreaseMonth,
                                                                         increaseMonth,
                                                                         increaseYear,
                                                                         decreaseYear,
                                                                         prevMonthButtonDisabled,
                                                                         nextMonthButtonDisabled,
                                                                         prevYearButtonDisabled,
                                                                         nextYearButtonDisabled,
                                                                     }) => (
                                                    <Flex justifyContent='space-between'>
                                                        <Box pl={3}>
                                                            <Text fontSize='18px'>
                                                                <button onClick={decreaseMonth}
                                                                        disabled={prevMonthButtonDisabled}
                                                                        style={{'margin-right': '5px'}}>
                                                                    <LeftArrow/>
                                                                </button>
                                                                <Text as='span' textDecoration='underline'>
                                                                    {months[getMonth(date)]}
                                                                </Text>
                                                                <button onClick={increaseMonth}
                                                                        disabled={nextMonthButtonDisabled}
                                                                        style={{'margin-left': '5px'}}>
                                                                    <RightArrow/>
                                                                </button>
                                                            </Text>
                                                        </Box>
                                                        <Box pr={3}>
                                                            <Text fontSize='18px'>
                                                                <button onClick={decreaseYear}
                                                                        disabled={prevYearButtonDisabled}
                                                                        style={{'margin-right': '5px'}}>
                                                                    <LeftArrow/>
                                                                </button>
                                                                <Text as='span' textDecoration='underline'>
                                                                    {getYear(date)}
                                                                </Text>
                                                                <button onClick={increaseYear}
                                                                        disabled={nextYearButtonDisabled}
                                                                        style={{'margin-left': '5px'}}>
                                                                    <RightArrow/>
                                                                </button>
                                                            </Text>
                                                        </Box>
                                                    </Flex>
                                                )}
                                                customInput={<RenderCustomInput/>}
                                                calendarContainer={renderCustomContainer}
                                            />
                                        </Box>
                                    </Box>
                                </Flex>
                                <Flex color='#2C628D' fontWeight='600' mt={6}>
                                    <Text width='50%'>Uploaded Files
                                    </Text>
                                    <Text width='50%'>Date</Text>
                                </Flex>
                            </>
                        )
                    }
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}

export default InstallationBidSelectModal