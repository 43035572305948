import {css} from '@emotion/react'

const styles = {
    menu: css`
      & > div:nth-of-type(2) {
        width: 100%;
      }
    `,
    customRadioButton: css`
      .radioButton{
        display: none;
      }
    `
}

export default styles
