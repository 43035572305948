import {
    Box,
    Button,
    Flex,
    Input,
    InputGroup,
    InputLeftElement,
    InputRightElement,
    Popover,
    PopoverArrow,
    PopoverBody,
    PopoverContent,
    PopoverTrigger,
    Text
} from "@chakra-ui/react";
import {ReactComponent as DownIcon} from "../../../assets/downArrow.svg";
import {ReactComponent as TileIcon} from "../../../assets/tilesIcons.svg";
import {Checkbox, Icon} from "../../../components/Theme";
import styles from "../styles";
import {ReactComponent as ListIcon} from "../../../assets/listIcon.svg";
import {Link} from "react-router-dom";
import {ReactComponent as Plus} from "../../../assets/plus.svg";
import {ReactComponent as RightIconSearch} from "../../../assets/rightIconSearch.svg";


const FILTERSEU = ['Project Awarded', 'Invited', 'Active Bidding', 'Awaiting Decision', 'Bidding Closed', 'Hidden',]
const FILTERS = ['My Projects', 'Priority', 'Light Count', 'Proposal Review', 'Approved', 'Shipped', 'Fulfilled', 'Archived', 'Hidden',]

const ProjectsNavbar = ({
                            refDashboard,
                            search,
                            projects,
                            history,
                            setSearch,
                            setOpenDashboard,
                            openDashboard,
                            setDashboardType,
                            dashboardType,
                            user,
                            handleCheckbox,
                            searchRef,
                            isOpen
                        }) => (
    <>
        <Box
            position='relative'
            ref={refDashboard}>
            <Button
                style={{zIndex: '99',}}
                onClick={() => setOpenDashboard(true)}
                mr={7}
                bg='#5DE2CA'
                color='black'
                size="sm"
                height='30px'
                width="139px"
                rightIcon={<DownIcon/>}>
                Dashboard View
            </Button>
            <Flex
                width='139px'
                position='absolute'
                borderRadius='0 0 5px 5px'
                bg='#F5F5F5'
                p='5px'
                zIndex='1'
                mt='-5px'
                flexDirection='column'
                display={openDashboard ? 'flex' : 'none'}>
                {openDashboard && (
                    <>
                        <Flex
                            justifyContent='space-between'
                            px={5}>
                            <Button
                                p={0}
                                height='30px'
                                bg='transparent'
                                _active=''
                                _focus=''
                                color='black'
                                leftIcon={<TileIcon/>}>
                                Tiles
                            </Button>
                            <Checkbox
                                css={styles.checkboxDashboard}
                                onChange={() => setDashboardType('tiles')}
                                isChecked={dashboardType.includes('tiles')}/>
                        </Flex>
                        <Flex
                            justifyContent='space-between'
                            px={5}>
                            <Button
                                p={0}
                                height='30px'
                                bg='transparent'
                                _active=''
                                _focus=''
                                color='black'
                                leftIcon={<ListIcon/>}>
                                List
                            </Button>
                            <Checkbox
                                css={styles.checkboxDashboard}
                                onChange={() => setDashboardType('list')}
                                isChecked={dashboardType.includes('list')}/>
                        </Flex>
                    </>
                )}
            </Flex>
        </Box>
        <Flex>
            <Popover>
                <PopoverTrigger>
                    <Button
                        leftIcon={<Icon type="filter"/>}
                        mr={7}
                        variant="lightBlue"
                        size="sm"
                        height='30px'
                        minW="139px">
                        Filter
                    </Button>
                </PopoverTrigger>
                <PopoverContent
                    bg="white"
                    p={6}
                    style={{zIndex: 999}}
                    pl={0}
                    width={user?.type === 'Eu' ? '239px' : '150px'}
                    pr={0}
                    flexDirection="row"
                    _focus={{outline: 'none'}}>
                    <PopoverArrow/>
                    <PopoverBody
                        display="flex">
                        <Text
                            display={user?.type === 'Eu' ? 'block' : 'none'}
                            fontSize='16px'
                            fontWeight='600'
                            mr={6}>
                            Status
                        </Text>
                        <Flex
                            flexDirection="column">
                            {user?.type === 'Eu' ? (
                                FILTERSEU.map((item) => (
                                    <Checkbox
                                        key={item}
                                        css={styles.checkbox}
                                        onChange={(e) => handleCheckbox(e.target.name)}
                                        name={item}
                                        mb={1}>
                                        <Box
                                            ml={1}
                                            fontSize="12px"
                                            as="span">
                                            {item}
                                        </Box>
                                    </Checkbox>
                                ))
                            ) : (
                                FILTERS.map((item) => (
                                    <Checkbox
                                        key={item}
                                        css={styles.checkbox}
                                        onChange={(e) => handleCheckbox(e.target.name)}
                                        name={item}
                                        mb={1}>
                                        <Box
                                            ml={1}
                                            fontSize="12px"
                                            as="span">
                                            {item}
                                        </Box>
                                    </Checkbox>
                                ))
                            )}
                        </Flex>
                    </PopoverBody>
                </PopoverContent>
            </Popover>
            {user?.type !== 'Au' && user?.type !== 'Eu' && user?.type !== 'Su' && (
                <Link
                    to="/projects/create">
                    <Button
                        leftIcon={<Plus/>}
                        variant="green"
                        size="sm"
                        mr={12}
                        height='30px'
                        minW="139px">
                        New Project
                    </Button>
                </Link>
            )}
            <Box
                ref={searchRef}
                position='relative'>
                <InputGroup
                    zIndex='9'
                    mr={8}>
                    <InputLeftElement
                        height="30px"
                        pointerEvents="none"
                        children={(<Icon height="16.5px" width="16.5px" type="search"/>)}/>
                    <Input
                        onChange={(e) => setSearch(e.target.value)}
                        value={search}
                        height="30px"
                        fontSize='16px'
                        color="white.100"
                        fontFamily='Titillium Web'
                        borderRadius="5px"
                        border="none"
                        bg="blue.200"
                        placeholder="Search"/>
                    {search?.length > 0 && (
                        <InputRightElement
                            height='30px'
                            onClick={() => setSearch('')}
                            cursor='pointer'
                            children={(<Icon height="12px" width="12px" type="close"/>)}/>)}
                </InputGroup>
                <Flex
                    width='100%'
                    position='absolute'
                    borderRadius='0 0 5px 5px'
                    bg='#F5F5F5'
                    p='5px 0'
                    zIndex='1'
                    mt='-2px'
                    flexDirection='column'
                    display={isOpen ? 'flex' : 'none'}>
                    {isOpen && (
                        projects?.length > 0 ? (projects?.map((item) => (
                                <Flex
                                    alignItems='center'
                                    css={styles.searchButton}>
                                    <RightIconSearch
                                        className='icon'
                                        width='20px'
                                        height='20px'
                                        style={{opacity: '0'}}/>
                                    <Button
                                        onClick={() => history.push(`/project/${item.project.id}`)}
                                        css={styles.searchButton}
                                        _active=''
                                        _focus=''>
                                        {item.project.title}
                                    </Button>
                                </Flex>))
                        ) : <Text
                            pl={9}
                            fontFamily='Titillium Web'
                            fontSize='16px'>
                            No result
                        </Text>
                    )}
                </Flex>
            </Box>
        </Flex>
    </>
)

export default ProjectsNavbar