import {css} from '@emotion/react'

const styles = {
    list: css`
      & li:hover {
        background: #2C628D;
        color: #ffffff;
        cursor: pointer;
      }
    `,
}
export default styles
