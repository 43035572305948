import {useState} from 'react'
import {Link} from 'react-router-dom'
import {useForm} from 'react-hook-form'
import {Box, Container, Heading, Text, Stack, Input, InputGroup, Button as TextButton,} from '@chakra-ui/react'
import {useSignIn, useUser, useGetUser, } from '../../core/hooks'
import {Button} from '../../components/Theme'
import styles from './styles'
import {NOT_VERIFED} from "../../constants/user/userEnum";

function Login() {
    const [error, setError] = useState('')
    const {register, handleSubmit, formState: {errors}} = useForm()
    const {signIn, isLoading} = useSignIn()
    const {getUser, isLoading: isUserLoading} = useGetUser()
    const {setUser} = useUser()

    const submit = (data) => {
        const input = {
            password: data.password,
            email: data.email.toLowerCase(),
        }
        signIn(
            input,
            {
                onSuccess: (response) => {
                    localStorage.setItem("token", response.data.token);
                    localStorage.setItem("refresh_token", response.data.refresh_token);
                    getUser(
                        response.data.token,
                        {
                            onSuccess: (userResponse) => {
                                if (userResponse.data.data === NOT_VERIFED){
                                    localStorage.removeItem("token", response.data.token);
                                    localStorage.removeItem("refresh_token", response.data.refresh_token)
                                    return setError('Confirm your email')
                                }
                                setUser(userResponse.data.data)
                            },
                        },
                    )

                },
                onError: () => {
                    return setError('Incorrect username or password.')
                },
            },
        )
    }

    return (
        <Container position="relative" display="flex" alignItems="center" flexDirection="column" mx={4} mt={30} py={8} maxW="6xl" borderRadius="10px" boxShadow="0px 4px 10px rgba(34, 34, 34, 0.25)" bg="rgba(255, 255, 255, 0.8)">
            <form onSubmit={handleSubmit(submit)}>
                <Stack mb={12} width="761px" alignItems="center" spacing={5}>
                    <Heading m={0} as="h2" fontSize="4xl">
                        Welcome to the Energy Portal (V: 17.4.7)
                    </Heading>
                    <InputGroup borderRadius="5px" borderColor="rgba(169, 169, 169, 0.87)">
                        <label style={{'width': '100%'}}>
                            <Text fontFamily='Titillium Web' color="#2C628D" fontWeight="600" as='span'>
                                Email:
                            </Text>
                            <Input
                                css={styles.input}
                                isInvalid={!!errors.email}
                                {...register('email', {required: true})}
                                name="email"/>
                        </label>
                    </InputGroup>
                    <InputGroup borderRadius="5px" borderColor="rgba(169, 169, 169, 0.87)">
                        <label style={{'width': '100%'}}>
                            <Text fontFamily='Titillium Web' color="#2C628D" fontWeight="600" as='span'>
                                Password:
                            </Text>
                            <Input
                                css={styles.input}
                                isInvalid={errors.password}
                                {...register('password', {required: true})}
                                type="password"/>
                        </label>
                    </InputGroup>
                    {error && (<Text color="red.100" as='h2' m={0} fontSize="sm">{error}</Text>)}
                    <Button isLoading={isLoading || isUserLoading} colorScheme="blue.200" type="submit" height="40px !important" width="467px">
                        <img width="25px" src="/images/login.svg" alt="Energy"/>
                        <Text ml={2} fontWeight="700" fontSize="18px" color="white">
                            Login
                        </Text>
                    </Button>
                    <Box minH='50px' w="100%" border="1px solid #A9A9A9" borderRadius="6px">
                        <Box minH='50px' display="flex" alignItems='center' fontSize="md" as="span">
                            <Box minH='50px' display='flex' justifyContent='center' alignItems='center' w="40%" borderRight='1px solid #A9A9A9' fontWeight="400" fontSize="18px" fontStyle="italic" color="black">
                                Don&apos;t have an account?
                            </Box>
                            <Box w="20%" minH='50px' alignItems='center' display='flex' borderRight='1px solid #A9A9A9' justifyContent='center'>
                                <Link to="/register">
                                    <TextButton fontFamily='Titillium Web' variant="transparent" textDecorationLine="underline" fontWeight="600" fontSize="18px">
                                        Sign Up
                                    </TextButton>
                                </Link>
                            </Box>
                            <Box textAlign="center" w="40%">
                                <Link to="/forgot-password">
                                    <TextButton fontFamily='Titillium Web' textDecorationLine="underline" variant="transparent" fontWeight="600" fontSize="18px">
                                        Forgot password?
                                    </TextButton>
                                </Link>
                            </Box>
                        </Box>
                    </Box>
                </Stack>
            </form>
        </Container>
    )
}

export default Login
