import React, {useEffect, useState} from 'react'
import {Box} from '@chakra-ui/react'
import {Bar} from 'react-chartjs-2'
import {S3_BUCKET} from "../../../constants";

const ChartActiveCompanies = ({company}) => {
    const [images, setImages] = useState([])

    useEffect(() => {
        if (!company) return
        let image = []
        for (let i = 0; i < company.length; i++) {
            if (company[i][0]?.image) {
                image.push(S3_BUCKET + company[i][0]?.image)
            } else {
                image.push('/images/defaultLamp.jpg')
            }
        }
        setImages(image)
    }, [company])


    const data = {
        labels: ['', '', '', '', '', ''],
        datasets: [
            {
                label: 'LED system',
                data: [
                    company[0]?.total,
                    company[1]?.total,
                    company[2]?.total,
                    company[3]?.total,
                    company[4]?.total,
                    company[5]?.total,
                ],
                fill: false,
                backgroundColor: [
                    '#FF3939',
                    '#93D500',
                    '#FCFF56',
                    '#56FFE1',
                    '#5671FF',
                    '#193C5B',
                ],
                borderColor: [
                    '#FF3939',
                    '#93D500',
                    '#FCFF56',
                    '#56FFE1',
                    '#5671FF',
                    '#193C5B',
                ],
                hoverBackgroundColor: [
                    '#FF3939',
                    '#93D500',
                    '#FCFF56',
                    '#56FFE1',
                    '#5671FF',
                    '#193C5B',
                ],
                hoverBorderColor: [
                    '#FF3939',
                    '#93D500',
                    '#FCFF56',
                    '#56FFE1',
                    '#5671FF',
                    '#193C5B',
                ],
                barPercentage: 0.3,
            },
        ],
    }

    const options = {
        responsive: true,
        legend: {
            display: false,
        },
        maintainAspectRatio: false,
        scales: {
            yAxes: [{
                ticks: {
                    beginAtZero: true,
                    callback(value, _index, values) {
                        return value.toLocaleString('en-US')
                    },
                },
            }],
            xAxes: [{
                ticks: {
                    callback: function(value, index) {
                        return '';
                    },
                },
            }],
        },
        tooltips: {
            enabled: false,
            custom: function (tooltipModel) {
                let tooltipEl = document.getElementById('chartjs-tooltip')

                if (!tooltipEl) {
                    tooltipEl = document.createElement('div');
                    tooltipEl.id = 'chartjs-tooltip';
                    tooltipEl.innerHTML = '<div class="main-div"></div>';
                    document.body.appendChild(tooltipEl);
                }
                if (tooltipModel.opacity === 0) {
                    tooltipEl.style.opacity = 0
                    return;
                }
                tooltipEl.classList.remove('above', 'below', 'no-transform')
                if (tooltipModel.yAlign) {
                    tooltipEl.classList.add(tooltipModel.yAlign)
                } else {
                    tooltipEl.classList.add('no-transform')
                }
                if (tooltipModel.body) {
                    const imageUrl = images[tooltipModel.dataPoints[0].index];
                    const number = company[tooltipModel.dataPoints[0].index]?.total;
                    const titles = company[tooltipModel.dataPoints[0].index][0]?.name;
                    const backgroundColor = tooltipModel.labelColors[0].backgroundColor;
                    tooltipEl.innerHTML = `
                      <div style="display: flex; align-items: center;justify-content: space-between; width: 489px; height: 60px; padding-left: 15px; padding-right: 15px; 
                      background-color: #FFFFFF; border-radius: 10px; box-shadow: 2px 2px 10px 3px #00000075;">
                        <div style="display: flex; align-items: center">
                             <img src="${imageUrl}" alt="Image" style="width: 76px; height: 51px; object-fit: cover"/>
                             <p style="margin-left: 20px; font-size: 18px">${titles}</p>
                        </div>
                        <div style="display: flex; align-items: center">
                            <h2 style="margin: 0; font-size: 18px">${number}</h2>
                            <div style="background-color: ${backgroundColor};margin-left: 20px; width: 15px; height: 60px"></div>
                        </div>
                      </div>
                    `;
                }
                const position = this._chart.canvas.getBoundingClientRect()

                tooltipEl.style.opacity = 1
                tooltipEl.style.position = 'absolute'
                tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
                tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
                tooltipEl.style.pointerEvents = 'none';
            }
        },
        animation: false,
        hover: {
            animationDuration: 0
        },
        responsiveAnimationDuration: 0
    }


    return (
        <Box
            position='relative'
            height="632px"
            width="1037px">
            <Bar
                redraw={true}
                plugins={[
                    {
                        id: 'pluginId',
                        afterDatasetsDraw: (chart) => {
                            const ctx = chart.ctx
                            const xAxis = chart.scales['x-axis-0']
                            xAxis.ticks.forEach((tick, index) => {
                                const x = xAxis.getPixelForTick(index)
                                const img = new Image()
                                img.src = images[index]
                                img.objectFit = 'cover'
                                img.onload = () => {
                                    ctx.drawImage(img, x - 25, chart.chartArea.bottom + 10, 50, 35)
                                }
                            })
                        },
                    },
                ]}
                options={options}
                data={data}/>
        </Box>
    )
}

export default ChartActiveCompanies
