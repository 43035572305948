import React from "react";
import {Modal, ModalBody, ModalContent, ModalHeader, ModalOverlay, Text, Flex, Button} from "@chakra-ui/react";
import IconButton from "../../Theme/IconButton";
import Icon from "../../Theme/Icon";

const DeleteReportModal = ({isOpen, onClose, deleteReport, loading}) => (
    <Modal isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay/>
        <ModalContent overflow="hidden" borderRadius="10px" minW="853px">
            <ModalHeader bg='#FF8383' fontWeight="700" fontSize="24px" textAlign="center">
                Delete Report?
            </ModalHeader>
            <IconButton height="unset" onClick={onClose} _hover={{bg: 'none',}} _active={{bg: 'none',}} bg="none" aria-label="close button" position="absolute" top="1.5rem" right="0.75rem" icon={<Icon type="darkClose"/>}/>
            <ModalBody pt='50px' pb='50px' pl='120px' pr='120px' fontSize='24px' textAlign='center' overflow="scroll" maxH="600px">
                <Text>
                    Are you sure you want to delete this report?  It will no longer be viewable to other project participants...
                </Text>
                <Flex fontFamily='Titillium Web' width='100%' justifyContent='space-between' mt={10}>
                    <Button fontSize='18px' bg='#DBDADA' color='black' width='254px' height='50px' onClick={onClose}>
                        Nevermind, Go Back
                    </Button>
                    <Button fontSize='18px' isLoading={loading} bg='#B01919' color='white' width='254px' height='50px' onClick={deleteReport}>
                        Delete Report
                    </Button>
                </Flex>
            </ModalBody>
        </ModalContent>
    </Modal>
)

export default DeleteReportModal