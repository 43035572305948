import React from 'react'
import {
    Flex,
    Box,
    Image,
} from '@chakra-ui/react'
import {S3_BUCKET} from '../../../constants'

const CompCard = ({
                      name,
                      image,
                      color,
                      onClick,
                      seen,
                  }) => (
    <Flex
        pl={2}
        alignItems="center"
        height="57px"
        width="330px"
        borderRadius="10px"
        boxShadow="2px 4px 10px rgba(0, 0, 0, 0.25)"
        bg="white.100">
        <Box
            mr={4}
            height="100%"
            width="12px"
            bg={color}/>
        <Image
            maxH="50px"
            maxW="50px"
            objectFit="cover"
            mr={6}
            name={name}
            src={image ? S3_BUCKET + image : ''}/>
        <Box
            onClick={onClick}
            _hover={{
                fontWeight: '700',
                textDecoration: 'underline',
            }}
            cursor="default"
            fontWeight="400"
            fontSize="xl"
            textTransform="capitalize"
            as="span">
            {name}
        </Box>
        {
            !seen && (
                <Box
                    ml="8"
                    fontWeight="bold"
                    fontSize="md"
                    color="green.100"
                    textTransform="capitalize"
                    as="span">
                    New
                </Box>
            )
        }
    </Flex>
)

export default CompCard
