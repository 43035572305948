import React from "react";
import {Modal, ModalBody,Flex,Button, ModalContent, ModalHeader, ModalOverlay, Text,} from "@chakra-ui/react";
import IconButton from "../../Theme/IconButton";
import Icon from "../../Theme/Icon";
import {useHistory} from "react-router-dom";

const ExitReport = ({isOpen, onClose, unfinished, route}) => {
    const history = useHistory()
    return (
        <Modal isCentered isOpen={isOpen} onClose={onClose}>
            <ModalOverlay/>
            <ModalContent overflow="hidden" borderRadius="10px" minW="853px">
                <ModalHeader bg='#FF8383' fontWeight="700" fontSize="24px" textAlign="center">
                    Leaving So Soon?
                </ModalHeader>
                <IconButton height="unset" onClick={onClose} _hover={{bg: 'none',}} _active={{bg: 'none',}} bg="none" aria-label="close button" position="absolute" top="1.5rem" right="0.75rem" icon={<Icon type="darkClose"/>}/>
                <ModalBody pt='50px' pb='50px' pl='120px' pr='120px' fontSize='24px' textAlign='center' overflow="scroll" maxH="600px">
                    <Text>
                        This report has not been saved with a designated a status! To proceed, Select Delete Report to
                        erase this proposal, or Mark as Unfinished to save it.
                    </Text>
                    <Flex fontFamily='Titillium Web' width='100%' justifyContent='space-between' mt={10}>
                        <Button fontSize='18px' bg='#B01919' color='white' width='254px' height='50px' onClick={() => {
                            history.push(`/${route.toLowerCase()}`)
                        }}>
                            Delete Report
                        </Button>
                        <Button fontSize='18px' bg='#FF7F56' color='black' width='254px' height='50px' onClick={() => {
                            onClose()
                            unfinished()
                        }}>
                            Mark As Unfinished
                        </Button>
                    </Flex>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}
export default ExitReport