import React from 'react'
import { Flex } from '@chakra-ui/react'

const FlexMask = ({ children }) => (
  <Flex
    height="57px"
    width="100%"
    alignItems="center"
    pl={6}
    pr={10}
    background="white.100"
    overflow="hidden"
    boxShadow="2px 4px 10px rgba(0, 0, 0, 0.25)"
    borderRadius="10px">
    {children}
  </Flex>
)

export default FlexMask
