import {Button, Flex, Modal, ModalBody, ModalContent, ModalHeader, ModalOverlay, Text} from "@chakra-ui/react";
import IconButton from "../../Theme/IconButton";
import Icon from "../../Theme/Icon";
import React from "react";

const BiddingErrorModal = ({isOpenBiddingError, onCloseBiddingError}) => {
    return (
        <Modal isCentered isOpen={isOpenBiddingError} onClose={onCloseBiddingError}>
            <ModalOverlay/>
            <ModalContent overflow="hidden" borderRadius="10px" minW="853px">
                <ModalHeader bg='#FFB68D' fontWeight="700" fontSize="24px" textAlign="center">
                    Error With Bidding Process
                </ModalHeader>
                <IconButton height="unset" onClick={onCloseBiddingError} _hover={{bg: 'none',}} _active={{bg: 'none',}} bg="none" aria-label="close button" position="absolute" top="1.5rem" right="0.75rem" icon={<Icon type="darkClose"/>}/>
                <ModalBody pt='50px' pl='120px' pr='120px' fontSize='24px' textAlign='center' overflow="scroll" maxH="600px">
                    <Text>
                        Sorry, you cannot open the bidding process until you've filled in the necessary fields for
                        reporting...
                    </Text>
                    <Flex pb='80px' alignItems='center' justifyContent='center'>
                        <Button mt={10} onClick={onCloseBiddingError} mb={3} width='254px' height='51px' fontWeight='700' fontSize='18px' backgroundColor='#2C628D'>
                            Understood
                        </Button>
                    </Flex>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}

export default BiddingErrorModal