import {Box, Heading, HStack, Switch, Text} from "@chakra-ui/react";
import styles from "./styles";
import {AU, EU, REU, SU, SUPERADMIN} from "../../../constants/user/userEnum";

const CommuniactionPreferences = ({readOnly = false,userType = REU, setCommunicationPreferences, communicationPreferences}) => (
    <Box pb='150px' width='100%'>
        <Heading fontSize='24px' mb={0}>Communication Preferences</Heading>
        <Text
            fontSize='18px'
            fontFamily='Titillium Web'
            fontWeight='400'>
            Choose when you’d like Focus Earth to send you an email notification:
        </Text>
        <HStack fontSize='18px' justifyContent='space-between' mt='50px'>
            <HStack css={styles.communiactionBox}>
                <Text>Messages</Text>
                <Switch
                    isChecked={communicationPreferences.messages}
                    onChange={(e) => {
                        setCommunicationPreferences({...communicationPreferences, messages: e.target.checked})
                    }}
                    isReadOnly={readOnly}/>
            </HStack>
            <HStack css={styles.communiactionBox}>
                <Text>
                    {userType === SUPERADMIN || userType === EU || userType === SU ? 'Bids' : userType === AU ? 'Catalogues' : 'Projects'}
                </Text>
                <Switch
                    isChecked={userType === SUPERADMIN || userType === EU || userType === SU ? communicationPreferences.bids : userType === AU ? communicationPreferences.catalogues : communicationPreferences.projects}
                    onChange={(e) => {
                        if (userType === SUPERADMIN || userType === EU || userType === SU) {
                            setCommunicationPreferences({...communicationPreferences, bids: e.target.checked})
                        } else if (userType === AU) {
                            setCommunicationPreferences({...communicationPreferences, catalogues: e.target.checked})
                        } else {
                            setCommunicationPreferences({...communicationPreferences, projects: e.target.checked})
                        }
                    }}
                    variant='secondary'
                    isReadOnly={readOnly}/>
            </HStack>
            <HStack css={styles.communiactionBox}>
                <Text>
                    {userType === SUPERADMIN ? 'Projects' : 'Orders'}
                </Text>
                <Switch
                    isChecked={userType === SUPERADMIN ? communicationPreferences.projects : communicationPreferences.orders}
                    onChange={(e) => {
                        if (userType === SUPERADMIN) {
                            setCommunicationPreferences({...communicationPreferences, projects: e.target.checked})
                        } else {
                            setCommunicationPreferences({...communicationPreferences, orders: e.target.checked})
                        }
                    }}
                    variant='secondary'
                    isReadOnly={readOnly}/>
            </HStack>
            <HStack css={styles.communiactionBox}>
                <Text>Shipping</Text>
                <Switch
                    isChecked={communicationPreferences.shipping}
                    onChange={(e) => {
                        setCommunicationPreferences({...communicationPreferences, shipping: e.target.checked})
                    }}
                    isReadOnly={readOnly}/>
            </HStack>
        </HStack>
    </Box>
)

export default CommuniactionPreferences