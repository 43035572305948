import {Box, Flex, Heading, Text, Image} from "@chakra-ui/react";
import UsersCountChart from "../DataCharts/UsersCountChart";
import ChartLuminaires from "../DataCharts/ChartLuminaires";
import {S3_BUCKET} from "../../../constants";
import CompanyChartFixedColors from "../DataCharts/CompanyChartFixedColors";
import ChartActiveCompanies from "../DataCharts/ChartActiveCompanies";
import ActiveUsersUsageChart from "../DataCharts/ActiveUsersUsageChart";
import Loading from "../../../components/Loading";

const Overview = ({countUsers, usage, isLoading, countUsersTotal, usersPerCompany, activeCompanies, companyColors}) => {
    let countUsersCompany = 0
    if (isLoading) return <Loading/>

    return (
        <Box>
            <Flex borderRadius='10px' pl={4} alignItems='center' minH='57px' bg='#E6E6E6'>
                <Heading m={0} fontSize='24px'>Overview</Heading>
            </Flex>
            <Flex mt={9} justifyContent='space-between'>
                <Flex width='487px' flexDirection='column'>
                    <Flex pl={4} justifyContent='space-between'>
                        <Heading m={0} fontSize='16px' width='33%'>User Type</Heading>
                        <Heading m={0} fontSize='16px' pl={3} width='33%'>User QTY</Heading>
                        <Heading m={0} fontSize='16px' pl={3} width='33%'>EP Usage (hrs)</Heading>
                    </Flex>
                    <Flex fontSize='18px' mt={4} pl={4} alignItems='center' minH='57px' bg='#FFFFFF'
                          boxShadow='2px 4px 10px rgba(0, 0, 0, 0.25)' borderRadius='10px'
                          justifyContent='space-between'>
                        <Flex width='33%' pl={3} alignItems='center' height='100%' borderLeft='12px solid #C956FF'>
                            Real Estate
                        </Flex>
                        <Flex pl={3}
                              width='33%'>{countUsers?.RealEstate?.length > 0 && countUsers?.RealEstate[0]?.count_c}</Flex>
                        <Flex pl={3}
                              width='33%'>{countUsers?.RealEstate?.length > 0 && countUsers?.RealEstate[0]?.sum_total}</Flex>
                    </Flex>
                    <Flex fontSize='18px' mt={4} pl={4} alignItems='center' minH='57px' bg='#FFFFFF'
                          boxShadow='2px 4px 10px rgba(0, 0, 0, 0.25)' borderRadius='10px'
                          justifyContent='space-between'>
                        <Flex width='33%' pl={3} alignItems='center' height='100%' borderLeft='12px solid #FFD439'>
                            Supplier
                        </Flex>
                        <Flex pl={3}
                              width='33%'>{countUsers?.Supplier?.length > 0 && countUsers?.Supplier[0]?.count_c}</Flex>
                        <Flex pl={3}
                              width='33%'>{countUsers?.Supplier?.length > 0 && countUsers?.Supplier[0]?.sum_total}</Flex>
                    </Flex>
                    <Flex fontSize='18px' mt={4} pl={4} alignItems='center' minH='57px' bg='#FFFFFF'
                          boxShadow='2px 4px 10px rgba(0, 0, 0, 0.25)' borderRadius='10px'
                          justifyContent='space-between'>
                        <Flex width='33%' pl={3} alignItems='center' height='100%' borderLeft='12px solid #FF7F56'>
                            Electrician
                        </Flex>
                        <Flex pl={3}
                              width='33%'>{countUsers?.Electrician?.length > 0 && countUsers?.Electrician[0]?.count_c}</Flex>
                        <Flex pl={3}
                              width='33%'>{countUsers?.Electrician?.length > 0 && countUsers?.Electrician[0]?.sum_total}</Flex>
                    </Flex>
                    <Flex fontSize='18px' mt={4} pl={4} alignItems='center' minH='57px' bg='#FFFFFF'
                          boxShadow='2px 4px 10px rgba(0, 0, 0, 0.25)' borderRadius='10px'
                          justifyContent='space-between'>
                        <Flex width='33%' pl={3} alignItems='center' height='100%' borderLeft='12px solid #8FCF74'>
                            Sales
                        </Flex>
                        <Flex pl={3} width='33%'>{countUsers?.Sales?.length > 0 && countUsers?.Sales[0]?.count_c}</Flex>
                        <Flex pl={3}
                              width='33%'>{countUsers?.Sales?.length > 0 && countUsers?.Sales[0]?.sum_total}</Flex>
                    </Flex>
                    <Flex fontSize='18px' mt={4} pl={4} alignItems='center' minH='57px' bg='#FFFFFF'
                          boxShadow='2px 4px 10px rgba(0, 0, 0, 0.25)' borderRadius='10px'
                          justifyContent='space-between'>
                        <Flex width='33%' pl={3} alignItems='center' height='100%' borderLeft='12px solid #D1D1D1'>
                            Administrator
                        </Flex>
                        <Flex pl={3}
                              width='33%'>{countUsers?.Administrator?.length > 0 && countUsers?.Administrator[0]?.count_c}</Flex>
                        <Flex pl={3}
                              width='33%'>{countUsers?.Administrator?.length > 0 && countUsers?.Administrator[0]?.sum_total}</Flex>
                    </Flex>
                    <Flex mt={4} pl={4} alignItems='center' minH='57px' border='2px solid #2E8A07' bg='#FFFFFF'
                          boxShadow='2px 4px 10px rgba(0, 0, 0, 0.25)' borderRadius='10px' fontWeight='700'
                          justifyContent='space-between'>
                        <Heading m={0} fontSize='18px' color='#2E8A07' width='33%' pl={3}>
                            Total
                        </Heading>
                        <Heading m={0} fontSize='18px' pl={3} width='33%'>{countUsersTotal[0]}</Heading>
                        <Heading m={0} fontSize='18px' pl={3} width='33%'>{countUsersTotal[1]}</Heading>
                    </Flex>
                </Flex>
                <Flex>
                    <Flex alignItems='center' flexDirection='column'>
                        <Heading m={0} fontSize='16px'>Total Users: {countUsersTotal[0]}</Heading>
                        <UsersCountChart
                            type={1}
                            score={countUsers}/>
                    </Flex>
                    <Flex ml={7} alignItems='center' flexDirection='column'>
                        <Heading m={0} fontSize='16px'>Total EP Usage: {countUsersTotal[1]}</Heading>
                        <UsersCountChart
                            type={2}
                            score={countUsers}/>
                    </Flex>
                </Flex>
            </Flex>
            <Flex mt={8} borderRadius='10px' pl={4} alignItems='center' minH='57px' bg='#E6E6E6'>
                <Heading m={0} fontSize='24px'>Most Active Companies</Heading>
            </Flex>
            <Flex mt={6} height="100%" justifyContent='space-evenly' alignItems="center">
                <Flex fontFamily='Titillium Web' fontSize='16px' style={{writingMode: 'tb-rl'}} justifyContent='center'
                      alignItems='center' transform='rotate(-180deg)'>
                    Hours
                </Flex>
                <ChartActiveCompanies company={activeCompanies}/>
            </Flex>
            <Flex mt={10} borderRadius='10px' pl={4} alignItems='center' minH='57px' bg='#E6E6E6'>
                <Heading m={0} fontSize='24px'>Users Per Company</Heading>
            </Flex>
            <Flex mt={9} justifyContent='space-between'>
                <Flex alignItems='center' flexDirection='column'>
                    <Heading m={0} fontSize='16px' fontWeight='700'>Total Users</Heading>
                    <CompanyChartFixedColors
                        width='519px'
                        height='519px'
                        colors={companyColors}
                        score={usersPerCompany}/>
                </Flex>
                <Flex flexDirection='column'>
                    <Flex pl={4} fontWeight='700' justifyContent='space-between'>
                        <Heading m={0} fontSize='16px' width='10%'>Color</Heading>
                        <Heading m={0} fontSize='16px' pl={3} width='55%'>Company</Heading>
                        <Heading m={0} fontSize='16px' pl={3} width='30%'>Total Users</Heading>
                    </Flex>
                    {usersPerCompany?.map((company, idx) => (
                        <Flex width='594px' fontSize='18px' key={idx} mt={4} pl={4} alignItems='center' minH='57px'
                              bg='#FFFFFF' boxShadow='2px 4px 10px rgba(0, 0, 0, 0.25)' borderRadius='10px'
                              justifyContent='space-between'>
                            <Flex width='10%' pl={3} alignItems='center' height='100%'
                                  borderLeft={'12px solid' + companyColors[idx]}></Flex>
                            <Flex pl={3} alignItems='center' width='55%'>
                                <Image width='50px' height='40px' objectFit='cover' mr={2}
                                       src={company[0]?.image ? S3_BUCKET + company[0]?.image : ' '}/>
                                {company[0]?.name.replace(/(.{30})..+/, "$1…")}
                            </Flex>
                            <Flex pl={3} width='30%'>{company?.total}</Flex>
                        </Flex>))}
                    <Flex width='594px' mt={4} pl={4} alignItems='center' minH='57px' border='2px solid #2E8A07'
                          bg='#FFFFFF' boxShadow='2px 4px 10px rgba(0, 0, 0, 0.25)' borderRadius='10px' fontWeight='700'
                          justifyContent='space-between'>
                        <Heading fontSize='18px' m={0} color='#2E8A07' width='70%' pl={3} alignItems='center'>
                            Total
                        </Heading>
                        <Heading fontSize='18px' m={0} pl={3} width='30%'>
                            {(() => {
                                if (usersPerCompany?.length > 0) {
                                    usersPerCompany?.map((a) => {
                                        countUsersCompany += Number(a.total)
                                    })
                                    return countUsersCompany
                                }
                            })()}
                        </Heading>
                    </Flex>
                </Flex>
            </Flex>
            <Flex mt={8} borderRadius='10px' pl={4} alignItems='center' minH='57px' bg='#E6E6E6'>
                <Heading m={0} fontSize='24px'>New Users</Heading>
            </Flex>
            <Flex mt={6} justifyContent='space-evenly' alignItems="center">
                <Flex fontFamily='Titillium Web' fontSize='16px' style={{writingMode: 'tb-rl'}} justifyContent='center'
                      alignItems='center' transform='rotate(-180deg)'>
                    New Users
                </Flex>
                <ChartLuminaires
                    countUsers={countUsers}/>
            </Flex>
            <Flex mt={8} borderRadius='10px' pl={4} alignItems='center' minH='57px' bg='#E6E6E6'>
                <Heading m={0} fontSize='24px'>Most Active Users</Heading>
            </Flex>
            <Flex mt={9} justifyContent='space-between'>
                <Flex flexDirection='column'>
                    <Flex pl={4} fontWeight='700' justifyContent='space-between'>
                        <Heading m={0} fontSize='16px' width='10%'>Color</Heading>
                        <Heading m={0} fontSize='16px' pl={3} width='20%'>Company</Heading>
                        <Heading m={0} fontSize='16px' pl={3} width='30%'>Users</Heading>
                        <Heading m={0} fontSize='16px' pl={3} width='30%'>Active Hours</Heading>
                    </Flex>
                    {usage?.map((company, idx) => (
                        <Flex width='594px' fontSize='18px' key={idx} mt={4} pl={4} alignItems='center' minH='57px'
                              bg='#FFFFFF' boxShadow='2px 4px 10px rgba(0, 0, 0, 0.25)' borderRadius='10px'
                              justifyContent='space-between'>
                            <Flex width='10%' pl={3} alignItems='center' height='100%'
                                  borderLeft={'12px solid' + companyColors[idx]}>
                            </Flex>
                            <Flex pl={3} alignItems='center' width='20%'>
                                <Image width='50px' height='40px' objectFit='cover' mr={2}
                                       src={company?.companyLogo ? S3_BUCKET + company?.companyLogo : ' '}/>
                            </Flex>
                            <Flex alignItems='center' pl={3}
                                  width='30%'>{company?.userName.replace(/(.{15})..+/, "$1…")}</Flex>
                            <Flex pl={3} width='30%'>{(Number(company?.total) / 60)?.toFixed(2)}</Flex>
                        </Flex>))}
                    <Flex width='594px' mt={4} pl={4} alignItems='center' minH='57px' border='2px solid #2E8A07'
                          bg='#FFFFFF' boxShadow='2px 4px 10px rgba(0, 0, 0, 0.25)' borderRadius='10px'
                          fontWeight='700' justifyContent='space-between'>
                        <Heading m={0} fontSize='18px' color='#2E8A07' width='70%' pl={3}>
                            Total
                        </Heading>
                        <Heading m={0} fontSize='18px' pl={3} width='30%'>
                            {(() => {
                                let countUsersCompany = 0
                                if (usage?.length > 0) {
                                    usage?.map((a) => {
                                        countUsersCompany += (Number(a.total) / 60)
                                    })
                                    return countUsersCompany.toFixed(2)
                                }
                            })()}
                        </Heading>
                    </Flex>
                </Flex>
                <Flex alignItems='center' flexDirection='column'>
                    <Heading m={0} fontSize='16px'>Most Active Users</Heading>
                    {usage && (
                        <ActiveUsersUsageChart
                            width='519px'
                            height='519px'
                            colors={companyColors}
                            score={usage}/>
                    )}
                </Flex>
            </Flex>
        </Box>
    )
}

export default Overview