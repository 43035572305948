import { IconButton as ChakraButton } from '@chakra-ui/react'
import styled from '@emotion/styled'

const IconButton = styled(ChakraButton)`
    &:active {
        opacity: 1;
    }
    &:focus {
        box-shadow: none;
        outline: none;
    }
`
export default IconButton
