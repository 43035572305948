import {Divider} from '@chakra-ui/react'

const CustomDivider = ({my, ...props}) => (
    <Divider
        my={my ? my : "36px"}
        borderColor="#DADADA"
        border="3px solid"
        orientation="horizontal"
        {...props}/>
)

export default CustomDivider