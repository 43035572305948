import { useQuery } from 'react-query'
import { companyUseCase } from '../../factories'

const useGetCompanyChanges = (id) => {
    const {
        refetch, isLoading, data: company, error,
    } = useQuery('companyChanges', () => companyUseCase.getCompanyChanges(id))

    return {
        refetch,
        isLoading,
        error,
        company,
    }
}

export default useGetCompanyChanges