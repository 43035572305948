import React, {useEffect, useState} from 'react'
import {
    Accordion,
    AccordionItem,
    AccordionButton,
    Flex,
    AccordionPanel,
    Text,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Heading,
} from '@chakra-ui/react'
import Icon from '../../../components/Theme/Icon'
import styles from './styles'

const AccordionSolo = ({luminaire}) => {
    const [description, setDescription] = useState([])

    useEffect(() => {
        const desc = []
        if (luminaire.fixtureDescription) {
            desc.push(luminaire.fixtureDescription)
        }
        if (luminaire.lampDescription) {
            desc.push(` , (${luminaire.lampPerFixture}) ${luminaire.lampDescription}`)
        }
        if (luminaire.powerSupplyDescription) {
            desc.push(` powered by ${luminaire.powerSupplyDescription}`)
        }
        if (luminaire.controlDescription) {
            desc.push(` controlled by ${luminaire.controlDescription}`)
        }
        if (luminaire.accessoryDescriptionA) {
            desc.push(` with ${luminaire.accessoryDescriptionA}`)
        }
        setDescription(desc)
    }, [luminaire])

    return (
        <>
            <Accordion
                allowMultiple
                defaultIndex={[0]}
                allowToggle>
                <AccordionItem
                    border="none">
                    {({isExpanded}) => (
                        <>
                            <AccordionButton
                                position="relative"
                                p={0}
                                width="550px"
                                minHeight="52px"
                                _focus={{boxShadow: '0px 2px 10px 2px rgba(0, 0, 0, 0.45)'}}
                                border="1px"
                                borderColor="grey.200"
                                boxShadow="0px 2px 10px 2px rgba(0, 0, 0, 0.45)">
                                <Flex
                                    px={4}
                                    minW="100%"
                                    alignItems="center"
                                    justifyContent="space-between">
                                    <Flex
                                        flex={1}
                                        alignItems="center"
                                        height="100%">
                                        <Text
                                            fontFamily='Frank Ruhl Libre'
                                            maxW="250px"
                                            overflow="scroll"
                                            whiteSpace="nowrap"
                                            fontSize="xl">
                                            {luminaire?.qty ? Number(luminaire?.qty) * (luminaire?.lampPerFixture ? Number(luminaire?.lampPerFixture) : 1) : ''}
                                        </Text>
                                    </Flex>
                                    <Flex
                                        flex={3}
                                        alignItems="center"
                                        height="100%">
                                        <Text
                                            fontFamily='Frank Ruhl Libre'
                                            maxW="350px"
                                            overflow="scroll"
                                            fontSize="xl">
                                            {description}
                                        </Text>
                                    </Flex>
                                    <Flex
                                        flex={1}
                                        height="100%"
                                        alignItems="center"
                                        justifyContent="flex-end">
                                        <Icon
                                            style={{
                                                transform: isExpanded ? 'rotate(90deg)' : 'inherit',
                                                position: 'relative',
                                                transition: 'all .3s',
                                            }}
                                            type="right-arrow"/>
                                    </Flex>
                                </Flex>
                            </AccordionButton>
                            <AccordionPanel
                                bg="white.100"
                                width="550px"
                                borderWidth="1px"
                                borderColor="grey.200"
                                p={0}>
                                <Table
                                    variant="unstyled">
                                    <Thead>
                                        <Tr>
                                            <Th
                                                fontFamily='Titilium Web'
                                                fontSize="xl"
                                                fontWeight="700"
                                                color="grey.200">
                                                COMPONENT
                                            </Th>
                                            <Th
                                                fontSize="xl"
                                                fontWeight="700"
                                                color="grey.200">
                                                <Flex
                                                    alignItems="center"
                                                    justifyContent="space-between">
                                                    <Heading
                                                        fontSize="xl"
                                                        as="h3">
                                                        DESCRIPTION
                                                    </Heading>
                                                </Flex>
                                            </Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody
                                        css={styles.tBody}>
                                        <Tr>
                                            <Td>
                                                Fixture
                                            </Td>
                                            <Td>
                                                <Flex
                                                    pr="1"
                                                    borderRadius="5px"
                                                    alignItems="center"
                                                    height="28px"
                                                    justifyContent="space-between"
                                                    border="1px solid"
                                                    borderColor="border.100">
                                                    <Text
                                                        maxW="230px"
                                                        overflow="scroll"
                                                        whiteSpace="nowrap"
                                                        fontSize="14px">
                                                        {luminaire?.fixtureDescription}
                                                    </Text>
                                                </Flex>
                                            </Td>
                                        </Tr>
                                        <Tr>
                                            <Td>
                                                Lamp
                                            </Td>
                                            <Td>
                                                <Flex
                                                    pr="1"
                                                    height="28px"
                                                    borderRadius="5px"
                                                    alignItems="center"
                                                    justifyContent="space-between"
                                                    border="1px solid"
                                                    borderColor="border.100">
                                                    <Text
                                                        maxW="230px"
                                                        overflow="scroll"
                                                        whiteSpace="nowrap"
                                                        fontSize="14px">
                                                        {luminaire?.lampDescription}
                                                    </Text>
                                                </Flex>
                                            </Td>
                                        </Tr>
                                        <Tr>
                                            <Td>
                                                Power Supply
                                            </Td>
                                            <Td>
                                                <Flex
                                                    pr="1"
                                                    height="28px"
                                                    borderRadius="5px"
                                                    alignItems="center"
                                                    justifyContent="space-between"
                                                    border="1px solid"
                                                    borderColor="border.100">
                                                    <Text
                                                        maxW="230px"
                                                        overflow="scroll"
                                                        whiteSpace="nowrap"
                                                        fontSize="14px">
                                                        {luminaire?.powerSupplyDescription}
                                                    </Text>
                                                </Flex>
                                            </Td>
                                        </Tr>
                                        <Tr>
                                            <Td>
                                                Control
                                            </Td>
                                            <Td>
                                                <Flex
                                                    pr="1"
                                                    height="28px"
                                                    borderRadius="5px"
                                                    alignItems="center"
                                                    justifyContent="space-between"
                                                    border="1px solid"
                                                    borderColor="border.100">
                                                    <Text
                                                        maxW="230px"
                                                        overflow="scroll"
                                                        whiteSpace="nowrap"
                                                        fontSize="14px">
                                                        {luminaire?.controlDescription}
                                                    </Text>
                                                </Flex>
                                            </Td>
                                        </Tr>
                                        <Tr>
                                            <Td>
                                                Accessory
                                            </Td>
                                            <Td>
                                                <Flex
                                                    pr="1"
                                                    height="28px"
                                                    borderRadius="5px"
                                                    alignItems="center"
                                                    justifyContent="space-between"
                                                    border="1px solid"
                                                    borderColor="border.100">
                                                    <Text
                                                        maxW="230px"
                                                        overflow="scroll"
                                                        whiteSpace="nowrap"
                                                        fontSize="14px">
                                                        {luminaire?.accessoryDescriptionA}
                                                    </Text>
                                                </Flex>
                                            </Td>
                                        </Tr>
                                    </Tbody>
                                </Table>
                            </AccordionPanel>
                        </>
                    )}
                </AccordionItem>
            </Accordion>
        </>
    )
}

export default AccordionSolo
