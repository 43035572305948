const ESTIMATE = 1
const ORDER = 2
const SHIPPING = 3
const INVOICE = 4
const RECEIPT = 5
const ARCHIVED = 6
const SHIPPING_DETAIL = 7
const REPORT_TYPE = 8
const PRINT_TYPE = 9

const ORDER_TYPES = {
    0 : ESTIMATE,
    1 : ORDER,
    2 : SHIPPING,
    3 : INVOICE,
    4 : RECEIPT,
    5 : ARCHIVED,
}

export {
    ESTIMATE,
    ORDER,
    SHIPPING,
    INVOICE,
    RECEIPT,
    ARCHIVED,
    ORDER_TYPES,
    SHIPPING_DETAIL,
    REPORT_TYPE,
    PRINT_TYPE,
}