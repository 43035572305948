import React, {useState} from 'react'
import {Link} from 'react-router-dom'
import InputMask from 'react-input-mask'
import {useForm} from 'react-hook-form'
import {Container, Flex, Heading, Text, Grid, GridItem, Box, Button as TextButton,} from '@chakra-ui/react'
import {useSignUp, useCreateUser, useValidateEmail,} from '../../core/hooks'
import {Button, Input, Checkbox,} from '../Theme'
import VerifyEmail from "../VerifyEmail";

const RegisterUser = ({type}) => {
    const [textErrors, setTextErrors] = useState([])
    const [checkbox, setCheckbox] = useState(false)
    const [done, setDone] = useState(false)
    const {register, handleSubmit, setValue, watch, formState: {errors},} = useForm()
    const {signUp, isLoading} = useSignUp();
    const {createUser, isLoading: isUserCreatingLoading} = useCreateUser()
    const validateEmail = useValidateEmail()
    const url = window.location.href
    const password = watch('password')

    const submit = (data) => {
        signUp(
            {
                email: data.email.toLowerCase(),
                password: data.password,
            },
            {
                onSuccess: (response) => {
                    if (url.substring(url.length - 2) === 'Sa') {
                        const input = {
                            id: response.userSub,
                            firstName: data.firstName,
                            lastName: data.lastName,
                            password: data.password,
                            email: data.email.toLowerCase(),
                            phone: data.phone,
                            token: url.substring(url.length - 13).slice(1),
                            type: 'Sa',
                        }
                        createUser(
                            input,
                            {
                                onSuccess: () => {
                                    setDone(true)
                                }, onError: (e) => {
                                    setTextErrors([e.response.data.errorMessage])
                                }
                            },
                        )
                    } else if (url.substring(url.length - 2) === 'Au') {
                        const input = {
                            id: response.userSub,
                            firstName: data.firstName,
                            lastName: data.lastName,
                            password: data.password,
                            email: data.email.toLowerCase(),
                            phone: data.phone,
                            token: url.substring(url.length - 13).slice(1),
                            type: 'Au',
                        }
                        createUser(
                            input,
                            {
                                onSuccess: () => {
                                    setDone(true)
                                }, onError: (e) => {
                                    setTextErrors([e.response.data.errorMessage])
                                }
                            },
                        )
                    } else {
                        const input = {
                            id: response.userSub,
                            firstName: data.firstName,
                            lastName: data.lastName,
                            password: data.password,
                            email: data.email.toLowerCase(),
                            phone: data.phone,
                            type,
                        }
                        createUser(
                            input,
                            {
                                onSuccess: () => {
                                    setDone(true)
                                }, onError: (e) => {
                                    setTextErrors([e.response.data.errorMessage])
                                }
                            },
                        )
                    }
                },
            },
        )
    }

    return (
        <Container position="relative" display="flex" alignItems="center" flexDirection="column" mx={4} py={12} mt={10} maxW="6xl" borderRadius="10px" boxShadow="0px 4px 10px rgba(34, 34, 34, 0.25)" bg="rgba(255, 255, 255, 0.8)">
            {done ? (<VerifyEmail/>) : (
                    <>
                        <Link to="/register">
                            <TextButton ml='7px' fontWeight="bold" variant="transparent" position="absolute" top="30px" left="30px">
                                <img width="25px" src="/images/back_register.png" alt="back arrow"/>
                                <Text ml={1} fontSize="18px" fontWeight='600'>BACK</Text>
                            </TextButton>
                        </Link>
                        <form noValidate onSubmit={handleSubmit(submit)}>
                            <Flex mb={12} width="761px" flexDirection="column" justifyContent="center">
                                <Heading textAlign="center" mt={-5} mb={3} as="h2" fontSize="4xl">
                                    Welcome to the Energy Portal
                                </Heading>
                                <Grid mt={8} width="100%" templateColumns="repeat(2, 1fr)" gap={8}>
                                    <GridItem colSpan={1}>
                                        <label style={{'width': '100%'}}>
                                            <Text as="span" fontWeight="600" fontSize="18px" color="#2C628D">First Name:</Text>
                                            <Input color="grey.300" isInvalid={!!errors.firstName} errorBorderColor="red.100" {...register('firstName', {required: true})}/>
                                        </label>
                                    </GridItem>
                                    <GridItem colSpan={1}>
                                        <label style={{'width': '100%'}}>
                                            <Text as="span" fontWeight="600" fontSize="18px" color="#2C628D">Last Name:</Text>
                                            <Input color="grey.300" errorBorderColor="red.100" isInvalid={!!errors.lastName} {...register('lastName', {required: true})} name="lastName"/>
                                        </label>
                                    </GridItem>
                                    <GridItem colSpan={1}>
                                        <label style={{'width': '100%'}}>
                                            <Text as="span" fontWeight="600" fontSize="18px" color="#2C628D">Email Address:</Text>
                                            <Input color="grey.300" errorBorderColor="red.100" isInvalid={!!errors.email} type="email"
                                                {...register('email', {
                                                    required: true,
                                                    validate: (value) => validateEmail(value),
                                                })}/>
                                        </label>
                                    </GridItem>
                                    <GridItem colSpan={1}>
                                        <label style={{'width': '100%'}}>
                                            <Text as="span" fontWeight="600" fontSize="18px" color="#2C628D">Phone Number:</Text>
                                            <Input color="grey.300" as={InputMask} mask="999-999-9999" maskChar="" autoComplete="on" formatChars={{'9': '[0-9]'}} errorBorderColor="red.100" isInvalid={!!errors.phone} onChange={(e) => setValue('phone', e.target.value)}/>
                                        </label>
                                    </GridItem>
                                    <GridItem colSpan={1}>
                                        <label style={{'width': '100%'}}>
                                            <Text as="span" fontWeight="600" fontSize="18px" color="#2C628D">Password:</Text>
                                            <Input color="grey.300" errorBorderColor="red.100" isInvalid={!!errors.password}
                                                {...register('password', {
                                                    required: true,
                                                    minLength: 8,
                                                })}
                                                type="password"/>
                                        </label>
                                    </GridItem>
                                    <GridItem colSpan={1}>
                                        <label style={{'width': '100%'}}>
                                            <Text as="span" fontWeight="600" fontSize="18px" color="#2C628D">Repeat Password:</Text>
                                            <Input color="grey.300" errorBorderColor="red.100" isInvalid={!!errors.repeatPassword}
                                                {...register('repeatPassword', {
                                                    required: true,
                                                    validate: (value) => value === password,
                                                })}
                                                type="password"/>
                                        </label>
                                    </GridItem>
                                </Grid>
                                <Text fontSize="xs" mb={5}>Minimum of 8 characters, one capital letter, and one number</Text>
                                {textErrors.length > 0 && textErrors.map((item) => (<Text key={item} textAlign="center" color="red.100" fontSize="sm">{item}</Text>))}
                                <label>
                                    <Text as="span" color="#2C628D" fontSize="18px" fontWeight="600" display="block">Terms & Conditions:</Text>
                                    <Checkbox w="100%" border="1px solid #A9A9A9" borderRadius="6px" isChecked={checkbox} p={2} onChange={() => setCheckbox(!checkbox)} iconColor="#2C628D" colorScheme="whiteAlpha">
                                        <Box color="#222222" fontSize="sm" as="span">
                                            Check here to indicate you have read and agree to the terms outlined in the Energy Portal &nbsp;
                                            <Text as="span" borderBottom="1px solid #A9A9A9"><a href="https://www.focusearth.com/terms-of-service-2" target='_blank'>Terms & Conditions</a></Text>
                                        </Box>
                                    </Checkbox>
                                </label>
                                <Button mx="auto" isLoading={isLoading || isUserCreatingLoading} isDisabled={!checkbox} colorScheme="blue.200" height="40px !important" type="submit" mt={12} width="467px">
                                    <img width="20px" src="/images/sign_up.svg" alt="sign Up"/>
                                    <Text ml={1} color="white" fontSize="18px">Create Account</Text>
                                </Button>
                            </Flex>
                        </form>
                    </>
                )
            }
        </Container>
    )
}

export default RegisterUser
