import {useEffect, useState} from 'react'
import {Box, Flex, useDisclosure, Text, Button, useToast, Stack, Select,} from '@chakra-ui/react'
import {InviteModal, Input,} from '../../../components/Theme'
import {ReactComponent as Plus} from '../../../assets/people.svg'
import UserModal from './UserModal'
import {useCreateNotification, useListUsers, useUser} from '../../../core/hooks'
import {ReactComponent as InvitationIcon} from "../../../assets/invitationSendIcon.svg";
import CustomSelectNames from "./CustomSelectNames";
import ToastComponent from "../../../components/toastComponent/ToastComponent";
import {REU, SA, SUPERADMIN, USER_LIST_TYPE_PROJECT} from "../../../constants/user/userEnum";
import httpRequest from "../../../core/services/http-request";

const ColleagueModal = ({project}) => {
    const [colleague, setColleague] = useState([])
    const [users, setUsers] = useState()
    const [names, setNames] = useState([])
    const toast = useToast()
    const {user} = useUser()
    const {isOpen, onClose, onOpen} = useDisclosure()
    const {createNotification, isLoading} = useCreateNotification()
    const {post} = httpRequest()

    const addColleague = () => {
        if (!names) return
        if (user?.type === SUPERADMIN) {
            // Invite Sales admin or Admin User to project
            createNotification({
                type: 104,
                receiverId: names,
                projectId: project.id,
            }, {
                onSuccess: () => {
                    toast({
                        render: () => (
                            <ToastComponent icon={<InvitationIcon/>} title={`Invitation sent to ${colleague}!`} bg='#2C628D'/>
                        ),
                        duration: 4000,
                        isClosable: true,
                        position: "top-right"
                    })
                    onClose()
                }
            })
        } else if (user?.type === SA) {
            createNotification({
                type: 104,
                receiverId: names,
                projectId: project.id,
            }, {
                onSuccess: () => {
                    toast({
                        render: () => (
                            <ToastComponent icon={<InvitationIcon/>} title={`Invitation sent to ${colleague}!`} bg='#2C628D'/>
                        ),
                        duration: 4000,
                        isClosable: true,
                        position: "top-right"
                    })
                    onClose()
                }
            })
        } else {
            createNotification({
                    type: 103,
                    receiverId: colleague,
                    projectId: project.id,
                },
                {
                    onSuccess: () => {
                        toast({
                            render: () => (
                                <ToastComponent icon={<InvitationIcon/>} title={`Invitation sent to ${names}!`} bg='#2C628D'/>
                            ),
                            duration: 4000,
                            isClosable: true,
                            position: "top-right"
                        })
                        onClose()
                    },
                })
        }
    }

    useEffect(() => {
        if (!project) return
        let variables = {
            type: USER_LIST_TYPE_PROJECT,
            projId: project?.id,
            ownerId: project?.user?.id,
            companyId: project?.company?.id
        }
        post('user-list',variables).then((usersResponse) => {
            setUsers(usersResponse.data.data)
            if (user?.type === REU){
                setUsers(project?.company?.owner ? [[project?.company?.owner?.firstName + ' ' + project?.company?.owner?.lastName, project?.company?.owner?.id]].concat(project?.company?.usersAssociated?.map((item) => [item.firstName + ' ' + item.lastName, item.id]))
                    :project?.company?.usersAssociated?.map((item) => [item.firstName + ' ' + item.lastName, item.id]))
            }
        })

    },[project])

    return (
        <Box>
            <Button onClick={onOpen} w="135px" leftIcon={<Plus/>} variant="blue">
                {user?.type === 'SUPERADMIN' || user?.type === 'Sa' ? 'Add User' : 'Add Colleague'}
            </Button>
            <InviteModal title={user?.type === 'SUPERADMIN' ? "Invite User to Project" : "Invite Colleague to Project"} isOpen={isOpen} onClose={onClose}>
                <Flex alignItems="center" flexDirection="column">
                    <Text my={12} fontSize="3xl">
                        {user?.type === 'SUPERADMIN' ? "Invite a user to participate on this project:" : "Invite a colleague to participate on this project:"}
                    </Text>
                    <Stack mx="auto" w="418px" spacing={4}>
                        <Input readOnly height='50px !important' border='1px solid #A9A9A9 !important' value={project?.title}/>
                        {
                            user?.type === 'Reu' ? (
                                <Select _focus='' placeholder='Select User' fontSize='16px' border='1px solid #A9A9A9 !important' height='50px'
                                    onChange={(e) => {
                                        let obj = JSON.parse(e.target.value)
                                        setColleague(Number(obj.id))
                                        setNames(obj.firstName + ' ' + obj.lastName)
                                    }}>
                                    {
                                        user?.company[0]?.usersAssociated?.map((item) => (
                                            <option value={JSON.stringify(item)}>
                                                {item.firstName + ' ' + item.lastName}
                                            </option>
                                        ))
                                    }
                                </Select>
                            ) : user?.type === 'Eu' ? (
                                <Select _focus='' placeholder='Select User' fontSize='16px' border='1px solid #A9A9A9 !important' height='50px'
                                    onChange={(e) => {
                                        let obj = JSON.parse(e.target.value)
                                        setColleague(Number(obj.id))
                                        setNames(obj.firstName + ' ' + obj.lastName)
                                    }}>
                                    {
                                        users?.map((item) => (
                                            <option value={JSON.stringify(item)}>
                                                {item.firstName + ' ' + item.lastName}
                                            </option>
                                        ))
                                    }
                                </Select>
                            ) : (
                                <CustomSelectNames
                                    names={names}
                                    setNames={setNames}
                                    repeat={1}
                                    value={colleague}
                                    optionsReu={users?.filter(filtered => filtered.type === 'Reu').map(item => [item.firstName + ' ' + item.lastName, item.id])}
                                    optionsSa={user?.type === 'SUPERADMIN' || user?.type === 'Sa' ? users?.filter((filtered) => filtered.type === 'Sa').map((item) => [item.firstName + ' ' + item.lastName, item.id]) : user?.company[0]?.usersAssociated?.map((item) => [item.firstName + ' ' + item.lastName, item.id])}
                                    setValue={setColleague}/>
                            )
                        }
                    </Stack>
                    <Button
                        mx="auto"
                        mt={12}
                        isLoading={isLoading}
                        onClick={addColleague}
                        mb="2.8rem"
                        height='51px'
                        fontSize='18px'
                        borderRadius="5px"
                        width="254px">
                        Invite
                    </Button>
                    <UserModal
                        onCloseInvite={onClose}/>
                </Flex>
            </InviteModal>
        </Box>
    )
}

export default ColleagueModal
