import React, {useEffect, useState} from 'react'
import {Stack, HStack, Box,} from '@chakra-ui/react'
import {ChakraSelectLabel, InputLabel} from '../../../components/Theme'
import NavigateModal from './NavigateModal'
import {CONTROL_TYPE} from "../../../constants/utils";
import {useGetExsiting} from "../../../core/hooks";

const Control = ({onClose, luminaireId, area, setArea, control, setControl, createKey, setCreateKey, setRecent, setType, description,}) => {
    const [filters, setFilters] = useState({type: '', manufacturer: '', dimming: '', model: '',controlQTYFixture: ''})
    const {refetch} = useGetExsiting( {
        type : 'Controls',
        controlType : control.controlType,
    })

    const create = () => {
        if (!control.controlType) return
        refetch().then((res) => {
            if (res?.data?.data?.data){
                setControl({...control, controlExisting: res.data.data.data})
            }
            const newArray = area?.luminaires.map((luminaire) => {
                let newObj = {...luminaire}
                if (newObj.id === luminaireId) {
                    newObj = {...newObj, ...control,controlExisting:res.data.data.data}
                }
                return newObj
            })
            setArea({...area, luminaires: newArray})
            setRecent([...newArray])
        })
    }

    if (createKey === true) {
        create()
        setCreateKey(false)
        onClose()
    }

    useEffect(() => {
        //Set description width parameters that exists
        const description = (control.controlType ? control.controlType : '') + (control.controlManufacturer ? " " + control.controlManufacturer : '') + (control.controlDimming ? " " + control.controlDimming : '') + (control.controlModel ? " " + control.controlModel : '')
        setControl({...control, controlDescription: description})
    }, [filters])

    return (
        <Stack spacing="5">
            <NavigateModal
                label={description}
                leftAction={() => {
                    create()
                    setType('power-supply')
                }}
                rightAction={() => {
                    create()
                    setType('accessory')
                }}
                left="Power Supply"
                right="Accessories"/>
            <HStack w="full" justifyContent="center" position="relative" alignItems="center" pt={5}>
                <Box position="absolute" bg="#A9A9A9" w="full" h="1px"/>
                <Box px="2" position="relative" bg="white" textTransform="uppercase" fontWeight="400" fontSize="xl" color="#2C628D" as="span">
                    control details
                </Box>
            </HStack>
            <HStack spacing="5" alignItems="flex-end">
                <InputLabel
                    height='46px'
                    maxW="90px"
                    type="number"
                    value={control?.controlQTYFixture}
                    onChange={(e) => {
                        setFilters({...filters, controlQTYFixture: e.target.value})
                        setControl({...control, controlQTYFixture: e.target.value,})
                    }}
                    pattern="[0-9]*"
                    label="QTY/Fixture"/>
                <InputLabel height='46px' label="Control Description:" color='#737373' backgroundColor='#EEEEEE !important' readOnly value={control?.controlDescription}
                    onChange={(e) => setControl({
                            ...control,
                            controlDescription: e.target.value
                        }
                    )}/>
            </HStack>
            <HStack w="full" justifyContent="center" position="relative" alignItems="center" py={5}>
                <Box position="absolute" bg="#A9A9A9" w="full" h="1px"/>
                <Box px="2" position="relative" bg="white" textTransform="uppercase" fontWeight="400" fontSize="xl" color="#2C628D" as="span">
                    filters
                </Box>
            </HStack>
            <HStack spacing="5">
                <ChakraSelectLabel
                    maxW="203px"
                    height='46px'
                    label="Type:"
                    placeholder="Select"
                    value={control?.controlType}
                    onChange={(e) => {
                        setFilters({
                            ...filters,
                            type: e.target.value
                        })
                        setControl({
                            ...control,
                            controlType: e.target.value
                        })
                    }}>
                    {CONTROL_TYPE.map((item, index) => (<option value={item} key={index}>{item}</option>))}
                </ChakraSelectLabel>
                <InputLabel height='46px' maxW="203px" label="Manufacturer:"
                    value={control?.controlManufacturer}
                    onChange={(e) => {
                        setFilters({
                            ...filters,
                            manufacturer: e.target.value
                        })
                        setControl({
                            ...control,
                            controlManufacturer: e.target.value
                        })
                    }}/>
                <InputLabel height='46px' maxW="203px" label="Dimming:"
                    value={control?.controlDimming}
                    onChange={(e) => {
                        setFilters({
                            ...filters,
                            dimming: e.target.value
                        })
                        setControl({
                            ...control,
                            controlDimming: e.target.value
                        })
                    }}/>
                <InputLabel
                    height='46px'
                    value={control?.controlModel}
                    onChange={(e) => {
                        setFilters({
                            ...filters,
                            model: e.target.value
                        })
                        setControl({
                            ...control,
                            controlModel: e.target.value
                        })
                    }}
                    label="Model:"/>
            </HStack>
        </Stack>
    )
}

export default Control
