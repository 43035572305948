import {useEffect, useState} from 'react'
import {useHistory} from 'react-router-dom'
import {Box, Flex, GridItem, Text, Button, Checkbox, Popover, PopoverArrow, PopoverBody, PopoverContent, PopoverTrigger, InputGroup, Input, InputLeftElement, SimpleGrid,} from '@chakra-ui/react'
import Navbar from '../../components/Navbar'
import {UserCard, Icon,} from '../../components/Theme'
import {S3_BUCKET} from '../../constants'
import InviteUserModal from './InviteUserModal'
import styles from "./styles";
import {createMuiTheme, MuiThemeProvider} from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Pagination from "material-ui-flat-pagination";
import {ReactComponent as LeftIcon} from "../../assets/leftIconShippingBlack.svg";
import {ReactComponent as RightIcon} from "../../assets/rightIconShippingBlack.svg";
import httpRequest from "../../core/services/http-request";
import {AU, EU, GENERAL, REU, SA, SU} from "../../constants/user/userEnum";

const Users = () => {
    const [filter, setFilter] = useState([])
    const [search, setSearch] = useState('')
    const [users, setUsers] = useState([])
    const history = useHistory()
    const [offset, setOffset] = useState(1)
    const [page, setPage] = useState(1)
    const theme = createMuiTheme();
    const {post} = httpRequest()

    const getUsers = () => {
        post('all-users',{
            filter: filter,
            page: page,
            search: search,
        }).then((e) => {
            setUsers(e.data.data)
        }).catch(() => {

        })
    }

    const handleClick = (check) => {
        if (filter.includes(check)) {
            return setFilter(filter.filter((item) => item !== check))
        }
        return setFilter([...filter, check])
    }

    useEffect(() => {
        getUsers()
    },[search, filter, page])

    useEffect(() => {
        getUsers()
    }, [])

    return (
        <>
            <Navbar minW="1050px" title="Users">
                <Flex>
                    <Popover>
                        <PopoverTrigger>
                            <Button leftIcon={<Icon type="filter"/>} variant="lightBlue" size="sm" height='30px' minW="173px">
                                <Text as='h2' mt={0} mb={0} ml={2} fontSize="sm">Filter</Text>
                            </Button>
                        </PopoverTrigger>
                        <PopoverContent bg="white" zIndex="500" pl={6} py={6} w="190px" flexDirection="row" _focus={{outline: 'none'}}>
                            <PopoverArrow/>
                            <PopoverBody display="flex" flexDirection="column" p={0}>
                                <Checkbox css={styles.checkboxGeneral} onChange={(e) => handleClick(e.target.name)} name='General' mb={1}>
                                    <Flex ml={2} fontSize="14px" as="span" alignItems='center'>
                                        General
                                        <Box css={styles.usersColors} bg='#193C5B'/>
                                    </Flex>
                                </Checkbox>
                                <Checkbox css={styles.checkboxReu} onChange={(e) => handleClick(e.target.name)} name='Reu' mb={1}>
                                    <Flex ml={2} fontSize="14px" as="span" alignItems='center'>
                                        Real Estate
                                        <Box css={styles.usersColors} bg='#C956FF'/>
                                    </Flex>
                                </Checkbox>
                                <Checkbox  css={styles.checkboxSu} onChange={(e) => handleClick(e.target.name)} name='Su' mb={1}>
                                    <Flex ml={2} fontSize="14px" as="span" alignItems='center'>
                                        Suppliers
                                        <Box css={styles.usersColors} bg='#FFD339'/>
                                    </Flex>
                                </Checkbox>
                                <Checkbox  css={styles.checkboxEu} onChange={(e) => handleClick(e.target.name)} name='Eu' mb={1}>
                                    <Flex ml={2} fontSize="14px" as="span" alignItems='center' width='100%'>
                                        Electricians
                                        <Box css={styles.usersColors} bg='#FF8660'/>
                                    </Flex>
                                </Checkbox>
                                <Checkbox  css={styles.checkboxSa} onChange={(e) => handleClick(e.target.name)} name='Sa' mb={1}>
                                    <Flex ml={2} fontSize="14px" as="span" alignItems='center'>
                                        Sales
                                        <Box css={styles.usersColors} bg='#A6F484'/>
                                    </Flex>
                                </Checkbox>
                                <Checkbox  css={styles.checkboxAu} onChange={(e) => handleClick(e.target.name)} name='Au' mb={1}>
                                    <Flex ml={2} fontSize="14px" as="span" alignItems='center'>
                                        Admin
                                        <Box css={styles.usersColors} bg='#ACDAFF'/>
                                    </Flex>
                                </Checkbox>
                            </PopoverBody>
                        </PopoverContent>
                    </Popover>
                    <InviteUserModal/>
                    <InputGroup mr={8}>
                        <InputLeftElement height="30px" pointerEvents="none" children={(<Icon height="16.5px" width="16.5px" type="search"/>)}/>
                        <Input value={search} onChange={(e) => setSearch(e.target.value)} height="30px" color="white.100" borderRadius="5px" border="none" bg="blue.200" placeholder="Search"/>
                    </InputGroup>
                </Flex>
            </Navbar>
            <Flex minH='90vh' flexDirection='column' justifyContent='space-between' m="auto" minW="1050px" maxW="1240px" px={14} pt={10} borderRight="1px solid" borderLeft="1px solid" borderColor="#DBDADA"  bg="#f5f5f5">
                <SimpleGrid mt={12} columns={{base: 1, sm: 2, md: 3,}} spacing="8">
                    {
                        users['items']?.map((user) => (
                            <GridItem colSpan={1} key={user.id}>
                                <Flex justifyContent="center">
                                    <UserCard
                                        onClick={() => history.push(`/users/${user.id}`)}
                                        color={
                                            user.type === REU ? '#C956FF'
                                                : user.type === SA ? '#A6F484'
                                                    : user.type === SU ? '#FFD339'
                                                        : user.type === EU ? '#FF8660'
                                                            : user.type === AU ? '#ACDAFF'
                                                                : user.type === GENERAL ? 'rgba(90, 90, 90, 0.87)'
                                                                    : '#4A85B4'
                                        }
                                        name={(user.firstName.length + user.lastName.length) > 22 ? `${(`${user.firstName} ${user.lastName}`).slice(0, 20)}...` : `${user.firstName} ${user.lastName}`}
                                        avatar={S3_BUCKET + user.avatar}/>
                                </Flex>
                            </GridItem>
                        ))}
                </SimpleGrid>
                <Box css={styles.paginator}>
                    <MuiThemeProvider theme={theme}>
                        <CssBaseline />
                        <Pagination
                            limit={24}
                            otherPageColor='black'
                            offset={offset}
                            previousPageLabel={<LeftIcon fill='black'/>}
                            nextPageLabel={<RightIcon />}
                            total={users['pagination']?.TotalItemCount}
                            onClick={(e, offset:number,page:number) => {
                                setOffset(offset)
                                setPage(page)
                            }}/>
                    </MuiThemeProvider>
                </Box>
            </Flex>
        </>
    )
}

export default Users
