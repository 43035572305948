import {useEffect, useState} from 'react'
import {useParams, Link, useHistory} from 'react-router-dom'
import {Text, Flex, Box, Image, Heading, Button, Divider, useDisclosure, useToast,} from '@chakra-ui/react'
import {useGetProject, useSignOut, useUser} from '../../core/hooks'
import {S3_BUCKET} from '../../constants'
import Navbar from '../../components/Navbar'
import {ReactComponent as EyeIcon} from "../../assets/EyeIcon.svg";
import httpRequest from "../../core/services/http-request";
import Loading from "../../components/Loading";
import {ReactComponent as DoneIcon} from "../../assets/Icons/DoneIcon.svg";
import {ReactComponent as Question} from "../../assets/questionIcon.svg";
import {AU, SA} from "../../constants/user/userEnum";
import SuTabs from "./SuTabs";
import ToastComponent from "../../components/toastComponent/ToastComponent";
import ExitBidModal from "./ExitBidModal";

const SuProject = () => {
    const {post} = httpRequest()
    const params = useParams()
    const [loading, setLoading] = useState(false)
    const {isOpen, onOpen, onClose} = useDisclosure()
    const {isOpen: isOpenExit, onOpen: onOpenExit, onClose: onCloseExit} = useDisclosure()
    const {data: projectResponse, isLoading, refetch} = useGetProject(params.id)
    const {user} = useUser()
    const [project, setProject] = useState([])
    const [priority, setPriority] = useState(false)
    const [adminName, setAdminName] = useState('')
    const [consultants, setConsultants] = useState([])
    const [colleagues, setColleagues] = useState([])
    const [editMode, setEditMode] = useState(true)
    const [route, setRoute] = useState('')
    const history = useHistory()
    const toast = useToast()
    const {signOut} = useSignOut()

    const hideProject = () => {
        setLoading(true)
        const data = {
            projectId: projectResponse?.data?.data[0]?.id,
            userId: user.id,
        }
        post('hide-project', data).then(() => {
            setLoading(false)
            history.push('/projects')
        })
    }

    const submitQuestion = () => {
        history.push(`/project/submit-question/${params.id}/${project?.title}/area`)
    }

    useEffect(() => {
        if (!projectResponse) return
        let colleg = [projectResponse.data.data[0]?.project?.user]
        setProject(projectResponse?.data?.data[0]?.project)
        setPriority(projectResponse?.data?.data[0]?.priority)
        for (let i = 0; i < projectResponse?.data?.data[0]?.project?.invitedUsersToProject?.length; i++) {
            if (projectResponse?.data.data[0].project.invitedUsersToProject[i].type !== AU && projectResponse?.data.data[0].project.invitedUsersToProject[i].type !== SA) {
                colleg.push(projectResponse?.data.data[0].project.invitedUsersToProject[i])
            }
        }
        if (projectResponse?.data?.data[0]?.project.archived){
            setEditMode(false)
        }
        setColleagues(colleg)
        if (consultants.length === 0) {
            post('get-admin-name').then((e) => setAdminName(e.data.data))
        }

    }, [projectResponse])

    const finishBid = () => {
        setEditMode(false)
        toast({
            render: () => (
                <ToastComponent bg='#FFE68D' icon={<DoneIcon/>} title='New Bid Added Successfully!'/>
            ),
            duration: 4000,
            isClosable: true,
            position: "top-right"
        })
    }

    if (isLoading) return <Loading/>

    const exitFunc = (name) => {
        if (name === 'signout') {
            signOut()
        } else {
            setRoute(name)
        }
        onOpenExit()
    }

    return (
        <>
            <Navbar open={exitFunc} editMode={editMode} minW="900px" title={(
                <Flex>
                    <Link as='h2' m={0} to="/projects">Projects</Link>
                    <Text as='h2' m={0}>
                        &nbsp;/&nbsp;
                        {((project?.city ? project?.city + ', ' : '') + (project?.state ? project?.state + ', ' : '') + ' ' + (project?.zipCode ? project?.zipCode : '')).replace(/^,|,$/g, '').trim()
                            + (project?.propertyType ? ' / ' + project?.propertyType : '') + ' / Scope of Work'}
                    </Text>
                </Flex>)}/>
            <ExitBidModal
                submitBid={finishBid}
                isOpen={isOpenExit}
                onClose={onCloseExit}
                route={route}/>
            <Flex minW="900px" m="auto" maxW="1240px" border="1px solid" borderColor="#DBDADA"
                  justifyContent="space-between" bg="#f5f5f5" w="100%">
                <Flex pl={8} py={8}>
                    <Image src={project?.image ? S3_BUCKET + project?.image : '/images/defaultLamp.jpg'}
                           borderRadius="5px" mr={8} objectFit="cover" height="146px" width="220px"/>
                    <Box>
                        <Heading mt={0} mb={1} fontSize="24px" as="h1">
                            <Flex alignItems='center'>
                                <Text as='h2' mt={0} mb={0}>
                                    {((project?.city ? project?.city + ', ' : '') + (project?.state ? project?.state + ', ' : '') + ' ' + (project?.zipCode ? project?.zipCode : '')).replace(/^,|,$/g, '').trim()}
                                </Text>
                            </Flex>
                        </Heading>
                        <Text fontSize="16px">
                            <Text display='inline-block' fontWeight='700' fontSize='18px'>
                                <Text fontWeight='700'>
                                    Energy Consultants:&nbsp;
                                    <Text as='span' fontWeight='400'>
                                        {consultants.length > 0 ? consultants?.map((user) => user?.firstName + ' ' + user?.lastName) : adminName}
                                    </Text>
                                </Text>
                            </Text>
                        </Text>
                        <Text fontSize="18px">
                            <Text display='inline-block' fontWeight='700'>
                                Colleagues:
                            </Text>
                            &nbsp;{colleagues?.length > 0 && colleagues?.map((user) => user?.firstName + ' ' + user?.lastName)?.toString()}
                        </Text>
                        <Text fontSize="18px">
                            <Text display='inline-block' fontWeight='700'>Status:&nbsp;</Text>
                            {project?.status}
                        </Text>
                    </Box>
                </Flex>
                <Flex justifyContent='center' alignItems="center">
                    <Divider orientation="vertical" borderColor="#DBDADA"/>
                    <Flex pl={10} pr={10} flexDirection="column">
                        <Button
                            disabled={!editMode}
                            w="135px"
                            bg={editMode ? '#C2EF62' : '#A9A9A9'}
                            mb={5}
                            onClick={finishBid}
                            color='black'
                            leftIcon={<DoneIcon/>}>
                            Done
                        </Button>
                        <Button bg="#4A4A4A" color="white" w="135px" mb={5}
                                leftIcon={<Question/>}>
                            <Text fontWeight="600" as='h2' onClick={submitQuestion} fontSize='12px' mt={0} mb={0}>
                                Submit Question
                            </Text>
                        </Button>
                        <Button w="135px" isLoading={loading} leftIcon={<EyeIcon/>}
                                onClick={hideProject} backgroundColor='#6D8DA7'>
                            Hide Project
                        </Button>
                    </Flex>
                </Flex>
            </Flex>
            <SuTabs
                refetchProject={refetch}
                editMode={editMode}
                isOpen={isOpen}
                onClose={onClose}
                onOpen={onOpen}
                project={project}
            />
        </>
    )
}

export default SuProject
