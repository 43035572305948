const uploadFileFunction = (
    file,
    setErrors,
    XLSX,
    upload,
    user,
    createProduct,
    setSuccess,
    setUploadingError,
    createAccessory,
) => {
    const newErrors = []
    const reader = new FileReader()
    const rABS = !!reader.readAsBinaryString
    reader.onload = (e) => {
        const bstr = e.target?.result
        const wb = XLSX.read(bstr, {type: rABS ? 'binary' : 'array'})
        // const wsname = wb.SheetNames.filter((item) => item === 'Intro')
        const wsname1 = wb.SheetNames.filter((item) => item === 'Fixture')
        const wsname2 = wb.SheetNames.filter((item) => item === 'Lamp')
        const wsname3 = wb.SheetNames.filter((item) => item === 'Accessory')
        const wsname4 = wb.SheetNames.filter((item) => item === 'Power-Supply')
        const wsname5 = wb.SheetNames.filter((item) => item === 'Control')
        // const ws = wb.Sheets[wsname]
        const ws1 = wb.Sheets[wsname1]
        const ws2 = wb.Sheets[wsname2]
        const ws3 = wb.Sheets[wsname3]
        const ws4 = wb.Sheets[wsname4]
        const ws5 = wb.Sheets[wsname5]
        // const intro = XLSX.utils.sheet_to_json(ws1)
        if (!wb.SheetNames.includes('Fixture') && !wb.SheetNames.includes('Lamp') && !wb.SheetNames.includes('Accessory') && !wb.SheetNames.includes('Power-Supply') && wb.SheetNames.includes('Control')) {
            return setErrors(['all'])
        }
        // if (!ws1['B12']?.v || !ws1['B9']?.v || !ws1['B12']?.v || !ws1['B12']?.v) {
        //     newErrors.push('intro')
        // }
        const fixtures = XLSX.utils.sheet_to_json(ws1)
        const lamps = XLSX.utils.sheet_to_json(ws2)
        const accessories = XLSX.utils.sheet_to_json(ws3)
        const powerSupplies = XLSX.utils.sheet_to_json(ws4)
        const controls = XLSX.utils.sheet_to_json(ws5)
        if (fixtures.length < 3 && lamps.length < 3 && accessories.length < 3 && powerSupplies.length < 3 && controls.length < 3) {
            newErrors.push('minimum')
        }
        if (fixtures.length > 2) {
            fixtures.map((item, index) => {
                console.log(item)
                if (index > 1) {
                    if (
                        !item['Focus Earth Fixture Catalogue Template']
                        || !item['__EMPTY']
                        || !item['__EMPTY_1']
                        || !item['__EMPTY_2']
                        || !item['__EMPTY_3']
                        || !item['__EMPTY_4']
                        || !item['__EMPTY_5']
                        || !item['__EMPTY_6']
                        || !item['__EMPTY_7']
                        || !item['__EMPTY_8']
                        || !item['__EMPTY_9']
                        || !item['__EMPTY_10']
                    ) {
                        if (!newErrors.includes('luminaires')) {
                            newErrors.push('luminaires')
                        }
                    }
                }
            })
        }
        if (lamps.length > 2) {
            lamps.map((item, index) => {
                if (index > 1) {
                    if (
                        !item['Focus Earth Lamp Catalogue Template']
                        || !item['__EMPTY']
                        || !item['__EMPTY_1']
                        || !item['__EMPTY_2']
                        || !item['__EMPTY_3']
                        || !item['__EMPTY_4']
                        || !item['__EMPTY_5']
                        || !item['__EMPTY_6']
                        || !item['__EMPTY_7']
                        || !item['__EMPTY_8']
                        || !item['__EMPTY_9']
                        || !item['__EMPTY_10']
                    ) {
                        if (!newErrors.includes('luminaires')) {
                            newErrors.push('luminaires')
                        }
                    }
                }
            })
        }
        if (accessories.length > 2) {
            accessories.map((item, index) => {
                if (index > 1) {
                    if (
                        !item['Focus Earth Accessory Catalogue Template']
                        || !item['__EMPTY']
                        || !item['__EMPTY_1']
                        || !item['__EMPTY_2']
                    ) {
                        if (!newErrors.includes('accessories')) {
                            newErrors.push('accessories')
                        }
                    }
                }
            })
        }
        if (powerSupplies.length > 2) {
            powerSupplies.map((item, index) => {
                if (index > 1) {
                    if (
                        !item['Focus Earth Power Supply Catalogue Template']
                        || !item['__EMPTY']
                        || !item['__EMPTY_1']
                        || !item['__EMPTY_2']
                    ) {
                        if (!newErrors.includes('accessories')) {
                            newErrors.push('accessories')
                        }
                    }
                }
            })
        }
        if (controls.length > 2) {
            controls.map((item, index) => {
                if (index > 1) {
                    if (
                        !item['Focus Earth Control Catalogue Template']
                        || !item['__EMPTY']
                        || !item['__EMPTY_1']
                        || !item['__EMPTY_2']
                    ) {
                        if (!newErrors.includes('accessories')) {
                            newErrors.push('accessories')
                        }
                    }
                }
            })
        }
        if (newErrors.length > 0) {
            return setErrors(newErrors)
        }
        const type = 'xlsx'
        const name = `${file.name.split('.')[0]}.${new Date().getTime() * Math.random()}.${type}`
        upload(
            {
                name,
                file,
                type,
            },
            {
                onSuccess: () => {
                    // setData(file)
                    console.log('success')
                },
                onError: (error) => console.log(error),
            },
        )
        if (fixtures.length > 0) {
            fixtures.map((item, index) => {
                const input = {
                    type: 'fixture',
                    userId: user?.id,
                    manufacturer: item['Focus Earth Fixture Catalogue Template'],
                    description: item['__EMPTY'],
                    lightType: item['__EMPTY_1'],
                    lightStyle: item['__EMPTY_2'],
                    category: item['__EMPTY_3'],
                    orderCode: item['__EMPTY_4'],
                    wattage: item['__EMPTY_5'],
                    lumens: item['__EMPTY_6'],
                    colorTemperature: item['__EMPTY_7'],
                    cri: item['__EMPTY_8'],
                    l70: item['__EMPTY_9'],
                    price: item['__EMPTY_10'],
                    nickname: item['__EMPTY_12'],
                    volumeTier1: item['__EMPTY_13'],
                    volumeTier1PPU: item['__EMPTY_14'],
                    volumeTier2: item['__EMPTY_15'],
                    volumeTier2PPU: item['__EMPTY_16'],
                    warranty: item['__EMPTY_17'],
                    voltageMin: item['__EMPTY_18'],
                    voltageMax: item['__EMPTY_19'],
                    thd: item['__EMPTY_28'],
                    baseType: item['__EMPTY_20'],
                    ipRating: item['__EMPTY_21'],
                    cbcp: item['__EMPTY_22'],
                    dimmability: item['__EMPTY_23'],
                    certifications: item['__EMPTY_24'],
                    assembly: item['__EMPTY_25'],
                    powerSupply: item['__EMPTY_26'],
                    powerFactor: item['__EMPTY_27'],
                    lampsPerFixture: item['__EMPTY_29'],
                    lensTypeLamp: item['__EMPTY_30'],
                    lensTypeFixture: item['__EMPTY_31'],
                    optics: item['__EMPTY_32'],
                    specifications: item['__EMPTY_33'],
                    additionalCertifications: item['__EMPTY_34'],
                    length: item['__EMPTY_35'],
                    width: item['__EMPTY_36'],
                    depth: item['__EMPTY_37'],
                    diameter: item['__EMPTY_38'],
                    compatibility: item['__EMPTY_40'],
                    productImage: item['__EMPTY_39'],
                    // company: JSON.stringify({
                    //     company: ws1['B9']?.v,
                    //     name: ws1['B10']?.v,
                    //     phone: ws1['B11']?.v,
                    //     email: ws1['B12']?.v,
                    // }),
                }
                if (index > 1) {
                    createProduct(
                        input,
                        {
                            onSuccess: () => {
                                setSuccess(true)
                            },
                            onError: (error) => {
                                console.log(error)
                                setUploadingError(true)
                            },
                        },
                    )
                }
            })
        }
        if (lamps.length > 0) {
            lamps.map((item, index) => {
                const input = {
                    type: 'lamp',
                    userId: user?.id,
                    manufacturer: item['Focus Earth Lamp Catalogue Template'],
                    description: item['__EMPTY'],
                    lightType: item['__EMPTY_1'],
                    lightStyle: item['__EMPTY_2'],
                    category: item['__EMPTY_3'],
                    orderCode: item['__EMPTY_4'],
                    wattage: item['__EMPTY_5'],
                    lumens: item['__EMPTY_6'],
                    colorTemperature: item['__EMPTY_7'],
                    cri: item['__EMPTY_8'],
                    l70: item['__EMPTY_9'],
                    price: item['__EMPTY_10'],
                    nickname: item['__EMPTY_12'],
                    volumeTier1: item['__EMPTY_13'],
                    volumeTier1PPU: item['__EMPTY_14'],
                    volumeTier2: item['__EMPTY_15'],
                    volumeTier2PPU: item['__EMPTY_16'],
                    warranty: item['__EMPTY_17'],
                    voltageMin: item['__EMPTY_18'],
                    voltageMax: item['__EMPTY_19'],
                    thd: item['__EMPTY_28'],
                    baseType: item['__EMPTY_20'],
                    ipRating: item['__EMPTY_21'],
                    cbcp: item['__EMPTY_22'],
                    dimmability: item['__EMPTY_23'],
                    certifications: item['__EMPTY_24'],
                    assembly: item['__EMPTY_25'],
                    powerSupply: item['__EMPTY_26'],
                    powerFactor: item['__EMPTY_27'],
                    lampsPerFixture: item['__EMPTY_29'],
                    lensTypeLamp: item['__EMPTY_30'],
                    lensTypeFixture: item['__EMPTY_31'],
                    optics: item['__EMPTY_32'],
                    specifications: item['__EMPTY_33'],
                    additionalCertifications: item['__EMPTY_34'],
                    length: item['__EMPTY_35'],
                    width: item['__EMPTY_36'],
                    depth: item['__EMPTY_37'],
                    diameter: item['__EMPTY_38'],
                    compatibility: item['__EMPTY_40'],
                    productImage: item['__EMPTY_39'],
                    // company: JSON.stringify({
                    //     company: ws1['B9']?.v,
                    //     name: ws1['B10']?.v,
                    //     phone: ws1['B11']?.v,
                    //     email: ws1['B12']?.v,
                    // }),
                }
                if (index > 1) {
                    createProduct(
                        input,
                        {
                            onSuccess: () => {
                                setSuccess(true)
                            },
                            onError: (error) => {
                                console.log(error)
                                setUploadingError(true)
                            },
                        },
                    )
                }
            })
        }
        if (accessories.length > 0) {
            accessories.map((item, index) => {
                const input = {
                    type: 'accessory',
                    userId: user.id,
                    manufacturer: item['Focus Earth Accessory Catalogue Template'],
                    description: item['__EMPTY'],
                    orderCode: item['__EMPTY_1'],
                    price: item['__EMPTY_2'],
                    nickname: item['__EMPTY_4'],
                    volumeTier1: item['__EMPTY_5'],
                    volumeTier1PPU: item['__EMPTY_6'],
                    volumeTier2: item['__EMPTY_7'],
                    volumeTier2PPU: item['__EMPTY_8'],
                    warranty: item['__EMPTY_9'],
                    color: item['__EMPTY_10'],
                    assembly: item['__EMPTY_11'],
                    length: item['__EMPTY_12'],
                    width: item['__EMPTY_13'],
                    depth: item['__EMPTY_14'],
                    diameter: item['__EMPTY_15'],
                    specifications: item['__EMPTY_16'],
                    compatibility: item['__EMPTY_18'],
                    accessoryImage: item['__EMPTY_17'],
                    // company: JSON.stringify({
                    //     company: ws1['B9']?.v,
                    //     name: ws1['B10']?.v,
                    //     phone: ws1['B11']?.v,
                    //     email: ws1['B12']?.v,
                    // }),
                }
                if (index > 1) {
                    createAccessory(
                        input,
                        {
                            onSuccess: () => {
                                setSuccess(true)
                            },
                            onError: () => {
                                setUploadingError(true)
                            },
                        },
                    )
                }
            })
        }
        if (powerSupplies.length > 0) {
            powerSupplies.map((item, index) => {
                const input = {
                    type: 'power-supply',
                    userId: user.id,
                    manufacturer: item['Focus Earth Power Supply Catalogue Template'],
                    description: item['__EMPTY'],
                    orderCode: item['__EMPTY_1'],
                    price: item['__EMPTY_2'],
                    nickname: item['__EMPTY_4'],
                    volumeTier1: item['__EMPTY_5'],
                    volumeTier1PPU: item['__EMPTY_6'],
                    volumeTier2: item['__EMPTY_7'],
                    volumeTier2PPU: item['__EMPTY_8'],
                    warranty: item['__EMPTY_9'],
                    color: item['__EMPTY_10'],
                    assembly: item['__EMPTY_11'],
                    length: item['__EMPTY_12'],
                    width: item['__EMPTY_13'],
                    depth: item['__EMPTY_14'],
                    diameter: item['__EMPTY_15'],
                    specifications: item['__EMPTY_16'],
                    compatibility: item['__EMPTY_18'],
                    accessoryImage: item['__EMPTY_17'],
                    // company: JSON.stringify({
                    //     company: ws1['B9']?.v,
                    //     name: ws1['B10']?.v,
                    //     phone: ws1['B11']?.v,
                    //     email: ws1['B12']?.v,
                    // }),

                }
                if (index > 1) {
                    createAccessory(
                        input,
                        {
                            onSuccess: () => {
                                setSuccess(true)
                            },
                            onError: () => {
                                setUploadingError(true)
                            },
                        },
                    )
                }
            })
        }
        if (controls.length > 0) {
            controls.map((item, index) => {
                const input = {
                    type: 'control',
                    userId: user.id,
                    manufacturer: item['Focus Earth Control Catalogue Template'],
                    description: item['__EMPTY'],
                    orderCode: item['__EMPTY_1'],
                    price: item['__EMPTY_2'],
                    nickname: item['__EMPTY_4'],
                    volumeTier1: item['__EMPTY_5'],
                    volumeTier1PPU: item['__EMPTY_6'],
                    volumeTier2: item['__EMPTY_7'],
                    volumeTier2PPU: item['__EMPTY_8'],
                    warranty: item['__EMPTY_9'],
                    color: item['__EMPTY_10'],
                    assembly: item['__EMPTY_11'],
                    length: item['__EMPTY_12'],
                    width: item['__EMPTY_13'],
                    depth: item['__EMPTY_14'],
                    diameter: item['__EMPTY_15'],
                    specifications: item['__EMPTY_16'],
                    compatibility: item['__EMPTY_18'],
                    accessoryImage: item['__EMPTY_17'],
                    // company: JSON.stringify({
                    //     company: ws1['B9']?.v,
                    //     name: ws1['B10']?.v,
                    //     phone: ws1['B11']?.v,
                    //     email: ws1['B12']?.v,
                    // }),

                }
                if (index > 1) {
                    createAccessory(
                        input,
                        {
                            onSuccess: () => {
                                setSuccess(true)
                            },
                            onError: () => {
                                setUploadingError(true)
                            },
                        },
                    )
                }
            })
        }
    }
    if (rABS) reader.readAsBinaryString(file); else reader.readAsArrayBuffer(file)
}

export default uploadFileFunction
