const projectUseCase = (projectRepo) => {
  const createProject = (input) => projectRepo.createProject(input) // NO VALIDATIONS NEED
  const updateProject = (input) => {
    if (!input?.id) {
      throw Error('ID is required')
    }
    return projectRepo.updateProject(input)
  }
  const deleteProject = (input) => {
    if (!input?.id) {
      throw Error('ID is required')
    }
    return projectRepo.deleteProject(input)
  }

  const getProject = (id) => {
    if (!id) {
      throw Error('ID is required')
    }

    return projectRepo.getProject(id)
  }

  const listProjects = (variables) => projectRepo.listProjects(variables)
  const searchProjects = (variables) => projectRepo.searchProjects(variables)

  return {
    createProject,
    updateProject,
    getProject,
    listProjects,
    deleteProject,
    searchProjects,
  }
}

export default projectUseCase
