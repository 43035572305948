import React from 'react'
import {
    FormControl,
    FormLabel,
    Select as ChakraSelect,
} from '@chakra-ui/react'
import styled from '@emotion/styled'

const Select = styled(ChakraSelect)`
  border: 1px solid rgba(169, 169, 169, 0.87);
  border-radius: 5px;
  font-size: inherit;
  background: #fff;

  &::placeholder {
    color: #a9a9a9;
    opacity: 1;
  }

  &:-ms-input-placeholder {
    color: #a9a9a9;
  }

  &::-ms-input-placeholder {
    color: #a9a9a9;
  }

  &:focus {
    box-shadow: none;
    outline: none;
    border-color: unset;
  }
`

const ChakraSelectLabel = React.forwardRef((props, ref) => {
    const {
        label,
        display,
        defaultValue,
        placeholder,
        onChange,
        value,
        mr,
        ml,
        mt,
        children,
        borderColor,
        backgroundColor,
        isDisabled,
        isInvalid,
        maxW,
        ...rest
    } = props
    return (
        <FormControl
            mr={mr}
            display={display}
            maxW={maxW}
            ml={ml}
            mt={mt}>
            <FormLabel
                fontFamily='Titillium Web'
                fontWeight="600"
                fontSize="md"
                color="blue.200">
                {label}
            </FormLabel>
            <Select
                height='50px'
                defaultValue={defaultValue}
                isInvalid={isInvalid}
                isDisabled={isDisabled}
                ref={ref}
                value={value}
                onChange={onChange}
                backgroundColor={backgroundColor}
                borderColor={borderColor}
                placeholder={placeholder}
                {...rest}>
                {children}
            </Select>
        </FormControl>

    )
})

export default ChakraSelectLabel
