import React from 'react'
import {
  Flex,
  Heading,
  Box,
} from '@chakra-ui/react'
import IconButton from '../IconButton'
import Icon from '../Icon'

const IncrementerBubble = ({
  index,
  title,
  bl,
  br,
  score,
  setScore,
  plus = true,
  type = 'default',
}) => {
  const plusIncrement = () => {
    if (Object.values(score).reduce((total, currentScore) => total + currentScore, 0) === 100) return
    const newTitle = title.toLowerCase().split(' ').reduce((total, currentScore) => `${total}_${currentScore}`)
    const newObject = score
    newObject[newTitle] += 5

    return setScore({
      ...score,
      ...newObject,
    })
  }

  const minusIncrement = () => {
    const newTitle = title.toLowerCase().split(' ').reduce((total, currentScore) => `${total}_${currentScore}`)
    const newObject = score
    if (newObject[newTitle] === 0) return
    newObject[newTitle] -= 5
    return setScore({
      ...score,
      ...newObject,
    })
  }

  return (
    <Flex
      borderLeft={bl}
      borderRight={br}
      pl={8}
      pr={2}
      height="71px"
      justifyContent="space-between"
      alignItems="center"
      bg={index % 2 === 0 ? '#DADADA' : 'transparent'}>
      <Heading
        as="h3"
        fontSize="lg">
        {title}
      </Heading>
      <Flex>
        {
          plus && (
            <>
              <IconButton
                onClick={plusIncrement}
                height="unset"
                minW="30px"
                bg="transparent"
                _active={{
                  bg: 'transparent',
                }}
                _hover={{
                  bg: 'transparent',
                }}
                aria-label="plus"
                icon={<Icon type={type === 'default' ? 'plus' : type === 'illumination' ? 'illPlus' : type === 'finance' ? 'finPlus' : type === 'technology' ? 'techPlus' : type === 'environment' ? 'envPlus' : 'plus'}/>}/>
              <IconButton
                onClick={minusIncrement}
                ml={4}
                height="unset"
                minW="30px"
                bg="transparent"
                _active={{
                  bg: 'transparent',
                }}
                _hover={{
                  bg: 'transparent',
                }}
                aria-label="minus"
                icon={
                  (
                    <Icon
                      type={type === 'default' ? 'Minus' : type === 'illumination' ? 'illMinus' : type === 'finance' ? 'finMinus' : type === 'technology' ? 'techMinus' : type === 'environment' ? 'envMinus' : 'minus'}/>
                  )
                }/>
            </>
          )
        }
        <Box
          ml={4}
          minW="47px"
          fontWeight="700"
          fontSize="xl"
          color="grey.200"
          as="span">
          {score[title.toLowerCase().split(' ').join('_')]}
          %
        </Box>
      </Flex>
    </Flex>
  )
}

export default IncrementerBubble
