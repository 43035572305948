import React from 'react'
import {Flex, SimpleGrid, GridItem} from '@chakra-ui/react'
import {
    APPLICATION,
    VOLTAGE,
    DIMMING,
    INSTALATIONSURFACE,
    CONTROLTYPE,
    INGRESSPROTECTION
} from '../../constants/luminaireDetails'
import {InputLabel, ChakraSelectLabel} from '../../components/Theme'

const AreaInput = ({register, idx, setDailyUsage, dailyUsage, setValue, areaSqFt, setAreaSqFt}) => (
    <Flex>
        <Flex
            justifyContent="space-between"
            flexDirection="column"
            mt="5rem"
            w="100%">
            <SimpleGrid
                mb="6"
                columns={{base: 2, md: 4,}}
                gap={6}>
                <GridItem>
                    <InputLabel
                        {...register(`areas.${idx}.ceilingHeight`)}
                        label="Ceiling Height (feet):"/>
                </GridItem>
                <GridItem>
                    <InputLabel
                        type="number"
                        pattern="[0-9]*"
                        value={dailyUsage}
                        onChange={(e) => {
                            if (e.target.value <= 24 && e.target.value >= 0) {
                                setDailyUsage(e.target.value)
                                setValue(`areas.${idx}.dailyUsage`, e.target.value)
                            } else {
                                return ''
                            }
                        }}
                        label="Daily Usage (hours):"/>
                </GridItem>
                <GridItem>
                    <InputLabel
                        type="number"
                        value={areaSqFt}
                        onChange={(e) => {
                            setAreaSqFt(e.target.value)
                            setValue(`areas.${idx}.areaSqFt`, e.target.value)
                        }}
                        label="Area (sq.ft.):"/>
                </GridItem>
                <GridItem>
                    <ChakraSelectLabel
                        {...register(`areas.${idx}.application`)}
                        placeholder="Select"
                        label="Application:">
                        {
                            APPLICATION.map(
                                (type) => (
                                    <option
                                        key={type}
                                        value={type}>
                                        {type}
                                    </option>
                                ),
                            )
                        }
                    </ChakraSelectLabel>
                </GridItem>
                <GridItem>
                    <ChakraSelectLabel
                        {...register(`areas.${idx}.voltage`)}
                        placeholder="Select"
                        label="Voltage:">
                        {VOLTAGE.map(
                            (type) => (
                                <option
                                    key={type}
                                    value={type}>
                                    {type}
                                </option>
                            ),
                        )}
                    </ChakraSelectLabel>
                </GridItem>
                <GridItem>
                    <ChakraSelectLabel
                        {...register(`areas.${idx}.installationSurface`)}
                        placeholder="Select"
                        label="Installation Surface:">
                        {INSTALATIONSURFACE.map(
                            (type) => (
                                <option
                                    key={type}
                                    value={type}>
                                    {type}
                                </option>
                            ),
                        )}
                    </ChakraSelectLabel>
                </GridItem>
                <GridItem>
                    <ChakraSelectLabel
                        {...register(`areas.${idx}.controlType`)}
                        placeholder="Select"
                        label="Control Type:">
                        {CONTROLTYPE.map(
                            (type) => (
                                <option
                                    key={type}
                                    value={type}>
                                    {type}
                                </option>
                            ),
                        )}
                    </ChakraSelectLabel>
                </GridItem>
                <GridItem>
                    <ChakraSelectLabel
                        {...register(`areas.${idx}.ingressProtection`)}
                        placeholder="Select"
                        label="Ingress Protection:">
                        {INGRESSPROTECTION.map(
                            (type) => (
                                <option
                                    key={type}
                                    value={type}>
                                    {type}
                                </option>
                            ),
                        )}
                    </ChakraSelectLabel>
                </GridItem>
            </SimpleGrid>
            <InputLabel
                label="Notes:"
                {...register(`areas.${idx}.notes`)}/>
        </Flex>
    </Flex>
)

export default AreaInput
