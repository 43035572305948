import React from 'react'
import {
    Box,Flex,
    FormControl,
    FormLabel,
} from '@chakra-ui/react'
import Textarea from '../Textarea'

const TextareaLabel = React.forwardRef((props, ref) => {
    const {
        label,
        placeholder,
        name,
        onChange,
        value,
        mr,
        ml,
        disabled,
        changeactive,
        isInvalid,
        type,
        mt,
        minH,
        ...rest
    } = props
    return (
        <FormControl
            minH={minH}
            mr={mr}
            ml={ml}>
            <FormLabel
                fontFamily='Titillium Web'
                fontSize="md"
                fontWeight='600'
                color="blue.200">
                {label}
            </FormLabel>
            <Flex position='relative' alignItems="center">
                <Box position='absolute' zIndex='999' borderRadius='50%' width='25px' height='25px' right='-10px' top='-10px' display={changeactive ? 'block' : 'none'} backgroundColor='orange'/>
                <Textarea
                    minH={minH}
                    type={type}
                    isInvalid={isInvalid}
                    value={value}
                    onChange={onChange}
                    name={name}
                    ref={ref}
                    mt={mt}
                    isDisabled={disabled}
                    placeholder={placeholder}
                    {...rest}/>
            </Flex>
        </FormControl>

    )
})

export default TextareaLabel
