import { useQuery } from 'react-query'
import { projectUseCase } from '../../factories'

const useListProjects = (variables) => {
  const {
    refetch: searchProjects, isLoading, data, error,
  } = useQuery('projects', () => projectUseCase.searchProjects(variables))

  return {
    searchProjects,
    isLoading,
    error,
    data,
  }
}

export default useListProjects
