import { useMutation } from 'react-query'
import { uploadHistoryUseCase } from '../../factories'

const useListUploadHistories = () => {
  const {
    mutate: listUploadHistories, isLoading, data, error,
  } = useMutation(uploadHistoryUseCase.listUploadHistories)

  return {
    listUploadHistories,
    isLoading,
    error,
    data,
  }
}

export default useListUploadHistories
