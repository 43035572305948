import React from 'react'
import {
    Heading,
    Text,
    Flex,
    Grid,
    GridItem,
    Divider,
    Box,
} from '@chakra-ui/react'
import {
    Incrementer,
    ChartInterests,
    IncrementerBubble,
    ChartBubble,
} from '../../../components/Theme'
import {PROFILE_INTERESTS, PROFILEFEATURES} from '../../../constants'

const Interests = ({
                       score,
                       setScore,
                       illumination,
                       setIllumination,
                       technology,
                       setTechnology,
                       valuation,
                       setValuation,
                       environment,
                       setEnvironment,
                   }) => (
    <Box pt='100px'>
        <Heading
            m={0}
            fontSize="xl"
            as="h2">
            Which Subjects Interest You?
        </Heading>
        <Text
            fontSize="xl">
            Add/subtract 5% increments to build a pie chart profile of your subject matter focus.  Focus Earth will share news and reports based on your priorities.
        </Text>
        <Flex
            mt={10}
            alignItems="center"
            justifyContent="space-between">
            <Grid
                templateColumns="repeat(2, 1fr)"
                gap={5}>
                {
                    PROFILE_INTERESTS.map((interest) => (
                        <GridItem
                            key={interest.title}
                            colSpan={1}>
                            <Incrementer
                                score={score}
                                setScore={setScore}
                                title={interest.title}
                                color={interest.active}/>
                        </GridItem>
                    ))
                }
            </Grid>
            <ChartInterests score={score}/>
        </Flex>
        <Divider
            my={20}
            borderColor="#DADADA"
            border="3px solid"
            orientation="horizontal"/>
        <Heading
            m={0}
            fontSize="xl"
            as="h2">
            Which Elements Of LED Lighting Would You Like To Learn More About?
        </Heading>
        <Text
            mb={10}
            fontSize="xl">
            Add/subtract 5% increments to build a bubble chart of your broader LED interests. In which areas would you
            most like to contribute or expand your understanding?
        </Text>
        <Flex
            flexWrap="nowrap"
            w="100%">
            <Flex flex={1}>
                <Flex
                    flex={1}
                    flexDirection="column">
                    <Heading
                        textAlign="center"
                        as="h2"
                        fontSize="xl">
                        Illumination
                    </Heading>
                    {
                        PROFILEFEATURES.illumination.map((feature, index) => (
                            <IncrementerBubble
                                type="illumination"
                                score={illumination}
                                setScore={setIllumination}
                                key={feature.title}
                                title={feature.title}
                                index={index}/>
                        ))
                    }
                </Flex>
                <Flex
                    flex={1}
                    flexDirection="column">
                    <Heading
                        textAlign="center"
                        as="h2"
                        fontSize="xl">
                        Technology
                    </Heading>
                    {
                        PROFILEFEATURES.technology.map((feature, index) => (
                            <IncrementerBubble
                                type="technology"
                                score={technology}
                                setScore={setTechnology}
                                bl="1px solid rgba(90, 90, 90, 0.87)"
                                br="1px solid rgba(90, 90, 90, 0.87)"
                                key={feature.title}
                                title={feature.title}
                                index={index}/>
                        ))
                    }
                </Flex>
            </Flex>
            <Flex flex={1}>
                <Flex
                    flex={1}
                    flexDirection="column">
                    <Heading
                        textAlign="center"
                        as="h2"
                        fontSize="xl">
                        Valuation
                    </Heading>
                    {
                        PROFILEFEATURES.valuation.map((feature, index) => (
                            <IncrementerBubble
                                type="finance"
                                score={valuation}
                                setScore={setValuation}
                                br="1px solid rgba(90, 90, 90, 0.87)"
                                key={feature.title}
                                title={feature.title}
                                index={index}/>
                        ))
                    }
                </Flex>
                <Flex
                    flex={1}
                    flexDirection="column">
                    <Heading
                        textAlign="center"
                        as="h2"
                        fontSize="xl">
                        Environment
                    </Heading>
                    {
                        PROFILEFEATURES.environment.map((feature, index) => (
                            <IncrementerBubble
                                type="environment"
                                score={environment}
                                setScore={setEnvironment}
                                key={feature.title}
                                title={feature.title}
                                index={index}/>
                        ))
                    }
                </Flex>
            </Flex>
        </Flex>
        <Flex
            mb={24}
            mt={12}
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            w="100%">
            <Flex
                w="50%"
                justifyContent="space-between">
                <Heading
                    mb={6}
                    as="h3"
                    fontSize="xl">
                    Illumination
                </Heading>
                <Heading
                    mb={6}
                    as="h3"
                    fontSize="xl">
                    Technology
                </Heading>
            </Flex>
            <Box
                height="503px"
                width="857px">
                <ChartBubble
                    illumination={illumination}
                    technology={technology}
                    financial={valuation}
                    environment={environment}/>
            </Box>
            <Flex
                w="50%"
                justifyContent="space-between">
                <Heading
                    mt={0}
                    as="h3"
                    fontSize="xl">
                    Valuation
                </Heading>
                <Heading
                    mt={0}
                    as="h3"
                    fontSize="xl">
                    Environment
                </Heading>
            </Flex>
        </Flex>
    </Box>
)

export default Interests
