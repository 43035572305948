import {Grid} from '@chakra-ui/react'
import Interests from './Interests'
import {CustomDivider, TextLabel} from "../../../components/Theme";
import CommuniactionPreferences from "../../../components/User/CommuniactionPreferences";
import {SUPERADMIN} from "../../../constants/user/userEnum";
import CommuniactionPreferencesAdmin from "../../../components/User/CommunicationPreferncesAdmin";

const Details = (props) => (
    <>
        <Grid mb='70px' w="full" gap="7" templateColumns='repeat(2, 1fr)'>
            <TextLabel label='First Name:' text={props?.user?.firstName}/>
            <TextLabel  label="Last Name:" text={props?.user?.lastName} />
            <TextLabel label="Title:" text={props?.user?.title}/>
            <TextLabel label="Company Name:" text={props?.company?.name}/>
            <TextLabel label="Phone:" text={props?.user?.phone}/>
            <TextLabel label="Email:" text={props?.user?.email}/>
        </Grid>
        <Interests
            setIllumination={props?.setIllumination}
            illumination={props?.illumination}
            setScore={props?.setScore}
            score={props?.score}
            setEnvironment={props?.setEnvironment}
            environment={props?.environment}
            setValuation={props?.setValuation}
            valuation={props?.valuation}
            setTechnology={props?.setTechnology}
            technology={props?.technology}
            edit={false}/>
        <CustomDivider/>
        {
            props?.user?.type === SUPERADMIN ? (
                <CommuniactionPreferencesAdmin
                    userType={props?.user?.type}
                    communicationPreferences={props?.communicationPreferences}
                    setCommunicationPreferences={props?.setCommunicationPreferences}
                    readOnly={true}
                />
            ) : (
                <CommuniactionPreferences
                    userType={props?.user?.type}
                    communicationPreferences={props?.communicationPreferences}
                    setCommunicationPreferences={props?.setCommunicationPreferences}
                    readOnly={true}/>
            )
        }
    </>
)

export default Details
