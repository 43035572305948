import httpRequest from "./http-request";

const activityService = () => {
  const {post} = httpRequest()
  const createActivity = (input) => {
    return post('create-activity',{input})
  }

  const updateActivity = (input) => {

  }

  const getActivity = (id) => {

  }

  const listActivitys = (variables) => {
    return post('list-activitys',{variables})
  }

  return {
    createActivity,
    updateActivity,
    getActivity,
    listActivitys,
  }
}

export default activityService
