import React, {useEffect, useState} from 'react'
import {useHistory, useLocation} from 'react-router-dom'
import {Heading, Text, GridItem, SimpleGrid,} from '@chakra-ui/react'
import {useListProducts} from '../../core/hooks'
import {Card} from '../../components/Theme'
import HeaderForCatalogues from './HeaderForCatalogues'
import Loading from "../../components/Loading";

const Accessories = () => {
    const [accessories, setAccessories] = useState()
    const location = useLocation()
    const history = useHistory()
    let condition = {
        condition: {
            filter: '',
            table: location.pathname.split('/')[2]==='controls' ? 'tblqim8EyBMuGFnpY' :location.pathname.includes('power') ? 'tblbMEURwpob3KEnp' : 'tbl8XHqWB5xJqMt9s'
        }
    }
    const {data: AccessoriesResponse, isLoading: isAccessoryLoading} = useListProducts(condition)
    const handleSearch = (e) => {
        setAccessories(AccessoriesResponse?.data.data.filter((item) => item.manufacturer.toLowerCase().includes(e.target.value.toLowerCase())))
    }

    useEffect(() => {
        setAccessories(AccessoriesResponse?.data.data)
    }, [AccessoriesResponse])

    if (isAccessoryLoading) return <Loading />

    return (
        <>
            <HeaderForCatalogues handleSearch={handleSearch} title={location.pathname.split('/')[2]}/>
            <SimpleGrid p="12" w="full" columns={{base: 1, sm: 2, md: 3, lg: 4,}} spacing="8">
                {accessories?.map((product) => (
                        <GridItem onClick={() => history.push(`/catalogues/accessory/${location.pathname.split('/')[2]}/${product.id}`)} colSpan={1} key={product.id}>
                            <Card image={product.fields['Image'] ? product.fields['Image'][0]?.url : ''}>
                                <Heading fontSize="md" as="h3">
                                    {product.fields['FE Product Description']}
                                </Heading>
                                <Text fontSize="sm">
                                    {
                                        (product.fields['Length (inches)'] ? product.fields['Length (inches)']?.toString()?.replace(/(.{15})..+/, "$1…") + '"x' : ' ' )+
                                        (product.fields['Width (inches)'] ? product.fields['Width (inches)']?.toString()?.replace(/(.{15})..+/, "$1…") + '"x' : ' ' )+
                                        (product.fields['Diameter (inches)'] ? product.fields['Diameter (inches)']?.toString()?.replace(/(.{15})..+/, "$1…") + '"x' : ' ' )+
                                        (product.fields['Depth (inches)'] ? product.fields['Depth (inches)']?.toString()?.replace(/(.{15})..+/, "$1…") + '" ' : ' ' )+
                                        (product.fields['Lumens'] ? ', '+product.fields['Lumens']: '') +
                                        (location.pathname.split('/')[2] === 'accessories' ? (product.fields['Product Description'] ? product.fields['Product Description'] : '')
                                            : (product.fields['Lifetime (hours)'] ? ', '+product.fields['Lifetime (hours)']+' hours ' : '') + (product.fields['Warranty (years)'] ? ', '+product.fields['Warranty (years)']+' years ' : ''))
                                    }
                                </Text>
                            </Card>
                        </GridItem>))}
            </SimpleGrid>
        </>
    )
}

export default Accessories
