import {Flex, Heading, Box, Button, Text} from '@chakra-ui/react'
import moment from "moment";
import downloadFile from "../../../constants/mainFunctions";
import {ReactComponent as Download} from "../../../assets/download.svg";
import {ReactComponent as Search} from "../../../assets/searchWhite.svg";
import {ReactComponent as DeleteIcon} from "../../../assets/del.svg";
import {useRef} from "react";
import {useUploadMultiple} from "../../../core/hooks";
import httpRequest from "../../../core/services/http-request";
import styles from "../styles";

const CompanyUploads = ({files,setFileForDelete,onOpenDeleteFile,refetchCompany, companyId}) => {
    const inputRef = useRef()
    const {post} = httpRequest()
    const {upload} = useUploadMultiple()

    const handleImage = (e) => {
        const filesToUpload = []
        const dataToPreview = []
        Object.values(e.target.files).forEach(
            (file) => {
                const type = file.type.split('/')[1] || 'jpeg'
                const name = `${new Date().getTime() * Math.random()}.${type}`
                dataToPreview.push({
                    name: file.name,
                    key: name,
                    sortId: files.length,
                    createdAt: new Date().toISOString(),
                })
                filesToUpload.push({name, file, type,})
            },
        )
        upload(filesToUpload,
            {
                onSuccess: (e) => {
                    post('create-uploads', [e[0].data.data, dataToPreview, 2, companyId]).then(() => {
                        refetchCompany()
                    })
                },
            },
        )
    }

    return (
        <>
            <Flex width='full' alignItems='center' justifyContent='space-between'>
                <Heading fontSize='xl'>Uploaded Files</Heading>
                <Heading width='170px' fontSize='xl'>Date</Heading>
                <Box>
                    <input onChange={handleImage} type="file" multiple style={{display: 'none'}} ref={inputRef}/>
                    <Button fontSize='12px' leftIcon={<Search width='14px'/>} onClick={() => inputRef.current.click()}
                            minW="160px" maxH='30px' bg='#153046'>
                        Browse Files
                    </Button>
                </Box>
            </Flex>
            {files?.map((value) => (
                <Flex justifyContent='space-between' width='full' position='relative' cursor='pointer' css={styles.file}
                      key={value?.file?.files?.key}>
                    <Flex p={0} alignItems='center' justifyContent='center' position='absolute'
                          onClick={() => {
                              setFileForDelete(value.id)
                              onOpenDeleteFile()
                          }}
                          left='-8px'
                          top='-10px'
                          cursor='pointer'
                          borderRadius='5px'
                          width="20px"
                          height="20px"
                          bg='#FB7575'>
                        <DeleteIcon width='10px' height='10px' fill='black'/>
                    </Flex>
                    <Text width='40%' fontWeight="500">{value?.file?.name?.replace(/(.{30})..+/, "$1…")}</Text>
                    <Text width='20%' color='#A9A9A9' fontWeight="700">{moment(value?.file?.createdAt?.date).format('MMM DD, YYYY')}</Text>
                    <Flex justifyContent='end' width='40%'>
                        <Button onClick={e => downloadFile(e, value?.file?.name)} mr={8} leftIcon={<Download/>} bg="#2C628D" color="white" height="30px" fontSize="12px">
                            Download
                        </Button>
                    </Flex>
                </Flex>
            ))}
        </>
    )
}

export default CompanyUploads