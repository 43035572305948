import {useEffect, useState} from 'react'
import {Link, useHistory, useParams} from 'react-router-dom'
import {
    Flex,
    Text,
    Box,
    Image,
    Heading,
    Divider,
    Button,
    Tabs,
    TabList,
    Tab,
    TabPanel,
    TabPanels
} from '@chakra-ui/react'
import Navbar from '../../../components/Navbar'
import {ReactComponent as Bid} from '../../../assets/arrowRightUp.svg'
import {ReactComponent as Question} from '../../../assets/questionIcon.svg'
import {S3_BUCKET} from "../../../constants";
import Lumins from "../Lumins";
import Loading from "../../../components/Loading";
import {useGetReport} from "../../../core/hooks";

const EuScope = () => {
    const params = useParams()
    const history = useHistory()
    const [proposal, setProposal] = useState([])
    const [project, setProject] = useState([])
    const {data: reportDataResponse, refetch: refetchReport, isLoading} = useGetReport(params.reportId)

    useEffect(() => {
        if (!reportDataResponse) return
        setProject(reportDataResponse.data.data.project)
        setProposal(reportDataResponse.data.data.report)
    }, [reportDataResponse])

    if (isLoading) return <Loading />

    return (
        <>
            <Navbar minW="1240px" title={(
                    <Flex>
                        <Link as='h2' m={0} to="/projects">Projects /&nbsp;</Link>
                        <Text as='h2' m={0} cursor='pointer' onClick={() => history.push(`/project/${project.id}`)}>{project?.address}</Text>
                        <Text as='h2' m={0} fontWeight='500'>&nbsp;/&nbsp;{proposal?.proposalName}</Text>
                    </Flex>)}/>
            <Flex m="auto" minW="1240px" maxW="1240px" border="1px solid" borderColor="#DBDADA" justifyContent="space-between" bg="#f5f5f5" w="100%">
                <Flex pl={5} py={8}>
                    <Image src={project.image ? S3_BUCKET + project.image : '/images/defaultLamp.jpg'} borderRadius="5px" mr={8} objectFit="contain" height="146px" width="220px"/>
                    <Box>
                        <Heading mt={2} mb={3} fontSize="3xl">{proposal?.proposalName}</Heading>
                        <Text fontWeight='600' fontSize="xl">
                            Areas:&nbsp;
                            <Text as='span' fontWeight='normal'>
                                {proposal?.proposal?.map((item) => item.title + ' ').toString()}
                            </Text>
                        </Text>
                        <Text fontWeight='600' fontSize="xl">
                            Notes:&nbsp;
                            <Text as='span' fontWeight='normal'>
                                {project?.notes?.length > 164 ? project?.notes?.substring(0, 164) + '...' : project?.notes}
                            </Text>
                        </Text>
                    </Box>
                </Flex>
                <Flex>
                    <Divider height="100%" border="1px solid" borderColor="#DBDADA" orientation="vertical"/>
                    <Flex px={12} py={8} alignItems="start" flexDirection="column">
                        <Button mb={4} width='150px' leftIcon={<Bid/>} height="40px" fontSize="sm" onClick={() => history.goBack()} _active={{background: '', boxShadow: 'none',}} _hover={{background: '',}} borderRadius="4px" bg="#FFE68D">
                            <Box as="span" color='black' fontWeight='500'>
                                Bid
                            </Box>
                        </Button>
                        <Button leftIcon={<Question/>} width='150px' height="40px" fontSize="sm" onClick={() => history.push(`/project/submit-question/${params.id}/${project.title}/${proposal.proposalName}`)} _active={{background: '', boxShadow: 'none',}} _hover={{background: '',}} borderRadius="4px" bg="#4A4A4A">
                            <Box color='white' as="span" mt={1} fontWeight='400'>
                                Submit Question
                            </Box>
                        </Button>
                    </Flex>
                </Flex>
            </Flex>
            <Tabs minW="1240px" m="auto" maxW="1240px">
                <TabList mx="-1px" border="none" pt={10} bg="white.100">
                    <Tab minW="208px" fontWeight="700" fontSize='18px' _active={{background: 'transparent',}} _selected={{color: 'green.200', borderColor: 'green.200', boxShadow: 'none',}} color="green.200">
                        Scope of Work
                    </Tab>
                </TabList>
                <TabPanels bg="#f5f5f5" py={10} px={40} border="1px solid #DBDADA" borderBottom="none">
                    <TabPanel px={8} py={6} border="1px solid" borderColor="#DBDADA" bg="#ffffff">
                        <Lumins project={project} proposal={proposal}/>
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </>
    )
}

export default EuScope