import {Grid, GridItem, Box, Button, Text, Flex} from "@chakra-ui/react";
import React, {useState} from "react";
import moment from "moment";
import {ReactComponent as ActivateIcon} from "../../assets/activateIcon.svg";
import httpRequest from "../../core/services/http-request";
import {Link} from "react-router-dom";

const Archived = ({estimates, getEstimates}) => {
    const {post} = httpRequest()
    const [loading, setLoading] = useState(false)

    const activateOrder = (id) => {
        setLoading(true)
        let data = {
            type: 'Su',
            id: id,
        }
        post('activate-order', data).then(() => {
            setLoading(false)
            return getEstimates()
        }).catch(() => {
            setLoading(false)
        })
    }

    return (
        <Box>
            {
                estimates?.filter((filtered) => filtered.archived)?.length > 0 ? (
                    <Grid fontSize='xl' fontFamily='Titillium Web' mb={3} fontWeight='600' width='full' templateColumns="repeat(6, 1fr)">
                        <GridItem pl={5}>Reference</GridItem>
                        <GridItem pl={2}>Status</GridItem>
                        <GridItem>Archived By</GridItem>
                        <GridItem>Archived Date</GridItem>
                        <GridItem>Project</GridItem>
                        <GridItem>Mark As Active</GridItem>
                    </Grid>
                ) : (
                    <Text fontSize='18px' textAlign='center'>
                        No active archived orders at this time - check back later!
                    </Text>
                )
            }
            {
                estimates?.filter((filtered) => filtered.archived).map((item: any) => (
                    <Grid mb={3} key={item.id} alignItems='center' bg='white' border='2px solid #A9A9A9' borderRadius='10px' minH='57px' fontSize='18px' width='full' templateColumns="repeat(6, 1fr)">
                        <GridItem textDecoration='underline' pl={5}>
                            <Link to={`/orders/estimate/su/${item.id}/1`} z-index='999'>
                                {item.reference}
                            </Link>
                        </GridItem>
                        <GridItem pl={2}>
                            <Link to={`/orders/estimate/su/${item.id}/1`} z-index='999'>
                                {
                                    item?.isEstimate ? (
                                        'Estimate'
                                    ) : item?.isOrder ? (
                                        'Order'
                                    ) : item?.isShipping ? (
                                        'Shipping'
                                    ) : item?.isInvoice ? (
                                        'Invoice'
                                    ) : item?.isReceipt ? (
                                        'Receipt'
                                    ) : ('Fulfilled')
                                }
                            </Link>
                        </GridItem>
                        <GridItem>
                            <Flex alignItems='center'>
                                <Text mr={2}
                                    bg={
                                        item?.archivedByUserEstimateSu?.type === 'Reu' ? '#C956FF' :
                                            item?.archivedByUserEstimateSu?.type === 'Su' ? '#FFD339' :
                                                item?.archivedByUserEstimateSu?.type === 'Sa' ? '#A6F484' :
                                                    item?.archivedByUserEstimateSu?.type === 'Au' ? '#ACDAFF' :
                                                        item?.archivedByUserEstimateSu?.type === 'Eu' ? '#FF8660' :
                                                            'red'
                                    }
                                    width='15px'
                                    height='15px'
                                    border='1px solid black'
                                    borderRadius='100%'/>
                                <Link to={`/orders/estimate/su/${item.id}/1`} z-index='999'>
                                    {item?.archivedByUserEstimateSu?.firstName + ' ' + item?.archivedByUserEstimateSu?.lastName}
                                </Link>
                            </Flex>
                        </GridItem>
                        <GridItem>
                            <Link to={`/orders/estimate/su/${item.id}/1`} z-index='999'>
                                {moment(item?.archivedDateCreated?.date).format('MMM DD, YYYY')}
                            </Link>
                        </GridItem>
                        <GridItem>
                            <Link to={`/orders/estimate/su/${item.id}/1`} z-index='999'>
                                {item?.report?.project?.title}
                            </Link>
                        </GridItem>
                        <GridItem>
                            <Button isLoading={loading} onClick={() => activateOrder(item.id)} width='117px' height='30px' leftIcon={<ActivateIcon/>}>
                                Activate
                            </Button>
                        </GridItem>
                    </Grid>
                ))
            }
        </Box>
    )
}

export default Archived