const reportUseCase = (reportRepo) => {
  const createReport = (input) => reportRepo.createReport(input) // NO VALIDATIONS NEED

  const doneReport = (input) => reportRepo.doneReport(input)

  const updateReport = (input) => {
    if (!input?.id) {
      throw Error('ID is required')
    }
    return reportRepo.updateReport(input)
  }

  const getReport = (id) => {
    if (!id) {
      throw Error('ID is required')
    }

    return reportRepo.getReport(id)
  }

  const listReports = (variables) => reportRepo.listReports(variables)

  return {
    doneReport,
    createReport,
    updateReport,
    getReport,
    listReports,
  }
}

export default reportUseCase
