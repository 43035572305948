import { css } from '@emotion/react'

const styles = {
  textCard: css`
        padding-left: 30px;
        border-radius: 10px;
        border-width: 2px;
        border-style: solid;
        box-shadow: 2px 4px 10px 0px rgba(0,0,0,0.25);
        width: 260px;
        height: 57px;
        text-align: left;
    `,
}

export default styles
