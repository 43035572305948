const orderUseCase = (orderRepo) => {
    const getEstimate = (input) => orderRepo.getEstimate(input)

    const getEstimates = (input) => orderRepo.getEstimates(input)

    return {
        getEstimate,
        getEstimates,
    }
}

export default orderUseCase
