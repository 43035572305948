import {css} from '@emotion/react'

const styles = {
    messages: css`
        & input,textarea {
          background-color: #DBDADA;
        }
    `,
    messages2: css`
        & input {
          background-color: #DBDADA;
        }
    `,
    tr: css`
      border: none;
      cursor: default;
      border-radius: 10px;

      & > td:first-of-type {
        font-weight: 600;
        border-radius: 10px 0 0 10px;

        &:hover {
          text-decoration: underline;
        }
      }

      & > td {
        cursor
        border: none;
        // text-align: center;
      }

      & > td:last-of-type {
        border-radius: 0 10px 10px 0;
      }

      &:hover {
        box-shadow: 0px 0px 3px 6px rgba(143, 207, 116, 1);
      }
    `,
    table: css`
      border-collapse: separate;
      border-spacing: 0 20px;

      th {
        text-transform: capitalize;
      }
    `,
}

export default styles
