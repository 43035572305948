import React from 'react'
import {Flex, SimpleGrid, GridItem} from '@chakra-ui/react'
import {InputLabel} from '../../components/Theme'

const EuAreaInput = ({register, idx,data}) => (
    <Flex>
        <Flex
            justifyContent="space-between"
            flexDirection="column"
            mt="5rem"
            w="100%">
            <SimpleGrid
                mb="6"
                columns={{
                    base: 2,
                    md: 4,
                }}
                gap={6}>
                <GridItem>
                    <InputLabel
                        value={data?.ceilingHeight}
                        label="Ceiling Height:"
                        disabled/>
                </GridItem>
                <GridItem>
                    <InputLabel
                        value={data?.dailyUsage}
                        label="Daily Usage (hours):"
                        disabled/>
                </GridItem>
                <GridItem>
                    <InputLabel
                        value={data?.application}
                        placeholder="Select"
                        label="Application:"
                        disabled/>
                </GridItem>
                <GridItem>
                    <InputLabel
                        value={data?.voltage}
                        placeholder="Select"
                        label="Voltage:"
                        disabled/>
                </GridItem>
                <GridItem>
                    <InputLabel
                        value={data?.dimming}
                        placeholder="Select"
                        label="Dimming:"
                        disabled/>
                </GridItem>
                <GridItem>
                    <InputLabel
                        value={data?.installationSurface}
                        placeholder="Select"
                        label="Installation Surface:"
                        disabled/>
                </GridItem>
                <GridItem>
                    <InputLabel
                        value={data?.controlType}
                        placeholder="Select"
                        label="Control Type:"
                        disabled/>
                </GridItem>
                <GridItem>
                    <InputLabel
                        value={data?.ingressProtection}
                        placeholder="Select"
                        label="Ingress Protection:"
                        disabled/>
                </GridItem>
            </SimpleGrid>
            <InputLabel
                label="Notes:"
                value={data?.notes}
                disabled/>
        </Flex>
    </Flex>
)

export default EuAreaInput
