import React from 'react'
import { Pie } from 'react-chartjs-2'
import { Flex } from '@chakra-ui/react'

const ChartInterests = ({ score }) => {
    // defaults.global.animation = undefined
    const {
        architecture,
        engineering,
        environment,
        energy,
        education,
        cyrcadian,
        illumination,
        property,
        technology,
        horticulture,
    } = score
    let empty = 100
    const data = {
        datasets: [
            {
                label: '# of Votes',
                data: Object.values(score)
                    .reduce((total, currentValue) => (
                        total + currentValue), 0) === 0
                    ? [100]
                    : [
                        architecture,
                        cyrcadian,
                        engineering,
                        illumination,
                        environment,
                        property,
                        energy,
                        technology,
                        education,
                        horticulture,
                        empty -= (horticulture + technology + architecture + engineering + environment + energy + education + cyrcadian + illumination + property),
                    ],
                backgroundColor: Object.values(score)
                    .reduce((total, currentValue) => (
                        total + currentValue), 0) === 0
                    ? ['#d9d9d9']
                    : [
                        '#FF8EFA',
                        '#FF7F56',
                        '#C1DBFF',
                        '#C2EF62',
                        '#5DE2CA',
                        '#DD9323',
                        '#F0D533',
                        '#4A85B4',
                        '#C956FF',
                        '#2E8A07',
                        '#d9d9d9',
                    ],
                borderWidth: 0,
            },
        ],
    }
    const options = {
        maintainAspectRatio: false,
        animation: false,
        responsive: true,
        tooltips: {
            callbacks: {
                title(tooltipItem) {
                    if (Object.values(score)
                        .reduce((total, currentValue) => (
                            total + currentValue), 0) === 0) {
                        return '0'
                    }
                    return Object.keys(score)[tooltipItem[0].index]
                },
                label(tooltipItem, newLabel) {
                    if (Object.values(score)
                        .reduce((total, currentValue) => (
                            total + currentValue), 0) === 0) {
                        return '0'
                    }
                    return `${newLabel['datasets'][0]['data'][tooltipItem['index']]}%`
                },
            },
        },
        hover: { mode: null },
    }

    return (
        <Flex
            width="349px"
            height="349px">
            <Pie
                width={1000}
                height={1000}
                options={options}
                data={data}
                redraw />
        </Flex>
    )
}

export default ChartInterests
