import {Button, Flex, Modal, ModalBody, ModalContent, ModalHeader, ModalOverlay, Text} from "@chakra-ui/react";
import IconButton from "../../components/Theme/IconButton";
import Icon from "../../components/Theme/Icon";
import React from "react";
import {RemoveProduct} from "../../constants/mainFunctions";

const RemoveProductModal = ({history, onClose, isOpen, productId, table, isDeleting, setIsDeleting}) => (
    <Modal isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay/>
        <ModalContent overflow="hidden" borderRadius="10px" minW="520px">
            <ModalHeader bg='#FF9494' fontFamily='Titillium Web' fontSize="24px" textAlign="center">
                Are you sure?
            </ModalHeader>
            <IconButton height="unset" onClick={onClose} _hover={{bg: 'none',}} _active={{bg: 'none',}} bg="none" aria-label="close button" position="absolute" top="1.5rem" right="0.75rem" icon={<Icon type="darkClose"/>}/>
            <ModalBody fontSize='24px' textAlign='center' overflow="scroll" maxH="600px">
                <Text fontFamily='Titillium Web'>
                    Are you sure you want to delete this product?
                    This will remove it as a recommendation option for active projects.
                </Text>
                <Flex fontFamily='Titillium Web' width='100%' justifyContent='space-between' mt={10}>
                    <Button isLoading={isDeleting} fontSize='14px' bg='#FB7575' color='black' width='230px' height='50px' onClick={() => {
                        setIsDeleting(true)
                        RemoveProduct(productId, table).then(() => {
                            setIsDeleting(false)
                            history.push('/catalogues')
                        })}}>
                        Yes, Delete Product
                    </Button>
                    <Button fontSize='14px' bg='#DBDADA' color='black' width='230px' height='50px' onClick={onClose}>
                        Nevermind, Let’s Go Back
                    </Button>
                </Flex>
            </ModalBody>
        </ModalContent>
    </Modal>
)

export default RemoveProductModal