import {useQuery} from 'react-query'
import {productUseCase} from '../../factories'

const useGetRecommended = (variables) => {
    const {
        refetch, isLoading, data, error
    } = useQuery('recommendedLamp', () => productUseCase.getRecommended(variables), {refetchOnWindowFocus: false})

    return {
        refetch,
        isLoading,
        error,
        data
    }
}

export default useGetRecommended
