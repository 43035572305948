import {Box, Flex, Heading, Image, Text} from "@chakra-ui/react";
import {S3_BUCKET} from "../../../constants";
import CompanyChart from "../DataCharts/CompanyChart";
import ChartProjects from "../DataCharts/ChartProjects";
import CompanyChartSupplier from "../DataCharts/CompanyChartSupplier";
import ActiveUsersUsageChart from "../DataCharts/ActiveUsersUsageChart";

const Supplier = ({
                      proposals,
                      pipeline,
                      companyFootage,
                      usage,
                      companyColors,
                      totalProducts
                  }) => {
    return (
        <Box>
            <Flex mt={8} borderRadius='10px' pl={4} alignItems='center' minH='57px' bg='#E6E6E6'>
                <Heading m={0} fontSize='24px'>Total Products</Heading>
            </Flex>
            <Flex mt={9}>
                <Flex flexDirection='column' width='594px'>
                    <Flex pl={4} justifyContent='space-between'>
                        <Heading m={0} fontSize='16px' width='10%'>Color</Heading>
                        <Heading m={0} fontSize='16px' pl={3} width='50%'>Company</Heading>
                        <Heading m={0} fontSize='16px' pl={3} width='40%'>Products</Heading>
                    </Flex>
                    {
                        totalProducts?.map((company, idx) => (
                            <Flex width='594px' fontSize='18px' key={idx} mt={4} pl={4} alignItems='center' minH='57px' bg='#FFFFFF'
                                  boxShadow='2px 4px 10px rgba(0, 0, 0, 0.25)' borderRadius='10px'
                                  justifyContent='space-between'>
                                <Flex width='10%' pl={3} alignItems='center' height='100%'
                                      borderLeft={`12px solid ${companyColors[idx]}`}>
                                </Flex>
                                <Flex pl={3} alignItems='center' width='50%'>
                                    <Image width='50px' height='40px' mr={2}
                                           src={S3_BUCKET + company[0].image}/>
                                    {company[0]?.name.replace(/(.{20})..+/, "$1…")}
                                </Flex>
                                <Flex pl={3} width='40%'>{Number(company?.totalSize)?.toLocaleString()}</Flex>
                            </Flex>
                        ))
                    }
                </Flex>
                <Flex alignItems='center' flexDirection='column'>
                    <Heading fontSize='16px' m={0}>Total Products</Heading>
                    <CompanyChartSupplier
                        width='519px'
                        height='519px'
                        colors={companyColors}
                        score={totalProducts}/>
                </Flex>
            </Flex>
            <Flex mt={8} borderRadius='10px' pl={4} alignItems='center' minH='57px' bg='#E6E6E6'>
                <Heading m={0} fontSize='24px'>Total Amount Sold</Heading>
            </Flex>
            <Flex mt={6} height="100%" alignItems="center" justifyContent="space-evenly">
                <Flex fontFamily='Titillium Web' fontSize='16px' style={{writingMode: 'tb-rl'}} justifyContent='center'
                      alignItems='center' transform='rotate(-180deg)'>
                    Dollars
                </Flex>
                <ChartProjects projects={pipeline}/>
            </Flex>
            <Flex mt={10} borderRadius='10px' pl={4} alignItems='center' minH='57px' bg='#E6E6E6'>
                <Heading m={0} fontSize='24px'>Luminaire Types Sold</Heading>
            </Flex>
            <Flex mt={9}>
                <Flex justifyContent='space-evenly' width='55%'>
                    <Flex alignItems='center' flexDirection='column'>
                        <Text fontWeight='700'>Total Luminaires Sold By Type</Text>
                        <CompanyChart width='450px' height='450px' colors={companyColors} score={companyFootage}/>
                    </Flex>
                </Flex>
                <Flex width='45%'>
                    <Flex width='full' flexDirection='column'>
                        <Flex pl={4} fontWeight='700' justifyContent='space-between'>
                            <Flex width='10%'>Color</Flex>
                            <Flex pl={3} width='50%'>Company</Flex>
                            <Flex pl={3} width='40%'>Total Luminaires Sold</Flex>
                        </Flex>
                        {companyFootage?.map((company, idx) => (
                            <Flex key={idx} mt={4} pl={4} alignItems='center' minH='57px' bg='#FFFFFF'
                                  boxShadow='2px 4px 10px rgba(0, 0, 0, 0.25)' borderRadius='10px'
                                  justifyContent='space-between'>
                                <Flex width='10%' pl={3} alignItems='center' height='100%'
                                      borderLeft={`12px solid ${companyColors[idx]}`}>
                                </Flex>
                                <Flex pl={3} alignItems='center' width='50%'>
                                    <Image width='50px' height='40px' mr={2}
                                           src={company[0]?.image ? S3_BUCKET + company[0]?.image : ' '}/>
                                    {company[0]?.name.replace(/(.{15})..+/, "$1…")}
                                </Flex>
                                <Flex pl={3} width='40%'>{Number(company?.totalSize)?.toLocaleString()}</Flex>
                            </Flex>
                        ))}
                    </Flex>
                </Flex>
            </Flex>
            <Flex mt={8} borderRadius='10px' pl={4} alignItems='center' minH='57px' bg='#E6E6E6'>
                <Heading m={0} fontSize='24px'>Total Luminaires Sold</Heading>
            </Flex>
            <Flex mt={6} height="100%" alignItems="center" justifyContent="space-evenly">
                <Flex fontFamily='Titillium Web' fontSize='16px' style={{writingMode: 'tb-rl'}} justifyContent='center'
                      alignItems='center' transform='rotate(-180deg)'>
                    QTY
                </Flex>
                <ChartProjects projects={proposals}/>
            </Flex>
            <Flex mt={10} borderRadius='10px' pl={4} alignItems='center' minH='57px' bg='#E6E6E6'>
                <Heading m={0} fontSize='24px'>Most Active Users</Heading>
            </Flex>
            <Flex mt={9}>
                <Flex width='45%'>
                    <Flex width='full' flexDirection='column'>
                        <Flex pl={4} fontWeight='700' justifyContent='space-between'>
                            <Flex width='10%'>Color</Flex>
                            <Flex pl={3} width='20%'>Company</Flex>
                            <Flex pl={3} width='30%'>Users</Flex>
                            <Flex pl={3} width='30%'>Active Hours</Flex>
                        </Flex>
                        {usage?.map((company, idx) => (
                            <Flex key={idx} mt={4} pl={4} alignItems='center' minH='57px' bg='#FFFFFF'
                                  boxShadow='2px 4px 10px rgba(0, 0, 0, 0.25)' borderRadius='10px'
                                  justifyContent='space-between'>
                                <Flex width='10%' pl={3} alignItems='center' height='100%'
                                      borderLeft={`12px solid ${companyColors[idx]}`}>
                                </Flex>
                                <Flex pl={3} alignItems='center' width='20%'>
                                    <Image
                                        objectFit='cover'
                                        width='50px'
                                        height='40px'
                                        mr={2}
                                        src={company?.companyLogo ? S3_BUCKET + company?.companyLogo : ' '}/>
                                </Flex>
                                <Flex alignItems='center' pl={3}
                                      width='30%'>{company?.userName?.replace(/(.{15})..+/, "$1…")}</Flex>
                                <Flex pl={3} width='30%'>{(Number(company?.total) / 60).toFixed(2)}</Flex>
                            </Flex>
                        ))}
                        <Flex mt={4} pl={4} alignItems='center' minH='57px' border='2px solid #2E8A07' bg='#FFFFFF'
                              boxShadow='2px 4px 10px rgba(0, 0, 0, 0.25)' borderRadius='10px' fontWeight='700'
                              justifyContent='space-between'>
                            <Flex color='#2E8A07' width='70%' pl={3} alignItems='center' height='100%'>
                                Total
                            </Flex>
                            <Flex pl={3} width='30%'>
                                {(() => {
                                    let countUsersCompany = 0
                                    if (usage?.length > 0) {
                                        usage?.map((a) => {
                                            countUsersCompany += (Number(a.total) / 60)
                                        })
                                        return countUsersCompany.toFixed(2)
                                    }
                                })()}
                            </Flex>
                        </Flex>
                    </Flex>
                </Flex>
                <Flex justifyContent='space-evenly' width='55%'>
                    <Flex alignItems='center' flexDirection='column'>
                        <Text fontWeight='700'>Most Active Users</Text>
                        {usage && (
                            <ActiveUsersUsageChart
                                width='450px'
                                height='450px'
                                colors={companyColors}
                                score={usage}/>
                        )}
                    </Flex>
                </Flex>
            </Flex>
        </Box>
    )
}

export default Supplier