import {Text, Input, Flex, Box, SimpleGrid,} from '@chakra-ui/react'

const Area = ({recentProposal, areaName, data}) => {
    if (areaName && areaName !== data.title) return <></>
    return (
        <>
            <Flex alignItems="center" justifyContent="space-between" height="37px" flex="1">
                <Flex alignItems="center">
                    <Input readOnly color="#193C5B" variant="unstyled" value={data.title} maxW="150px" fontWeight='700' fontSize='18px'/>
                </Flex>
            </Flex>
            <Box border='1px solid #9CBDD8' mt={2} p={3}>
                <Flex color='#2C628D' fontWeight='600' fontSize='14px' justifyContent='space-between' width='full'>
                    <Text width='25%'>
                        Ceiling Height:
                        <Text as='span' ml={1} fontWeight='normal' color='black'>
                            {data?.ceilingHeight ? data?.ceilingHeight + "'" : ''}
                        </Text>
                    </Text>
                    <Text width='25%'>
                        Daily Usage:
                        <Text as='span' ml={1} fontWeight='normal' color='black'>
                            {data?.dailyUsage ? data?.dailyUsage + ' Hours' : ''}
                        </Text>
                    </Text>
                    <Text width='25%'>
                        Control Type:
                        <Text as='span' ml={1} fontWeight='normal' color='black'>
                            {data?.controlType}
                        </Text>
                    </Text>
                    <Text width='25%'>
                        Application:
                        <Text as='span' ml={1} fontWeight='normal' color='black'>
                            {data?.application}
                        </Text>
                    </Text>
                </Flex>
                <Flex color='#2C628D' fontWeight='600' fontSize='14px' justifyContent='space-between' width='full' mt={1}>
                    <Text width='25%'>
                        Voltage:
                        <Text as='span' ml={1} fontWeight='normal' color='black'>
                            {data?.voltage ? data?.voltage : ''}
                        </Text>
                    </Text>
                    <Text width='25%'>
                        Area (sq.ft.):
                        <Text as='span' ml={1} fontWeight='normal' color='black'>
                            {data?.areaSqFt}
                        </Text>
                    </Text>
                    <Text width='25%'>
                        Installation Surface:
                        <Text as='span' ml={1} fontWeight='normal' color='black'>
                            {data?.installationSurface}
                        </Text>
                    </Text>
                    <Text width='25%'>
                        Ingress Protection:
                        <Text as='span' ml={1} fontWeight='normal' color='black'>
                            {data?.ingressProtection ? data?.ingressProtection : ''}
                        </Text>
                    </Text>
                </Flex>
                <Flex color='#2C628D' fontWeight='600' fontSize='14px' width='full' mt={1}>
                    <Text>
                        Notes:
                        <Text as='span' ml={1} fontWeight='normal' color='black'>
                            {data?.notes ? data?.notes : ''}
                        </Text>
                    </Text>
                </Flex>
            </Box>
            <SimpleGrid my="2" w="full" columns={{base: 1}}>
                {recentProposal?.luminaires?.filter((filtered) => filtered.lampRecomendedAirtable || filtered.fixtureRecomendedDescription || filtered.controlRecomendedDescription || filtered.powerSupplyRecomendedDescription || filtered.accessoryRecomendedDescription).map((luminaire) => (
                        <Flex mb={2} key={luminaire.id}>
                            <Box mr={6} p={1} minH='45px' flex={1}  border="1px solid #C4C4C4" fontSize="12px" color='#757575'>
                                ({(luminaire.qty ? luminaire.qty : 1) * (luminaire.lampPerFixture ? luminaire.lampPerFixture : 1)}) &nbsp;
                                {
                                    luminaire.fixtureRecomendedDescription ? (luminaire.fixtureDescription ? luminaire.fixtureDescription : '') : luminaire.lampRecomendedDescription ?
                                        (luminaire.lampDescription ? luminaire.lampDescription : '') : ''
                                }
                            </Box>
                            <Box p={1} flex={1} minH='45px' border="1px solid #8FCF74" fontSize="12px" color='#428027'>
                                ({ ((luminaire.recomendedLampQty ? Number(luminaire.recomendedLampQty) : (luminaire.lampPerFixture ? luminaire.lampPerFixture : 1)) * (luminaire.recomendedFixtureQty ? Number(luminaire.recomendedFixtureQty) : (luminaire.qty ? Number(luminaire.qty) : 1))) })&nbsp;
                                {luminaire?.fixtureRecomendedDescription ? luminaire?.fixtureRecomendedDescription : ''}
                                {luminaire?.lampRecomendedDescription ? luminaire?.lampRecomendedDescription : ''}
                                {luminaire?.fixtureMountHeight ? '{'+luminaire?.fixtureMountHeight+'}' : ''}
                            </Box>
                        </Flex>
                    ))}
            </SimpleGrid>
        </>
    )
}

export default Area