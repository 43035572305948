import {Flex, Container, List, ListItem, Button, Menu, MenuButton, MenuList, MenuItem,} from '@chakra-ui/react'
import {Link} from 'react-router-dom'
import {Icon} from '../../../components/Theme'
import styles from './styles'
const { REACT_APP_WEB_LINK } = process.env;

const Header = () => (
    <Container height="115px" position="absolute" top="0" maxW="7xl">
        <Flex height="100%" justifyContent="space-between" alignItems="center">
            <img width="125px" height="auto" alt="logo" src="/images/logo.png"/>
            <List css={styles.list}>
                <ListItem alignItems="center" display="flex">
                    <Menu>
                        <MenuButton
                            css={styles.menu}
                            as={Button}
                            rightIcon={(<Icon width="12px" height="12px" type="down"/>)}>
                            Solutions
                        </MenuButton>
                        <MenuList fontFamily='Frank Ruhl Libre, serif' css={styles.menuList}>
                            <MenuItem><a href={`${REACT_APP_WEB_LINK}/ep-overview-2`}>The Energy Portal</a></MenuItem>
                            <MenuItem><a href={`${REACT_APP_WEB_LINK}/advantages-of-led`}>LED Upgrade Advantages</a></MenuItem>
                            <MenuItem><a href={`${REACT_APP_WEB_LINK}/solutions/real-estate`}>Real Estate</a></MenuItem>
                            <MenuItem><a href={`${REACT_APP_WEB_LINK}/solutions/led-suppliers`}>LED Suppliers</a></MenuItem>
                            <MenuItem><a href={`${REACT_APP_WEB_LINK}/solutions/architects-electrical-engineers`}>Architects & Electrical Engineers</a></MenuItem>
                            <MenuItem><a href={`${REACT_APP_WEB_LINK}/giving-back-2`}>Giving Back</a></MenuItem>
                        </MenuList>
                    </Menu>
                </ListItem>
                <ListItem>
                    <Menu>
                        <MenuButton
                            css={styles.menu}
                            as={Button}
                            rightIcon={(<Icon width="12px" height="12px" type="down"/>)}>
                            Sectors
                        </MenuButton>
                        <MenuList css={styles.menuList}>
                            <MenuItem><a href={`${REACT_APP_WEB_LINK}/sectors/office`}>Office</a></MenuItem>
                            <MenuItem><a href={`${REACT_APP_WEB_LINK}/sectors/industrial`}>Industrial</a></MenuItem>
                            <MenuItem><a href={`${REACT_APP_WEB_LINK}/sectors/exterior`}>Exterior</a></MenuItem>
                            <MenuItem><a href={`${REACT_APP_WEB_LINK}/sectors/parking`}>Parking</a></MenuItem>
                            <MenuItem><a href={`${REACT_APP_WEB_LINK}/sectors/multi-family-residential`}>Residential</a></MenuItem>
                            <MenuItem><a href={`${REACT_APP_WEB_LINK}/sectors/hospitality`}>Hospitality</a></MenuItem>
                            <MenuItem><a href={`${REACT_APP_WEB_LINK}/sectors/healthcare`}>Healthcare</a></MenuItem>
                            <MenuItem><a href={`${REACT_APP_WEB_LINK}/sectors/cannabis`}>Cannabis</a></MenuItem>
                            <MenuItem><a href={`${REACT_APP_WEB_LINK}/sectors/horticulture`}>Horticulture</a></MenuItem>
                        </MenuList>
                    </Menu>
                </ListItem>
                <ListItem fontWeight='500' fontFamily='Frank Ruhl Libre, serif' fontSize='16px !important'>
                    <a href={`${REACT_APP_WEB_LINK}/insights`}>Insights</a>
                </ListItem>
                <ListItem fontWeight='500' fontFamily='Frank Ruhl Libre, serif' fontSize='16px !important'>
                    <a href={`${REACT_APP_WEB_LINK}/contact`}>Contact</a>
                </ListItem>
                <ListItem ml='150px'>
                    <Link to='/register'>
                        <Button
                            _hover={{transition: '0.2s', color: 'white', backgroundPosition: '-100% 0'}}
                            css={styles.loginButton}>
                            <h2>Get Started</h2>
                        </Button>
                    </Link>
                    <Link to="/login">
                        <Button
                            _hover={{transition: '0.2s', color: 'white', backgroundPosition: '-100% 0'}}
                            css={styles.loginButton}>
                            <h2>Login</h2>
                        </Button>
                    </Link>
                </ListItem>
            </List>
        </Flex>
    </Container>
)

export default Header
