import {useMutation} from 'react-query'
import {productUseCase} from '../../factories'

const useCreateProduct = () => {
    const {
        mutate: createProduct, isLoading, data, error
    } = useMutation(productUseCase.createProduct)

    return {
        createProduct,
        isLoading,
        error,
        data
    }
}

export default useCreateProduct
