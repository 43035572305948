import React from 'react'
import Overview from '../../components/Overview'

function Report() {
    return (
        <Overview/>
    )
}

export default Report
