import {InputLabel, InviteModal} from "../../components/Theme";
import {Box, Button, Flex, HStack, VStack} from "@chakra-ui/react";
import {useEffect, useState} from "react";
import {useUpdatePriceBid} from "../../core/hooks";
import {ReactComponent as ReviewIcon} from "../../assets/Icons/reviewIcon.svg";


const CustomBidModal = ({isOpen, onClose, led, refetchProject, refetch}) => {
    const [price, setPrice] = useState('')
    const {updatePrice, isLoading,} = useUpdatePriceBid()

    const sendPrice = () => {
        updatePrice({
            price: price,
            recordId: led && led['id'],
        }, {
            onSuccess: () => {
                refetch()
                onClose()
            }
        })
    }

    useEffect(() => {
        if (!led) return
        if (led['fields']['SU PPU ($)']) setPrice(led['fields']['SU PPU ($)'])
    }, [led]);

    return (
        <InviteModal
            isOpen={isOpen}
            onClose={onClose}
            minW='992px'
            bg="#FFE68D"
            title="Custom Bid Pricing">
            {
                led && (
                    <VStack px={10} py={7} m='auto'>
                        <HStack justifyContent='space-between' w='full'>
                            <Box position='relative'>
                                <InputLabel
                                    disabled={true}
                                    maxW='217px'
                                    height='46px'
                                    value={led['fields']['New QTY'] ? led['fields']['New QTY'] : led['fields']['QTY']}
                                    label='Quantity (QTY):'
                                />
                                <Flex position='absolute'
                                      right='-7px'
                                      top='22px'
                                      display={led['fields']['New QTY'] ? 'flex' : 'none'}
                                      alignItems='center' justifyContent='center'
                                      backgroundColor='#FF9B9B' width='16px' height='16px' borderRadius='50%'>
                                    <ReviewIcon/>
                                </Flex>
                            </Box>

                            <InputLabel
                                disabled={true}
                                value={led['fields']['Product Description']}
                                maxW='634px'
                                height='46px'
                                label='Recommended Product:'
                            />
                        </HStack>
                        <HStack justifyContent='space-between' w='full' alignItems='end'>
                            <InputLabel
                                height='46px'
                                type='number'
                                defaultValue={led['fields']['SU PPU ($)']}
                                onChange={(e) => setPrice(e.target.value)}
                                label='Price Per Unit ($): '
                                maxW='217px'
                            />
                            <Button isLoading={isLoading} onClick={sendPrice} height='46px' fontSize='xl' width='634px'
                                    color='black' bg='#FFE68D'>
                                Submit Bid
                            </Button>
                        </HStack>
                    </VStack>
                )
            }
        </InviteModal>
    )
}

export default CustomBidModal