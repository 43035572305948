import React, {useState, useRef, useEffect} from 'react'
import {utils, read} from 'xlsx'
import {Box, Text, Flex, Button as ChakraButton, Stack,} from '@chakra-ui/react'
import {DragDrop} from '../../../components/Theme'
import History from '../../Upload/History'
import {useListUploadHistories, useUser, useCreateProduct, useCreateUploadHistory, useUpload, useCreateAccessory,} from '../../../core/hooks'
import {ReactComponent as Search} from '../../../assets/search.svg'
import uploadFileFunction from '../../Upload/uploadFileFunction'

const XLSX = {utils, read}

const EuUploadsBid = () => {
    const [loadedHistory, setLoadedHistory] = useState(false)
    const [historyData, setHistoryData] = useState([])
    const [success, setSuccess] = useState(false)
    const [uploadingError, setUploadingError] = useState(false)
    const {user} = useUser()
    const [errors, setErrors] = useState([])
    const {createProduct, isLoading: isCreatingLoading,} = useCreateProduct()
    const {createAccessory, isLoading: isCreatingAccessoryLoading,} = useCreateAccessory()
    const {createUploadHistory} = useCreateUploadHistory()
    const {data: uploadResponse, isLoading, upload} = useUpload()
    const {listUploadHistories, data: historyResponse} = useListUploadHistories()
    const inputRef = useRef()

    const handleFile = (file) => uploadFileFunction(file, setErrors, XLSX, upload, user, createProduct, setSuccess, setUploadingError, createAccessory)
    const handleChange = (e) => {
        const {files} = e.target
        if (files[0].type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
            return
        }
        if (files && files[0]) {
            handleFile(files[0])
        }
    }

    useEffect(() => {
        listUploadHistories({
            filter: {
                userId: {
                    eq: user?.id,
                },
            },
        })
    }, [loadedHistory])

    useEffect(() => {
        if (!uploadResponse || !user) return

        const input = {
            name: uploadResponse,
            userId: user?.id,
        }

        createUploadHistory(
            input,
            {
                onSuccess: () => {
                    setErrors([])
                    setLoadedHistory(true)
                },
                onError: (uploadError) => console.log(uploadError),
            },
        )
    }, [uploadResponse])

    useEffect(() => {
        setHistoryData(historyResponse?.data.data?.items)
    }, [historyResponse, historyData])

    return (
        <>
            <Box minW="850px" m="auto" maxW="1240px" bg="#f5f5f5" px={6}>
                <Flex my={5} alignItems="center" justifyContent="space-between" w="100%">
                    <Text mb={8} fontSize="xl">
                        Drag and drop pertinent files into the designated area (below) or browse your device (blue button).
                    </Text>
                    <Flex flexDirection='column'>
                        <input onChange={handleChange} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ref={inputRef} style={{display: 'none'}} type="file"/>
                        <ChakraButton leftIcon={<Search/>} onClick={() => inputRef.current.click()} minW="251px" fontSize="xl" size="lg" variant="blue">
                            Browse Files
                        </ChakraButton>
                    </Flex>
                </Flex>
                <DragDrop
                    isCreatingLoading={isCreatingLoading}
                    isCreatingAccessoryLoading={isCreatingAccessoryLoading}
                    success={success}
                    error={uploadingError}
                    isLoading={isLoading}
                    handleFile={handleFile}/>
                {
                    errors.length > 0 && (
                        errors.map((error) => {
                            if (error === 'intro') {
                                return (
                                    <Text key={error} color="red.100">Company fields are all required</Text>
                                )
                            }
                            if (error === 'accessories') {
                                return (
                                    <Text key={error} color="red.100">
                                        Fill all required fields of Accessories
                                    </Text>
                                )
                            }
                            if (error === 'minimum') {
                                return (
                                    <Text
                                        key={error}
                                        color="red.100">
                                        Products (lamp, fixture) or Accessories (accessory, power supply, control) Sheet is
                                        required
                                    </Text>
                                )
                            }
                            if (error === 'luminaires') {
                                return (
                                    <Text
                                        key={error}
                                        color="red.100">
                                        Fill all required fields of Products (Fixtures or Lamps)
                                    </Text>
                                )
                            }
                            if (error === 'all') {
                                return (
                                    <Text
                                        key={error}
                                        color="red.100">
                                        Minimum 1 Sheet required
                                    </Text>
                                )
                            }
                        })
                    )

                }
                {historyData?.length > 0 && (
                        <>
                            <Box mb={4} display="block" mt={12} fontWeight="600" fontSize="xl" as="span">
                                Uploaded Files
                            </Box>
                            <Stack spacing={4}>
                                {historyData.map((history) => (
                                        <History
                                            key={history.file + history.createdAt}
                                            history={history}/>
                                    ))
                                }
                            </Stack>
                        </>
                    )}
            </Box>
        </>
    )
}

export default EuUploadsBid
