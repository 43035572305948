const accessoryRepo = (accessoryService) => {
  const createAccessory = (input) => accessoryService.createAccessory(input)

  const updateAccessory = (input) => accessoryService.updateAccessory(input)

  const listAccessories = (variables) => accessoryService.listAccessories(variables)

  const getAccessory = (id) => accessoryService.getAccessory(id)

  return {
    createAccessory,
    updateAccessory,
    listAccessories,
    getAccessory,
  }
}

export default accessoryRepo
