const validateEmail = (email) => {
  if (
    /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(
      email,
    )
  ) {
    return true
  }
  return false
}

const authUseCase = (authRepo) => {
  const signIn = async ({ email, password }) => {
    if (!email || !password) {
      throw Error('Credentials cannot be empty')
    }

    if (!validateEmail(email)) {
      throw Error('Wrong email format')
    }

    return authRepo.signIn({
      email,
      password,
    })
  }

  const signUp = async (input) => {
    if (!input.email || !input.password) {
      throw Error('Credentials cannot be empty')
    }

    if (!validateEmail(input.email)) {
      throw Error('Wrong email format')
    }
    const response = await authRepo.signUp(input)

    return response
  }

  const signOut = async () => {
    try {
      await authRepo.signOut()
      localStorage.removeItem('token')
      localStorage.removeItem('refresh_token')
      return true
    } catch (e) {
      return e
    }
  }

  const confirmCode = async ({ email, code }) => {
    if (!email || !code) {
      throw Error('Credentials cannot be empty')
    }

    if (!validateEmail(email)) {
      throw Error('Wrong email format')
    }

    const response = await authRepo.confirmCode({
      email,
      code,
    })

    return response
  }

  const resendConfirmCode = async ({ email }) => {
    if (!validateEmail(email)) {
      throw Error('Wrond email format')
    }

    const response = await authRepo.resendConfirmCode({ email })

    return response
  }

  const changePassword = ({ oldPassword, newPassword }) => {
    if (!oldPassword || !newPassword) {
      throw Error('Old and new passwords are required')
    }

    if (oldPassword === newPassword) {
      throw Error('Same password')
    }

    return authRepo.changePassword({
      oldPassword,
      newPassword,
    })
  }

  const forgotPassword = async ({ email }) => {
    if (!email) {
      throw Error('Email cannot be empty')
    }
    if (!validateEmail(email)) {
      throw Error('Wrong email format')
    }

    const response = await authRepo.forgotPassword({ email })

    return response
  }

  const resetPassword = async ({ email, code, password }) => {
    if (!email || !code || !password) {
      throw Error('Credentials are required')
    }

    if (!validateEmail(email)) {
      throw Error('Wrong email format')
    }

    const response = await authRepo.resetPassword({
      email,
      code,
      password,
    })

    return response
  }

  return {
    signIn,
    signUp,
    signOut,
    confirmCode,
    resetPassword,
    resendConfirmCode,
    forgotPassword,
    changePassword,
  }
}

export default authUseCase
