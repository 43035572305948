import React, {useState} from 'react'
import {
    useDisclosure,
    Button,
    Flex,
    Text,
    Input,
    Select,
    Stack, useToast, Box,
} from '@chakra-ui/react'
import {InviteModal} from '../../components/Theme'
import {ReactComponent as Plus} from '../../assets/plus.svg'
import styles from './styles'
import {useInviteUser} from "../../core/hooks";
import {ReactComponent as InvitationIcon} from "../../assets/invitationSendIcon.svg";

const USER_TYPES = [
    'Real Estate',
    'Supplier',
    'Electrician',
    'General',
    'Administrator',
    'Sales',
]

const InviteUserModal = () => {
    const {isOpen, onOpen, onClose} = useDisclosure()
    const [email, setEmail] = useState('')
    const [error, setError] = useState('')
    const [userType, setUserType] = useState('')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const {inviteUser, isLoading} = useInviteUser()
    const toast = useToast()

    const sendInvite = () => {
        if (userType === '' || email === '') {
            return setError('Choose user type')
        }
        setError('')
        inviteUser({
                receiver: email,
                url: `${process.env.REACT_APP_API_ENDPOINT.replace("api/", "register/")}${userType}`,
                senderName: firstName,
                senderLastName: lastName,
            },
            {
                onSuccess: () => {
                    toast({
                        render: () => (
                            <Flex
                                overflow='hidden'
                                position='relative'
                                justifyContent='center'
                                flexDirection='column'
                                borderRadius='4px 0px 0px 4px'
                                mt='80px'
                                color='white'
                                bg='#2C628D'
                                pl={3}
                                width='530px'
                                fontWeight='600'
                                fontSize='14px'
                                height='40px'>
                                <Flex
                                    alignItems='center'>
                                <InvitationIcon/>
                                <Text
                                    mt={1}
                                    ml={2}>
                                    Invitation sent To {firstName} {lastName}!
                                </Text>
                                </Flex>
                                <Box
                                    css={styles.toast}
                                    position='absolute'
                                    left='5px'
                                    bottom='0'
                                    mt={1}
                                    borderRadius='6px'
                                    backgroundColor='#D5D5D5'
                                    height='3px'
                                    width='40px'>
                                </Box>
                            </Flex>
                        ),
                        duration: 4000,
                        isClosable: true,
                        position: "top-right"
                    })
                    onClose()
                },
                onError: (e) => {
                    if (e.response.data.errorMessage === 1109) {
                        setError('*This email is already active in the system.')
                    }
                },
            })
    }

    return (
        <>
            <Button
                onClick={onOpen}
                mx={5}
                minW="173px"
                height='30px'
                size="sm"
                leftIcon={<Plus/>}
                variant="green">
                Add New User
            </Button>
            <InviteModal
                title="Add New User"
                isOpen={isOpen}
                onClose={onClose}>
                <Flex
                    alignItems="center"
                    flexDirection="column">
                    <Text
                        my={12}
                        fontSize="3xl">
                        Invite a new user to join the Energy Portal.
                    </Text>
                    <Stack
                        mx="auto"
                        w="418px"
                        spacing={4}>
                        <Input
                            fontSize='18px'
                            borderColor="#A9A9A9"
                            onChange={(e) => setFirstName(e.target.value)}
                            placeholder="First Name"/>
                        <Input
                            fontSize='18px'
                            borderColor="#A9A9A9"
                            onChange={(e) => setLastName(e.target.value)}
                            placeholder="Last Name"/>
                        <Input
                            fontSize='18px'
                            onChange={(e) => setEmail(e.target.value)}
                            borderColor="#A9A9A9"
                            placeholder="Email"/>
                        <Select
                            fontSize='18px'
                            border="1px solid"
                            borderColor="#A9A9A9"
                            onChange={(e) => {
                                if (e.target.value === 'Real Estate') {
                                    setUserType('Reu')
                                } else if (e.target.value === 'Supplier') {
                                    setUserType('Su')
                                } else if (e.target.value === 'Electrician') {
                                    setUserType('Eu')
                                } else if (e.target.value === 'Administrator') {
                                    setUserType('Au')
                                } else if (e.target.value === 'Sales') {
                                    setUserType('Sa')
                                } else if (e.target.value === 'General') {
                                    setUserType('Gu')
                                }
                            }}
                            width="full"
                            color='#A9A9A9'
                            css={styles.select}
                            placeholder="User Type">
                            {
                                USER_TYPES.map((user) => (
                                    <option
                                        key={user}
                                        value={user}>
                                        {user}
                                    </option>
                                ))
                            }
                        </Select>
                        {
                            error && (
                                <Text
                                    textAlign='center'
                                    color="red.100"
                                    fontSize="sm">
                                    {error}
                                </Text>
                            )
                        }
                    </Stack>
                    <Button
                        mx="auto"
                        mt={12}
                        fontSize='18px'
                        height='51px'
                        isLoading={isLoading}
                        fontWeight='700'
                        onClick={sendInvite}
                        mb={20}
                        width="254px"
                        large={+true}>
                        Submit
                    </Button>
                </Flex>
            </InviteModal>
        </>
    )
}

export default InviteUserModal
