import { css } from '@emotion/react'

const styles = {
  tr: css`
        height: 52px;
        & > td {
            font-size: 1rem;
            border: 6px solid;
            border-color: rgba(255, 255, 255, 1);
            padding-top: 0rem !important;
            padding-bottom: 0rem !important;
        }
        & > td:not(:first-of-type) {
            text-align: center;
        }
    `,
}

export default styles
